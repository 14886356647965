/*import AsyncStorage from '@react-native-async-storage/async-storage';*/
import {extend} from 'consistencss';
/*import {create, persist} from 'mobx-persist';*/
import {observer} from 'mobx-react-lite';
import React from 'react';
import {LogBox, Platform} from 'react-native';
/*import {dataStore, profileSchema} from './stores/profileStore';*/
import {colors, deviceWidth, isWeb} from './design/gStyles';
import {Navigator} from './routes';
import {ProfileStore} from './stores/profileStore';
/*import './index.css';*/

LogBox.ignoreLogs(['Warning:', 'Running ', 'WARN', '[mobx] Out of bounds read']);
LogBox.ignoreAllLogs();

if ((isWeb && process.env.NODE_ENV === 'production') || (!isWeb && __DEV__)) {
    console.log = () => {
    };
}
const guidelineBaseWidth = 350;
const scale = (size) =>
    (deviceWidth / guidelineBaseWidth) * size;

/*export const profile = persist(profileSchema)(dataStore);*/

/**Mobx Persist
 const hydrate = create({
  storage: isWeb ? localStorage : AsyncStorage,
  jsonify: true,
});
 hydrate('profile', profile).then(() => console.log('profile has been hydrated'));
 */
extend({
    colors: {...colors},
    /*sizing: {
        base: scale(4),
    },*/
    fonts: {
        primary: Platform.select({
            android: 'monospace',
            ios: 'San Francisco',
            default: 'sans-serif',
        }),
        title: 'Baloo2',
        body: 'Karla',
    },
});

export const profile = ProfileStore();
console.log = () => {
};

export default observer(() => <Navigator/>);
