import {useNavigation} from '@react-navigation/core';
import C, {apply} from 'consistencss';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {SafeAreaView, View} from 'react-native';
import {profile} from '../App';
import {CloseButton, ResourcesMap} from '../comp/Box';
import Cell from '../comp/Cell';
import EmojiBubble from '../comp/EmojiBubble';
import {absCenter, bordColor, bubblePos, colors, isWeb, shadow} from '../design/gStyles';
import {screens} from '../routes';
import {barcelona, MapCellWrapper, MapWrapper} from '../utils/MapUtils';
import {natureToResource} from '../utils/sets';

export default observer(() => {
    const currentBoard = profile.boards.worldMap;
    const {navigate, goBack} = useNavigation();
    const [showHarvest, setShowHarvest] = useState(false);

    useEffect(() => {
        /**Yourself => Todo user choose location*/
        currentBoard.setCell({buildIcon: '⛺️', isEvil: false, id: 0, flag: profile.flag});
        /**Mock data: Enemies*/
        currentBoard.setCell({buildIcon: '⛺️', isEvil: true, id: 17, flag: '🇫🇷'});
        currentBoard.setCell({buildIcon: '🛖', isEvil: true, id: 20, flag: '🇨🇭'});
        currentBoard.setCell({buildIcon: '🏰', isEvil: true, id: 36, flag: '🇩🇪'});
        currentBoard.setCell({buildIcon: '🏛', isEvil: true, id: 12, flag: '🇮🇹'});
        currentBoard.setCurrent(22);
    }, []);
    return (
        <SafeAreaView style={apply(C.flex, C.hFull, C.wFull)}>
            <MapWrapper
                onPress={() => setShowHarvest(false)}
                zoomMin={currentBoard.zoomMin}
                zoomMax={currentBoard.zoomMax}
                initPos={barcelona}>
                {currentBoard.cells.map((item, index) => {
                    const bgColor =
                        currentBoard.currCellId === index
                            ? colors.grass + '60'
                            : item.building
                            ? item.isEvil
                                ? colors.enemy + '30'
                                : colors.blue + (isWeb ? '60' : '60')
                            : 'transparent'; /*colors.white + '10'*/
                    return (
                        <MapCellWrapper
                            lat={item.coord.latitude}
                            lng={item.coord.longitude}
                            onPress={() => {
                                currentBoard.setCurrent(index);
                                if (item.building) {
                                    navigate(item.isEvil ? screens.Battle.name : screens.Village.name, {initPos: item.coord});
                                } else if (item.availResources) {
                                    navigate(screens.Collect.name, {
                                        harvestList: natureToResource(item.icon),
                                        practiceMode: false,
                                        isResource: true, //item === harvest,
                                        level: index,
                                    });
                                } else {
                                    profile.buildModal.toggleModal();
                                }
                            }}
                            coord={item.coord}>
                            <Cell
                                lat={item.coord.latitude}
                                lng={item.coord.longitude}
                                bg={bgColor}
                                cBord={bordColor(bgColor, 2)}
                                cShadow={shadow(item.building ? (item.isEvil ? colors.enemy : colors.blue) : 'transparent', 8)}
                                index={index}
                                showFlag
                                showRes
                                wrapStyle={[C.radius2]}
                                currCellId={currentBoard.currCellId}
                                item={item}
                            />
                        </MapCellWrapper>
                    );
                })}
            </MapWrapper>

            {/**Resources*/}
            <View style={absCenter}>
                <ResourcesMap list={profile.resources}/>
            </View>
            <View style={[bubblePos]}>
                <EmojiBubble
                    showOps
                    //chatMsg={'Add buildings 🏰, attack ⚔️ villages️, and conquer the world 🌎'}
                    onPressSettings={() => navigate(screens.Profile.name)}
                    onPressBuildings={(item) => profile.buyBuilding(item)}
                    currProfile={profile}
                    emoji={profile.emoji}
                    flag={profile.flag}
                />
            </View>
            {isWeb && <CloseButton onPress={() => navigate(screens.Onboarding)}/>}
        </SafeAreaView>
    );
});
