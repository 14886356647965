import {useNavigation} from '@react-navigation/core';
import C from 'consistencss';
import {observer} from 'mobx-react-lite';
import React, {Suspense, useState} from 'react';
import {FlatList, Image, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {Card} from '../comp/Card';
import {Footer, OnbHeader, TitleHeader} from '../comp/Header';

import {SideInfo} from '../comp/Slider';
import {EmojiCompat} from '../comp/WrapMoji';
import {bgColor, colors, deviceWidth, imgs, isWide} from '../design/gStyles';
import {fonts} from '../design/fonts';

import {conquestWorlds, screens, topGames} from '../routes';
import {availWorlds} from '../utils/sets';
import {Spinner} from '../comp/Box';

const LazyScoreBoard = React.lazy(() => import('../comp/ScoreBoard'));

export default observer(() => {
    const {navigate} = useNavigation();
    const [showSoon, setShowSoon] = useState(false);

    return (
        <ScrollView style={[C.bgWhite, {maxWidth: deviceWidth}]}>
            {/**Header */}
            <OnbHeader list={topGames}/>

            <TitleHeader title={'Play eMoji Games'} subtitle={'Have fun playing with your friends'}/>

            {/**Games*/}
            <View style={[C.wFull, C.selfLeft]}>
                <FlatList
                    horizontal
                    initialScrollIndex={0}
                    style={isWide && C.selfCenter}
                    keyExtractor={({index}) => index}
                    contentContainerStyle={[C.itemsCenter]}
                    data={topGames}
                    renderItem={({item, index}) => (
                        <Card
                            {...item}

                            onPress={() => {
                                navigate(item.screen);
                            }}
                        />
                    )}
                />
            </View>

            {/**Stores*/}
            {isWide && <TouchableOpacity activeOpacity={0.6} opacity={0.7} onPress={() => setShowSoon(!showSoon)}
                                         style={[C.row, C.my8]}>
                <Image source={imgs.gPlay} resizeMode={'contain'} style={[C.flex, isWide ? C.h12 : C.h8]}/>
                {showSoon && (
                    <View
                        style={[bgColor(colors.black40), C.row, C.px2, C.radius4, C.itemsCenter, C.selfCenter, C.maxwHalf]}>
                        <EmojiCompat emoji={'📱'} sizeNum={0}/>
                        <Text style={[fonts.title2, C.itemsCenter, C.textWhite, C.m2, C.alignCenter]}>Soon
                            available</Text>
                        <EmojiCompat emoji={'🚀'} sizeNum={0}/>
                    </View>
                )}
                <Image source={imgs.appStore} resizeMode={'contain'} style={[C.flex, isWide ? C.h12 : C.h8]}/>
            </TouchableOpacity>}

            {/**Worlds*/}
            <View style={[isWide ? C.mb12 : C.my8, C.wFull, C.selfLeft]}>
                <Text style={[fonts.mainTitle, C.alignCenter]}>Conquer the eMoji world</Text>
                <FlatList
                    horizontal
                    initialScrollIndex={0}
                    style={isWide && C.selfCenter}
                    keyExtractor={({index}) => index}
                    contentContainerStyle={[C.itemsCenter]}
                    data={availWorlds}
                    renderItem={({item, index}) => (
                        <Card {...item} fixIcons onPress={() => index === 0 && navigate(screens.Planet.name)}/>
                    )}
                />
            </View>
            {/*<Spinner size={4}/>*/}
            {/*<View style={[C.m4, C.selfCenter, C.itemsCenter]}>
                <Text style={[fonts.mainTitle]}>This is a test main title</Text>
                <Text style={[font.h1]}>This is a test font.h2</Text>
                <Text style={[font.h1thin, C.textDanger]}>This is a test font.h1 thin</Text>
                <Text style={[font.h2]}>This is a test font.h2</Text>
                <Text style={[font.h2thin]}>This is a test font.h2 thin</Text>
                <Text style={[font.h3]}>This is a test font.h3</Text>
                <Text style={[font.body, C.mt4]}>This is a test font.body thin</Text>
                <Text style={[font.bodyBold]}>This is a test font.bodyBold thin</Text>
                <Text style={[font.caption, C.mt4]}>This is a test font.caption thin</Text>
                <Text style={[font.captionBold]}>This is a test font.CaptionBold thin</Text>
                <Text style={[font.badge]}>This is a test font.Badge thin</Text>
            </View>*/}
            {/**Mockups*/}
            {conquestWorlds.map((item, index) => (
                <SideInfo key={index} {...item} index={index}/>
            ))}
            {/**Top Score*/}
            <Suspense fallback={<Spinner/>}>
                <LazyScoreBoard/>
            </Suspense>

            {/**Footer*/}
            <Footer/>
        </ScrollView>
    );
});
