import gFire from 'firebase';
import {makeAutoObservable} from 'mobx';
import {profile} from '../App';
import {isWeb} from '../design/gStyles';
import {levelsMap} from '../utils/levels';
import {objAdd, pickRandom} from '../utils/utils';
import {boardsMap, BoardStore, CHESS_SIZE} from './boardStore';
import {comboRef} from './profileStore';

export const INIT_MOVES = 8;
export const COMBO_SIZE = 6;

const boardInitState = {score: 0, currLevel: 1, collected: {}, moves: INIT_MOVES, allowDiag: true};

export const HarvestStore = ({score, moves, collected, currLevel, allowDiag} = boardInitState) =>
    makeAutoObservable({
        score: score,
        addScore(newScore) {
            this.score += newScore;
        },
        currLevel: currLevel,
        /* get remainingScore() {
          return Math.pow(10, this.currLevel);
        },
        get remainingScoreForm() {
          return numFormat(this.remainingScore);
        },*/
        setLevel(lev = 1) {
            this.currLevel = lev;
        },

        fixIcons: false,
        get iconsSet() {
            return this.fixIcons ? this.fixIcons : levelsMap.hit[profile.level - 1];
        },

        /**Bonus*/
        moves: moves,
        addMoves(mov = -1) {
            this.moves += mov;
        },

        /**Shuffle*/
        shuffles: 1,
        shuffle(level) {
            this.currLevel = level;
            this.collected = {};
            this.moves = INIT_MOVES + level;
            //if (this.shuffles > 0) {
            this.currentBoard = BoardStore(boardsMap.HARVEST, levelsMap.hit[level], CHESS_SIZE, allowDiag);
            //this.shuffles--;
            //}
        },
        /**Bomb*/
        bombs: 1,
        get bombIcon() {
            return pickRandom(this.iconsSet, 1);
        },
        bomb() {
            if (this.bombs > 0) {
                this.currentBoard.reassignCells({
                    cells: this.currentBoard.cells.filter(({icon}) => icon === this.bombIcon).map(({id}) => id),
                    currIcon: this.bombIcon,
                    newIcons: this.iconsSet,
                });
                this.bombs--;
            }
        },

        /**Ops*/
        allowDiag: allowDiag,
        isResource: false,
        isPractice: true,

        /**Map*/
        currentBoard: BoardStore(boardsMap.HARVEST, levelsMap.hit[0], CHESS_SIZE, allowDiag),

        /**History & Analytics candidates*/
        collected: collected, //[{'💧': 1}]  => number of each emojis For each level
        get sortCollected() {
            return Object.entries(this.collected).sort((a, b) => b[1] - a[1]);
        },
        setCollected(newIcon, size) {
            delete this.collected[newIcon];
        },
        lastHarvest: {}, //score, icon, size, isCombo
        maxCombo: {size: 0, icon: ''}, //icon, size

        /**Analytics, not yet configured on Mobile*/
        sendAnalytics(icon, size) {
            if (isWeb) {
                const analytics = {
                    id: profile.username || 'New User v2',
                    img: profile?.img || '',
                    gender: profile.gender,
                    flag: profile.flag,
                    icon: icon,
                    collected: this.collected,
                    level: this.currLevel,
                    score: this.score,
                    size: size,
                    timeStamp: new Date().getTime(),
                };
                gFire.analytics().logEvent('Combo', analytics);
                comboRef.add(analytics);
            }
        },

        reset(icons = levelsMap.hit[0], isResource = false, level) {
            if (this.moves !== INIT_MOVES) {
                this.sendAnalytics(this.lastHarvest?.icon || '', this.lastHarvest?.size || 1);
            }
            this.score = score;
            this.fixIcons = level ? false : icons;
            this.collected = collected;
            this.isResource = isResource;
            this.currLevel = 1;
            this.moves = INIT_MOVES;
            this.currentBoard = BoardStore(boardsMap.HARVEST, icons || levelsMap.hit[level], CHESS_SIZE, allowDiag);
        },

        nextLevel(level = this.currLevel + 1) {
            this.shuffle(level);
            //this.currentBoard = BoardStore(boardsMap.HARVEST, this.iconsSet, CHESS_SIZE, allowDiag);
            /*this.currentBoard.shuffle();*/
            this.currLevel = profile.level;
            this.addMoves(INIT_MOVES);
            this.bombs++;
            this.shuffles++;
            this.sendAnalytics(this.lastHarvest.icon, this.lastHarvest.size);
        },
        onCollect(matchIcon, size) {
            const newScore = matchIcon === '🔥' ? size * 2 : this.currLevel * (size > COMBO_SIZE ? size * size : size);

            /**Update both game score && profile Score*/
            this.score += newScore;
            profile.addScore(newScore);
            /* if (this.isResource) {
              profile.addResource(matchIcon, size);
            } else if (profile.level !== this.currLevel) {
              this.currentBoard = BoardStore(boardsMap.HARVEST, this.iconsSet, CHESS_SIZE, allowDiag);
              /!*this.currentBoard.shuffle();*!/
              this.currLevel = profile.level;
              this.addMoves(INIT_MOVES);
              this.bombs++;
              this.shuffles++;
              this.sendAnalytics(matchIcon, size);
            }*/

            /**1. Add collected*/
            this.lastHarvest = {icon: matchIcon, size, isCombo: size >= COMBO_SIZE, score: newScore};
            objAdd(this.collected, matchIcon, size);

            /**2. Strike*/
            if (size >= COMBO_SIZE) {
                this.addMoves(size - COMBO_SIZE);
                if (size >= this.maxCombo.size) {
                    this.maxCombo = {size, icon: matchIcon};
                }
                this.sendAnalytics(matchIcon, size);
            }
        },
    });
