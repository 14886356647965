/**
 * @format
 */

import {AppRegistry} from 'react-native';
import {name as appName} from './app.json';
import {isWeb} from './design/gStyles';
import App from './App';

AppRegistry.registerComponent(appName, () => App);
isWeb &&
AppRegistry.runApplication(appName, {
    rootTag: document.getElementById('root'),
});
