import C, {apply} from 'consistencss';
import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {profile} from '../App';
import {colors, deviceWidth, imgs, isSmall, isWide, URLS} from '../design/gStyles';
import {screens} from '../routes';
import {respImgSize} from '../utils/utils';
import {LineageWrapper} from './Box';
import EmojiBubble from './EmojiBubble';
import {EmojiCompat, hasDecentIcons, WrapMoji} from './WrapMoji';
import {allIcons} from '../utils/sets';
import {font, fonts} from '../design/fonts';
import {FooterShape, FooterShape2, HeaderWave} from '../design/shapes';
import {useNavigation} from '@react-navigation/core';

export const SocialImg = ({onPress, uri, img, size = 0}) => <TouchableOpacity style={C.p2}
                                                                              onPress={onPress || (() => window.open(uri, '_blank'))}>
    <Image source={img} style={[respImgSize[size].img, C.radius2]}/>
</TouchableOpacity>;
const HeaderComp = ({title, icon, screen}) => {
    const {navigate, goBack} = useNavigation();

    return (
        <TouchableOpacity
            key={title}
            onPress={() => navigate(screen)}
            style={apply(C.itemsCenter, isWide && C.row, C.justifyCenter, C.radius8, isWide ? C.px4 : C.px1)}>
            <EmojiCompat emoji={icon} sizeNum={1}/>
            {title && isWide && (
                <Text
                    style={apply(
                        C.uppercase,
                        fonts.cta1,
                        /*isWide ? fonts.subtitle : fonts.subtitleSmall,*/
                        /*C.textPaleGreyTwo,*/
                        isWide && C.ml2,
                    )}>
                    {title}
                </Text>
            )}
            {/*{isWide && <BgShapeHoriz col={colors.ocean + '80'} wrapStyle={C.absolute} />}
    {isWide && (
      <BgShapeHoriz
        col={colors.oceanBg + '80'}
        wrapStyle={{position: 'absolute', left: -10, transform: 'rotateY(90deg)'}}
      />
    )}*/}
        </TouchableOpacity>
    );
};

export const TitleHeader = ({title, emoji, subtitle}) => (
    <View
        style={[C.wFull,
            isWide ? C.my_14 : C.my_4,
            {
                zIndex: -500,
                height: deviceWidth * 0.25,
                /*width: deviceWidth * 1.1,*/
                marginVertical: deviceWidth * (isSmall ? 0.1 : -0.04),
            },
        ]}>
        <HeaderWave col={colors.blue} opacity={0.1} wrapStyle={C.absolute}/>
        <View style={[C.row, C.itemsCenter, C.justifyCenter, C.flex, isWide && C.ml_12]}>
            {emoji && <EmojiCompat emoji={emoji} sizeNum={4} extraStyle={[C.mr4]}/>}
            <View>
                <Text style={[fonts.mainTitle]}>{title}</Text>
                <Text style={[font.body, {maxWidth: deviceWidth * 0.7}, C.alignLeft]}>{subtitle}</Text>
            </View>
        </View>
    </View>
);

export const OnbHeader = ({currProfile = profile, onPressSound, onPressBack, onSet, list}) => {
    const {navigate} = useNavigation();
    return (
        <View style={apply(C.row, C.wFull, C.justifyBetween, C.p2, C.itemsCenter)}>
            <TouchableOpacity onPress={() => navigate(screens.Onboarding.name)}>
                <Image style={[C.w40, C.h16, isSmall && C.maxw16]} resizeMode={'contain'}
                       source={isSmall ? imgs.logo : imgs.logoHoriz}/>
            </TouchableOpacity>

            <View style={[C.row, C.justifyCenter, C.itemsCenter]}>
                {/**Instagram*/}
                <SocialImg uri={URLS.insta} size={0} img={imgs.insta}/>

                {/**Linkedn*/}
                <SocialImg uri={URLS.linkedn} img={imgs.linkdn}/>

                {/**Profile*/}
                <HeaderComp icon={'👾'} title={'games'} screen={screens.Onboarding.name}/>
                <HeaderComp icon={'👤'} title={'Profile'} screen={screens.Profile.name}/>
                {/*<HeaderComp

        icon={profile.emoji}
        title={profile.username || 'Login'}
        screen={screens.Profile.name}
      />*/}
                <EmojiBubble
                    wrapStyle={[C.top1, C.mx2]}
                    ModalComp={<LineageWrapper currProfile={currProfile}/>}
                    currProfile={currProfile}
                />
                <View style={[C.top1]}>
                    {onPressBack && (
                        <WrapMoji emoji={'✖️'} onPress={onPressBack || (() => navigate(screens.Onboarding.name))}
                                  sizeNum={1}/>
                    )}
                    {onPressSound &&
                        <WrapMoji emoji={currProfile.soundsOn ? '🔊' : '🔇'} onPress={onPressSound} sizeNum={1}/>}
                </View>
            </View>
        </View>
    );
};
const wrapStyle = {position: 'absolute', bottom: 0, zIndex: -99, marginTop: 120};
export const Footer = () => (<>
    {/*<View style={[C.bottom0, below, C.pt10]}>*/}
    <FooterShape wrapStyle={wrapStyle}/>
    <FooterShape2 wrapStyle={wrapStyle}/>

    {/* </View>*/}
    <View style={[C.mt42, C.wFull, C.itemsCenter]}>
        <TouchableOpacity
            style={[C.row, C.m4, C.selfCenter, C.alignCenter, C.justifyCenter, C.itemsCenter]}
            onPress={() => window.open(URLS.guillesierra, '_blank')}>
            <EmojiCompat emoji={'🖥'} sizeNum={1}/>
            <Text style={[font.h2thin, C.mx2]}>Made with</Text>
            <EmojiCompat emoji={'❤️'} sizeNum={1}/>
            <Text style={[font.h2thin, C.selfCenter, isSmall && C.mt_8, C.mx2]}> By</Text>
            <EmojiCompat emoji={'🧑‍💻'} sizeNum={1}/>
            <Text style={[font.h2, C.textBlue, C.ml2]}>Guillermo Sierra</Text>
        </TouchableOpacity>

        {/** Emojis Footer*/}
        {hasDecentIcons && (
            <Text style={[C.alignCenter, C.p4]}>
                {allIcons.map((item) => (
                    <EmojiCompat emoji={item} sizeNum={isWide ? 1 : 0}/>
                ))}
            </Text>
        )}
        <Text style={[font.body, C.alignCenter, C.textGrey, C.mb6]}>
            Available for 📱 Mobile 💻 Web 🤖 Android 🍫 Tablet
        </Text>
        <View style={[C.row, C.selfCenter]}>
            <SocialImg uri={URLS.insta} img={imgs.insta}/>
            <SocialImg uri={URLS.linkedn} img={imgs.linkdn}/>
        </View>
    </View>
</>);
