export const emojiCodes = {
  '#️⃣': {
    name: 'hash key',
    dotName: ':hash:',
    code: 'U+0023 U+FE0F U+20E3',
    img: '0023-fe0f-20e3.png',
  },
  '*️⃣': {
    name: '',
    dotName: ':keycap_star:',
    code: 'U+002A U+FE0F U+20E3',
    img: '002a-fe0f-20e3.png',
  },
  '0️⃣': {
    name: 'keycap 0',
    dotName: ':zero:',
    code: 'U+0030 U+FE0F U+20E3',
    img: '0030-fe0f-20e3.png',
  },
  '1️⃣': {
    name: 'keycap 1',
    dotName: ':one:',
    code: 'U+0031 U+FE0F U+20E3',
    img: '0031-fe0f-20e3.png',
  },
  '2️⃣': {
    name: 'keycap 2',
    dotName: ':two:',
    code: 'U+0032 U+FE0F U+20E3',
    img: '0032-fe0f-20e3.png',
  },
  '3️⃣': {
    name: 'keycap 3',
    dotName: ':three:',
    code: 'U+0033 U+FE0F U+20E3',
    img: '0033-fe0f-20e3.png',
  },
  '4️⃣': {
    name: 'keycap 4',
    dotName: ':four:',
    code: 'U+0034 U+FE0F U+20E3',
    img: '0034-fe0f-20e3.png',
  },
  '5️⃣': {
    name: 'keycap 5',
    dotName: ':five:',
    code: 'U+0035 U+FE0F U+20E3',
    img: '0035-fe0f-20e3.png',
  },
  '6️⃣': {
    name: 'keycap 6',
    dotName: ':six:',
    code: 'U+0036 U+FE0F U+20E3',
    img: '0036-fe0f-20e3.png',
  },
  '7️⃣': {
    name: 'keycap 7',
    dotName: ':seven:',
    code: 'U+0037 U+FE0F U+20E3',
    img: '0037-fe0f-20e3.png',
  },
  '8️⃣': {
    name: 'keycap 8',
    dotName: ':eight:',
    code: 'U+0038 U+FE0F U+20E3',
    img: '0038-fe0f-20e3.png',
  },
  '9️⃣': {
    name: 'keycap 9',
    dotName: ':nine:',
    code: 'U+0039 U+FE0F U+20E3',
    img: '0039-fe0f-20e3.png',
  },
  '©️': {
    name: 'copyright sign',
    dotName: ':copyright:',
    code: 'U+00A9 U+FE0F',
    img: '00a9-fe0f.png',
  },
  '®️': {
    name: 'registered sign',
    dotName: ':registered:',
    code: 'U+00AE U+FE0F',
    img: '00ae-fe0f.png',
  },
  '🀄': {
    name: 'mahjong tile red dragon',
    dotName: ':mahjong:',
    code: 'U+1F004',
    img: '1f004.png',
  },
  '🃏': {
    name: 'playing card black joker',
    dotName: ':black_joker:',
    code: 'U+1F0CF',
    img: '1f0cf.png',
  },
  '🅰️': {
    name: 'negative squared latin capital letter a',
    dotName: ':a:',
    code: 'U+1F170 U+FE0F',
    img: '1f170-fe0f.png',
  },
  '🅱️': {
    name: 'negative squared latin capital letter b',
    dotName: ':b:',
    code: 'U+1F171 U+FE0F',
    img: '1f171-fe0f.png',
  },
  '🅾️': {
    name: 'negative squared latin capital letter o',
    dotName: ':o2:',
    code: 'U+1F17E U+FE0F',
    img: '1f17e-fe0f.png',
  },
  '🅿️': {
    name: 'negative squared latin capital letter p',
    dotName: ':parking:',
    code: 'U+1F17F U+FE0F',
    img: '1f17f-fe0f.png',
  },
  '🆎': {
    name: 'negative squared ab',
    dotName: ':ab:',
    code: 'U+1F18E',
    img: '1f18e.png',
  },
  '🆑': {
    name: 'squared cl',
    dotName: ':cl:',
    code: 'U+1F191',
    img: '1f191.png',
  },
  '🆒': {
    name: 'squared cool',
    dotName: ':cool:',
    code: 'U+1F192',
    img: '1f192.png',
  },
  '🆓': {
    name: 'squared free',
    dotName: ':free:',
    code: 'U+1F193',
    img: '1f193.png',
  },
  '🆔': {
    name: 'squared id',
    dotName: ':id:',
    code: 'U+1F194',
    img: '1f194.png',
  },
  '🆕': {
    name: 'squared new',
    dotName: ':new:',
    code: 'U+1F195',
    img: '1f195.png',
  },
  '🆖': {
    name: 'squared ng',
    dotName: ':ng:',
    code: 'U+1F196',
    img: '1f196.png',
  },
  '🆗': {
    name: 'squared ok',
    dotName: ':ok:',
    code: 'U+1F197',
    img: '1f197.png',
  },
  '🆘': {
    name: 'squared sos',
    dotName: ':sos:',
    code: 'U+1F198',
    img: '1f198.png',
  },
  '🆙': {
    name: 'squared up with exclamation mark',
    dotName: ':up:',
    code: 'U+1F199',
    img: '1f199.png',
  },
  '🆚': {
    name: 'squared vs',
    dotName: ':vs:',
    code: 'U+1F19A',
    img: '1f19a.png',
  },
  '🇦🇨': {
    name: 'ascension island flag',
    dotName: ':flag-ac:',
    code: 'U+1F1E6 U+1F1E8',
    img: '1f1e6-1f1e8.png',
  },
  '🇦🇩': {
    name: 'andorra flag',
    dotName: ':flag-ad:',
    code: 'U+1F1E6 U+1F1E9',
    img: '1f1e6-1f1e9.png',
  },
  '🇦🇪': {
    name: 'united arab emirates flag',
    dotName: ':flag-ae:',
    code: 'U+1F1E6 U+1F1EA',
    img: '1f1e6-1f1ea.png',
  },
  '🇦🇫': {
    name: 'afghanistan flag',
    dotName: ':flag-af:',
    code: 'U+1F1E6 U+1F1EB',
    img: '1f1e6-1f1eb.png',
  },
  '🇦🇬': {
    name: 'antigua & barbuda flag',
    dotName: ':flag-ag:',
    code: 'U+1F1E6 U+1F1EC',
    img: '1f1e6-1f1ec.png',
  },
  '🇦🇮': {
    name: 'anguilla flag',
    dotName: ':flag-ai:',
    code: 'U+1F1E6 U+1F1EE',
    img: '1f1e6-1f1ee.png',
  },
  '🇦🇱': {
    name: 'albania flag',
    dotName: ':flag-al:',
    code: 'U+1F1E6 U+1F1F1',
    img: '1f1e6-1f1f1.png',
  },
  '🇦🇲': {
    name: 'armenia flag',
    dotName: ':flag-am:',
    code: 'U+1F1E6 U+1F1F2',
    img: '1f1e6-1f1f2.png',
  },
  '🇦🇴': {
    name: 'angola flag',
    dotName: ':flag-ao:',
    code: 'U+1F1E6 U+1F1F4',
    img: '1f1e6-1f1f4.png',
  },
  '🇦🇶': {
    name: 'antarctica flag',
    dotName: ':flag-aq:',
    code: 'U+1F1E6 U+1F1F6',
    img: '1f1e6-1f1f6.png',
  },
  '🇦🇷': {
    name: 'argentina flag',
    dotName: ':flag-ar:',
    code: 'U+1F1E6 U+1F1F7',
    img: '1f1e6-1f1f7.png',
  },
  '🇦🇸': {
    name: 'american samoa flag',
    dotName: ':flag-as:',
    code: 'U+1F1E6 U+1F1F8',
    img: '1f1e6-1f1f8.png',
  },
  '🇦🇹': {
    name: 'austria flag',
    dotName: ':flag-at:',
    code: 'U+1F1E6 U+1F1F9',
    img: '1f1e6-1f1f9.png',
  },
  '🇦🇺': {
    name: 'australia flag',
    dotName: ':flag-au:',
    code: 'U+1F1E6 U+1F1FA',
    img: '1f1e6-1f1fa.png',
  },
  '🇦🇼': {
    name: 'aruba flag',
    dotName: ':flag-aw:',
    code: 'U+1F1E6 U+1F1FC',
    img: '1f1e6-1f1fc.png',
  },
  '🇦🇽': {
    name: 'åland islands flag',
    dotName: ':flag-ax:',
    code: 'U+1F1E6 U+1F1FD',
    img: '1f1e6-1f1fd.png',
  },
  '🇦🇿': {
    name: 'azerbaijan flag',
    dotName: ':flag-az:',
    code: 'U+1F1E6 U+1F1FF',
    img: '1f1e6-1f1ff.png',
  },
  '🇧🇦': {
    name: 'bosnia & herzegovina flag',
    dotName: ':flag-ba:',
    code: 'U+1F1E7 U+1F1E6',
    img: '1f1e7-1f1e6.png',
  },
  '🇧🇧': {
    name: 'barbados flag',
    dotName: ':flag-bb:',
    code: 'U+1F1E7 U+1F1E7',
    img: '1f1e7-1f1e7.png',
  },
  '🇧🇩': {
    name: 'bangladesh flag',
    dotName: ':flag-bd:',
    code: 'U+1F1E7 U+1F1E9',
    img: '1f1e7-1f1e9.png',
  },
  '🇧🇪': {
    name: 'belgium flag',
    dotName: ':flag-be:',
    code: 'U+1F1E7 U+1F1EA',
    img: '1f1e7-1f1ea.png',
  },
  '🇧🇫': {
    name: 'burkina faso flag',
    dotName: ':flag-bf:',
    code: 'U+1F1E7 U+1F1EB',
    img: '1f1e7-1f1eb.png',
  },
  '🇧🇬': {
    name: 'bulgaria flag',
    dotName: ':flag-bg:',
    code: 'U+1F1E7 U+1F1EC',
    img: '1f1e7-1f1ec.png',
  },
  '🇧🇭': {
    name: 'bahrain flag',
    dotName: ':flag-bh:',
    code: 'U+1F1E7 U+1F1ED',
    img: '1f1e7-1f1ed.png',
  },
  '🇧🇮': {
    name: 'burundi flag',
    dotName: ':flag-bi:',
    code: 'U+1F1E7 U+1F1EE',
    img: '1f1e7-1f1ee.png',
  },
  '🇧🇯': {
    name: 'benin flag',
    dotName: ':flag-bj:',
    code: 'U+1F1E7 U+1F1EF',
    img: '1f1e7-1f1ef.png',
  },
  '🇧🇱': {
    name: 'st. barthélemy flag',
    dotName: ':flag-bl:',
    code: 'U+1F1E7 U+1F1F1',
    img: '1f1e7-1f1f1.png',
  },
  '🇧🇲': {
    name: 'bermuda flag',
    dotName: ':flag-bm:',
    code: 'U+1F1E7 U+1F1F2',
    img: '1f1e7-1f1f2.png',
  },
  '🇧🇳': {
    name: 'brunei flag',
    dotName: ':flag-bn:',
    code: 'U+1F1E7 U+1F1F3',
    img: '1f1e7-1f1f3.png',
  },
  '🇧🇴': {
    name: 'bolivia flag',
    dotName: ':flag-bo:',
    code: 'U+1F1E7 U+1F1F4',
    img: '1f1e7-1f1f4.png',
  },
  '🇧🇶': {
    name: 'caribbean netherlands flag',
    dotName: ':flag-bq:',
    code: 'U+1F1E7 U+1F1F6',
    img: '1f1e7-1f1f6.png',
  },
  '🇧🇷': {
    name: 'brazil flag',
    dotName: ':flag-br:',
    code: 'U+1F1E7 U+1F1F7',
    img: '1f1e7-1f1f7.png',
  },
  '🇧🇸': {
    name: 'bahamas flag',
    dotName: ':flag-bs:',
    code: 'U+1F1E7 U+1F1F8',
    img: '1f1e7-1f1f8.png',
  },
  '🇧🇹': {
    name: 'bhutan flag',
    dotName: ':flag-bt:',
    code: 'U+1F1E7 U+1F1F9',
    img: '1f1e7-1f1f9.png',
  },
  '🇧🇻': {
    name: 'bouvet island flag',
    dotName: ':flag-bv:',
    code: 'U+1F1E7 U+1F1FB',
    img: '1f1e7-1f1fb.png',
  },
  '🇧🇼': {
    name: 'botswana flag',
    dotName: ':flag-bw:',
    code: 'U+1F1E7 U+1F1FC',
    img: '1f1e7-1f1fc.png',
  },
  '🇧🇾': {
    name: 'belarus flag',
    dotName: ':flag-by:',
    code: 'U+1F1E7 U+1F1FE',
    img: '1f1e7-1f1fe.png',
  },
  '🇧🇿': {
    name: 'belize flag',
    dotName: ':flag-bz:',
    code: 'U+1F1E7 U+1F1FF',
    img: '1f1e7-1f1ff.png',
  },
  '🇨🇦': {
    name: 'canada flag',
    dotName: ':flag-ca:',
    code: 'U+1F1E8 U+1F1E6',
    img: '1f1e8-1f1e6.png',
  },
  '🇨🇨': {
    name: 'cocos (keeling) islands flag',
    dotName: ':flag-cc:',
    code: 'U+1F1E8 U+1F1E8',
    img: '1f1e8-1f1e8.png',
  },
  '🇨🇩': {
    name: 'congo - kinshasa flag',
    dotName: ':flag-cd:',
    code: 'U+1F1E8 U+1F1E9',
    img: '1f1e8-1f1e9.png',
  },
  '🇨🇫': {
    name: 'central african republic flag',
    dotName: ':flag-cf:',
    code: 'U+1F1E8 U+1F1EB',
    img: '1f1e8-1f1eb.png',
  },
  '🇨🇬': {
    name: 'congo - brazzaville flag',
    dotName: ':flag-cg:',
    code: 'U+1F1E8 U+1F1EC',
    img: '1f1e8-1f1ec.png',
  },
  '🇨🇭': {
    name: 'switzerland flag',
    dotName: ':flag-ch:',
    code: 'U+1F1E8 U+1F1ED',
    img: '1f1e8-1f1ed.png',
  },
  '🇨🇮': {
    name: 'côte d’ivoire flag',
    dotName: ':flag-ci:',
    code: 'U+1F1E8 U+1F1EE',
    img: '1f1e8-1f1ee.png',
  },
  '🇨🇰': {
    name: 'cook islands flag',
    dotName: ':flag-ck:',
    code: 'U+1F1E8 U+1F1F0',
    img: '1f1e8-1f1f0.png',
  },
  '🇨🇱': {
    name: 'chile flag',
    dotName: ':flag-cl:',
    code: 'U+1F1E8 U+1F1F1',
    img: '1f1e8-1f1f1.png',
  },
  '🇨🇲': {
    name: 'cameroon flag',
    dotName: ':flag-cm:',
    code: 'U+1F1E8 U+1F1F2',
    img: '1f1e8-1f1f2.png',
  },
  '🇨🇳': {
    name: 'china flag',
    dotName: ':cn:',
    code: 'U+1F1E8 U+1F1F3',
    img: '1f1e8-1f1f3.png',
  },
  '🇨🇴': {
    name: 'colombia flag',
    dotName: ':flag-co:',
    code: 'U+1F1E8 U+1F1F4',
    img: '1f1e8-1f1f4.png',
  },
  '🇨🇵': {
    name: 'clipperton island flag',
    dotName: ':flag-cp:',
    code: 'U+1F1E8 U+1F1F5',
    img: '1f1e8-1f1f5.png',
  },
  '🇨🇷': {
    name: 'costa rica flag',
    dotName: ':flag-cr:',
    code: 'U+1F1E8 U+1F1F7',
    img: '1f1e8-1f1f7.png',
  },
  '🇨🇺': {
    name: 'cuba flag',
    dotName: ':flag-cu:',
    code: 'U+1F1E8 U+1F1FA',
    img: '1f1e8-1f1fa.png',
  },
  '🇨🇻': {
    name: 'cape verde flag',
    dotName: ':flag-cv:',
    code: 'U+1F1E8 U+1F1FB',
    img: '1f1e8-1f1fb.png',
  },
  '🇨🇼': {
    name: 'curaçao flag',
    dotName: ':flag-cw:',
    code: 'U+1F1E8 U+1F1FC',
    img: '1f1e8-1f1fc.png',
  },
  '🇨🇽': {
    name: 'christmas island flag',
    dotName: ':flag-cx:',
    code: 'U+1F1E8 U+1F1FD',
    img: '1f1e8-1f1fd.png',
  },
  '🇨🇾': {
    name: 'cyprus flag',
    dotName: ':flag-cy:',
    code: 'U+1F1E8 U+1F1FE',
    img: '1f1e8-1f1fe.png',
  },
  '🇨🇿': {
    name: 'czechia flag',
    dotName: ':flag-cz:',
    code: 'U+1F1E8 U+1F1FF',
    img: '1f1e8-1f1ff.png',
  },
  '🇩🇪': {
    name: 'germany flag',
    dotName: ':de:',
    code: 'U+1F1E9 U+1F1EA',
    img: '1f1e9-1f1ea.png',
  },
  '🇩🇬': {
    name: 'diego garcia flag',
    dotName: ':flag-dg:',
    code: 'U+1F1E9 U+1F1EC',
    img: '1f1e9-1f1ec.png',
  },
  '🇩🇯': {
    name: 'djibouti flag',
    dotName: ':flag-dj:',
    code: 'U+1F1E9 U+1F1EF',
    img: '1f1e9-1f1ef.png',
  },
  '🇩🇰': {
    name: 'denmark flag',
    dotName: ':flag-dk:',
    code: 'U+1F1E9 U+1F1F0',
    img: '1f1e9-1f1f0.png',
  },
  '🇩🇲': {
    name: 'dominica flag',
    dotName: ':flag-dm:',
    code: 'U+1F1E9 U+1F1F2',
    img: '1f1e9-1f1f2.png',
  },
  '🇩🇴': {
    name: 'dominican republic flag',
    dotName: ':flag-do:',
    code: 'U+1F1E9 U+1F1F4',
    img: '1f1e9-1f1f4.png',
  },
  '🇩🇿': {
    name: 'algeria flag',
    dotName: ':flag-dz:',
    code: 'U+1F1E9 U+1F1FF',
    img: '1f1e9-1f1ff.png',
  },
  '🇪🇦': {
    name: 'ceuta & melilla flag',
    dotName: ':flag-ea:',
    code: 'U+1F1EA U+1F1E6',
    img: '1f1ea-1f1e6.png',
  },
  '🇪🇨': {
    name: 'ecuador flag',
    dotName: ':flag-ec:',
    code: 'U+1F1EA U+1F1E8',
    img: '1f1ea-1f1e8.png',
  },
  '🇪🇪': {
    name: 'estonia flag',
    dotName: ':flag-ee:',
    code: 'U+1F1EA U+1F1EA',
    img: '1f1ea-1f1ea.png',
  },
  '🇪🇬': {
    name: 'egypt flag',
    dotName: ':flag-eg:',
    code: 'U+1F1EA U+1F1EC',
    img: '1f1ea-1f1ec.png',
  },
  '🇪🇭': {
    name: 'western sahara flag',
    dotName: ':flag-eh:',
    code: 'U+1F1EA U+1F1ED',
    img: '1f1ea-1f1ed.png',
  },
  '🇪🇷': {
    name: 'eritrea flag',
    dotName: ':flag-er:',
    code: 'U+1F1EA U+1F1F7',
    img: '1f1ea-1f1f7.png',
  },
  '🇪🇸': {
    name: 'spain flag',
    dotName: ':es:',
    code: 'U+1F1EA U+1F1F8',
    img: '1f1ea-1f1f8.png',
  },
  '🇪🇹': {
    name: 'ethiopia flag',
    dotName: ':flag-et:',
    code: 'U+1F1EA U+1F1F9',
    img: '1f1ea-1f1f9.png',
  },
  '🇪🇺': {
    name: 'european union flag',
    dotName: ':flag-eu:',
    code: 'U+1F1EA U+1F1FA',
    img: '1f1ea-1f1fa.png',
  },
  '🇫🇮': {
    name: 'finland flag',
    dotName: ':flag-fi:',
    code: 'U+1F1EB U+1F1EE',
    img: '1f1eb-1f1ee.png',
  },
  '🇫🇯': {
    name: 'fiji flag',
    dotName: ':flag-fj:',
    code: 'U+1F1EB U+1F1EF',
    img: '1f1eb-1f1ef.png',
  },
  '🇫🇰': {
    name: 'falkland islands flag',
    dotName: ':flag-fk:',
    code: 'U+1F1EB U+1F1F0',
    img: '1f1eb-1f1f0.png',
  },
  '🇫🇲': {
    name: 'micronesia flag',
    dotName: ':flag-fm:',
    code: 'U+1F1EB U+1F1F2',
    img: '1f1eb-1f1f2.png',
  },
  '🇫🇴': {
    name: 'faroe islands flag',
    dotName: ':flag-fo:',
    code: 'U+1F1EB U+1F1F4',
    img: '1f1eb-1f1f4.png',
  },
  '🇫🇷': {
    name: 'france flag',
    dotName: ':fr:',
    code: 'U+1F1EB U+1F1F7',
    img: '1f1eb-1f1f7.png',
  },
  '🇬🇦': {
    name: 'gabon flag',
    dotName: ':flag-ga:',
    code: 'U+1F1EC U+1F1E6',
    img: '1f1ec-1f1e6.png',
  },
  '🇬🇧': {
    name: 'united kingdom flag',
    dotName: ':gb:',
    code: 'U+1F1EC U+1F1E7',
    img: '1f1ec-1f1e7.png',
  },
  '🇬🇩': {
    name: 'grenada flag',
    dotName: ':flag-gd:',
    code: 'U+1F1EC U+1F1E9',
    img: '1f1ec-1f1e9.png',
  },
  '🇬🇪': {
    name: 'georgia flag',
    dotName: ':flag-ge:',
    code: 'U+1F1EC U+1F1EA',
    img: '1f1ec-1f1ea.png',
  },
  '🇬🇫': {
    name: 'french guiana flag',
    dotName: ':flag-gf:',
    code: 'U+1F1EC U+1F1EB',
    img: '1f1ec-1f1eb.png',
  },
  '🇬🇬': {
    name: 'guernsey flag',
    dotName: ':flag-gg:',
    code: 'U+1F1EC U+1F1EC',
    img: '1f1ec-1f1ec.png',
  },
  '🇬🇭': {
    name: 'ghana flag',
    dotName: ':flag-gh:',
    code: 'U+1F1EC U+1F1ED',
    img: '1f1ec-1f1ed.png',
  },
  '🇬🇮': {
    name: 'gibraltar flag',
    dotName: ':flag-gi:',
    code: 'U+1F1EC U+1F1EE',
    img: '1f1ec-1f1ee.png',
  },
  '🇬🇱': {
    name: 'greenland flag',
    dotName: ':flag-gl:',
    code: 'U+1F1EC U+1F1F1',
    img: '1f1ec-1f1f1.png',
  },
  '🇬🇲': {
    name: 'gambia flag',
    dotName: ':flag-gm:',
    code: 'U+1F1EC U+1F1F2',
    img: '1f1ec-1f1f2.png',
  },
  '🇬🇳': {
    name: 'guinea flag',
    dotName: ':flag-gn:',
    code: 'U+1F1EC U+1F1F3',
    img: '1f1ec-1f1f3.png',
  },
  '🇬🇵': {
    name: 'guadeloupe flag',
    dotName: ':flag-gp:',
    code: 'U+1F1EC U+1F1F5',
    img: '1f1ec-1f1f5.png',
  },
  '🇬🇶': {
    name: 'equatorial guinea flag',
    dotName: ':flag-gq:',
    code: 'U+1F1EC U+1F1F6',
    img: '1f1ec-1f1f6.png',
  },
  '🇬🇷': {
    name: 'greece flag',
    dotName: ':flag-gr:',
    code: 'U+1F1EC U+1F1F7',
    img: '1f1ec-1f1f7.png',
  },
  '🇬🇸': {
    name: 'south georgia & south sandwich islands flag',
    dotName: ':flag-gs:',
    code: 'U+1F1EC U+1F1F8',
    img: '1f1ec-1f1f8.png',
  },
  '🇬🇹': {
    name: 'guatemala flag',
    dotName: ':flag-gt:',
    code: 'U+1F1EC U+1F1F9',
    img: '1f1ec-1f1f9.png',
  },
  '🇬🇺': {
    name: 'guam flag',
    dotName: ':flag-gu:',
    code: 'U+1F1EC U+1F1FA',
    img: '1f1ec-1f1fa.png',
  },
  '🇬🇼': {
    name: 'guinea-bissau flag',
    dotName: ':flag-gw:',
    code: 'U+1F1EC U+1F1FC',
    img: '1f1ec-1f1fc.png',
  },
  '🇬🇾': {
    name: 'guyana flag',
    dotName: ':flag-gy:',
    code: 'U+1F1EC U+1F1FE',
    img: '1f1ec-1f1fe.png',
  },
  '🇭🇰': {
    name: 'hong kong sar china flag',
    dotName: ':flag-hk:',
    code: 'U+1F1ED U+1F1F0',
    img: '1f1ed-1f1f0.png',
  },
  '🇭🇲': {
    name: 'heard & mcdonald islands flag',
    dotName: ':flag-hm:',
    code: 'U+1F1ED U+1F1F2',
    img: '1f1ed-1f1f2.png',
  },
  '🇭🇳': {
    name: 'honduras flag',
    dotName: ':flag-hn:',
    code: 'U+1F1ED U+1F1F3',
    img: '1f1ed-1f1f3.png',
  },
  '🇭🇷': {
    name: 'croatia flag',
    dotName: ':flag-hr:',
    code: 'U+1F1ED U+1F1F7',
    img: '1f1ed-1f1f7.png',
  },
  '🇭🇹': {
    name: 'haiti flag',
    dotName: ':flag-ht:',
    code: 'U+1F1ED U+1F1F9',
    img: '1f1ed-1f1f9.png',
  },
  '🇭🇺': {
    name: 'hungary flag',
    dotName: ':flag-hu:',
    code: 'U+1F1ED U+1F1FA',
    img: '1f1ed-1f1fa.png',
  },
  '🇮🇨': {
    name: 'canary islands flag',
    dotName: ':flag-ic:',
    code: 'U+1F1EE U+1F1E8',
    img: '1f1ee-1f1e8.png',
  },
  '🇮🇩': {
    name: 'indonesia flag',
    dotName: ':flag-id:',
    code: 'U+1F1EE U+1F1E9',
    img: '1f1ee-1f1e9.png',
  },
  '🇮🇪': {
    name: 'ireland flag',
    dotName: ':flag-ie:',
    code: 'U+1F1EE U+1F1EA',
    img: '1f1ee-1f1ea.png',
  },
  '🇮🇱': {
    name: 'israel flag',
    dotName: ':flag-il:',
    code: 'U+1F1EE U+1F1F1',
    img: '1f1ee-1f1f1.png',
  },
  '🇮🇲': {
    name: 'isle of man flag',
    dotName: ':flag-im:',
    code: 'U+1F1EE U+1F1F2',
    img: '1f1ee-1f1f2.png',
  },
  '🇮🇳': {
    name: 'india flag',
    dotName: ':flag-in:',
    code: 'U+1F1EE U+1F1F3',
    img: '1f1ee-1f1f3.png',
  },
  '🇮🇴': {
    name: 'british indian ocean territory flag',
    dotName: ':flag-io:',
    code: 'U+1F1EE U+1F1F4',
    img: '1f1ee-1f1f4.png',
  },
  '🇮🇶': {
    name: 'iraq flag',
    dotName: ':flag-iq:',
    code: 'U+1F1EE U+1F1F6',
    img: '1f1ee-1f1f6.png',
  },
  '🇮🇷': {
    name: 'iran flag',
    dotName: ':flag-ir:',
    code: 'U+1F1EE U+1F1F7',
    img: '1f1ee-1f1f7.png',
  },
  '🇮🇸': {
    name: 'iceland flag',
    dotName: ':flag-is:',
    code: 'U+1F1EE U+1F1F8',
    img: '1f1ee-1f1f8.png',
  },
  '🇮🇹': {
    name: 'italy flag',
    dotName: ':it:',
    code: 'U+1F1EE U+1F1F9',
    img: '1f1ee-1f1f9.png',
  },
  '🇯🇪': {
    name: 'jersey flag',
    dotName: ':flag-je:',
    code: 'U+1F1EF U+1F1EA',
    img: '1f1ef-1f1ea.png',
  },
  '🇯🇲': {
    name: 'jamaica flag',
    dotName: ':flag-jm:',
    code: 'U+1F1EF U+1F1F2',
    img: '1f1ef-1f1f2.png',
  },
  '🇯🇴': {
    name: 'jordan flag',
    dotName: ':flag-jo:',
    code: 'U+1F1EF U+1F1F4',
    img: '1f1ef-1f1f4.png',
  },
  '🇯🇵': {
    name: 'japan flag',
    dotName: ':jp:',
    code: 'U+1F1EF U+1F1F5',
    img: '1f1ef-1f1f5.png',
  },
  '🇰🇪': {
    name: 'kenya flag',
    dotName: ':flag-ke:',
    code: 'U+1F1F0 U+1F1EA',
    img: '1f1f0-1f1ea.png',
  },
  '🇰🇬': {
    name: 'kyrgyzstan flag',
    dotName: ':flag-kg:',
    code: 'U+1F1F0 U+1F1EC',
    img: '1f1f0-1f1ec.png',
  },
  '🇰🇭': {
    name: 'cambodia flag',
    dotName: ':flag-kh:',
    code: 'U+1F1F0 U+1F1ED',
    img: '1f1f0-1f1ed.png',
  },
  '🇰🇮': {
    name: 'kiribati flag',
    dotName: ':flag-ki:',
    code: 'U+1F1F0 U+1F1EE',
    img: '1f1f0-1f1ee.png',
  },
  '🇰🇲': {
    name: 'comoros flag',
    dotName: ':flag-km:',
    code: 'U+1F1F0 U+1F1F2',
    img: '1f1f0-1f1f2.png',
  },
  '🇰🇳': {
    name: 'st. kitts & nevis flag',
    dotName: ':flag-kn:',
    code: 'U+1F1F0 U+1F1F3',
    img: '1f1f0-1f1f3.png',
  },
  '🇰🇵': {
    name: 'north korea flag',
    dotName: ':flag-kp:',
    code: 'U+1F1F0 U+1F1F5',
    img: '1f1f0-1f1f5.png',
  },
  '🇰🇷': {
    name: 'south korea flag',
    dotName: ':kr:',
    code: 'U+1F1F0 U+1F1F7',
    img: '1f1f0-1f1f7.png',
  },
  '🇰🇼': {
    name: 'kuwait flag',
    dotName: ':flag-kw:',
    code: 'U+1F1F0 U+1F1FC',
    img: '1f1f0-1f1fc.png',
  },
  '🇰🇾': {
    name: 'cayman islands flag',
    dotName: ':flag-ky:',
    code: 'U+1F1F0 U+1F1FE',
    img: '1f1f0-1f1fe.png',
  },
  '🇰🇿': {
    name: 'kazakhstan flag',
    dotName: ':flag-kz:',
    code: 'U+1F1F0 U+1F1FF',
    img: '1f1f0-1f1ff.png',
  },
  '🇱🇦': {
    name: 'laos flag',
    dotName: ':flag-la:',
    code: 'U+1F1F1 U+1F1E6',
    img: '1f1f1-1f1e6.png',
  },
  '🇱🇧': {
    name: 'lebanon flag',
    dotName: ':flag-lb:',
    code: 'U+1F1F1 U+1F1E7',
    img: '1f1f1-1f1e7.png',
  },
  '🇱🇨': {
    name: 'st. lucia flag',
    dotName: ':flag-lc:',
    code: 'U+1F1F1 U+1F1E8',
    img: '1f1f1-1f1e8.png',
  },
  '🇱🇮': {
    name: 'liechtenstein flag',
    dotName: ':flag-li:',
    code: 'U+1F1F1 U+1F1EE',
    img: '1f1f1-1f1ee.png',
  },
  '🇱🇰': {
    name: 'sri lanka flag',
    dotName: ':flag-lk:',
    code: 'U+1F1F1 U+1F1F0',
    img: '1f1f1-1f1f0.png',
  },
  '🇱🇷': {
    name: 'liberia flag',
    dotName: ':flag-lr:',
    code: 'U+1F1F1 U+1F1F7',
    img: '1f1f1-1f1f7.png',
  },
  '🇱🇸': {
    name: 'lesotho flag',
    dotName: ':flag-ls:',
    code: 'U+1F1F1 U+1F1F8',
    img: '1f1f1-1f1f8.png',
  },
  '🇱🇹': {
    name: 'lithuania flag',
    dotName: ':flag-lt:',
    code: 'U+1F1F1 U+1F1F9',
    img: '1f1f1-1f1f9.png',
  },
  '🇱🇺': {
    name: 'luxembourg flag',
    dotName: ':flag-lu:',
    code: 'U+1F1F1 U+1F1FA',
    img: '1f1f1-1f1fa.png',
  },
  '🇱🇻': {
    name: 'latvia flag',
    dotName: ':flag-lv:',
    code: 'U+1F1F1 U+1F1FB',
    img: '1f1f1-1f1fb.png',
  },
  '🇱🇾': {
    name: 'libya flag',
    dotName: ':flag-ly:',
    code: 'U+1F1F1 U+1F1FE',
    img: '1f1f1-1f1fe.png',
  },
  '🇲🇦': {
    name: 'morocco flag',
    dotName: ':flag-ma:',
    code: 'U+1F1F2 U+1F1E6',
    img: '1f1f2-1f1e6.png',
  },
  '🇲🇨': {
    name: 'monaco flag',
    dotName: ':flag-mc:',
    code: 'U+1F1F2 U+1F1E8',
    img: '1f1f2-1f1e8.png',
  },
  '🇲🇩': {
    name: 'moldova flag',
    dotName: ':flag-md:',
    code: 'U+1F1F2 U+1F1E9',
    img: '1f1f2-1f1e9.png',
  },
  '🇲🇪': {
    name: 'montenegro flag',
    dotName: ':flag-me:',
    code: 'U+1F1F2 U+1F1EA',
    img: '1f1f2-1f1ea.png',
  },
  '🇲🇫': {
    name: 'st. martin flag',
    dotName: ':flag-mf:',
    code: 'U+1F1F2 U+1F1EB',
    img: '1f1f2-1f1eb.png',
  },
  '🇲🇬': {
    name: 'madagascar flag',
    dotName: ':flag-mg:',
    code: 'U+1F1F2 U+1F1EC',
    img: '1f1f2-1f1ec.png',
  },
  '🇲🇭': {
    name: 'marshall islands flag',
    dotName: ':flag-mh:',
    code: 'U+1F1F2 U+1F1ED',
    img: '1f1f2-1f1ed.png',
  },
  '🇲🇰': {
    name: 'north macedonia flag',
    dotName: ':flag-mk:',
    code: 'U+1F1F2 U+1F1F0',
    img: '1f1f2-1f1f0.png',
  },
  '🇲🇱': {
    name: 'mali flag',
    dotName: ':flag-ml:',
    code: 'U+1F1F2 U+1F1F1',
    img: '1f1f2-1f1f1.png',
  },
  '🇲🇲': {
    name: 'myanmar (burma) flag',
    dotName: ':flag-mm:',
    code: 'U+1F1F2 U+1F1F2',
    img: '1f1f2-1f1f2.png',
  },
  '🇲🇳': {
    name: 'mongolia flag',
    dotName: ':flag-mn:',
    code: 'U+1F1F2 U+1F1F3',
    img: '1f1f2-1f1f3.png',
  },
  '🇲🇴': {
    name: 'macao sar china flag',
    dotName: ':flag-mo:',
    code: 'U+1F1F2 U+1F1F4',
    img: '1f1f2-1f1f4.png',
  },
  '🇲🇵': {
    name: 'northern mariana islands flag',
    dotName: ':flag-mp:',
    code: 'U+1F1F2 U+1F1F5',
    img: '1f1f2-1f1f5.png',
  },
  '🇲🇶': {
    name: 'martinique flag',
    dotName: ':flag-mq:',
    code: 'U+1F1F2 U+1F1F6',
    img: '1f1f2-1f1f6.png',
  },
  '🇲🇷': {
    name: 'mauritania flag',
    dotName: ':flag-mr:',
    code: 'U+1F1F2 U+1F1F7',
    img: '1f1f2-1f1f7.png',
  },
  '🇲🇸': {
    name: 'montserrat flag',
    dotName: ':flag-ms:',
    code: 'U+1F1F2 U+1F1F8',
    img: '1f1f2-1f1f8.png',
  },
  '🇲🇹': {
    name: 'malta flag',
    dotName: ':flag-mt:',
    code: 'U+1F1F2 U+1F1F9',
    img: '1f1f2-1f1f9.png',
  },
  '🇲🇺': {
    name: 'mauritius flag',
    dotName: ':flag-mu:',
    code: 'U+1F1F2 U+1F1FA',
    img: '1f1f2-1f1fa.png',
  },
  '🇲🇻': {
    name: 'maldives flag',
    dotName: ':flag-mv:',
    code: 'U+1F1F2 U+1F1FB',
    img: '1f1f2-1f1fb.png',
  },
  '🇲🇼': {
    name: 'malawi flag',
    dotName: ':flag-mw:',
    code: 'U+1F1F2 U+1F1FC',
    img: '1f1f2-1f1fc.png',
  },
  '🇲🇽': {
    name: 'mexico flag',
    dotName: ':flag-mx:',
    code: 'U+1F1F2 U+1F1FD',
    img: '1f1f2-1f1fd.png',
  },
  '🇲🇾': {
    name: 'malaysia flag',
    dotName: ':flag-my:',
    code: 'U+1F1F2 U+1F1FE',
    img: '1f1f2-1f1fe.png',
  },
  '🇲🇿': {
    name: 'mozambique flag',
    dotName: ':flag-mz:',
    code: 'U+1F1F2 U+1F1FF',
    img: '1f1f2-1f1ff.png',
  },
  '🇳🇦': {
    name: 'namibia flag',
    dotName: ':flag-na:',
    code: 'U+1F1F3 U+1F1E6',
    img: '1f1f3-1f1e6.png',
  },
  '🇳🇨': {
    name: 'new caledonia flag',
    dotName: ':flag-nc:',
    code: 'U+1F1F3 U+1F1E8',
    img: '1f1f3-1f1e8.png',
  },
  '🇳🇪': {
    name: 'niger flag',
    dotName: ':flag-ne:',
    code: 'U+1F1F3 U+1F1EA',
    img: '1f1f3-1f1ea.png',
  },
  '🇳🇫': {
    name: 'norfolk island flag',
    dotName: ':flag-nf:',
    code: 'U+1F1F3 U+1F1EB',
    img: '1f1f3-1f1eb.png',
  },
  '🇳🇬': {
    name: 'nigeria flag',
    dotName: ':flag-ng:',
    code: 'U+1F1F3 U+1F1EC',
    img: '1f1f3-1f1ec.png',
  },
  '🇳🇮': {
    name: 'nicaragua flag',
    dotName: ':flag-ni:',
    code: 'U+1F1F3 U+1F1EE',
    img: '1f1f3-1f1ee.png',
  },
  '🇳🇱': {
    name: 'netherlands flag',
    dotName: ':flag-nl:',
    code: 'U+1F1F3 U+1F1F1',
    img: '1f1f3-1f1f1.png',
  },
  '🇳🇴': {
    name: 'norway flag',
    dotName: ':flag-no:',
    code: 'U+1F1F3 U+1F1F4',
    img: '1f1f3-1f1f4.png',
  },
  '🇳🇵': {
    name: 'nepal flag',
    dotName: ':flag-np:',
    code: 'U+1F1F3 U+1F1F5',
    img: '1f1f3-1f1f5.png',
  },
  '🇳🇷': {
    name: 'nauru flag',
    dotName: ':flag-nr:',
    code: 'U+1F1F3 U+1F1F7',
    img: '1f1f3-1f1f7.png',
  },
  '🇳🇺': {
    name: 'niue flag',
    dotName: ':flag-nu:',
    code: 'U+1F1F3 U+1F1FA',
    img: '1f1f3-1f1fa.png',
  },
  '🇳🇿': {
    name: 'new zealand flag',
    dotName: ':flag-nz:',
    code: 'U+1F1F3 U+1F1FF',
    img: '1f1f3-1f1ff.png',
  },
  '🇴🇲': {
    name: 'oman flag',
    dotName: ':flag-om:',
    code: 'U+1F1F4 U+1F1F2',
    img: '1f1f4-1f1f2.png',
  },
  '🇵🇦': {
    name: 'panama flag',
    dotName: ':flag-pa:',
    code: 'U+1F1F5 U+1F1E6',
    img: '1f1f5-1f1e6.png',
  },
  '🇵🇪': {
    name: 'peru flag',
    dotName: ':flag-pe:',
    code: 'U+1F1F5 U+1F1EA',
    img: '1f1f5-1f1ea.png',
  },
  '🇵🇫': {
    name: 'french polynesia flag',
    dotName: ':flag-pf:',
    code: 'U+1F1F5 U+1F1EB',
    img: '1f1f5-1f1eb.png',
  },
  '🇵🇬': {
    name: 'papua new guinea flag',
    dotName: ':flag-pg:',
    code: 'U+1F1F5 U+1F1EC',
    img: '1f1f5-1f1ec.png',
  },
  '🇵🇭': {
    name: 'philippines flag',
    dotName: ':flag-ph:',
    code: 'U+1F1F5 U+1F1ED',
    img: '1f1f5-1f1ed.png',
  },
  '🇵🇰': {
    name: 'pakistan flag',
    dotName: ':flag-pk:',
    code: 'U+1F1F5 U+1F1F0',
    img: '1f1f5-1f1f0.png',
  },
  '🇵🇱': {
    name: 'poland flag',
    dotName: ':flag-pl:',
    code: 'U+1F1F5 U+1F1F1',
    img: '1f1f5-1f1f1.png',
  },
  '🇵🇲': {
    name: 'st. pierre & miquelon flag',
    dotName: ':flag-pm:',
    code: 'U+1F1F5 U+1F1F2',
    img: '1f1f5-1f1f2.png',
  },
  '🇵🇳': {
    name: 'pitcairn islands flag',
    dotName: ':flag-pn:',
    code: 'U+1F1F5 U+1F1F3',
    img: '1f1f5-1f1f3.png',
  },
  '🇵🇷': {
    name: 'puerto rico flag',
    dotName: ':flag-pr:',
    code: 'U+1F1F5 U+1F1F7',
    img: '1f1f5-1f1f7.png',
  },
  '🇵🇸': {
    name: 'palestinian territories flag',
    dotName: ':flag-ps:',
    code: 'U+1F1F5 U+1F1F8',
    img: '1f1f5-1f1f8.png',
  },
  '🇵🇹': {
    name: 'portugal flag',
    dotName: ':flag-pt:',
    code: 'U+1F1F5 U+1F1F9',
    img: '1f1f5-1f1f9.png',
  },
  '🇵🇼': {
    name: 'palau flag',
    dotName: ':flag-pw:',
    code: 'U+1F1F5 U+1F1FC',
    img: '1f1f5-1f1fc.png',
  },
  '🇵🇾': {
    name: 'paraguay flag',
    dotName: ':flag-py:',
    code: 'U+1F1F5 U+1F1FE',
    img: '1f1f5-1f1fe.png',
  },
  '🇶🇦': {
    name: 'qatar flag',
    dotName: ':flag-qa:',
    code: 'U+1F1F6 U+1F1E6',
    img: '1f1f6-1f1e6.png',
  },
  '🇷🇪': {
    name: 'réunion flag',
    dotName: ':flag-re:',
    code: 'U+1F1F7 U+1F1EA',
    img: '1f1f7-1f1ea.png',
  },
  '🇷🇴': {
    name: 'romania flag',
    dotName: ':flag-ro:',
    code: 'U+1F1F7 U+1F1F4',
    img: '1f1f7-1f1f4.png',
  },
  '🇷🇸': {
    name: 'serbia flag',
    dotName: ':flag-rs:',
    code: 'U+1F1F7 U+1F1F8',
    img: '1f1f7-1f1f8.png',
  },
  '🇷🇺': {
    name: 'russia flag',
    dotName: ':ru:',
    code: 'U+1F1F7 U+1F1FA',
    img: '1f1f7-1f1fa.png',
  },
  '🇷🇼': {
    name: 'rwanda flag',
    dotName: ':flag-rw:',
    code: 'U+1F1F7 U+1F1FC',
    img: '1f1f7-1f1fc.png',
  },
  '🇸🇦': {
    name: 'saudi arabia flag',
    dotName: ':flag-sa:',
    code: 'U+1F1F8 U+1F1E6',
    img: '1f1f8-1f1e6.png',
  },
  '🇸🇧': {
    name: 'solomon islands flag',
    dotName: ':flag-sb:',
    code: 'U+1F1F8 U+1F1E7',
    img: '1f1f8-1f1e7.png',
  },
  '🇸🇨': {
    name: 'seychelles flag',
    dotName: ':flag-sc:',
    code: 'U+1F1F8 U+1F1E8',
    img: '1f1f8-1f1e8.png',
  },
  '🇸🇩': {
    name: 'sudan flag',
    dotName: ':flag-sd:',
    code: 'U+1F1F8 U+1F1E9',
    img: '1f1f8-1f1e9.png',
  },
  '🇸🇪': {
    name: 'sweden flag',
    dotName: ':flag-se:',
    code: 'U+1F1F8 U+1F1EA',
    img: '1f1f8-1f1ea.png',
  },
  '🇸🇬': {
    name: 'singapore flag',
    dotName: ':flag-sg:',
    code: 'U+1F1F8 U+1F1EC',
    img: '1f1f8-1f1ec.png',
  },
  '🇸🇭': {
    name: 'st. helena flag',
    dotName: ':flag-sh:',
    code: 'U+1F1F8 U+1F1ED',
    img: '1f1f8-1f1ed.png',
  },
  '🇸🇮': {
    name: 'slovenia flag',
    dotName: ':flag-si:',
    code: 'U+1F1F8 U+1F1EE',
    img: '1f1f8-1f1ee.png',
  },
  '🇸🇯': {
    name: 'svalbard & jan mayen flag',
    dotName: ':flag-sj:',
    code: 'U+1F1F8 U+1F1EF',
    img: '1f1f8-1f1ef.png',
  },
  '🇸🇰': {
    name: 'slovakia flag',
    dotName: ':flag-sk:',
    code: 'U+1F1F8 U+1F1F0',
    img: '1f1f8-1f1f0.png',
  },
  '🇸🇱': {
    name: 'sierra leone flag',
    dotName: ':flag-sl:',
    code: 'U+1F1F8 U+1F1F1',
    img: '1f1f8-1f1f1.png',
  },
  '🇸🇲': {
    name: 'san marino flag',
    dotName: ':flag-sm:',
    code: 'U+1F1F8 U+1F1F2',
    img: '1f1f8-1f1f2.png',
  },
  '🇸🇳': {
    name: 'senegal flag',
    dotName: ':flag-sn:',
    code: 'U+1F1F8 U+1F1F3',
    img: '1f1f8-1f1f3.png',
  },
  '🇸🇴': {
    name: 'somalia flag',
    dotName: ':flag-so:',
    code: 'U+1F1F8 U+1F1F4',
    img: '1f1f8-1f1f4.png',
  },
  '🇸🇷': {
    name: 'suriname flag',
    dotName: ':flag-sr:',
    code: 'U+1F1F8 U+1F1F7',
    img: '1f1f8-1f1f7.png',
  },
  '🇸🇸': {
    name: 'south sudan flag',
    dotName: ':flag-ss:',
    code: 'U+1F1F8 U+1F1F8',
    img: '1f1f8-1f1f8.png',
  },
  '🇸🇹': {
    name: 'são tomé & príncipe flag',
    dotName: ':flag-st:',
    code: 'U+1F1F8 U+1F1F9',
    img: '1f1f8-1f1f9.png',
  },
  '🇸🇻': {
    name: 'el salvador flag',
    dotName: ':flag-sv:',
    code: 'U+1F1F8 U+1F1FB',
    img: '1f1f8-1f1fb.png',
  },
  '🇸🇽': {
    name: 'sint maarten flag',
    dotName: ':flag-sx:',
    code: 'U+1F1F8 U+1F1FD',
    img: '1f1f8-1f1fd.png',
  },
  '🇸🇾': {
    name: 'syria flag',
    dotName: ':flag-sy:',
    code: 'U+1F1F8 U+1F1FE',
    img: '1f1f8-1f1fe.png',
  },
  '🇸🇿': {
    name: 'eswatini flag',
    dotName: ':flag-sz:',
    code: 'U+1F1F8 U+1F1FF',
    img: '1f1f8-1f1ff.png',
  },
  '🇹🇦': {
    name: 'tristan da cunha flag',
    dotName: ':flag-ta:',
    code: 'U+1F1F9 U+1F1E6',
    img: '1f1f9-1f1e6.png',
  },
  '🇹🇨': {
    name: 'turks & caicos islands flag',
    dotName: ':flag-tc:',
    code: 'U+1F1F9 U+1F1E8',
    img: '1f1f9-1f1e8.png',
  },
  '🇹🇩': {
    name: 'chad flag',
    dotName: ':flag-td:',
    code: 'U+1F1F9 U+1F1E9',
    img: '1f1f9-1f1e9.png',
  },
  '🇹🇫': {
    name: 'french southern territories flag',
    dotName: ':flag-tf:',
    code: 'U+1F1F9 U+1F1EB',
    img: '1f1f9-1f1eb.png',
  },
  '🇹🇬': {
    name: 'togo flag',
    dotName: ':flag-tg:',
    code: 'U+1F1F9 U+1F1EC',
    img: '1f1f9-1f1ec.png',
  },
  '🇹🇭': {
    name: 'thailand flag',
    dotName: ':flag-th:',
    code: 'U+1F1F9 U+1F1ED',
    img: '1f1f9-1f1ed.png',
  },
  '🇹🇯': {
    name: 'tajikistan flag',
    dotName: ':flag-tj:',
    code: 'U+1F1F9 U+1F1EF',
    img: '1f1f9-1f1ef.png',
  },
  '🇹🇰': {
    name: 'tokelau flag',
    dotName: ':flag-tk:',
    code: 'U+1F1F9 U+1F1F0',
    img: '1f1f9-1f1f0.png',
  },
  '🇹🇱': {
    name: 'timor-leste flag',
    dotName: ':flag-tl:',
    code: 'U+1F1F9 U+1F1F1',
    img: '1f1f9-1f1f1.png',
  },
  '🇹🇲': {
    name: 'turkmenistan flag',
    dotName: ':flag-tm:',
    code: 'U+1F1F9 U+1F1F2',
    img: '1f1f9-1f1f2.png',
  },
  '🇹🇳': {
    name: 'tunisia flag',
    dotName: ':flag-tn:',
    code: 'U+1F1F9 U+1F1F3',
    img: '1f1f9-1f1f3.png',
  },
  '🇹🇴': {
    name: 'tonga flag',
    dotName: ':flag-to:',
    code: 'U+1F1F9 U+1F1F4',
    img: '1f1f9-1f1f4.png',
  },
  '🇹🇷': {
    name: 'turkey flag',
    dotName: ':flag-tr:',
    code: 'U+1F1F9 U+1F1F7',
    img: '1f1f9-1f1f7.png',
  },
  '🇹🇹': {
    name: 'trinidad & tobago flag',
    dotName: ':flag-tt:',
    code: 'U+1F1F9 U+1F1F9',
    img: '1f1f9-1f1f9.png',
  },
  '🇹🇻': {
    name: 'tuvalu flag',
    dotName: ':flag-tv:',
    code: 'U+1F1F9 U+1F1FB',
    img: '1f1f9-1f1fb.png',
  },
  '🇹🇼': {
    name: 'taiwan flag',
    dotName: ':flag-tw:',
    code: 'U+1F1F9 U+1F1FC',
    img: '1f1f9-1f1fc.png',
  },
  '🇹🇿': {
    name: 'tanzania flag',
    dotName: ':flag-tz:',
    code: 'U+1F1F9 U+1F1FF',
    img: '1f1f9-1f1ff.png',
  },
  '🇺🇦': {
    name: 'ukraine flag',
    dotName: ':flag-ua:',
    code: 'U+1F1FA U+1F1E6',
    img: '1f1fa-1f1e6.png',
  },
  '🇺🇬': {
    name: 'uganda flag',
    dotName: ':flag-ug:',
    code: 'U+1F1FA U+1F1EC',
    img: '1f1fa-1f1ec.png',
  },
  '🇺🇲': {
    name: 'u.s. outlying islands flag',
    dotName: ':flag-um:',
    code: 'U+1F1FA U+1F1F2',
    img: '1f1fa-1f1f2.png',
  },
  '🇺🇳': {
    name: 'united nations flag',
    dotName: ':flag-un:',
    code: 'U+1F1FA U+1F1F3',
    img: '1f1fa-1f1f3.png',
  },
  '🇺🇸': {
    name: 'united states flag',
    dotName: ':us:',
    code: 'U+1F1FA U+1F1F8',
    img: '1f1fa-1f1f8.png',
  },
  '🇺🇾': {
    name: 'uruguay flag',
    dotName: ':flag-uy:',
    code: 'U+1F1FA U+1F1FE',
    img: '1f1fa-1f1fe.png',
  },
  '🇺🇿': {
    name: 'uzbekistan flag',
    dotName: ':flag-uz:',
    code: 'U+1F1FA U+1F1FF',
    img: '1f1fa-1f1ff.png',
  },
  '🇻🇦': {
    name: 'vatican city flag',
    dotName: ':flag-va:',
    code: 'U+1F1FB U+1F1E6',
    img: '1f1fb-1f1e6.png',
  },
  '🇻🇨': {
    name: 'st. vincent & grenadines flag',
    dotName: ':flag-vc:',
    code: 'U+1F1FB U+1F1E8',
    img: '1f1fb-1f1e8.png',
  },
  '🇻🇪': {
    name: 'venezuela flag',
    dotName: ':flag-ve:',
    code: 'U+1F1FB U+1F1EA',
    img: '1f1fb-1f1ea.png',
  },
  '🇻🇬': {
    name: 'british virgin islands flag',
    dotName: ':flag-vg:',
    code: 'U+1F1FB U+1F1EC',
    img: '1f1fb-1f1ec.png',
  },
  '🇻🇮': {
    name: 'u.s. virgin islands flag',
    dotName: ':flag-vi:',
    code: 'U+1F1FB U+1F1EE',
    img: '1f1fb-1f1ee.png',
  },
  '🇻🇳': {
    name: 'vietnam flag',
    dotName: ':flag-vn:',
    code: 'U+1F1FB U+1F1F3',
    img: '1f1fb-1f1f3.png',
  },
  '🇻🇺': {
    name: 'vanuatu flag',
    dotName: ':flag-vu:',
    code: 'U+1F1FB U+1F1FA',
    img: '1f1fb-1f1fa.png',
  },
  '🇼🇫': {
    name: 'wallis & futuna flag',
    dotName: ':flag-wf:',
    code: 'U+1F1FC U+1F1EB',
    img: '1f1fc-1f1eb.png',
  },
  '🇼🇸': {
    name: 'samoa flag',
    dotName: ':flag-ws:',
    code: 'U+1F1FC U+1F1F8',
    img: '1f1fc-1f1f8.png',
  },
  '🇽🇰': {
    name: 'kosovo flag',
    dotName: ':flag-xk:',
    code: 'U+1F1FD U+1F1F0',
    img: '1f1fd-1f1f0.png',
  },
  '🇾🇪': {
    name: 'yemen flag',
    dotName: ':flag-ye:',
    code: 'U+1F1FE U+1F1EA',
    img: '1f1fe-1f1ea.png',
  },
  '🇾🇹': {
    name: 'mayotte flag',
    dotName: ':flag-yt:',
    code: 'U+1F1FE U+1F1F9',
    img: '1f1fe-1f1f9.png',
  },
  '🇿🇦': {
    name: 'south africa flag',
    dotName: ':flag-za:',
    code: 'U+1F1FF U+1F1E6',
    img: '1f1ff-1f1e6.png',
  },
  '🇿🇲': {
    name: 'zambia flag',
    dotName: ':flag-zm:',
    code: 'U+1F1FF U+1F1F2',
    img: '1f1ff-1f1f2.png',
  },
  '🇿🇼': {
    name: 'zimbabwe flag',
    dotName: ':flag-zw:',
    code: 'U+1F1FF U+1F1FC',
    img: '1f1ff-1f1fc.png',
  },
  '🈁': {
    name: 'squared katakana koko',
    dotName: ':koko:',
    code: 'U+1F201',
    img: '1f201.png',
  },
  '🈂️': {
    name: 'squared katakana sa',
    dotName: ':sa:',
    code: 'U+1F202 U+FE0F',
    img: '1f202-fe0f.png',
  },
  '🈚': {
    name: 'squared cjk unified ideograph-7121',
    dotName: ':u7121:',
    code: 'U+1F21A',
    img: '1f21a.png',
  },
  '🈯': {
    name: 'squared cjk unified ideograph-6307',
    dotName: ':u6307:',
    code: 'U+1F22F',
    img: '1f22f.png',
  },
  '🈲': {
    name: 'squared cjk unified ideograph-7981',
    dotName: ':u7981:',
    code: 'U+1F232',
    img: '1f232.png',
  },
  '🈳': {
    name: 'squared cjk unified ideograph-7a7a',
    dotName: ':u7a7a:',
    code: 'U+1F233',
    img: '1f233.png',
  },
  '🈴': {
    name: 'squared cjk unified ideograph-5408',
    dotName: ':u5408:',
    code: 'U+1F234',
    img: '1f234.png',
  },
  '🈵': {
    name: 'squared cjk unified ideograph-6e80',
    dotName: ':u6e80:',
    code: 'U+1F235',
    img: '1f235.png',
  },
  '🈶': {
    name: 'squared cjk unified ideograph-6709',
    dotName: ':u6709:',
    code: 'U+1F236',
    img: '1f236.png',
  },
  '🈷️': {
    name: 'squared cjk unified ideograph-6708',
    dotName: ':u6708:',
    code: 'U+1F237 U+FE0F',
    img: '1f237-fe0f.png',
  },
  '🈸': {
    name: 'squared cjk unified ideograph-7533',
    dotName: ':u7533:',
    code: 'U+1F238',
    img: '1f238.png',
  },
  '🈹': {
    name: 'squared cjk unified ideograph-5272',
    dotName: ':u5272:',
    code: 'U+1F239',
    img: '1f239.png',
  },
  '🈺': {
    name: 'squared cjk unified ideograph-55b6',
    dotName: ':u55b6:',
    code: 'U+1F23A',
    img: '1f23a.png',
  },
  '🉐': {
    name: 'circled ideograph advantage',
    dotName: ':ideograph_advantage:',
    code: 'U+1F250',
    img: '1f250.png',
  },
  '🉑': {
    name: 'circled ideograph accept',
    dotName: ':accept:',
    code: 'U+1F251',
    img: '1f251.png',
  },
  '🌀': {
    name: 'cyclone',
    dotName: ':cyclone:',
    code: 'U+1F300',
    img: '1f300.png',
  },
  '🌁': {
    name: 'foggy',
    dotName: ':foggy:',
    code: 'U+1F301',
    img: '1f301.png',
  },
  '🌂': {
    name: 'closed umbrella',
    dotName: ':closed_umbrella:',
    code: 'U+1F302',
    img: '1f302.png',
  },
  '🌃': {
    name: 'night with stars',
    dotName: ':night_with_stars:',
    code: 'U+1F303',
    img: '1f303.png',
  },
  '🌄': {
    name: 'sunrise over mountains',
    dotName: ':sunrise_over_mountains:',
    code: 'U+1F304',
    img: '1f304.png',
  },
  '🌅': {
    name: 'sunrise',
    dotName: ':sunrise:',
    code: 'U+1F305',
    img: '1f305.png',
  },
  '🌆': {
    name: 'cityscape at dusk',
    dotName: ':city_sunset:',
    code: 'U+1F306',
    img: '1f306.png',
  },
  '🌇': {
    name: 'sunset over buildings',
    dotName: ':city_sunrise:',
    code: 'U+1F307',
    img: '1f307.png',
  },
  '🌈': {
    name: 'rainbow',
    dotName: ':rainbow:',
    code: 'U+1F308',
    img: '1f308.png',
  },
  '🌉': {
    name: 'bridge at night',
    dotName: ':bridge_at_night:',
    code: 'U+1F309',
    img: '1f309.png',
  },
  '🌊': {
    name: 'water wave',
    dotName: ':ocean:',
    code: 'U+1F30A',
    img: '1f30a.png',
  },
  '🌋': {
    name: 'volcano',
    dotName: ':volcano:',
    code: 'U+1F30B',
    img: '1f30b.png',
  },
  '🌌': {
    name: 'milky way',
    dotName: ':milky_way:',
    code: 'U+1F30C',
    img: '1f30c.png',
  },
  '🌍': {
    name: 'earth globe europe-africa',
    dotName: ':earth_africa:',
    code: 'U+1F30D',
    img: '1f30d.png',
  },
  '🌎': {
    name: 'earth globe americas',
    dotName: ':earth_americas:',
    code: 'U+1F30E',
    img: '1f30e.png',
  },
  '🌏': {
    name: 'earth globe asia-australia',
    dotName: ':earth_asia:',
    code: 'U+1F30F',
    img: '1f30f.png',
  },
  '🌐': {
    name: 'globe with meridians',
    dotName: ':globe_with_meridians:',
    code: 'U+1F310',
    img: '1f310.png',
  },
  '🌑': {
    name: 'new moon symbol',
    dotName: ':new_moon:',
    code: 'U+1F311',
    img: '1f311.png',
  },
  '🌒': {
    name: 'waxing crescent moon symbol',
    dotName: ':waxing_crescent_moon:',
    code: 'U+1F312',
    img: '1f312.png',
  },
  '🌓': {
    name: 'first quarter moon symbol',
    dotName: ':first_quarter_moon:',
    code: 'U+1F313',
    img: '1f313.png',
  },
  '🌔': {
    name: 'waxing gibbous moon symbol',
    dotName: ':moon:',
    code: 'U+1F314',
    img: '1f314.png',
  },
  '🌕': {
    name: 'full moon symbol',
    dotName: ':full_moon:',
    code: 'U+1F315',
    img: '1f315.png',
  },
  '🌖': {
    name: 'waning gibbous moon symbol',
    dotName: ':waning_gibbous_moon:',
    code: 'U+1F316',
    img: '1f316.png',
  },
  '🌗': {
    name: 'last quarter moon symbol',
    dotName: ':last_quarter_moon:',
    code: 'U+1F317',
    img: '1f317.png',
  },
  '🌘': {
    name: 'waning crescent moon symbol',
    dotName: ':waning_crescent_moon:',
    code: 'U+1F318',
    img: '1f318.png',
  },
  '🌙': {
    name: 'crescent moon',
    dotName: ':crescent_moon:',
    code: 'U+1F319',
    img: '1f319.png',
  },
  '🌚': {
    name: 'new moon with face',
    dotName: ':new_moon_with_face:',
    code: 'U+1F31A',
    img: '1f31a.png',
  },
  '🌛': {
    name: 'first quarter moon with face',
    dotName: ':first_quarter_moon_with_face:',
    code: 'U+1F31B',
    img: '1f31b.png',
  },
  '🌜': {
    name: 'last quarter moon with face',
    dotName: ':last_quarter_moon_with_face:',
    code: 'U+1F31C',
    img: '1f31c.png',
  },
  '🌝': {
    name: 'full moon with face',
    dotName: ':full_moon_with_face:',
    code: 'U+1F31D',
    img: '1f31d.png',
  },
  '🌞': {
    name: 'sun with face',
    dotName: ':sun_with_face:',
    code: 'U+1F31E',
    img: '1f31e.png',
  },
  '🌟': {
    name: 'glowing star',
    dotName: ':star2:',
    code: 'U+1F31F',
    img: '1f31f.png',
  },
  '🌠': {
    name: 'shooting star',
    dotName: ':stars:',
    code: 'U+1F320',
    img: '1f320.png',
  },
  '🌡️': {
    name: '',
    dotName: ':thermometer:',
    code: 'U+1F321 U+FE0F',
    img: '1f321-fe0f.png',
  },
  '🌤️': {
    name: '',
    dotName: ':mostly_sunny:',
    code: 'U+1F324 U+FE0F',
    img: '1f324-fe0f.png',
  },
  '🌥️': {
    name: '',
    dotName: ':barely_sunny:',
    code: 'U+1F325 U+FE0F',
    img: '1f325-fe0f.png',
  },
  '🌦️': {
    name: '',
    dotName: ':partly_sunny_rain:',
    code: 'U+1F326 U+FE0F',
    img: '1f326-fe0f.png',
  },
  '🌧️': {
    name: '',
    dotName: ':rain_cloud:',
    code: 'U+1F327 U+FE0F',
    img: '1f327-fe0f.png',
  },
  '🌨️': {
    name: '',
    dotName: ':snow_cloud:',
    code: 'U+1F328 U+FE0F',
    img: '1f328-fe0f.png',
  },
  '🌩️': {
    name: '',
    dotName: ':lightning:',
    code: 'U+1F329 U+FE0F',
    img: '1f329-fe0f.png',
  },
  '🌪️': {
    name: '',
    dotName: ':tornado:',
    code: 'U+1F32A U+FE0F',
    img: '1f32a-fe0f.png',
  },
  '🌫️': {
    name: '',
    dotName: ':fog:',
    code: 'U+1F32B U+FE0F',
    img: '1f32b-fe0f.png',
  },
  '🌬️': {
    name: '',
    dotName: ':wind_blowing_face:',
    code: 'U+1F32C U+FE0F',
    img: '1f32c-fe0f.png',
  },
  '🌭': {
    name: 'hot dog',
    dotName: ':hotdog:',
    code: 'U+1F32D',
    img: '1f32d.png',
  },
  '🌮': {
    name: 'taco',
    dotName: ':taco:',
    code: 'U+1F32E',
    img: '1f32e.png',
  },
  '🌯': {
    name: 'burrito',
    dotName: ':burrito:',
    code: 'U+1F32F',
    img: '1f32f.png',
  },
  '🌰': {
    name: 'chestnut',
    dotName: ':chestnut:',
    code: 'U+1F330',
    img: '1f330.png',
  },
  '🌱': {
    name: 'seedling',
    dotName: ':seedling:',
    code: 'U+1F331',
    img: '1f331.png',
  },
  '🌲': {
    name: 'evergreen tree',
    dotName: ':evergreen_tree:',
    code: 'U+1F332',
    img: '1f332.png',
  },
  '🌳': {
    name: 'deciduous tree',
    dotName: ':deciduous_tree:',
    code: 'U+1F333',
    img: '1f333.png',
  },
  '🌴': {
    name: 'palm tree',
    dotName: ':palm_tree:',
    code: 'U+1F334',
    img: '1f334.png',
  },
  '🌵': {
    name: 'cactus',
    dotName: ':cactus:',
    code: 'U+1F335',
    img: '1f335.png',
  },
  '🌶️': {
    name: '',
    dotName: ':hot_pepper:',
    code: 'U+1F336 U+FE0F',
    img: '1f336-fe0f.png',
  },
  '🌷': {
    name: 'tulip',
    dotName: ':tulip:',
    code: 'U+1F337',
    img: '1f337.png',
  },
  '🌸': {
    name: 'cherry blossom',
    dotName: ':cherry_blossom:',
    code: 'U+1F338',
    img: '1f338.png',
  },
  '🌹': {
    name: 'rose',
    dotName: ':rose:',
    code: 'U+1F339',
    img: '1f339.png',
  },
  '🌺': {
    name: 'hibiscus',
    dotName: ':hibiscus:',
    code: 'U+1F33A',
    img: '1f33a.png',
  },
  '🌻': {
    name: 'sunflower',
    dotName: ':sunflower:',
    code: 'U+1F33B',
    img: '1f33b.png',
  },
  '🌼': {
    name: 'blossom',
    dotName: ':blossom:',
    code: 'U+1F33C',
    img: '1f33c.png',
  },
  '🌽': {
    name: 'ear of maize',
    dotName: ':corn:',
    code: 'U+1F33D',
    img: '1f33d.png',
  },
  '🌾': {
    name: 'ear of rice',
    dotName: ':ear_of_rice:',
    code: 'U+1F33E',
    img: '1f33e.png',
  },
  '🌿': {
    name: 'herb',
    dotName: ':herb:',
    code: 'U+1F33F',
    img: '1f33f.png',
  },
  '🍀': {
    name: 'four leaf clover',
    dotName: ':four_leaf_clover:',
    code: 'U+1F340',
    img: '1f340.png',
  },
  '🍁': {
    name: 'maple leaf',
    dotName: ':maple_leaf:',
    code: 'U+1F341',
    img: '1f341.png',
  },
  '🍂': {
    name: 'fallen leaf',
    dotName: ':fallen_leaf:',
    code: 'U+1F342',
    img: '1f342.png',
  },
  '🍃': {
    name: 'leaf fluttering in wind',
    dotName: ':leaves:',
    code: 'U+1F343',
    img: '1f343.png',
  },
  '🍄': {
    name: 'mushroom',
    dotName: ':mushroom:',
    code: 'U+1F344',
    img: '1f344.png',
  },
  '🍅': {
    name: 'tomato',
    dotName: ':tomato:',
    code: 'U+1F345',
    img: '1f345.png',
  },
  '🍆': {
    name: 'aubergine',
    dotName: ':eggplant:',
    code: 'U+1F346',
    img: '1f346.png',
  },
  '🍇': {
    name: 'grapes',
    dotName: ':grapes:',
    code: 'U+1F347',
    img: '1f347.png',
  },
  '🍈': {
    name: 'melon',
    dotName: ':melon:',
    code: 'U+1F348',
    img: '1f348.png',
  },
  '🍉': {
    name: 'watermelon',
    dotName: ':watermelon:',
    code: 'U+1F349',
    img: '1f349.png',
  },
  '🍊': {
    name: 'tangerine',
    dotName: ':tangerine:',
    code: 'U+1F34A',
    img: '1f34a.png',
  },
  '🍋': {
    name: 'lemon',
    dotName: ':lemon:',
    code: 'U+1F34B',
    img: '1f34b.png',
  },
  '🍌': {
    name: 'banana',
    dotName: ':banana:',
    code: 'U+1F34C',
    img: '1f34c.png',
  },
  '🍍': {
    name: 'pineapple',
    dotName: ':pineapple:',
    code: 'U+1F34D',
    img: '1f34d.png',
  },
  '🍎': {
    name: 'red apple',
    dotName: ':apple:',
    code: 'U+1F34E',
    img: '1f34e.png',
  },
  '🍏': {
    name: 'green apple',
    dotName: ':green_apple:',
    code: 'U+1F34F',
    img: '1f34f.png',
  },
  '🍐': {
    name: 'pear',
    dotName: ':pear:',
    code: 'U+1F350',
    img: '1f350.png',
  },
  '🍑': {
    name: 'peach',
    dotName: ':peach:',
    code: 'U+1F351',
    img: '1f351.png',
  },
  '🍒': {
    name: 'cherries',
    dotName: ':cherries:',
    code: 'U+1F352',
    img: '1f352.png',
  },
  '🍓': {
    name: 'strawberry',
    dotName: ':strawberry:',
    code: 'U+1F353',
    img: '1f353.png',
  },
  '🍔': {
    name: 'hamburger',
    dotName: ':hamburger:',
    code: 'U+1F354',
    img: '1f354.png',
  },
  '🍕': {
    name: 'slice of pizza',
    dotName: ':pizza:',
    code: 'U+1F355',
    img: '1f355.png',
  },
  '🍖': {
    name: 'meat on bone',
    dotName: ':meat_on_bone:',
    code: 'U+1F356',
    img: '1f356.png',
  },
  '🍗': {
    name: 'poultry leg',
    dotName: ':poultry_leg:',
    code: 'U+1F357',
    img: '1f357.png',
  },
  '🍘': {
    name: 'rice cracker',
    dotName: ':rice_cracker:',
    code: 'U+1F358',
    img: '1f358.png',
  },
  '🍙': {
    name: 'rice ball',
    dotName: ':rice_ball:',
    code: 'U+1F359',
    img: '1f359.png',
  },
  '🍚': {
    name: 'cooked rice',
    dotName: ':rice:',
    code: 'U+1F35A',
    img: '1f35a.png',
  },
  '🍛': {
    name: 'curry and rice',
    dotName: ':curry:',
    code: 'U+1F35B',
    img: '1f35b.png',
  },
  '🍜': {
    name: 'steaming bowl',
    dotName: ':ramen:',
    code: 'U+1F35C',
    img: '1f35c.png',
  },
  '🍝': {
    name: 'spaghetti',
    dotName: ':spaghetti:',
    code: 'U+1F35D',
    img: '1f35d.png',
  },
  '🍞': {
    name: 'bread',
    dotName: ':bread:',
    code: 'U+1F35E',
    img: '1f35e.png',
  },
  '🍟': {
    name: 'french fries',
    dotName: ':fries:',
    code: 'U+1F35F',
    img: '1f35f.png',
  },
  '🍠': {
    name: 'roasted sweet potato',
    dotName: ':sweet_potato:',
    code: 'U+1F360',
    img: '1f360.png',
  },
  '🍡': {
    name: 'dango',
    dotName: ':dango:',
    code: 'U+1F361',
    img: '1f361.png',
  },
  '🍢': {
    name: 'oden',
    dotName: ':oden:',
    code: 'U+1F362',
    img: '1f362.png',
  },
  '🍣': {
    name: 'sushi',
    dotName: ':sushi:',
    code: 'U+1F363',
    img: '1f363.png',
  },
  '🍤': {
    name: 'fried shrimp',
    dotName: ':fried_shrimp:',
    code: 'U+1F364',
    img: '1f364.png',
  },
  '🍥': {
    name: 'fish cake with swirl design',
    dotName: ':fish_cake:',
    code: 'U+1F365',
    img: '1f365.png',
  },
  '🍦': {
    name: 'soft ice cream',
    dotName: ':icecream:',
    code: 'U+1F366',
    img: '1f366.png',
  },
  '🍧': {
    name: 'shaved ice',
    dotName: ':shaved_ice:',
    code: 'U+1F367',
    img: '1f367.png',
  },
  '🍨': {
    name: 'ice cream',
    dotName: ':ice_cream:',
    code: 'U+1F368',
    img: '1f368.png',
  },
  '🍩': {
    name: 'doughnut',
    dotName: ':doughnut:',
    code: 'U+1F369',
    img: '1f369.png',
  },
  '🍪': {
    name: 'cookie',
    dotName: ':cookie:',
    code: 'U+1F36A',
    img: '1f36a.png',
  },
  '🍫': {
    name: 'chocolate bar',
    dotName: ':chocolate_bar:',
    code: 'U+1F36B',
    img: '1f36b.png',
  },
  '🍬': {
    name: 'candy',
    dotName: ':candy:',
    code: 'U+1F36C',
    img: '1f36c.png',
  },
  '🍭': {
    name: 'lollipop',
    dotName: ':lollipop:',
    code: 'U+1F36D',
    img: '1f36d.png',
  },
  '🍮': {
    name: 'custard',
    dotName: ':custard:',
    code: 'U+1F36E',
    img: '1f36e.png',
  },
  '🍯': {
    name: 'honey pot',
    dotName: ':honey_pot:',
    code: 'U+1F36F',
    img: '1f36f.png',
  },
  '🍰': {
    name: 'shortcake',
    dotName: ':cake:',
    code: 'U+1F370',
    img: '1f370.png',
  },
  '🍱': {
    name: 'bento box',
    dotName: ':bento:',
    code: 'U+1F371',
    img: '1f371.png',
  },
  '🍲': {
    name: 'pot of food',
    dotName: ':stew:',
    code: 'U+1F372',
    img: '1f372.png',
  },
  '🍳': {
    name: 'cooking',
    dotName: ':fried_egg:',
    code: 'U+1F373',
    img: '1f373.png',
  },
  '🍴': {
    name: 'fork and knife',
    dotName: ':fork_and_knife:',
    code: 'U+1F374',
    img: '1f374.png',
  },
  '🍵': {
    name: 'teacup without handle',
    dotName: ':tea:',
    code: 'U+1F375',
    img: '1f375.png',
  },
  '🍶': {
    name: 'sake bottle and cup',
    dotName: ':sake:',
    code: 'U+1F376',
    img: '1f376.png',
  },
  '🍷': {
    name: 'wine glass',
    dotName: ':wine_glass:',
    code: 'U+1F377',
    img: '1f377.png',
  },
  '🍸': {
    name: 'cocktail glass',
    dotName: ':cocktail:',
    code: 'U+1F378',
    img: '1f378.png',
  },
  '🍹': {
    name: 'tropical drink',
    dotName: ':tropical_drink:',
    code: 'U+1F379',
    img: '1f379.png',
  },
  '🍺': {
    name: 'beer mug',
    dotName: ':beer:',
    code: 'U+1F37A',
    img: '1f37a.png',
  },
  '🍻': {
    name: 'clinking beer mugs',
    dotName: ':beers:',
    code: 'U+1F37B',
    img: '1f37b.png',
  },
  '🍼': {
    name: 'baby bottle',
    dotName: ':baby_bottle:',
    code: 'U+1F37C',
    img: '1f37c.png',
  },
  '🍽️': {
    name: '',
    dotName: ':knife_fork_plate:',
    code: 'U+1F37D U+FE0F',
    img: '1f37d-fe0f.png',
  },
  '🍾': {
    name: 'bottle with popping cork',
    dotName: ':champagne:',
    code: 'U+1F37E',
    img: '1f37e.png',
  },
  '🍿': {
    name: 'popcorn',
    dotName: ':popcorn:',
    code: 'U+1F37F',
    img: '1f37f.png',
  },
  '🎀': {
    name: 'ribbon',
    dotName: ':ribbon:',
    code: 'U+1F380',
    img: '1f380.png',
  },
  '🎁': {
    name: 'wrapped present',
    dotName: ':gift:',
    code: 'U+1F381',
    img: '1f381.png',
  },
  '🎂': {
    name: 'birthday cake',
    dotName: ':birthday:',
    code: 'U+1F382',
    img: '1f382.png',
  },
  '🎃': {
    name: 'jack-o-lantern',
    dotName: ':jack_o_lantern:',
    code: 'U+1F383',
    img: '1f383.png',
  },
  '🎄': {
    name: 'christmas tree',
    dotName: ':christmas_tree:',
    code: 'U+1F384',
    img: '1f384.png',
  },
  '🎅': {
    name: 'father christmas',
    dotName: ':santa:',
    code: 'U+1F385',
    img: '1f385.png',
  },
  '🎆': {
    name: 'fireworks',
    dotName: ':fireworks:',
    code: 'U+1F386',
    img: '1f386.png',
  },
  '🎇': {
    name: 'firework sparkler',
    dotName: ':sparkler:',
    code: 'U+1F387',
    img: '1f387.png',
  },
  '🎈': {
    name: 'balloon',
    dotName: ':balloon:',
    code: 'U+1F388',
    img: '1f388.png',
  },
  '🎉': {
    name: 'party popper',
    dotName: ':tada:',
    code: 'U+1F389',
    img: '1f389.png',
  },
  '🎊': {
    name: 'confetti ball',
    dotName: ':confetti_ball:',
    code: 'U+1F38A',
    img: '1f38a.png',
  },
  '🎋': {
    name: 'tanabata tree',
    dotName: ':tanabata_tree:',
    code: 'U+1F38B',
    img: '1f38b.png',
  },
  '🎌': {
    name: 'crossed flags',
    dotName: ':crossed_flags:',
    code: 'U+1F38C',
    img: '1f38c.png',
  },
  '🎍': {
    name: 'pine decoration',
    dotName: ':bamboo:',
    code: 'U+1F38D',
    img: '1f38d.png',
  },
  '🎎': {
    name: 'japanese dolls',
    dotName: ':dolls:',
    code: 'U+1F38E',
    img: '1f38e.png',
  },
  '🎏': {
    name: 'carp streamer',
    dotName: ':flags:',
    code: 'U+1F38F',
    img: '1f38f.png',
  },
  '🎐': {
    name: 'wind chime',
    dotName: ':wind_chime:',
    code: 'U+1F390',
    img: '1f390.png',
  },
  '🎑': {
    name: 'moon viewing ceremony',
    dotName: ':rice_scene:',
    code: 'U+1F391',
    img: '1f391.png',
  },
  '🎒': {
    name: 'school satchel',
    dotName: ':school_satchel:',
    code: 'U+1F392',
    img: '1f392.png',
  },
  '🎓': {
    name: 'graduation cap',
    dotName: ':mortar_board:',
    code: 'U+1F393',
    img: '1f393.png',
  },
  '🎖️': {
    name: '',
    dotName: ':medal:',
    code: 'U+1F396 U+FE0F',
    img: '1f396-fe0f.png',
  },
  '🎗️': {
    name: '',
    dotName: ':reminder_ribbon:',
    code: 'U+1F397 U+FE0F',
    img: '1f397-fe0f.png',
  },
  '🎙️': {
    name: '',
    dotName: ':studio_microphone:',
    code: 'U+1F399 U+FE0F',
    img: '1f399-fe0f.png',
  },
  '🎚️': {
    name: '',
    dotName: ':level_slider:',
    code: 'U+1F39A U+FE0F',
    img: '1f39a-fe0f.png',
  },
  '🎛️': {
    name: '',
    dotName: ':control_knobs:',
    code: 'U+1F39B U+FE0F',
    img: '1f39b-fe0f.png',
  },
  '🎞️': {
    name: '',
    dotName: ':film_frames:',
    code: 'U+1F39E U+FE0F',
    img: '1f39e-fe0f.png',
  },
  '🎟️': {
    name: '',
    dotName: ':admission_tickets:',
    code: 'U+1F39F U+FE0F',
    img: '1f39f-fe0f.png',
  },
  '🎠': {
    name: 'carousel horse',
    dotName: ':carousel_horse:',
    code: 'U+1F3A0',
    img: '1f3a0.png',
  },
  '🎡': {
    name: 'ferris wheel',
    dotName: ':ferris_wheel:',
    code: 'U+1F3A1',
    img: '1f3a1.png',
  },
  '🎢': {
    name: 'roller coaster',
    dotName: ':roller_coaster:',
    code: 'U+1F3A2',
    img: '1f3a2.png',
  },
  '🎣': {
    name: 'fishing pole and fish',
    dotName: ':fishing_pole_and_fish:',
    code: 'U+1F3A3',
    img: '1f3a3.png',
  },
  '🎤': {
    name: 'microphone',
    dotName: ':microphone:',
    code: 'U+1F3A4',
    img: '1f3a4.png',
  },
  '🎥': {
    name: 'movie camera',
    dotName: ':movie_camera:',
    code: 'U+1F3A5',
    img: '1f3a5.png',
  },
  '🎦': {
    name: 'cinema',
    dotName: ':cinema:',
    code: 'U+1F3A6',
    img: '1f3a6.png',
  },
  '🎧': {
    name: 'headphone',
    dotName: ':headphones:',
    code: 'U+1F3A7',
    img: '1f3a7.png',
  },
  '🎨': {
    name: 'artist palette',
    dotName: ':art:',
    code: 'U+1F3A8',
    img: '1f3a8.png',
  },
  '🎩': {
    name: 'top hat',
    dotName: ':tophat:',
    code: 'U+1F3A9',
    img: '1f3a9.png',
  },
  '🎪': {
    name: 'circus tent',
    dotName: ':circus_tent:',
    code: 'U+1F3AA',
    img: '1f3aa.png',
  },
  '🎫': {
    name: 'ticket',
    dotName: ':ticket:',
    code: 'U+1F3AB',
    img: '1f3ab.png',
  },
  '🎬': {
    name: 'clapper board',
    dotName: ':clapper:',
    code: 'U+1F3AC',
    img: '1f3ac.png',
  },
  '🎭': {
    name: 'performing arts',
    dotName: ':performing_arts:',
    code: 'U+1F3AD',
    img: '1f3ad.png',
  },
  '🎮': {
    name: 'video game',
    dotName: ':video_game:',
    code: 'U+1F3AE',
    img: '1f3ae.png',
  },
  '🎯': {
    name: 'direct hit',
    dotName: ':dart:',
    code: 'U+1F3AF',
    img: '1f3af.png',
  },
  '🎰': {
    name: 'slot machine',
    dotName: ':slot_machine:',
    code: 'U+1F3B0',
    img: '1f3b0.png',
  },
  '🎱': {
    name: 'billiards',
    dotName: ':8ball:',
    code: 'U+1F3B1',
    img: '1f3b1.png',
  },
  '🎲': {
    name: 'game die',
    dotName: ':game_die:',
    code: 'U+1F3B2',
    img: '1f3b2.png',
  },
  '🎳': {
    name: 'bowling',
    dotName: ':bowling:',
    code: 'U+1F3B3',
    img: '1f3b3.png',
  },
  '🎴': {
    name: 'flower playing cards',
    dotName: ':flower_playing_cards:',
    code: 'U+1F3B4',
    img: '1f3b4.png',
  },
  '🎵': {
    name: 'musical note',
    dotName: ':musical_note:',
    code: 'U+1F3B5',
    img: '1f3b5.png',
  },
  '🎶': {
    name: 'multiple musical notes',
    dotName: ':notes:',
    code: 'U+1F3B6',
    img: '1f3b6.png',
  },
  '🎷': {
    name: 'saxophone',
    dotName: ':saxophone:',
    code: 'U+1F3B7',
    img: '1f3b7.png',
  },
  '🎸': {
    name: 'guitar',
    dotName: ':guitar:',
    code: 'U+1F3B8',
    img: '1f3b8.png',
  },
  '🎹': {
    name: 'musical keyboard',
    dotName: ':musical_keyboard:',
    code: 'U+1F3B9',
    img: '1f3b9.png',
  },
  '🎺': {
    name: 'trumpet',
    dotName: ':trumpet:',
    code: 'U+1F3BA',
    img: '1f3ba.png',
  },
  '🎻': {
    name: 'violin',
    dotName: ':violin:',
    code: 'U+1F3BB',
    img: '1f3bb.png',
  },
  '🎼': {
    name: 'musical score',
    dotName: ':musical_score:',
    code: 'U+1F3BC',
    img: '1f3bc.png',
  },
  '🎽': {
    name: 'running shirt with sash',
    dotName: ':running_shirt_with_sash:',
    code: 'U+1F3BD',
    img: '1f3bd.png',
  },
  '🎾': {
    name: 'tennis racquet and ball',
    dotName: ':tennis:',
    code: 'U+1F3BE',
    img: '1f3be.png',
  },
  '🎿': {
    name: 'ski and ski boot',
    dotName: ':ski:',
    code: 'U+1F3BF',
    img: '1f3bf.png',
  },
  '🏀': {
    name: 'basketball and hoop',
    dotName: ':basketball:',
    code: 'U+1F3C0',
    img: '1f3c0.png',
  },
  '🏁': {
    name: 'chequered flag',
    dotName: ':checkered_flag:',
    code: 'U+1F3C1',
    img: '1f3c1.png',
  },
  '🏂': {
    name: 'snowboarder',
    dotName: ':snowboarder:',
    code: 'U+1F3C2',
    img: '1f3c2.png',
  },
  '🏃‍♀️': {
    name: '',
    dotName: ':woman-running:',
    code: 'U+1F3C3 U+200D U+2640 U+FE0F',
    img: '1f3c3-200d-2640-fe0f.png',
  },
  '🏃‍♂️': {
    name: '',
    dotName: ':man-running:',
    code: 'U+1F3C3 U+200D U+2642 U+FE0F',
    img: '1f3c3-200d-2642-fe0f.png',
  },
  '🏃': {
    name: 'runner',
    dotName: ':runner:',
    code: 'U+1F3C3',
    img: '1f3c3.png',
  },
  '🏄‍♀️': {
    name: '',
    dotName: ':woman-surfing:',
    code: 'U+1F3C4 U+200D U+2640 U+FE0F',
    img: '1f3c4-200d-2640-fe0f.png',
  },
  '🏄‍♂️': {
    name: '',
    dotName: ':man-surfing:',
    code: 'U+1F3C4 U+200D U+2642 U+FE0F',
    img: '1f3c4-200d-2642-fe0f.png',
  },
  '🏄': {
    name: 'surfer',
    dotName: ':surfer:',
    code: 'U+1F3C4',
    img: '1f3c4.png',
  },
  '🏅': {
    name: 'sports medal',
    dotName: ':sports_medal:',
    code: 'U+1F3C5',
    img: '1f3c5.png',
  },
  '🏆': {
    name: 'trophy',
    dotName: ':trophy:',
    code: 'U+1F3C6',
    img: '1f3c6.png',
  },
  '🏇': {
    name: 'horse racing',
    dotName: ':horse_racing:',
    code: 'U+1F3C7',
    img: '1f3c7.png',
  },
  '🏈': {
    name: 'american football',
    dotName: ':football:',
    code: 'U+1F3C8',
    img: '1f3c8.png',
  },
  '🏉': {
    name: 'rugby football',
    dotName: ':rugby_football:',
    code: 'U+1F3C9',
    img: '1f3c9.png',
  },
  '🏊‍♀️': {
    name: '',
    dotName: ':woman-swimming:',
    code: 'U+1F3CA U+200D U+2640 U+FE0F',
    img: '1f3ca-200d-2640-fe0f.png',
  },
  '🏊‍♂️': {
    name: '',
    dotName: ':man-swimming:',
    code: 'U+1F3CA U+200D U+2642 U+FE0F',
    img: '1f3ca-200d-2642-fe0f.png',
  },
  '🏊': {
    name: 'swimmer',
    dotName: ':swimmer:',
    code: 'U+1F3CA',
    img: '1f3ca.png',
  },
  '🏋️‍♀️': {
    name: '',
    dotName: ':woman-lifting-weights:',
    code: 'U+1F3CB U+FE0F U+200D U+2640 U+FE0F',
    img: '1f3cb-fe0f-200d-2640-fe0f.png',
  },
  '🏋️‍♂️': {
    name: '',
    dotName: ':man-lifting-weights:',
    code: 'U+1F3CB U+FE0F U+200D U+2642 U+FE0F',
    img: '1f3cb-fe0f-200d-2642-fe0f.png',
  },
  '🏋️': {
    name: '',
    dotName: ':weight_lifter:',
    code: 'U+1F3CB U+FE0F',
    img: '1f3cb-fe0f.png',
  },
  '🏌️‍♀️': {
    name: '',
    dotName: ':woman-golfing:',
    code: 'U+1F3CC U+FE0F U+200D U+2640 U+FE0F',
    img: '1f3cc-fe0f-200d-2640-fe0f.png',
  },
  '🏌️‍♂️': {
    name: '',
    dotName: ':man-golfing:',
    code: 'U+1F3CC U+FE0F U+200D U+2642 U+FE0F',
    img: '1f3cc-fe0f-200d-2642-fe0f.png',
  },
  '🏌️': {
    name: '',
    dotName: ':golfer:',
    code: 'U+1F3CC U+FE0F',
    img: '1f3cc-fe0f.png',
  },
  '🏍️': {
    name: '',
    dotName: ':racing_motorcycle:',
    code: 'U+1F3CD U+FE0F',
    img: '1f3cd-fe0f.png',
  },
  '🏎️': {
    name: '',
    dotName: ':racing_car:',
    code: 'U+1F3CE U+FE0F',
    img: '1f3ce-fe0f.png',
  },
  '🏏': {
    name: 'cricket bat and ball',
    dotName: ':cricket_bat_and_ball:',
    code: 'U+1F3CF',
    img: '1f3cf.png',
  },
  '🏐': {
    name: 'volleyball',
    dotName: ':volleyball:',
    code: 'U+1F3D0',
    img: '1f3d0.png',
  },
  '🏑': {
    name: 'field hockey stick and ball',
    dotName: ':field_hockey_stick_and_ball:',
    code: 'U+1F3D1',
    img: '1f3d1.png',
  },
  '🏒': {
    name: 'ice hockey stick and puck',
    dotName: ':ice_hockey_stick_and_puck:',
    code: 'U+1F3D2',
    img: '1f3d2.png',
  },
  '🏓': {
    name: 'table tennis paddle and ball',
    dotName: ':table_tennis_paddle_and_ball:',
    code: 'U+1F3D3',
    img: '1f3d3.png',
  },
  '🏔️': {
    name: '',
    dotName: ':snow_capped_mountain:',
    code: 'U+1F3D4 U+FE0F',
    img: '1f3d4-fe0f.png',
  },
  '🏕️': {
    name: '',
    dotName: ':camping:',
    code: 'U+1F3D5 U+FE0F',
    img: '1f3d5-fe0f.png',
  },
  '🏖️': {
    name: '',
    dotName: ':beach_with_umbrella:',
    code: 'U+1F3D6 U+FE0F',
    img: '1f3d6-fe0f.png',
  },
  '🏗️': {
    name: '',
    dotName: ':building_construction:',
    code: 'U+1F3D7 U+FE0F',
    img: '1f3d7-fe0f.png',
  },
  '🏘️': {
    name: '',
    dotName: ':house_buildings:',
    code: 'U+1F3D8 U+FE0F',
    img: '1f3d8-fe0f.png',
  },
  '🏙️': {
    name: '',
    dotName: ':cityscape:',
    code: 'U+1F3D9 U+FE0F',
    img: '1f3d9-fe0f.png',
  },
  '🏚️': {
    name: '',
    dotName: ':derelict_house_building:',
    code: 'U+1F3DA U+FE0F',
    img: '1f3da-fe0f.png',
  },
  '🏛️': {
    name: '',
    dotName: ':classical_building:',
    code: 'U+1F3DB U+FE0F',
    img: '1f3db-fe0f.png',
  },
  '🏜️': {
    name: '',
    dotName: ':desert:',
    code: 'U+1F3DC U+FE0F',
    img: '1f3dc-fe0f.png',
  },
  '🏝️': {
    name: '',
    dotName: ':desert_island:',
    code: 'U+1F3DD U+FE0F',
    img: '1f3dd-fe0f.png',
  },
  '🏞️': {
    name: '',
    dotName: ':national_park:',
    code: 'U+1F3DE U+FE0F',
    img: '1f3de-fe0f.png',
  },
  '🏟️': {
    name: '',
    dotName: ':stadium:',
    code: 'U+1F3DF U+FE0F',
    img: '1f3df-fe0f.png',
  },
  '🏠': {
    name: 'house building',
    dotName: ':house:',
    code: 'U+1F3E0',
    img: '1f3e0.png',
  },
  '🏡': {
    name: 'house with garden',
    dotName: ':house_with_garden:',
    code: 'U+1F3E1',
    img: '1f3e1.png',
  },
  '🏢': {
    name: 'office building',
    dotName: ':office:',
    code: 'U+1F3E2',
    img: '1f3e2.png',
  },
  '🏣': {
    name: 'japanese post office',
    dotName: ':post_office:',
    code: 'U+1F3E3',
    img: '1f3e3.png',
  },
  '🏤': {
    name: 'european post office',
    dotName: ':european_post_office:',
    code: 'U+1F3E4',
    img: '1f3e4.png',
  },
  '🏥': {
    name: 'hospital',
    dotName: ':hospital:',
    code: 'U+1F3E5',
    img: '1f3e5.png',
  },
  '🏦': {
    name: 'bank',
    dotName: ':bank:',
    code: 'U+1F3E6',
    img: '1f3e6.png',
  },
  '🏧': {
    name: 'automated teller machine',
    dotName: ':atm:',
    code: 'U+1F3E7',
    img: '1f3e7.png',
  },
  '🏨': {
    name: 'hotel',
    dotName: ':hotel:',
    code: 'U+1F3E8',
    img: '1f3e8.png',
  },
  '🏩': {
    name: 'love hotel',
    dotName: ':love_hotel:',
    code: 'U+1F3E9',
    img: '1f3e9.png',
  },
  '🏪': {
    name: 'convenience store',
    dotName: ':convenience_store:',
    code: 'U+1F3EA',
    img: '1f3ea.png',
  },
  '🏫': {
    name: 'school',
    dotName: ':school:',
    code: 'U+1F3EB',
    img: '1f3eb.png',
  },
  '🏬': {
    name: 'department store',
    dotName: ':department_store:',
    code: 'U+1F3EC',
    img: '1f3ec.png',
  },
  '🏭': {
    name: 'factory',
    dotName: ':factory:',
    code: 'U+1F3ED',
    img: '1f3ed.png',
  },
  '🏮': {
    name: 'izakaya lantern',
    dotName: ':izakaya_lantern:',
    code: 'U+1F3EE',
    img: '1f3ee.png',
  },
  '🏯': {
    name: 'japanese castle',
    dotName: ':japanese_castle:',
    code: 'U+1F3EF',
    img: '1f3ef.png',
  },
  '🏰': {
    name: 'european castle',
    dotName: ':european_castle:',
    code: 'U+1F3F0',
    img: '1f3f0.png',
  },
  '🏳️‍🌈': {
    name: '',
    dotName: ':rainbow-flag:',
    code: 'U+1F3F3 U+FE0F U+200D U+1F308',
    img: '1f3f3-fe0f-200d-1f308.png',
  },
  '🏳️‍⚧️': {
    name: '',
    dotName: ':transgender_flag:',
    code: 'U+1F3F3 U+FE0F U+200D U+26A7 U+FE0F',
    img: '1f3f3-fe0f-200d-26a7-fe0f.png',
  },
  '🏳️': {
    name: '',
    dotName: ':waving_white_flag:',
    code: 'U+1F3F3 U+FE0F',
    img: '1f3f3-fe0f.png',
  },
  '🏴‍☠️': {
    name: '',
    dotName: ':pirate_flag:',
    code: 'U+1F3F4 U+200D U+2620 U+FE0F',
    img: '1f3f4-200d-2620-fe0f.png',
  },
  '🏴󠁧󠁢󠁥󠁮󠁧󠁿': {
    name: 'england flag',
    dotName: ':flag-england:',
    code: 'U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F',
    img: '1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png',
  },
  '🏴󠁧󠁢󠁳󠁣󠁴󠁿': {
    name: 'scotland flag',
    dotName: ':flag-scotland:',
    code: 'U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F',
    img: '1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png',
  },
  '🏴󠁧󠁢󠁷󠁬󠁳󠁿': {
    name: 'wales flag',
    dotName: ':flag-wales:',
    code: 'U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F',
    img: '1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png',
  },
  '🏴': {
    name: 'waving black flag',
    dotName: ':waving_black_flag:',
    code: 'U+1F3F4',
    img: '1f3f4.png',
  },
  '🏵️': {
    name: '',
    dotName: ':rosette:',
    code: 'U+1F3F5 U+FE0F',
    img: '1f3f5-fe0f.png',
  },
  '🏷️': {
    name: '',
    dotName: ':label:',
    code: 'U+1F3F7 U+FE0F',
    img: '1f3f7-fe0f.png',
  },
  '🏸': {
    name: 'badminton racquet and shuttlecock',
    dotName: ':badminton_racquet_and_shuttlecock:',
    code: 'U+1F3F8',
    img: '1f3f8.png',
  },
  '🏹': {
    name: 'bow and arrow',
    dotName: ':bow_and_arrow:',
    code: 'U+1F3F9',
    img: '1f3f9.png',
  },
  '🏺': {
    name: 'amphora',
    dotName: ':amphora:',
    code: 'U+1F3FA',
    img: '1f3fa.png',
  },
  '🏻': {
    name: 'emoji modifier fitzpatrick type-1-2',
    dotName: ':skin-tone-2:',
    code: 'U+1F3FB',
    img: '1f3fb.png',
  },
  '🏼': {
    name: 'emoji modifier fitzpatrick type-3',
    dotName: ':skin-tone-3:',
    code: 'U+1F3FC',
    img: '1f3fc.png',
  },
  '🏽': {
    name: 'emoji modifier fitzpatrick type-4',
    dotName: ':skin-tone-4:',
    code: 'U+1F3FD',
    img: '1f3fd.png',
  },
  '🏾': {
    name: 'emoji modifier fitzpatrick type-5',
    dotName: ':skin-tone-5:',
    code: 'U+1F3FE',
    img: '1f3fe.png',
  },
  '🏿': {
    name: 'emoji modifier fitzpatrick type-6',
    dotName: ':skin-tone-6:',
    code: 'U+1F3FF',
    img: '1f3ff.png',
  },
  '🐀': {
    name: 'rat',
    dotName: ':rat:',
    code: 'U+1F400',
    img: '1f400.png',
  },
  '🐁': {
    name: 'mouse',
    dotName: ':mouse2:',
    code: 'U+1F401',
    img: '1f401.png',
  },
  '🐂': {
    name: 'ox',
    dotName: ':ox:',
    code: 'U+1F402',
    img: '1f402.png',
  },
  '🐃': {
    name: 'water buffalo',
    dotName: ':water_buffalo:',
    code: 'U+1F403',
    img: '1f403.png',
  },
  '🐄': {
    name: 'cow',
    dotName: ':cow2:',
    code: 'U+1F404',
    img: '1f404.png',
  },
  '🐅': {
    name: 'tiger',
    dotName: ':tiger2:',
    code: 'U+1F405',
    img: '1f405.png',
  },
  '🐆': {
    name: 'leopard',
    dotName: ':leopard:',
    code: 'U+1F406',
    img: '1f406.png',
  },
  '🐇': {
    name: 'rabbit',
    dotName: ':rabbit2:',
    code: 'U+1F407',
    img: '1f407.png',
  },
  '🐈‍⬛': {
    name: '',
    dotName: ':black_cat:',
    code: 'U+1F408 U+200D U+2B1B',
    img: '1f408-200d-2b1b.png',
  },
  '🐈': {
    name: 'cat',
    dotName: ':cat2:',
    code: 'U+1F408',
    img: '1f408.png',
  },
  '🐉': {
    name: 'dragon',
    dotName: ':dragon:',
    code: 'U+1F409',
    img: '1f409.png',
  },
  '🐊': {
    name: 'crocodile',
    dotName: ':crocodile:',
    code: 'U+1F40A',
    img: '1f40a.png',
  },
  '🐋': {
    name: 'whale',
    dotName: ':whale2:',
    code: 'U+1F40B',
    img: '1f40b.png',
  },
  '🐌': {
    name: 'snail',
    dotName: ':snail:',
    code: 'U+1F40C',
    img: '1f40c.png',
  },
  '🐍': {
    name: 'snake',
    dotName: ':snake:',
    code: 'U+1F40D',
    img: '1f40d.png',
  },
  '🐎': {
    name: 'horse',
    dotName: ':racehorse:',
    code: 'U+1F40E',
    img: '1f40e.png',
  },
  '🐏': {
    name: 'ram',
    dotName: ':ram:',
    code: 'U+1F40F',
    img: '1f40f.png',
  },
  '🐐': {
    name: 'goat',
    dotName: ':goat:',
    code: 'U+1F410',
    img: '1f410.png',
  },
  '🐑': {
    name: 'sheep',
    dotName: ':sheep:',
    code: 'U+1F411',
    img: '1f411.png',
  },
  '🐒': {
    name: 'monkey',
    dotName: ':monkey:',
    code: 'U+1F412',
    img: '1f412.png',
  },
  '🐓': {
    name: 'rooster',
    dotName: ':rooster:',
    code: 'U+1F413',
    img: '1f413.png',
  },
  '🐔': {
    name: 'chicken',
    dotName: ':chicken:',
    code: 'U+1F414',
    img: '1f414.png',
  },
  '🐕‍🦺': {
    name: '',
    dotName: ':service_dog:',
    code: 'U+1F415 U+200D U+1F9BA',
    img: '1f415-200d-1f9ba.png',
  },
  '🐕': {
    name: 'dog',
    dotName: ':dog2:',
    code: 'U+1F415',
    img: '1f415.png',
  },
  '🐖': {
    name: 'pig',
    dotName: ':pig2:',
    code: 'U+1F416',
    img: '1f416.png',
  },
  '🐗': {
    name: 'boar',
    dotName: ':boar:',
    code: 'U+1F417',
    img: '1f417.png',
  },
  '🐘': {
    name: 'elephant',
    dotName: ':elephant:',
    code: 'U+1F418',
    img: '1f418.png',
  },
  '🐙': {
    name: 'octopus',
    dotName: ':octopus:',
    code: 'U+1F419',
    img: '1f419.png',
  },
  '🐚': {
    name: 'spiral shell',
    dotName: ':shell:',
    code: 'U+1F41A',
    img: '1f41a.png',
  },
  '🐛': {
    name: 'bug',
    dotName: ':bug:',
    code: 'U+1F41B',
    img: '1f41b.png',
  },
  '🐜': {
    name: 'ant',
    dotName: ':ant:',
    code: 'U+1F41C',
    img: '1f41c.png',
  },
  '🐝': {
    name: 'honeybee',
    dotName: ':bee:',
    code: 'U+1F41D',
    img: '1f41d.png',
  },
  '🐞': {
    name: 'lady beetle',
    dotName: ':beetle:',
    code: 'U+1F41E',
    img: '1f41e.png',
  },
  '🐟': {
    name: 'fish',
    dotName: ':fish:',
    code: 'U+1F41F',
    img: '1f41f.png',
  },
  '🐠': {
    name: 'tropical fish',
    dotName: ':tropical_fish:',
    code: 'U+1F420',
    img: '1f420.png',
  },
  '🐡': {
    name: 'blowfish',
    dotName: ':blowfish:',
    code: 'U+1F421',
    img: '1f421.png',
  },
  '🐢': {
    name: 'turtle',
    dotName: ':turtle:',
    code: 'U+1F422',
    img: '1f422.png',
  },
  '🐣': {
    name: 'hatching chick',
    dotName: ':hatching_chick:',
    code: 'U+1F423',
    img: '1f423.png',
  },
  '🐤': {
    name: 'baby chick',
    dotName: ':baby_chick:',
    code: 'U+1F424',
    img: '1f424.png',
  },
  '🐥': {
    name: 'front-facing baby chick',
    dotName: ':hatched_chick:',
    code: 'U+1F425',
    img: '1f425.png',
  },
  '🐦': {
    name: 'bird',
    dotName: ':bird:',
    code: 'U+1F426',
    img: '1f426.png',
  },
  '🐧': {
    name: 'penguin',
    dotName: ':penguin:',
    code: 'U+1F427',
    img: '1f427.png',
  },
  '🐨': {
    name: 'koala',
    dotName: ':koala:',
    code: 'U+1F428',
    img: '1f428.png',
  },
  '🐩': {
    name: 'poodle',
    dotName: ':poodle:',
    code: 'U+1F429',
    img: '1f429.png',
  },
  '🐪': {
    name: 'dromedary camel',
    dotName: ':dromedary_camel:',
    code: 'U+1F42A',
    img: '1f42a.png',
  },
  '🐫': {
    name: 'bactrian camel',
    dotName: ':camel:',
    code: 'U+1F42B',
    img: '1f42b.png',
  },
  '🐬': {
    name: 'dolphin',
    dotName: ':dolphin:',
    code: 'U+1F42C',
    img: '1f42c.png',
  },
  '🐭': {
    name: 'mouse face',
    dotName: ':mouse:',
    code: 'U+1F42D',
    img: '1f42d.png',
  },
  '🐮': {
    name: 'cow face',
    dotName: ':cow:',
    code: 'U+1F42E',
    img: '1f42e.png',
  },
  '🐯': {
    name: 'tiger face',
    dotName: ':tiger:',
    code: 'U+1F42F',
    img: '1f42f.png',
  },
  '🐰': {
    name: 'rabbit face',
    dotName: ':rabbit:',
    code: 'U+1F430',
    img: '1f430.png',
  },
  '🐱': {
    name: 'cat face',
    dotName: ':cat:',
    code: 'U+1F431',
    img: '1f431.png',
  },
  '🐲': {
    name: 'dragon face',
    dotName: ':dragon_face:',
    code: 'U+1F432',
    img: '1f432.png',
  },
  '🐳': {
    name: 'spouting whale',
    dotName: ':whale:',
    code: 'U+1F433',
    img: '1f433.png',
  },
  '🐴': {
    name: 'horse face',
    dotName: ':horse:',
    code: 'U+1F434',
    img: '1f434.png',
  },
  '🐵': {
    name: 'monkey face',
    dotName: ':monkey_face:',
    code: 'U+1F435',
    img: '1f435.png',
  },
  '🐶': {
    name: 'dog face',
    dotName: ':dog:',
    code: 'U+1F436',
    img: '1f436.png',
  },
  '🐷': {
    name: 'pig face',
    dotName: ':pig:',
    code: 'U+1F437',
    img: '1f437.png',
  },
  '🐸': {
    name: 'frog face',
    dotName: ':frog:',
    code: 'U+1F438',
    img: '1f438.png',
  },
  '🐹': {
    name: 'hamster face',
    dotName: ':hamster:',
    code: 'U+1F439',
    img: '1f439.png',
  },
  '🐺': {
    name: 'wolf face',
    dotName: ':wolf:',
    code: 'U+1F43A',
    img: '1f43a.png',
  },
  '🐻‍❄️': {
    name: '',
    dotName: ':polar_bear:',
    code: 'U+1F43B U+200D U+2744 U+FE0F',
    img: '1f43b-200d-2744-fe0f.png',
  },
  '🐻': {
    name: 'bear face',
    dotName: ':bear:',
    code: 'U+1F43B',
    img: '1f43b.png',
  },
  '🐼': {
    name: 'panda face',
    dotName: ':panda_face:',
    code: 'U+1F43C',
    img: '1f43c.png',
  },
  '🐽': {
    name: 'pig nose',
    dotName: ':pig_nose:',
    code: 'U+1F43D',
    img: '1f43d.png',
  },
  '🐾': {
    name: 'paw prints',
    dotName: ':feet:',
    code: 'U+1F43E',
    img: '1f43e.png',
  },
  '🐿️': {
    name: '',
    dotName: ':chipmunk:',
    code: 'U+1F43F U+FE0F',
    img: '1f43f-fe0f.png',
  },
  '👀': {
    name: 'eyes',
    dotName: ':eyes:',
    code: 'U+1F440',
    img: '1f440.png',
  },
  '👁️‍🗨️': {
    name: '',
    dotName: ':eye-in-speech-bubble:',
    code: 'U+1F441 U+FE0F U+200D U+1F5E8 U+FE0F',
    img: '1f441-fe0f-200d-1f5e8-fe0f.png',
  },
  '👁️': {
    name: '',
    dotName: ':eye:',
    code: 'U+1F441 U+FE0F',
    img: '1f441-fe0f.png',
  },
  '👂': {
    name: 'ear',
    dotName: ':ear:',
    code: 'U+1F442',
    img: '1f442.png',
  },
  '👃': {
    name: 'nose',
    dotName: ':nose:',
    code: 'U+1F443',
    img: '1f443.png',
  },
  '👄': {
    name: 'mouth',
    dotName: ':lips:',
    code: 'U+1F444',
    img: '1f444.png',
  },
  '👅': {
    name: 'tongue',
    dotName: ':tongue:',
    code: 'U+1F445',
    img: '1f445.png',
  },
  '👆': {
    name: 'white up pointing backhand index',
    dotName: ':point_up_2:',
    code: 'U+1F446',
    img: '1f446.png',
  },
  '👇': {
    name: 'white down pointing backhand index',
    dotName: ':point_down:',
    code: 'U+1F447',
    img: '1f447.png',
  },
  '👈': {
    name: 'white left pointing backhand index',
    dotName: ':point_left:',
    code: 'U+1F448',
    img: '1f448.png',
  },
  '👉': {
    name: 'white right pointing backhand index',
    dotName: ':point_right:',
    code: 'U+1F449',
    img: '1f449.png',
  },
  '👊': {
    name: 'fisted hand sign',
    dotName: ':facepunch:',
    code: 'U+1F44A',
    img: '1f44a.png',
  },
  '👋': {
    name: 'waving hand sign',
    dotName: ':wave:',
    code: 'U+1F44B',
    img: '1f44b.png',
  },
  '👌': {
    name: 'ok hand sign',
    dotName: ':ok_hand:',
    code: 'U+1F44C',
    img: '1f44c.png',
  },
  '👍': {
    name: 'thumbs up sign',
    dotName: ':+1:',
    code: 'U+1F44D',
    img: '1f44d.png',
  },
  '👎': {
    name: 'thumbs down sign',
    dotName: ':-1:',
    code: 'U+1F44E',
    img: '1f44e.png',
  },
  '👏': {
    name: 'clapping hands sign',
    dotName: ':clap:',
    code: 'U+1F44F',
    img: '1f44f.png',
  },
  '👐': {
    name: 'open hands sign',
    dotName: ':open_hands:',
    code: 'U+1F450',
    img: '1f450.png',
  },
  '👑': {
    name: 'crown',
    dotName: ':crown:',
    code: 'U+1F451',
    img: '1f451.png',
  },
  '👒': {
    name: 'womans hat',
    dotName: ':womans_hat:',
    code: 'U+1F452',
    img: '1f452.png',
  },
  '👓': {
    name: 'eyeglasses',
    dotName: ':eyeglasses:',
    code: 'U+1F453',
    img: '1f453.png',
  },
  '👔': {
    name: 'necktie',
    dotName: ':necktie:',
    code: 'U+1F454',
    img: '1f454.png',
  },
  '👕': {
    name: 't-shirt',
    dotName: ':shirt:',
    code: 'U+1F455',
    img: '1f455.png',
  },
  '👖': {
    name: 'jeans',
    dotName: ':jeans:',
    code: 'U+1F456',
    img: '1f456.png',
  },
  '👗': {
    name: 'dress',
    dotName: ':dress:',
    code: 'U+1F457',
    img: '1f457.png',
  },
  '👘': {
    name: 'kimono',
    dotName: ':kimono:',
    code: 'U+1F458',
    img: '1f458.png',
  },
  '👙': {
    name: 'bikini',
    dotName: ':bikini:',
    code: 'U+1F459',
    img: '1f459.png',
  },
  '👚': {
    name: 'womans clothes',
    dotName: ':womans_clothes:',
    code: 'U+1F45A',
    img: '1f45a.png',
  },
  '👛': {
    name: 'purse',
    dotName: ':purse:',
    code: 'U+1F45B',
    img: '1f45b.png',
  },
  '👜': {
    name: 'handbag',
    dotName: ':handbag:',
    code: 'U+1F45C',
    img: '1f45c.png',
  },
  '👝': {
    name: 'pouch',
    dotName: ':pouch:',
    code: 'U+1F45D',
    img: '1f45d.png',
  },
  '👞': {
    name: 'mans shoe',
    dotName: ':mans_shoe:',
    code: 'U+1F45E',
    img: '1f45e.png',
  },
  '👟': {
    name: 'athletic shoe',
    dotName: ':athletic_shoe:',
    code: 'U+1F45F',
    img: '1f45f.png',
  },
  '👠': {
    name: 'high-heeled shoe',
    dotName: ':high_heel:',
    code: 'U+1F460',
    img: '1f460.png',
  },
  '👡': {
    name: 'womans sandal',
    dotName: ':sandal:',
    code: 'U+1F461',
    img: '1f461.png',
  },
  '👢': {
    name: 'womans boots',
    dotName: ':boot:',
    code: 'U+1F462',
    img: '1f462.png',
  },
  '👣': {
    name: 'footprints',
    dotName: ':footprints:',
    code: 'U+1F463',
    img: '1f463.png',
  },
  '👤': {
    name: 'bust in silhouette',
    dotName: ':bust_in_silhouette:',
    code: 'U+1F464',
    img: '1f464.png',
  },
  '👥': {
    name: 'busts in silhouette',
    dotName: ':busts_in_silhouette:',
    code: 'U+1F465',
    img: '1f465.png',
  },
  '👦': {
    name: 'boy',
    dotName: ':boy:',
    code: 'U+1F466',
    img: '1f466.png',
  },
  '👧': {
    name: 'girl',
    dotName: ':girl:',
    code: 'U+1F467',
    img: '1f467.png',
  },
  '👨‍🌾': {
    name: '',
    dotName: ':male-farmer:',
    code: 'U+1F468 U+200D U+1F33E',
    img: '1f468-200d-1f33e.png',
  },
  '👨‍🍳': {
    name: '',
    dotName: ':male-cook:',
    code: 'U+1F468 U+200D U+1F373',
    img: '1f468-200d-1f373.png',
  },
  '👨‍🍼': {
    name: '',
    dotName: ':man_feeding_baby:',
    code: 'U+1F468 U+200D U+1F37C',
    img: '1f468-200d-1f37c.png',
  },
  '👨‍🎓': {
    name: '',
    dotName: ':male-student:',
    code: 'U+1F468 U+200D U+1F393',
    img: '1f468-200d-1f393.png',
  },
  '👨‍🎤': {
    name: '',
    dotName: ':male-singer:',
    code: 'U+1F468 U+200D U+1F3A4',
    img: '1f468-200d-1f3a4.png',
  },
  '👨‍🎨': {
    name: '',
    dotName: ':male-artist:',
    code: 'U+1F468 U+200D U+1F3A8',
    img: '1f468-200d-1f3a8.png',
  },
  '👨‍🏫': {
    name: '',
    dotName: ':male-teacher:',
    code: 'U+1F468 U+200D U+1F3EB',
    img: '1f468-200d-1f3eb.png',
  },
  '👨‍🏭': {
    name: '',
    dotName: ':male-factory-worker:',
    code: 'U+1F468 U+200D U+1F3ED',
    img: '1f468-200d-1f3ed.png',
  },
  '👨‍👦‍👦': {
    name: '',
    dotName: ':man-boy-boy:',
    code: 'U+1F468 U+200D U+1F466 U+200D U+1F466',
    img: '1f468-200d-1f466-200d-1f466.png',
  },
  '👨‍👦': {
    name: '',
    dotName: ':man-boy:',
    code: 'U+1F468 U+200D U+1F466',
    img: '1f468-200d-1f466.png',
  },
  '👨‍👧‍👦': {
    name: '',
    dotName: ':man-girl-boy:',
    code: 'U+1F468 U+200D U+1F467 U+200D U+1F466',
    img: '1f468-200d-1f467-200d-1f466.png',
  },
  '👨‍👧‍👧': {
    name: '',
    dotName: ':man-girl-girl:',
    code: 'U+1F468 U+200D U+1F467 U+200D U+1F467',
    img: '1f468-200d-1f467-200d-1f467.png',
  },
  '👨‍👧': {
    name: '',
    dotName: ':man-girl:',
    code: 'U+1F468 U+200D U+1F467',
    img: '1f468-200d-1f467.png',
  },
  '👨‍👨‍👦': {
    name: '',
    dotName: ':man-man-boy:',
    code: 'U+1F468 U+200D U+1F468 U+200D U+1F466',
    img: '1f468-200d-1f468-200d-1f466.png',
  },
  '👨‍👨‍👦‍👦': {
    name: '',
    dotName: ':man-man-boy-boy:',
    code: 'U+1F468 U+200D U+1F468 U+200D U+1F466 U+200D U+1F466',
    img: '1f468-200d-1f468-200d-1f466-200d-1f466.png',
  },
  '👨‍👨‍👧': {
    name: '',
    dotName: ':man-man-girl:',
    code: 'U+1F468 U+200D U+1F468 U+200D U+1F467',
    img: '1f468-200d-1f468-200d-1f467.png',
  },
  '👨‍👨‍👧‍👦': {
    name: '',
    dotName: ':man-man-girl-boy:',
    code: 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F466',
    img: '1f468-200d-1f468-200d-1f467-200d-1f466.png',
  },
  '👨‍👨‍👧‍👧': {
    name: '',
    dotName: ':man-man-girl-girl:',
    code: 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F467',
    img: '1f468-200d-1f468-200d-1f467-200d-1f467.png',
  },
  '👨‍👩‍👦': {
    name: '',
    dotName: ':man-woman-boy:',
    code: 'U+1F468 U+200D U+1F469 U+200D U+1F466',
    img: '1f468-200d-1f469-200d-1f466.png',
  },
  '👨‍👩‍👦‍👦': {
    name: '',
    dotName: ':man-woman-boy-boy:',
    code: 'U+1F468 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466',
    img: '1f468-200d-1f469-200d-1f466-200d-1f466.png',
  },
  '👨‍👩‍👧': {
    name: '',
    dotName: ':man-woman-girl:',
    code: 'U+1F468 U+200D U+1F469 U+200D U+1F467',
    img: '1f468-200d-1f469-200d-1f467.png',
  },
  '👨‍👩‍👧‍👦': {
    name: '',
    dotName: ':man-woman-girl-boy:',
    code: 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466',
    img: '1f468-200d-1f469-200d-1f467-200d-1f466.png',
  },
  '👨‍👩‍👧‍👧': {
    name: '',
    dotName: ':man-woman-girl-girl:',
    code: 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467',
    img: '1f468-200d-1f469-200d-1f467-200d-1f467.png',
  },
  '👨‍💻': {
    name: '',
    dotName: ':male-technologist:',
    code: 'U+1F468 U+200D U+1F4BB',
    img: '1f468-200d-1f4bb.png',
  },
  '👨‍💼': {
    name: '',
    dotName: ':male-office-worker:',
    code: 'U+1F468 U+200D U+1F4BC',
    img: '1f468-200d-1f4bc.png',
  },
  '👨‍🔧': {
    name: '',
    dotName: ':male-mechanic:',
    code: 'U+1F468 U+200D U+1F527',
    img: '1f468-200d-1f527.png',
  },
  '👨‍🔬': {
    name: '',
    dotName: ':male-scientist:',
    code: 'U+1F468 U+200D U+1F52C',
    img: '1f468-200d-1f52c.png',
  },
  '👨‍🚀': {
    name: '',
    dotName: ':male-astronaut:',
    code: 'U+1F468 U+200D U+1F680',
    img: '1f468-200d-1f680.png',
  },
  '👨‍🚒': {
    name: '',
    dotName: ':male-firefighter:',
    code: 'U+1F468 U+200D U+1F692',
    img: '1f468-200d-1f692.png',
  },
  '👨‍🦯': {
    name: '',
    dotName: ':man_with_probing_cane:',
    code: 'U+1F468 U+200D U+1F9AF',
    img: '1f468-200d-1f9af.png',
  },
  '👨‍🦰': {
    name: '',
    dotName: ':red_haired_man:',
    code: 'U+1F468 U+200D U+1F9B0',
    img: '1f468-200d-1f9b0.png',
  },
  '👨‍🦱': {
    name: '',
    dotName: ':curly_haired_man:',
    code: 'U+1F468 U+200D U+1F9B1',
    img: '1f468-200d-1f9b1.png',
  },
  '👨‍🦲': {
    name: '',
    dotName: ':bald_man:',
    code: 'U+1F468 U+200D U+1F9B2',
    img: '1f468-200d-1f9b2.png',
  },
  '👨‍🦳': {
    name: '',
    dotName: ':white_haired_man:',
    code: 'U+1F468 U+200D U+1F9B3',
    img: '1f468-200d-1f9b3.png',
  },
  '👨‍🦼': {
    name: '',
    dotName: ':man_in_motorized_wheelchair:',
    code: 'U+1F468 U+200D U+1F9BC',
    img: '1f468-200d-1f9bc.png',
  },
  '👨‍🦽': {
    name: '',
    dotName: ':man_in_manual_wheelchair:',
    code: 'U+1F468 U+200D U+1F9BD',
    img: '1f468-200d-1f9bd.png',
  },
  '👨‍⚕️': {
    name: '',
    dotName: ':male-doctor:',
    code: 'U+1F468 U+200D U+2695 U+FE0F',
    img: '1f468-200d-2695-fe0f.png',
  },
  '👨‍⚖️': {
    name: '',
    dotName: ':male-judge:',
    code: 'U+1F468 U+200D U+2696 U+FE0F',
    img: '1f468-200d-2696-fe0f.png',
  },
  '👨‍✈️': {
    name: '',
    dotName: ':male-pilot:',
    code: 'U+1F468 U+200D U+2708 U+FE0F',
    img: '1f468-200d-2708-fe0f.png',
  },
  '👨‍❤️‍👨': {
    name: '',
    dotName: ':man-heart-man:',
    code: 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F468',
    img: '1f468-200d-2764-fe0f-200d-1f468.png',
  },
  '👨‍❤️‍💋‍👨': {
    name: '',
    dotName: ':man-kiss-man:',
    code: 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468',
    img: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.png',
  },
  '👨': {
    name: 'man',
    dotName: ':man:',
    code: 'U+1F468',
    img: '1f468.png',
  },
  '👩‍🌾': {
    name: '',
    dotName: ':female-farmer:',
    code: 'U+1F469 U+200D U+1F33E',
    img: '1f469-200d-1f33e.png',
  },
  '👩‍🍳': {
    name: '',
    dotName: ':female-cook:',
    code: 'U+1F469 U+200D U+1F373',
    img: '1f469-200d-1f373.png',
  },
  '👩‍🍼': {
    name: '',
    dotName: ':woman_feeding_baby:',
    code: 'U+1F469 U+200D U+1F37C',
    img: '1f469-200d-1f37c.png',
  },
  '👩‍🎓': {
    name: '',
    dotName: ':female-student:',
    code: 'U+1F469 U+200D U+1F393',
    img: '1f469-200d-1f393.png',
  },
  '👩‍🎤': {
    name: '',
    dotName: ':female-singer:',
    code: 'U+1F469 U+200D U+1F3A4',
    img: '1f469-200d-1f3a4.png',
  },
  '👩‍🎨': {
    name: '',
    dotName: ':female-artist:',
    code: 'U+1F469 U+200D U+1F3A8',
    img: '1f469-200d-1f3a8.png',
  },
  '👩‍🏫': {
    name: '',
    dotName: ':female-teacher:',
    code: 'U+1F469 U+200D U+1F3EB',
    img: '1f469-200d-1f3eb.png',
  },
  '👩‍🏭': {
    name: '',
    dotName: ':female-factory-worker:',
    code: 'U+1F469 U+200D U+1F3ED',
    img: '1f469-200d-1f3ed.png',
  },
  '👩‍👦‍👦': {
    name: '',
    dotName: ':woman-boy-boy:',
    code: 'U+1F469 U+200D U+1F466 U+200D U+1F466',
    img: '1f469-200d-1f466-200d-1f466.png',
  },
  '👩‍👦': {
    name: '',
    dotName: ':woman-boy:',
    code: 'U+1F469 U+200D U+1F466',
    img: '1f469-200d-1f466.png',
  },
  '👩‍👧‍👦': {
    name: '',
    dotName: ':woman-girl-boy:',
    code: 'U+1F469 U+200D U+1F467 U+200D U+1F466',
    img: '1f469-200d-1f467-200d-1f466.png',
  },
  '👩‍👧‍👧': {
    name: '',
    dotName: ':woman-girl-girl:',
    code: 'U+1F469 U+200D U+1F467 U+200D U+1F467',
    img: '1f469-200d-1f467-200d-1f467.png',
  },
  '👩‍👧': {
    name: '',
    dotName: ':woman-girl:',
    code: 'U+1F469 U+200D U+1F467',
    img: '1f469-200d-1f467.png',
  },
  '👩‍👩‍👦': {
    name: '',
    dotName: ':woman-woman-boy:',
    code: 'U+1F469 U+200D U+1F469 U+200D U+1F466',
    img: '1f469-200d-1f469-200d-1f466.png',
  },
  '👩‍👩‍👦‍👦': {
    name: '',
    dotName: ':woman-woman-boy-boy:',
    code: 'U+1F469 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466',
    img: '1f469-200d-1f469-200d-1f466-200d-1f466.png',
  },
  '👩‍👩‍👧': {
    name: '',
    dotName: ':woman-woman-girl:',
    code: 'U+1F469 U+200D U+1F469 U+200D U+1F467',
    img: '1f469-200d-1f469-200d-1f467.png',
  },
  '👩‍👩‍👧‍👦': {
    name: '',
    dotName: ':woman-woman-girl-boy:',
    code: 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466',
    img: '1f469-200d-1f469-200d-1f467-200d-1f466.png',
  },
  '👩‍👩‍👧‍👧': {
    name: '',
    dotName: ':woman-woman-girl-girl:',
    code: 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467',
    img: '1f469-200d-1f469-200d-1f467-200d-1f467.png',
  },
  '👩‍💻': {
    name: '',
    dotName: ':female-technologist:',
    code: 'U+1F469 U+200D U+1F4BB',
    img: '1f469-200d-1f4bb.png',
  },
  '👩‍💼': {
    name: '',
    dotName: ':female-office-worker:',
    code: 'U+1F469 U+200D U+1F4BC',
    img: '1f469-200d-1f4bc.png',
  },
  '👩‍🔧': {
    name: '',
    dotName: ':female-mechanic:',
    code: 'U+1F469 U+200D U+1F527',
    img: '1f469-200d-1f527.png',
  },
  '👩‍🔬': {
    name: '',
    dotName: ':female-scientist:',
    code: 'U+1F469 U+200D U+1F52C',
    img: '1f469-200d-1f52c.png',
  },
  '👩‍🚀': {
    name: '',
    dotName: ':female-astronaut:',
    code: 'U+1F469 U+200D U+1F680',
    img: '1f469-200d-1f680.png',
  },
  '👩‍🚒': {
    name: '',
    dotName: ':female-firefighter:',
    code: 'U+1F469 U+200D U+1F692',
    img: '1f469-200d-1f692.png',
  },
  '👩‍🦯': {
    name: '',
    dotName: ':woman_with_probing_cane:',
    code: 'U+1F469 U+200D U+1F9AF',
    img: '1f469-200d-1f9af.png',
  },
  '👩‍🦰': {
    name: '',
    dotName: ':red_haired_woman:',
    code: 'U+1F469 U+200D U+1F9B0',
    img: '1f469-200d-1f9b0.png',
  },
  '👩‍🦱': {
    name: '',
    dotName: ':curly_haired_woman:',
    code: 'U+1F469 U+200D U+1F9B1',
    img: '1f469-200d-1f9b1.png',
  },
  '👩‍🦲': {
    name: '',
    dotName: ':bald_woman:',
    code: 'U+1F469 U+200D U+1F9B2',
    img: '1f469-200d-1f9b2.png',
  },
  '👩‍🦳': {
    name: '',
    dotName: ':white_haired_woman:',
    code: 'U+1F469 U+200D U+1F9B3',
    img: '1f469-200d-1f9b3.png',
  },
  '👩‍🦼': {
    name: '',
    dotName: ':woman_in_motorized_wheelchair:',
    code: 'U+1F469 U+200D U+1F9BC',
    img: '1f469-200d-1f9bc.png',
  },
  '👩‍🦽': {
    name: '',
    dotName: ':woman_in_manual_wheelchair:',
    code: 'U+1F469 U+200D U+1F9BD',
    img: '1f469-200d-1f9bd.png',
  },
  '👩‍⚕️': {
    name: '',
    dotName: ':female-doctor:',
    code: 'U+1F469 U+200D U+2695 U+FE0F',
    img: '1f469-200d-2695-fe0f.png',
  },
  '👩‍⚖️': {
    name: '',
    dotName: ':female-judge:',
    code: 'U+1F469 U+200D U+2696 U+FE0F',
    img: '1f469-200d-2696-fe0f.png',
  },
  '👩‍✈️': {
    name: '',
    dotName: ':female-pilot:',
    code: 'U+1F469 U+200D U+2708 U+FE0F',
    img: '1f469-200d-2708-fe0f.png',
  },
  '👩‍❤️‍👨': {
    name: '',
    dotName: ':woman-heart-man:',
    code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F468',
    img: '1f469-200d-2764-fe0f-200d-1f468.png',
  },
  '👩‍❤️‍👩': {
    name: '',
    dotName: ':woman-heart-woman:',
    code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F469',
    img: '1f469-200d-2764-fe0f-200d-1f469.png',
  },
  '👩‍❤️‍💋‍👨': {
    name: '',
    dotName: ':woman-kiss-man:',
    code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468',
    img: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.png',
  },
  '👩‍❤️‍💋‍👩': {
    name: '',
    dotName: ':woman-kiss-woman:',
    code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F469',
    img: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.png',
  },
  '👩': {
    name: 'woman',
    dotName: ':woman:',
    code: 'U+1F469',
    img: '1f469.png',
  },
  '👪': {
    name: 'family',
    dotName: ':family:',
    code: 'U+1F46A',
    img: '1f46a.png',
  },
  '👫': {
    name: 'man and woman holding hands',
    dotName: ':couple:',
    code: 'U+1F46B',
    img: '1f46b.png',
  },
  '👬': {
    name: 'two men holding hands',
    dotName: ':two_men_holding_hands:',
    code: 'U+1F46C',
    img: '1f46c.png',
  },
  '👭': {
    name: 'two women holding hands',
    dotName: ':two_women_holding_hands:',
    code: 'U+1F46D',
    img: '1f46d.png',
  },
  '👮‍♀️': {
    name: '',
    dotName: ':female-police-officer:',
    code: 'U+1F46E U+200D U+2640 U+FE0F',
    img: '1f46e-200d-2640-fe0f.png',
  },
  '👮‍♂️': {
    name: '',
    dotName: ':male-police-officer:',
    code: 'U+1F46E U+200D U+2642 U+FE0F',
    img: '1f46e-200d-2642-fe0f.png',
  },
  '👮': {
    name: 'police officer',
    dotName: ':cop:',
    code: 'U+1F46E',
    img: '1f46e.png',
  },
  '👯‍♀️': {
    name: '',
    dotName: ':woman-with-bunny-ears-partying:',
    code: 'U+1F46F U+200D U+2640 U+FE0F',
    img: '1f46f-200d-2640-fe0f.png',
  },
  '👯‍♂️': {
    name: '',
    dotName: ':man-with-bunny-ears-partying:',
    code: 'U+1F46F U+200D U+2642 U+FE0F',
    img: '1f46f-200d-2642-fe0f.png',
  },
  '👯': {
    name: 'woman with bunny ears',
    dotName: ':dancers:',
    code: 'U+1F46F',
    img: '1f46f.png',
  },
  '👰‍♀️': {
    name: '',
    dotName: ':woman_with_veil:',
    code: 'U+1F470 U+200D U+2640 U+FE0F',
    img: '1f470-200d-2640-fe0f.png',
  },
  '👰‍♂️': {
    name: '',
    dotName: ':man_with_veil:',
    code: 'U+1F470 U+200D U+2642 U+FE0F',
    img: '1f470-200d-2642-fe0f.png',
  },
  '👰': {
    name: 'bride with veil',
    dotName: ':bride_with_veil:',
    code: 'U+1F470',
    img: '1f470.png',
  },
  '👱‍♀️': {
    name: '',
    dotName: ':blond-haired-woman:',
    code: 'U+1F471 U+200D U+2640 U+FE0F',
    img: '1f471-200d-2640-fe0f.png',
  },
  '👱‍♂️': {
    name: '',
    dotName: ':blond-haired-man:',
    code: 'U+1F471 U+200D U+2642 U+FE0F',
    img: '1f471-200d-2642-fe0f.png',
  },
  '👱': {
    name: 'person with blond hair',
    dotName: ':person_with_blond_hair:',
    code: 'U+1F471',
    img: '1f471.png',
  },
  '👲': {
    name: 'man with gua pi mao',
    dotName: ':man_with_gua_pi_mao:',
    code: 'U+1F472',
    img: '1f472.png',
  },
  '👳‍♀️': {
    name: '',
    dotName: ':woman-wearing-turban:',
    code: 'U+1F473 U+200D U+2640 U+FE0F',
    img: '1f473-200d-2640-fe0f.png',
  },
  '👳‍♂️': {
    name: '',
    dotName: ':man-wearing-turban:',
    code: 'U+1F473 U+200D U+2642 U+FE0F',
    img: '1f473-200d-2642-fe0f.png',
  },
  '👳': {
    name: 'man with turban',
    dotName: ':man_with_turban:',
    code: 'U+1F473',
    img: '1f473.png',
  },
  '👴': {
    name: 'older man',
    dotName: ':older_man:',
    code: 'U+1F474',
    img: '1f474.png',
  },
  '👵': {
    name: 'older woman',
    dotName: ':older_woman:',
    code: 'U+1F475',
    img: '1f475.png',
  },
  '👶': {
    name: 'baby',
    dotName: ':baby:',
    code: 'U+1F476',
    img: '1f476.png',
  },
  '👷‍♀️': {
    name: '',
    dotName: ':female-construction-worker:',
    code: 'U+1F477 U+200D U+2640 U+FE0F',
    img: '1f477-200d-2640-fe0f.png',
  },
  '👷‍♂️': {
    name: '',
    dotName: ':male-construction-worker:',
    code: 'U+1F477 U+200D U+2642 U+FE0F',
    img: '1f477-200d-2642-fe0f.png',
  },
  '👷': {
    name: 'construction worker',
    dotName: ':construction_worker:',
    code: 'U+1F477',
    img: '1f477.png',
  },
  '👸': {
    name: 'princess',
    dotName: ':princess:',
    code: 'U+1F478',
    img: '1f478.png',
  },
  '👹': {
    name: 'japanese ogre',
    dotName: ':japanese_ogre:',
    code: 'U+1F479',
    img: '1f479.png',
  },
  '👺': {
    name: 'japanese goblin',
    dotName: ':japanese_goblin:',
    code: 'U+1F47A',
    img: '1f47a.png',
  },
  '👻': {
    name: 'ghost',
    dotName: ':ghost:',
    code: 'U+1F47B',
    img: '1f47b.png',
  },
  '👼': {
    name: 'baby angel',
    dotName: ':angel:',
    code: 'U+1F47C',
    img: '1f47c.png',
  },
  '👽': {
    name: 'extraterrestrial alien',
    dotName: ':alien:',
    code: 'U+1F47D',
    img: '1f47d.png',
  },
  '👾': {
    name: 'alien monster',
    dotName: ':space_invader:',
    code: 'U+1F47E',
    img: '1f47e.png',
  },
  '👿': {
    name: 'imp',
    dotName: ':imp:',
    code: 'U+1F47F',
    img: '1f47f.png',
  },
  '💀': {
    name: 'skull',
    dotName: ':skull:',
    code: 'U+1F480',
    img: '1f480.png',
  },
  '💁‍♀️': {
    name: '',
    dotName: ':woman-tipping-hand:',
    code: 'U+1F481 U+200D U+2640 U+FE0F',
    img: '1f481-200d-2640-fe0f.png',
  },
  '💁‍♂️': {
    name: '',
    dotName: ':man-tipping-hand:',
    code: 'U+1F481 U+200D U+2642 U+FE0F',
    img: '1f481-200d-2642-fe0f.png',
  },
  '💁': {
    name: 'information desk person',
    dotName: ':information_desk_person:',
    code: 'U+1F481',
    img: '1f481.png',
  },
  '💂‍♀️': {
    name: '',
    dotName: ':female-guard:',
    code: 'U+1F482 U+200D U+2640 U+FE0F',
    img: '1f482-200d-2640-fe0f.png',
  },
  '💂‍♂️': {
    name: '',
    dotName: ':male-guard:',
    code: 'U+1F482 U+200D U+2642 U+FE0F',
    img: '1f482-200d-2642-fe0f.png',
  },
  '💂': {
    name: 'guardsman',
    dotName: ':guardsman:',
    code: 'U+1F482',
    img: '1f482.png',
  },
  '💃': {
    name: 'dancer',
    dotName: ':dancer:',
    code: 'U+1F483',
    img: '1f483.png',
  },
  '💄': {
    name: 'lipstick',
    dotName: ':lipstick:',
    code: 'U+1F484',
    img: '1f484.png',
  },
  '💅': {
    name: 'nail polish',
    dotName: ':nail_care:',
    code: 'U+1F485',
    img: '1f485.png',
  },
  '💆‍♀️': {
    name: '',
    dotName: ':woman-getting-massage:',
    code: 'U+1F486 U+200D U+2640 U+FE0F',
    img: '1f486-200d-2640-fe0f.png',
  },
  '💆‍♂️': {
    name: '',
    dotName: ':man-getting-massage:',
    code: 'U+1F486 U+200D U+2642 U+FE0F',
    img: '1f486-200d-2642-fe0f.png',
  },
  '💆': {
    name: 'face massage',
    dotName: ':massage:',
    code: 'U+1F486',
    img: '1f486.png',
  },
  '💇‍♀️': {
    name: '',
    dotName: ':woman-getting-haircut:',
    code: 'U+1F487 U+200D U+2640 U+FE0F',
    img: '1f487-200d-2640-fe0f.png',
  },
  '💇‍♂️': {
    name: '',
    dotName: ':man-getting-haircut:',
    code: 'U+1F487 U+200D U+2642 U+FE0F',
    img: '1f487-200d-2642-fe0f.png',
  },
  '💇': {
    name: 'haircut',
    dotName: ':haircut:',
    code: 'U+1F487',
    img: '1f487.png',
  },
  '💈': {
    name: 'barber pole',
    dotName: ':barber:',
    code: 'U+1F488',
    img: '1f488.png',
  },
  '💉': {
    name: 'syringe',
    dotName: ':syringe:',
    code: 'U+1F489',
    img: '1f489.png',
  },
  '💊': {
    name: 'pill',
    dotName: ':pill:',
    code: 'U+1F48A',
    img: '1f48a.png',
  },
  '💋': {
    name: 'kiss mark',
    dotName: ':kiss:',
    code: 'U+1F48B',
    img: '1f48b.png',
  },
  '💌': {
    name: 'love letter',
    dotName: ':love_letter:',
    code: 'U+1F48C',
    img: '1f48c.png',
  },
  '💍': {
    name: 'ring',
    dotName: ':ring:',
    code: 'U+1F48D',
    img: '1f48d.png',
  },
  '💎': {
    name: 'gem stone',
    dotName: ':gem:',
    code: 'U+1F48E',
    img: '1f48e.png',
  },
  '💏': {
    name: 'kiss',
    dotName: ':couplekiss:',
    code: 'U+1F48F',
    img: '1f48f.png',
  },
  '💐': {
    name: 'bouquet',
    dotName: ':bouquet:',
    code: 'U+1F490',
    img: '1f490.png',
  },
  '💑': {
    name: 'couple with heart',
    dotName: ':couple_with_heart:',
    code: 'U+1F491',
    img: '1f491.png',
  },
  '💒': {
    name: 'wedding',
    dotName: ':wedding:',
    code: 'U+1F492',
    img: '1f492.png',
  },
  '💓': {
    name: 'beating heart',
    dotName: ':heartbeat:',
    code: 'U+1F493',
    img: '1f493.png',
  },
  '💔': {
    name: 'broken heart',
    dotName: ':broken_heart:',
    code: 'U+1F494',
    img: '1f494.png',
  },
  '💕': {
    name: 'two hearts',
    dotName: ':two_hearts:',
    code: 'U+1F495',
    img: '1f495.png',
  },
  '💖': {
    name: 'sparkling heart',
    dotName: ':sparkling_heart:',
    code: 'U+1F496',
    img: '1f496.png',
  },
  '💗': {
    name: 'growing heart',
    dotName: ':heartpulse:',
    code: 'U+1F497',
    img: '1f497.png',
  },
  '💘': {
    name: 'heart with arrow',
    dotName: ':cupid:',
    code: 'U+1F498',
    img: '1f498.png',
  },
  '💙': {
    name: 'blue heart',
    dotName: ':blue_heart:',
    code: 'U+1F499',
    img: '1f499.png',
  },
  '💚': {
    name: 'green heart',
    dotName: ':green_heart:',
    code: 'U+1F49A',
    img: '1f49a.png',
  },
  '💛': {
    name: 'yellow heart',
    dotName: ':yellow_heart:',
    code: 'U+1F49B',
    img: '1f49b.png',
  },
  '💜': {
    name: 'purple heart',
    dotName: ':purple_heart:',
    code: 'U+1F49C',
    img: '1f49c.png',
  },
  '💝': {
    name: 'heart with ribbon',
    dotName: ':gift_heart:',
    code: 'U+1F49D',
    img: '1f49d.png',
  },
  '💞': {
    name: 'revolving hearts',
    dotName: ':revolving_hearts:',
    code: 'U+1F49E',
    img: '1f49e.png',
  },
  '💟': {
    name: 'heart decoration',
    dotName: ':heart_decoration:',
    code: 'U+1F49F',
    img: '1f49f.png',
  },
  '💠': {
    name: 'diamond shape with a dot inside',
    dotName: ':diamond_shape_with_a_dot_inside:',
    code: 'U+1F4A0',
    img: '1f4a0.png',
  },
  '💡': {
    name: 'electric light bulb',
    dotName: ':bulb:',
    code: 'U+1F4A1',
    img: '1f4a1.png',
  },
  '💢': {
    name: 'anger symbol',
    dotName: ':anger:',
    code: 'U+1F4A2',
    img: '1f4a2.png',
  },
  '💣': {
    name: 'bomb',
    dotName: ':bomb:',
    code: 'U+1F4A3',
    img: '1f4a3.png',
  },
  '💤': {
    name: 'sleeping symbol',
    dotName: ':zzz:',
    code: 'U+1F4A4',
    img: '1f4a4.png',
  },
  '💥': {
    name: 'collision symbol',
    dotName: ':boom:',
    code: 'U+1F4A5',
    img: '1f4a5.png',
  },
  '💦': {
    name: 'splashing sweat symbol',
    dotName: ':sweat_drops:',
    code: 'U+1F4A6',
    img: '1f4a6.png',
  },
  '💧': {
    name: 'droplet',
    dotName: ':droplet:',
    code: 'U+1F4A7',
    img: '1f4a7.png',
  },
  '💨': {
    name: 'dash symbol',
    dotName: ':dash:',
    code: 'U+1F4A8',
    img: '1f4a8.png',
  },
  '💩': {
    name: 'pile of poo',
    dotName: ':hankey:',
    code: 'U+1F4A9',
    img: '1f4a9.png',
  },
  '💪': {
    name: 'flexed biceps',
    dotName: ':muscle:',
    code: 'U+1F4AA',
    img: '1f4aa.png',
  },
  '💫': {
    name: 'dizzy symbol',
    dotName: ':dizzy:',
    code: 'U+1F4AB',
    img: '1f4ab.png',
  },
  '💬': {
    name: 'speech balloon',
    dotName: ':speech_balloon:',
    code: 'U+1F4AC',
    img: '1f4ac.png',
  },
  '💭': {
    name: 'thought balloon',
    dotName: ':thought_balloon:',
    code: 'U+1F4AD',
    img: '1f4ad.png',
  },
  '💮': {
    name: 'white flower',
    dotName: ':white_flower:',
    code: 'U+1F4AE',
    img: '1f4ae.png',
  },
  '💯': {
    name: 'hundred points symbol',
    dotName: ':100:',
    code: 'U+1F4AF',
    img: '1f4af.png',
  },
  '💰': {
    name: 'money bag',
    dotName: ':moneybag:',
    code: 'U+1F4B0',
    img: '1f4b0.png',
  },
  '💱': {
    name: 'currency exchange',
    dotName: ':currency_exchange:',
    code: 'U+1F4B1',
    img: '1f4b1.png',
  },
  '💲': {
    name: 'heavy dollar sign',
    dotName: ':heavy_dollar_sign:',
    code: 'U+1F4B2',
    img: '1f4b2.png',
  },
  '💳': {
    name: 'credit card',
    dotName: ':credit_card:',
    code: 'U+1F4B3',
    img: '1f4b3.png',
  },
  '💴': {
    name: 'banknote with yen sign',
    dotName: ':yen:',
    code: 'U+1F4B4',
    img: '1f4b4.png',
  },
  '💵': {
    name: 'banknote with dollar sign',
    dotName: ':dollar:',
    code: 'U+1F4B5',
    img: '1f4b5.png',
  },
  '💶': {
    name: 'banknote with euro sign',
    dotName: ':euro:',
    code: 'U+1F4B6',
    img: '1f4b6.png',
  },
  '💷': {
    name: 'banknote with pound sign',
    dotName: ':pound:',
    code: 'U+1F4B7',
    img: '1f4b7.png',
  },
  '💸': {
    name: 'money with wings',
    dotName: ':money_with_wings:',
    code: 'U+1F4B8',
    img: '1f4b8.png',
  },
  '💹': {
    name: 'chart with upwards trend and yen sign',
    dotName: ':chart:',
    code: 'U+1F4B9',
    img: '1f4b9.png',
  },
  '💺': {
    name: 'seat',
    dotName: ':seat:',
    code: 'U+1F4BA',
    img: '1f4ba.png',
  },
  '💻': {
    name: 'personal computer',
    dotName: ':computer:',
    code: 'U+1F4BB',
    img: '1f4bb.png',
  },
  '💼': {
    name: 'briefcase',
    dotName: ':briefcase:',
    code: 'U+1F4BC',
    img: '1f4bc.png',
  },
  '💽': {
    name: 'minidisc',
    dotName: ':minidisc:',
    code: 'U+1F4BD',
    img: '1f4bd.png',
  },
  '💾': {
    name: 'floppy disk',
    dotName: ':floppy_disk:',
    code: 'U+1F4BE',
    img: '1f4be.png',
  },
  '💿': {
    name: 'optical disc',
    dotName: ':cd:',
    code: 'U+1F4BF',
    img: '1f4bf.png',
  },
  '📀': {
    name: 'dvd',
    dotName: ':dvd:',
    code: 'U+1F4C0',
    img: '1f4c0.png',
  },
  '📁': {
    name: 'file folder',
    dotName: ':file_folder:',
    code: 'U+1F4C1',
    img: '1f4c1.png',
  },
  '📂': {
    name: 'open file folder',
    dotName: ':open_file_folder:',
    code: 'U+1F4C2',
    img: '1f4c2.png',
  },
  '📃': {
    name: 'page with curl',
    dotName: ':page_with_curl:',
    code: 'U+1F4C3',
    img: '1f4c3.png',
  },
  '📄': {
    name: 'page facing up',
    dotName: ':page_facing_up:',
    code: 'U+1F4C4',
    img: '1f4c4.png',
  },
  '📅': {
    name: 'calendar',
    dotName: ':date:',
    code: 'U+1F4C5',
    img: '1f4c5.png',
  },
  '📆': {
    name: 'tear-off calendar',
    dotName: ':calendar:',
    code: 'U+1F4C6',
    img: '1f4c6.png',
  },
  '📇': {
    name: 'card index',
    dotName: ':card_index:',
    code: 'U+1F4C7',
    img: '1f4c7.png',
  },
  '📈': {
    name: 'chart with upwards trend',
    dotName: ':chart_with_upwards_trend:',
    code: 'U+1F4C8',
    img: '1f4c8.png',
  },
  '📉': {
    name: 'chart with downwards trend',
    dotName: ':chart_with_downwards_trend:',
    code: 'U+1F4C9',
    img: '1f4c9.png',
  },
  '📊': {
    name: 'bar chart',
    dotName: ':bar_chart:',
    code: 'U+1F4CA',
    img: '1f4ca.png',
  },
  '📋': {
    name: 'clipboard',
    dotName: ':clipboard:',
    code: 'U+1F4CB',
    img: '1f4cb.png',
  },
  '📌': {
    name: 'pushpin',
    dotName: ':pushpin:',
    code: 'U+1F4CC',
    img: '1f4cc.png',
  },
  '📍': {
    name: 'round pushpin',
    dotName: ':round_pushpin:',
    code: 'U+1F4CD',
    img: '1f4cd.png',
  },
  '📎': {
    name: 'paperclip',
    dotName: ':paperclip:',
    code: 'U+1F4CE',
    img: '1f4ce.png',
  },
  '📏': {
    name: 'straight ruler',
    dotName: ':straight_ruler:',
    code: 'U+1F4CF',
    img: '1f4cf.png',
  },
  '📐': {
    name: 'triangular ruler',
    dotName: ':triangular_ruler:',
    code: 'U+1F4D0',
    img: '1f4d0.png',
  },
  '📑': {
    name: 'bookmark tabs',
    dotName: ':bookmark_tabs:',
    code: 'U+1F4D1',
    img: '1f4d1.png',
  },
  '📒': {
    name: 'ledger',
    dotName: ':ledger:',
    code: 'U+1F4D2',
    img: '1f4d2.png',
  },
  '📓': {
    name: 'notebook',
    dotName: ':notebook:',
    code: 'U+1F4D3',
    img: '1f4d3.png',
  },
  '📔': {
    name: 'notebook with decorative cover',
    dotName: ':notebook_with_decorative_cover:',
    code: 'U+1F4D4',
    img: '1f4d4.png',
  },
  '📕': {
    name: 'closed book',
    dotName: ':closed_book:',
    code: 'U+1F4D5',
    img: '1f4d5.png',
  },
  '📖': {
    name: 'open book',
    dotName: ':book:',
    code: 'U+1F4D6',
    img: '1f4d6.png',
  },
  '📗': {
    name: 'green book',
    dotName: ':green_book:',
    code: 'U+1F4D7',
    img: '1f4d7.png',
  },
  '📘': {
    name: 'blue book',
    dotName: ':blue_book:',
    code: 'U+1F4D8',
    img: '1f4d8.png',
  },
  '📙': {
    name: 'orange book',
    dotName: ':orange_book:',
    code: 'U+1F4D9',
    img: '1f4d9.png',
  },
  '📚': {
    name: 'books',
    dotName: ':books:',
    code: 'U+1F4DA',
    img: '1f4da.png',
  },
  '📛': {
    name: 'name badge',
    dotName: ':name_badge:',
    code: 'U+1F4DB',
    img: '1f4db.png',
  },
  '📜': {
    name: 'scroll',
    dotName: ':scroll:',
    code: 'U+1F4DC',
    img: '1f4dc.png',
  },
  '📝': {
    name: 'memo',
    dotName: ':memo:',
    code: 'U+1F4DD',
    img: '1f4dd.png',
  },
  '📞': {
    name: 'telephone receiver',
    dotName: ':telephone_receiver:',
    code: 'U+1F4DE',
    img: '1f4de.png',
  },
  '📟': {
    name: 'pager',
    dotName: ':pager:',
    code: 'U+1F4DF',
    img: '1f4df.png',
  },
  '📠': {
    name: 'fax machine',
    dotName: ':fax:',
    code: 'U+1F4E0',
    img: '1f4e0.png',
  },
  '📡': {
    name: 'satellite antenna',
    dotName: ':satellite_antenna:',
    code: 'U+1F4E1',
    img: '1f4e1.png',
  },
  '📢': {
    name: 'public address loudspeaker',
    dotName: ':loudspeaker:',
    code: 'U+1F4E2',
    img: '1f4e2.png',
  },
  '📣': {
    name: 'cheering megaphone',
    dotName: ':mega:',
    code: 'U+1F4E3',
    img: '1f4e3.png',
  },
  '📤': {
    name: 'outbox tray',
    dotName: ':outbox_tray:',
    code: 'U+1F4E4',
    img: '1f4e4.png',
  },
  '📥': {
    name: 'inbox tray',
    dotName: ':inbox_tray:',
    code: 'U+1F4E5',
    img: '1f4e5.png',
  },
  '📦': {
    name: 'package',
    dotName: ':package:',
    code: 'U+1F4E6',
    img: '1f4e6.png',
  },
  '📧': {
    name: 'e-mail symbol',
    dotName: ':e-mail:',
    code: 'U+1F4E7',
    img: '1f4e7.png',
  },
  '📨': {
    name: 'incoming envelope',
    dotName: ':incoming_envelope:',
    code: 'U+1F4E8',
    img: '1f4e8.png',
  },
  '📩': {
    name: 'envelope with downwards arrow above',
    dotName: ':envelope_with_arrow:',
    code: 'U+1F4E9',
    img: '1f4e9.png',
  },
  '📪': {
    name: 'closed mailbox with lowered flag',
    dotName: ':mailbox_closed:',
    code: 'U+1F4EA',
    img: '1f4ea.png',
  },
  '📫': {
    name: 'closed mailbox with raised flag',
    dotName: ':mailbox:',
    code: 'U+1F4EB',
    img: '1f4eb.png',
  },
  '📬': {
    name: 'open mailbox with raised flag',
    dotName: ':mailbox_with_mail:',
    code: 'U+1F4EC',
    img: '1f4ec.png',
  },
  '📭': {
    name: 'open mailbox with lowered flag',
    dotName: ':mailbox_with_no_mail:',
    code: 'U+1F4ED',
    img: '1f4ed.png',
  },
  '📮': {
    name: 'postbox',
    dotName: ':postbox:',
    code: 'U+1F4EE',
    img: '1f4ee.png',
  },
  '📯': {
    name: 'postal horn',
    dotName: ':postal_horn:',
    code: 'U+1F4EF',
    img: '1f4ef.png',
  },
  '📰': {
    name: 'newspaper',
    dotName: ':newspaper:',
    code: 'U+1F4F0',
    img: '1f4f0.png',
  },
  '📱': {
    name: 'mobile phone',
    dotName: ':iphone:',
    code: 'U+1F4F1',
    img: '1f4f1.png',
  },
  '📲': {
    name: 'mobile phone with rightwards arrow at left',
    dotName: ':calling:',
    code: 'U+1F4F2',
    img: '1f4f2.png',
  },
  '📳': {
    name: 'vibration mode',
    dotName: ':vibration_mode:',
    code: 'U+1F4F3',
    img: '1f4f3.png',
  },
  '📴': {
    name: 'mobile phone off',
    dotName: ':mobile_phone_off:',
    code: 'U+1F4F4',
    img: '1f4f4.png',
  },
  '📵': {
    name: 'no mobile phones',
    dotName: ':no_mobile_phones:',
    code: 'U+1F4F5',
    img: '1f4f5.png',
  },
  '📶': {
    name: 'antenna with bars',
    dotName: ':signal_strength:',
    code: 'U+1F4F6',
    img: '1f4f6.png',
  },
  '📷': {
    name: 'camera',
    dotName: ':camera:',
    code: 'U+1F4F7',
    img: '1f4f7.png',
  },
  '📸': {
    name: 'camera with flash',
    dotName: ':camera_with_flash:',
    code: 'U+1F4F8',
    img: '1f4f8.png',
  },
  '📹': {
    name: 'video camera',
    dotName: ':video_camera:',
    code: 'U+1F4F9',
    img: '1f4f9.png',
  },
  '📺': {
    name: 'television',
    dotName: ':tv:',
    code: 'U+1F4FA',
    img: '1f4fa.png',
  },
  '📻': {
    name: 'radio',
    dotName: ':radio:',
    code: 'U+1F4FB',
    img: '1f4fb.png',
  },
  '📼': {
    name: 'videocassette',
    dotName: ':vhs:',
    code: 'U+1F4FC',
    img: '1f4fc.png',
  },
  '📽️': {
    name: '',
    dotName: ':film_projector:',
    code: 'U+1F4FD U+FE0F',
    img: '1f4fd-fe0f.png',
  },
  '📿': {
    name: 'prayer beads',
    dotName: ':prayer_beads:',
    code: 'U+1F4FF',
    img: '1f4ff.png',
  },
  '🔀': {
    name: 'twisted rightwards arrows',
    dotName: ':twisted_rightwards_arrows:',
    code: 'U+1F500',
    img: '1f500.png',
  },
  '🔁': {
    name: 'clockwise rightwards and leftwards open circle arrows',
    dotName: ':repeat:',
    code: 'U+1F501',
    img: '1f501.png',
  },
  '🔂': {
    name: 'clockwise rightwards and leftwards open circle arrows with circled one overlay',
    dotName: ':repeat_one:',
    code: 'U+1F502',
    img: '1f502.png',
  },
  '🔃': {
    name: 'clockwise downwards and upwards open circle arrows',
    dotName: ':arrows_clockwise:',
    code: 'U+1F503',
    img: '1f503.png',
  },
  '🔄': {
    name: 'anticlockwise downwards and upwards open circle arrows',
    dotName: ':arrows_counterclockwise:',
    code: 'U+1F504',
    img: '1f504.png',
  },
  '🔅': {
    name: 'low brightness symbol',
    dotName: ':low_brightness:',
    code: 'U+1F505',
    img: '1f505.png',
  },
  '🔆': {
    name: 'high brightness symbol',
    dotName: ':high_brightness:',
    code: 'U+1F506',
    img: '1f506.png',
  },
  '🔇': {
    name: 'speaker with cancellation stroke',
    dotName: ':mute:',
    code: 'U+1F507',
    img: '1f507.png',
  },
  '🔈': {
    name: 'speaker',
    dotName: ':speaker:',
    code: 'U+1F508',
    img: '1f508.png',
  },
  '🔉': {
    name: 'speaker with one sound wave',
    dotName: ':sound:',
    code: 'U+1F509',
    img: '1f509.png',
  },
  '🔊': {
    name: 'speaker with three sound waves',
    dotName: ':loud_sound:',
    code: 'U+1F50A',
    img: '1f50a.png',
  },
  '🔋': {
    name: 'battery',
    dotName: ':battery:',
    code: 'U+1F50B',
    img: '1f50b.png',
  },
  '🔌': {
    name: 'electric plug',
    dotName: ':electric_plug:',
    code: 'U+1F50C',
    img: '1f50c.png',
  },
  '🔍': {
    name: 'left-pointing magnifying glass',
    dotName: ':mag:',
    code: 'U+1F50D',
    img: '1f50d.png',
  },
  '🔎': {
    name: 'right-pointing magnifying glass',
    dotName: ':mag_right:',
    code: 'U+1F50E',
    img: '1f50e.png',
  },
  '🔏': {
    name: 'lock with ink pen',
    dotName: ':lock_with_ink_pen:',
    code: 'U+1F50F',
    img: '1f50f.png',
  },
  '🔐': {
    name: 'closed lock with key',
    dotName: ':closed_lock_with_key:',
    code: 'U+1F510',
    img: '1f510.png',
  },
  '🔑': {
    name: 'key',
    dotName: ':key:',
    code: 'U+1F511',
    img: '1f511.png',
  },
  '🔒': {
    name: 'lock',
    dotName: ':lock:',
    code: 'U+1F512',
    img: '1f512.png',
  },
  '🔓': {
    name: 'open lock',
    dotName: ':unlock:',
    code: 'U+1F513',
    img: '1f513.png',
  },
  '🔔': {
    name: 'bell',
    dotName: ':bell:',
    code: 'U+1F514',
    img: '1f514.png',
  },
  '🔕': {
    name: 'bell with cancellation stroke',
    dotName: ':no_bell:',
    code: 'U+1F515',
    img: '1f515.png',
  },
  '🔖': {
    name: 'bookmark',
    dotName: ':bookmark:',
    code: 'U+1F516',
    img: '1f516.png',
  },
  '🔗': {
    name: 'link symbol',
    dotName: ':link:',
    code: 'U+1F517',
    img: '1f517.png',
  },
  '🔘': {
    name: 'radio button',
    dotName: ':radio_button:',
    code: 'U+1F518',
    img: '1f518.png',
  },
  '🔙': {
    name: 'back with leftwards arrow above',
    dotName: ':back:',
    code: 'U+1F519',
    img: '1f519.png',
  },
  '🔚': {
    name: 'end with leftwards arrow above',
    dotName: ':end:',
    code: 'U+1F51A',
    img: '1f51a.png',
  },
  '🔛': {
    name: 'on with exclamation mark with left right arrow above',
    dotName: ':on:',
    code: 'U+1F51B',
    img: '1f51b.png',
  },
  '🔜': {
    name: 'soon with rightwards arrow above',
    dotName: ':soon:',
    code: 'U+1F51C',
    img: '1f51c.png',
  },
  '🔝': {
    name: 'top with upwards arrow above',
    dotName: ':top:',
    code: 'U+1F51D',
    img: '1f51d.png',
  },
  '🔞': {
    name: 'no one under eighteen symbol',
    dotName: ':underage:',
    code: 'U+1F51E',
    img: '1f51e.png',
  },
  '🔟': {
    name: 'keycap ten',
    dotName: ':keycap_ten:',
    code: 'U+1F51F',
    img: '1f51f.png',
  },
  '🔠': {
    name: 'input symbol for latin capital letters',
    dotName: ':capital_abcd:',
    code: 'U+1F520',
    img: '1f520.png',
  },
  '🔡': {
    name: 'input symbol for latin small letters',
    dotName: ':abcd:',
    code: 'U+1F521',
    img: '1f521.png',
  },
  '🔢': {
    name: 'input symbol for numbers',
    dotName: ':1234:',
    code: 'U+1F522',
    img: '1f522.png',
  },
  '🔣': {
    name: 'input symbol for symbols',
    dotName: ':symbols:',
    code: 'U+1F523',
    img: '1f523.png',
  },
  '🔤': {
    name: 'input symbol for latin letters',
    dotName: ':abc:',
    code: 'U+1F524',
    img: '1f524.png',
  },
  '🔥': {
    name: 'fire',
    dotName: ':fire:',
    code: 'U+1F525',
    img: '1f525.png',
  },
  '🔦': {
    name: 'electric torch',
    dotName: ':flashlight:',
    code: 'U+1F526',
    img: '1f526.png',
  },
  '🔧': {
    name: 'wrench',
    dotName: ':wrench:',
    code: 'U+1F527',
    img: '1f527.png',
  },
  '🔨': {
    name: 'hammer',
    dotName: ':hammer:',
    code: 'U+1F528',
    img: '1f528.png',
  },
  '🔩': {
    name: 'nut and bolt',
    dotName: ':nut_and_bolt:',
    code: 'U+1F529',
    img: '1f529.png',
  },
  '🔪': {
    name: 'hocho',
    dotName: ':hocho:',
    code: 'U+1F52A',
    img: '1f52a.png',
  },
  '🔫': {
    name: 'pistol',
    dotName: ':gun:',
    code: 'U+1F52B',
    img: '1f52b.png',
  },
  '🔬': {
    name: 'microscope',
    dotName: ':microscope:',
    code: 'U+1F52C',
    img: '1f52c.png',
  },
  '🔭': {
    name: 'telescope',
    dotName: ':telescope:',
    code: 'U+1F52D',
    img: '1f52d.png',
  },
  '🔮': {
    name: 'crystal ball',
    dotName: ':crystal_ball:',
    code: 'U+1F52E',
    img: '1f52e.png',
  },
  '🔯': {
    name: 'six pointed star with middle dot',
    dotName: ':six_pointed_star:',
    code: 'U+1F52F',
    img: '1f52f.png',
  },
  '🔰': {
    name: 'japanese symbol for beginner',
    dotName: ':beginner:',
    code: 'U+1F530',
    img: '1f530.png',
  },
  '🔱': {
    name: 'trident emblem',
    dotName: ':trident:',
    code: 'U+1F531',
    img: '1f531.png',
  },
  '🔲': {
    name: 'black square button',
    dotName: ':black_square_button:',
    code: 'U+1F532',
    img: '1f532.png',
  },
  '🔳': {
    name: 'white square button',
    dotName: ':white_square_button:',
    code: 'U+1F533',
    img: '1f533.png',
  },
  '🔴': {
    name: 'large red circle',
    dotName: ':red_circle:',
    code: 'U+1F534',
    img: '1f534.png',
  },
  '🔵': {
    name: 'large blue circle',
    dotName: ':large_blue_circle:',
    code: 'U+1F535',
    img: '1f535.png',
  },
  '🔶': {
    name: 'large orange diamond',
    dotName: ':large_orange_diamond:',
    code: 'U+1F536',
    img: '1f536.png',
  },
  '🔷': {
    name: 'large blue diamond',
    dotName: ':large_blue_diamond:',
    code: 'U+1F537',
    img: '1f537.png',
  },
  '🔸': {
    name: 'small orange diamond',
    dotName: ':small_orange_diamond:',
    code: 'U+1F538',
    img: '1f538.png',
  },
  '🔹': {
    name: 'small blue diamond',
    dotName: ':small_blue_diamond:',
    code: 'U+1F539',
    img: '1f539.png',
  },
  '🔺': {
    name: 'up-pointing red triangle',
    dotName: ':small_red_triangle:',
    code: 'U+1F53A',
    img: '1f53a.png',
  },
  '🔻': {
    name: 'down-pointing red triangle',
    dotName: ':small_red_triangle_down:',
    code: 'U+1F53B',
    img: '1f53b.png',
  },
  '🔼': {
    name: 'up-pointing small red triangle',
    dotName: ':arrow_up_small:',
    code: 'U+1F53C',
    img: '1f53c.png',
  },
  '🔽': {
    name: 'down-pointing small red triangle',
    dotName: ':arrow_down_small:',
    code: 'U+1F53D',
    img: '1f53d.png',
  },
  '🕉️': {
    name: '',
    dotName: ':om_symbol:',
    code: 'U+1F549 U+FE0F',
    img: '1f549-fe0f.png',
  },
  '🕊️': {
    name: '',
    dotName: ':dove_of_peace:',
    code: 'U+1F54A U+FE0F',
    img: '1f54a-fe0f.png',
  },
  '🕋': {
    name: 'kaaba',
    dotName: ':kaaba:',
    code: 'U+1F54B',
    img: '1f54b.png',
  },
  '🕌': {
    name: 'mosque',
    dotName: ':mosque:',
    code: 'U+1F54C',
    img: '1f54c.png',
  },
  '🕍': {
    name: 'synagogue',
    dotName: ':synagogue:',
    code: 'U+1F54D',
    img: '1f54d.png',
  },
  '🕎': {
    name: 'menorah with nine branches',
    dotName: ':menorah_with_nine_branches:',
    code: 'U+1F54E',
    img: '1f54e.png',
  },
  '🕐': {
    name: 'clock face one oclock',
    dotName: ':clock1:',
    code: 'U+1F550',
    img: '1f550.png',
  },
  '🕑': {
    name: 'clock face two oclock',
    dotName: ':clock2:',
    code: 'U+1F551',
    img: '1f551.png',
  },
  '🕒': {
    name: 'clock face three oclock',
    dotName: ':clock3:',
    code: 'U+1F552',
    img: '1f552.png',
  },
  '🕓': {
    name: 'clock face four oclock',
    dotName: ':clock4:',
    code: 'U+1F553',
    img: '1f553.png',
  },
  '🕔': {
    name: 'clock face five oclock',
    dotName: ':clock5:',
    code: 'U+1F554',
    img: '1f554.png',
  },
  '🕕': {
    name: 'clock face six oclock',
    dotName: ':clock6:',
    code: 'U+1F555',
    img: '1f555.png',
  },
  '🕖': {
    name: 'clock face seven oclock',
    dotName: ':clock7:',
    code: 'U+1F556',
    img: '1f556.png',
  },
  '🕗': {
    name: 'clock face eight oclock',
    dotName: ':clock8:',
    code: 'U+1F557',
    img: '1f557.png',
  },
  '🕘': {
    name: 'clock face nine oclock',
    dotName: ':clock9:',
    code: 'U+1F558',
    img: '1f558.png',
  },
  '🕙': {
    name: 'clock face ten oclock',
    dotName: ':clock10:',
    code: 'U+1F559',
    img: '1f559.png',
  },
  '🕚': {
    name: 'clock face eleven oclock',
    dotName: ':clock11:',
    code: 'U+1F55A',
    img: '1f55a.png',
  },
  '🕛': {
    name: 'clock face twelve oclock',
    dotName: ':clock12:',
    code: 'U+1F55B',
    img: '1f55b.png',
  },
  '🕜': {
    name: 'clock face one-thirty',
    dotName: ':clock130:',
    code: 'U+1F55C',
    img: '1f55c.png',
  },
  '🕝': {
    name: 'clock face two-thirty',
    dotName: ':clock230:',
    code: 'U+1F55D',
    img: '1f55d.png',
  },
  '🕞': {
    name: 'clock face three-thirty',
    dotName: ':clock330:',
    code: 'U+1F55E',
    img: '1f55e.png',
  },
  '🕟': {
    name: 'clock face four-thirty',
    dotName: ':clock430:',
    code: 'U+1F55F',
    img: '1f55f.png',
  },
  '🕠': {
    name: 'clock face five-thirty',
    dotName: ':clock530:',
    code: 'U+1F560',
    img: '1f560.png',
  },
  '🕡': {
    name: 'clock face six-thirty',
    dotName: ':clock630:',
    code: 'U+1F561',
    img: '1f561.png',
  },
  '🕢': {
    name: 'clock face seven-thirty',
    dotName: ':clock730:',
    code: 'U+1F562',
    img: '1f562.png',
  },
  '🕣': {
    name: 'clock face eight-thirty',
    dotName: ':clock830:',
    code: 'U+1F563',
    img: '1f563.png',
  },
  '🕤': {
    name: 'clock face nine-thirty',
    dotName: ':clock930:',
    code: 'U+1F564',
    img: '1f564.png',
  },
  '🕥': {
    name: 'clock face ten-thirty',
    dotName: ':clock1030:',
    code: 'U+1F565',
    img: '1f565.png',
  },
  '🕦': {
    name: 'clock face eleven-thirty',
    dotName: ':clock1130:',
    code: 'U+1F566',
    img: '1f566.png',
  },
  '🕧': {
    name: 'clock face twelve-thirty',
    dotName: ':clock1230:',
    code: 'U+1F567',
    img: '1f567.png',
  },
  '🕯️': {
    name: '',
    dotName: ':candle:',
    code: 'U+1F56F U+FE0F',
    img: '1f56f-fe0f.png',
  },
  '🕰️': {
    name: '',
    dotName: ':mantelpiece_clock:',
    code: 'U+1F570 U+FE0F',
    img: '1f570-fe0f.png',
  },
  '🕳️': {
    name: '',
    dotName: ':hole:',
    code: 'U+1F573 U+FE0F',
    img: '1f573-fe0f.png',
  },
  '🕴️': {
    name: '',
    dotName: ':man_in_business_suit_levitating:',
    code: 'U+1F574 U+FE0F',
    img: '1f574-fe0f.png',
  },
  '🕵️‍♀️': {
    name: '',
    dotName: ':female-detective:',
    code: 'U+1F575 U+FE0F U+200D U+2640 U+FE0F',
    img: '1f575-fe0f-200d-2640-fe0f.png',
  },
  '🕵️‍♂️': {
    name: '',
    dotName: ':male-detective:',
    code: 'U+1F575 U+FE0F U+200D U+2642 U+FE0F',
    img: '1f575-fe0f-200d-2642-fe0f.png',
  },
  '🕵️': {
    name: '',
    dotName: ':sleuth_or_spy:',
    code: 'U+1F575 U+FE0F',
    img: '1f575-fe0f.png',
  },
  '🕶️': {
    name: '',
    dotName: ':dark_sunglasses:',
    code: 'U+1F576 U+FE0F',
    img: '1f576-fe0f.png',
  },
  '🕷️': {
    name: '',
    dotName: ':spider:',
    code: 'U+1F577 U+FE0F',
    img: '1f577-fe0f.png',
  },
  '🕸️': {
    name: '',
    dotName: ':spider_web:',
    code: 'U+1F578 U+FE0F',
    img: '1f578-fe0f.png',
  },
  '🕹️': {
    name: '',
    dotName: ':joystick:',
    code: 'U+1F579 U+FE0F',
    img: '1f579-fe0f.png',
  },
  '🕺': {
    name: 'man dancing',
    dotName: ':man_dancing:',
    code: 'U+1F57A',
    img: '1f57a.png',
  },
  '🖇️': {
    name: '',
    dotName: ':linked_paperclips:',
    code: 'U+1F587 U+FE0F',
    img: '1f587-fe0f.png',
  },
  '🖊️': {
    name: '',
    dotName: ':lower_left_ballpoint_pen:',
    code: 'U+1F58A U+FE0F',
    img: '1f58a-fe0f.png',
  },
  '🖋️': {
    name: '',
    dotName: ':lower_left_fountain_pen:',
    code: 'U+1F58B U+FE0F',
    img: '1f58b-fe0f.png',
  },
  '🖌️': {
    name: '',
    dotName: ':lower_left_paintbrush:',
    code: 'U+1F58C U+FE0F',
    img: '1f58c-fe0f.png',
  },
  '🖍️': {
    name: '',
    dotName: ':lower_left_crayon:',
    code: 'U+1F58D U+FE0F',
    img: '1f58d-fe0f.png',
  },
  '🖐️': {
    name: '',
    dotName: ':raised_hand_with_fingers_splayed:',
    code: 'U+1F590 U+FE0F',
    img: '1f590-fe0f.png',
  },
  '🖕': {
    name: 'reversed hand with middle finger extended',
    dotName: ':middle_finger:',
    code: 'U+1F595',
    img: '1f595.png',
  },
  '🖖': {
    name: 'raised hand with part between middle and ring fingers',
    dotName: ':spock-hand:',
    code: 'U+1F596',
    img: '1f596.png',
  },
  '🖤': {
    name: 'black heart',
    dotName: ':black_heart:',
    code: 'U+1F5A4',
    img: '1f5a4.png',
  },
  '🖥️': {
    name: '',
    dotName: ':desktop_computer:',
    code: 'U+1F5A5 U+FE0F',
    img: '1f5a5-fe0f.png',
  },
  '🖨️': {
    name: '',
    dotName: ':printer:',
    code: 'U+1F5A8 U+FE0F',
    img: '1f5a8-fe0f.png',
  },
  '🖱️': {
    name: '',
    dotName: ':three_button_mouse:',
    code: 'U+1F5B1 U+FE0F',
    img: '1f5b1-fe0f.png',
  },
  '🖲️': {
    name: '',
    dotName: ':trackball:',
    code: 'U+1F5B2 U+FE0F',
    img: '1f5b2-fe0f.png',
  },
  '🖼️': {
    name: '',
    dotName: ':frame_with_picture:',
    code: 'U+1F5BC U+FE0F',
    img: '1f5bc-fe0f.png',
  },
  '🗂️': {
    name: '',
    dotName: ':card_index_dividers:',
    code: 'U+1F5C2 U+FE0F',
    img: '1f5c2-fe0f.png',
  },
  '🗃️': {
    name: '',
    dotName: ':card_file_box:',
    code: 'U+1F5C3 U+FE0F',
    img: '1f5c3-fe0f.png',
  },
  '🗄️': {
    name: '',
    dotName: ':file_cabinet:',
    code: 'U+1F5C4 U+FE0F',
    img: '1f5c4-fe0f.png',
  },
  '🗑️': {
    name: '',
    dotName: ':wastebasket:',
    code: 'U+1F5D1 U+FE0F',
    img: '1f5d1-fe0f.png',
  },
  '🗒️': {
    name: '',
    dotName: ':spiral_note_pad:',
    code: 'U+1F5D2 U+FE0F',
    img: '1f5d2-fe0f.png',
  },
  '🗓️': {
    name: '',
    dotName: ':spiral_calendar_pad:',
    code: 'U+1F5D3 U+FE0F',
    img: '1f5d3-fe0f.png',
  },
  '🗜️': {
    name: '',
    dotName: ':compression:',
    code: 'U+1F5DC U+FE0F',
    img: '1f5dc-fe0f.png',
  },
  '🗝️': {
    name: '',
    dotName: ':old_key:',
    code: 'U+1F5DD U+FE0F',
    img: '1f5dd-fe0f.png',
  },
  '🗞️': {
    name: '',
    dotName: ':rolled_up_newspaper:',
    code: 'U+1F5DE U+FE0F',
    img: '1f5de-fe0f.png',
  },
  '🗡️': {
    name: '',
    dotName: ':dagger_knife:',
    code: 'U+1F5E1 U+FE0F',
    img: '1f5e1-fe0f.png',
  },
  '🗣️': {
    name: '',
    dotName: ':speaking_head_in_silhouette:',
    code: 'U+1F5E3 U+FE0F',
    img: '1f5e3-fe0f.png',
  },
  '🗨️': {
    name: '',
    dotName: ':left_speech_bubble:',
    code: 'U+1F5E8 U+FE0F',
    img: '1f5e8-fe0f.png',
  },
  '🗯️': {
    name: '',
    dotName: ':right_anger_bubble:',
    code: 'U+1F5EF U+FE0F',
    img: '1f5ef-fe0f.png',
  },
  '🗳️': {
    name: '',
    dotName: ':ballot_box_with_ballot:',
    code: 'U+1F5F3 U+FE0F',
    img: '1f5f3-fe0f.png',
  },
  '🗺️': {
    name: '',
    dotName: ':world_map:',
    code: 'U+1F5FA U+FE0F',
    img: '1f5fa-fe0f.png',
  },
  '🗻': {
    name: 'mount fuji',
    dotName: ':mount_fuji:',
    code: 'U+1F5FB',
    img: '1f5fb.png',
  },
  '🗼': {
    name: 'tokyo tower',
    dotName: ':tokyo_tower:',
    code: 'U+1F5FC',
    img: '1f5fc.png',
  },
  '🗽': {
    name: 'statue of liberty',
    dotName: ':statue_of_liberty:',
    code: 'U+1F5FD',
    img: '1f5fd.png',
  },
  '🗾': {
    name: 'silhouette of japan',
    dotName: ':japan:',
    code: 'U+1F5FE',
    img: '1f5fe.png',
  },
  '🗿': {
    name: 'moyai',
    dotName: ':moyai:',
    code: 'U+1F5FF',
    img: '1f5ff.png',
  },
  '😀': {
    name: 'grinning face',
    dotName: ':grinning:',
    code: 'U+1F600',
    img: '1f600.png',
  },
  '😁': {
    name: 'grinning face with smiling eyes',
    dotName: ':grin:',
    code: 'U+1F601',
    img: '1f601.png',
  },
  '😂': {
    name: 'face with tears of joy',
    dotName: ':joy:',
    code: 'U+1F602',
    img: '1f602.png',
  },
  '😃': {
    name: 'smiling face with open mouth',
    dotName: ':smiley:',
    code: 'U+1F603',
    img: '1f603.png',
  },
  '😄': {
    name: 'smiling face with open mouth and smiling eyes',
    dotName: ':smile:',
    code: 'U+1F604',
    img: '1f604.png',
  },
  '😅': {
    name: 'smiling face with open mouth and cold sweat',
    dotName: ':sweat_smile:',
    code: 'U+1F605',
    img: '1f605.png',
  },
  '😆': {
    name: 'smiling face with open mouth and tightly-closed eyes',
    dotName: ':laughing:',
    code: 'U+1F606',
    img: '1f606.png',
  },
  '😇': {
    name: 'smiling face with halo',
    dotName: ':innocent:',
    code: 'U+1F607',
    img: '1f607.png',
  },
  '😈': {
    name: 'smiling face with horns',
    dotName: ':smiling_imp:',
    code: 'U+1F608',
    img: '1f608.png',
  },
  '😉': {
    name: 'winking face',
    dotName: ':wink:',
    code: 'U+1F609',
    img: '1f609.png',
  },
  '😊': {
    name: 'smiling face with smiling eyes',
    dotName: ':blush:',
    code: 'U+1F60A',
    img: '1f60a.png',
  },
  '😋': {
    name: 'face savouring delicious food',
    dotName: ':yum:',
    code: 'U+1F60B',
    img: '1f60b.png',
  },
  '😌': {
    name: 'relieved face',
    dotName: ':relieved:',
    code: 'U+1F60C',
    img: '1f60c.png',
  },
  '😍': {
    name: 'smiling face with heart-shaped eyes',
    dotName: ':heart_eyes:',
    code: 'U+1F60D',
    img: '1f60d.png',
  },
  '😎': {
    name: 'smiling face with sunglasses',
    dotName: ':sunglasses:',
    code: 'U+1F60E',
    img: '1f60e.png',
  },
  '😏': {
    name: 'smirking face',
    dotName: ':smirk:',
    code: 'U+1F60F',
    img: '1f60f.png',
  },
  '😐': {
    name: 'neutral face',
    dotName: ':neutral_face:',
    code: 'U+1F610',
    img: '1f610.png',
  },
  '😑': {
    name: 'expressionless face',
    dotName: ':expressionless:',
    code: 'U+1F611',
    img: '1f611.png',
  },
  '😒': {
    name: 'unamused face',
    dotName: ':unamused:',
    code: 'U+1F612',
    img: '1f612.png',
  },
  '😓': {
    name: 'face with cold sweat',
    dotName: ':sweat:',
    code: 'U+1F613',
    img: '1f613.png',
  },
  '😔': {
    name: 'pensive face',
    dotName: ':pensive:',
    code: 'U+1F614',
    img: '1f614.png',
  },
  '😕': {
    name: 'confused face',
    dotName: ':confused:',
    code: 'U+1F615',
    img: '1f615.png',
  },
  '😖': {
    name: 'confounded face',
    dotName: ':confounded:',
    code: 'U+1F616',
    img: '1f616.png',
  },
  '😗': {
    name: 'kissing face',
    dotName: ':kissing:',
    code: 'U+1F617',
    img: '1f617.png',
  },
  '😘': {
    name: 'face throwing a kiss',
    dotName: ':kissing_heart:',
    code: 'U+1F618',
    img: '1f618.png',
  },
  '😙': {
    name: 'kissing face with smiling eyes',
    dotName: ':kissing_smiling_eyes:',
    code: 'U+1F619',
    img: '1f619.png',
  },
  '😚': {
    name: 'kissing face with closed eyes',
    dotName: ':kissing_closed_eyes:',
    code: 'U+1F61A',
    img: '1f61a.png',
  },
  '😛': {
    name: 'face with stuck-out tongue',
    dotName: ':stuck_out_tongue:',
    code: 'U+1F61B',
    img: '1f61b.png',
  },
  '😜': {
    name: 'face with stuck-out tongue and winking eye',
    dotName: ':stuck_out_tongue_winking_eye:',
    code: 'U+1F61C',
    img: '1f61c.png',
  },
  '😝': {
    name: 'face with stuck-out tongue and tightly-closed eyes',
    dotName: ':stuck_out_tongue_closed_eyes:',
    code: 'U+1F61D',
    img: '1f61d.png',
  },
  '😞': {
    name: 'disappointed face',
    dotName: ':disappointed:',
    code: 'U+1F61E',
    img: '1f61e.png',
  },
  '😟': {
    name: 'worried face',
    dotName: ':worried:',
    code: 'U+1F61F',
    img: '1f61f.png',
  },
  '😠': {
    name: 'angry face',
    dotName: ':angry:',
    code: 'U+1F620',
    img: '1f620.png',
  },
  '😡': {
    name: 'pouting face',
    dotName: ':rage:',
    code: 'U+1F621',
    img: '1f621.png',
  },
  '😢': {
    name: 'crying face',
    dotName: ':cry:',
    code: 'U+1F622',
    img: '1f622.png',
  },
  '😣': {
    name: 'persevering face',
    dotName: ':persevere:',
    code: 'U+1F623',
    img: '1f623.png',
  },
  '😤': {
    name: 'face with look of triumph',
    dotName: ':triumph:',
    code: 'U+1F624',
    img: '1f624.png',
  },
  '😥': {
    name: 'disappointed but relieved face',
    dotName: ':disappointed_relieved:',
    code: 'U+1F625',
    img: '1f625.png',
  },
  '😦': {
    name: 'frowning face with open mouth',
    dotName: ':frowning:',
    code: 'U+1F626',
    img: '1f626.png',
  },
  '😧': {
    name: 'anguished face',
    dotName: ':anguished:',
    code: 'U+1F627',
    img: '1f627.png',
  },
  '😨': {
    name: 'fearful face',
    dotName: ':fearful:',
    code: 'U+1F628',
    img: '1f628.png',
  },
  '😩': {
    name: 'weary face',
    dotName: ':weary:',
    code: 'U+1F629',
    img: '1f629.png',
  },
  '😪': {
    name: 'sleepy face',
    dotName: ':sleepy:',
    code: 'U+1F62A',
    img: '1f62a.png',
  },
  '😫': {
    name: 'tired face',
    dotName: ':tired_face:',
    code: 'U+1F62B',
    img: '1f62b.png',
  },
  '😬': {
    name: 'grimacing face',
    dotName: ':grimacing:',
    code: 'U+1F62C',
    img: '1f62c.png',
  },
  '😭': {
    name: 'loudly crying face',
    dotName: ':sob:',
    code: 'U+1F62D',
    img: '1f62d.png',
  },
  '😮': {
    name: 'face with open mouth',
    dotName: ':open_mouth:',
    code: 'U+1F62E',
    img: '1f62e.png',
  },
  '😯': {
    name: 'hushed face',
    dotName: ':hushed:',
    code: 'U+1F62F',
    img: '1f62f.png',
  },
  '😰': {
    name: 'face with open mouth and cold sweat',
    dotName: ':cold_sweat:',
    code: 'U+1F630',
    img: '1f630.png',
  },
  '😱': {
    name: 'face screaming in fear',
    dotName: ':scream:',
    code: 'U+1F631',
    img: '1f631.png',
  },
  '😲': {
    name: 'astonished face',
    dotName: ':astonished:',
    code: 'U+1F632',
    img: '1f632.png',
  },
  '😳': {
    name: 'flushed face',
    dotName: ':flushed:',
    code: 'U+1F633',
    img: '1f633.png',
  },
  '😴': {
    name: 'sleeping face',
    dotName: ':sleeping:',
    code: 'U+1F634',
    img: '1f634.png',
  },
  '😵': {
    name: 'dizzy face',
    dotName: ':dizzy_face:',
    code: 'U+1F635',
    img: '1f635.png',
  },
  '😶': {
    name: 'face without mouth',
    dotName: ':no_mouth:',
    code: 'U+1F636',
    img: '1f636.png',
  },
  '😷': {
    name: 'face with medical mask',
    dotName: ':mask:',
    code: 'U+1F637',
    img: '1f637.png',
  },
  '😸': {
    name: 'grinning cat face with smiling eyes',
    dotName: ':smile_cat:',
    code: 'U+1F638',
    img: '1f638.png',
  },
  '😹': {
    name: 'cat face with tears of joy',
    dotName: ':joy_cat:',
    code: 'U+1F639',
    img: '1f639.png',
  },
  '😺': {
    name: 'smiling cat face with open mouth',
    dotName: ':smiley_cat:',
    code: 'U+1F63A',
    img: '1f63a.png',
  },
  '😻': {
    name: 'smiling cat face with heart-shaped eyes',
    dotName: ':heart_eyes_cat:',
    code: 'U+1F63B',
    img: '1f63b.png',
  },
  '😼': {
    name: 'cat face with wry smile',
    dotName: ':smirk_cat:',
    code: 'U+1F63C',
    img: '1f63c.png',
  },
  '😽': {
    name: 'kissing cat face with closed eyes',
    dotName: ':kissing_cat:',
    code: 'U+1F63D',
    img: '1f63d.png',
  },
  '😾': {
    name: 'pouting cat face',
    dotName: ':pouting_cat:',
    code: 'U+1F63E',
    img: '1f63e.png',
  },
  '😿': {
    name: 'crying cat face',
    dotName: ':crying_cat_face:',
    code: 'U+1F63F',
    img: '1f63f.png',
  },
  '🙀': {
    name: 'weary cat face',
    dotName: ':scream_cat:',
    code: 'U+1F640',
    img: '1f640.png',
  },
  '🙁': {
    name: 'slightly frowning face',
    dotName: ':slightly_frowning_face:',
    code: 'U+1F641',
    img: '1f641.png',
  },
  '🙂': {
    name: 'slightly smiling face',
    dotName: ':slightly_smiling_face:',
    code: 'U+1F642',
    img: '1f642.png',
  },
  '🙃': {
    name: 'upside-down face',
    dotName: ':upside_down_face:',
    code: 'U+1F643',
    img: '1f643.png',
  },
  '🙄': {
    name: 'face with rolling eyes',
    dotName: ':face_with_rolling_eyes:',
    code: 'U+1F644',
    img: '1f644.png',
  },
  '🙅‍♀️': {
    name: '',
    dotName: ':woman-gesturing-no:',
    code: 'U+1F645 U+200D U+2640 U+FE0F',
    img: '1f645-200d-2640-fe0f.png',
  },
  '🙅‍♂️': {
    name: '',
    dotName: ':man-gesturing-no:',
    code: 'U+1F645 U+200D U+2642 U+FE0F',
    img: '1f645-200d-2642-fe0f.png',
  },
  '🙅': {
    name: 'face with no good gesture',
    dotName: ':no_good:',
    code: 'U+1F645',
    img: '1f645.png',
  },
  '🙆‍♀️': {
    name: '',
    dotName: ':woman-gesturing-ok:',
    code: 'U+1F646 U+200D U+2640 U+FE0F',
    img: '1f646-200d-2640-fe0f.png',
  },
  '🙆‍♂️': {
    name: '',
    dotName: ':man-gesturing-ok:',
    code: 'U+1F646 U+200D U+2642 U+FE0F',
    img: '1f646-200d-2642-fe0f.png',
  },
  '🙆': {
    name: 'face with ok gesture',
    dotName: ':ok_woman:',
    code: 'U+1F646',
    img: '1f646.png',
  },
  '🙇‍♀️': {
    name: '',
    dotName: ':woman-bowing:',
    code: 'U+1F647 U+200D U+2640 U+FE0F',
    img: '1f647-200d-2640-fe0f.png',
  },
  '🙇‍♂️': {
    name: '',
    dotName: ':man-bowing:',
    code: 'U+1F647 U+200D U+2642 U+FE0F',
    img: '1f647-200d-2642-fe0f.png',
  },
  '🙇': {
    name: 'person bowing deeply',
    dotName: ':bow:',
    code: 'U+1F647',
    img: '1f647.png',
  },
  '🙈': {
    name: 'see-no-evil monkey',
    dotName: ':see_no_evil:',
    code: 'U+1F648',
    img: '1f648.png',
  },
  '🙉': {
    name: 'hear-no-evil monkey',
    dotName: ':hear_no_evil:',
    code: 'U+1F649',
    img: '1f649.png',
  },
  '🙊': {
    name: 'speak-no-evil monkey',
    dotName: ':speak_no_evil:',
    code: 'U+1F64A',
    img: '1f64a.png',
  },
  '🙋‍♀️': {
    name: '',
    dotName: ':woman-raising-hand:',
    code: 'U+1F64B U+200D U+2640 U+FE0F',
    img: '1f64b-200d-2640-fe0f.png',
  },
  '🙋‍♂️': {
    name: '',
    dotName: ':man-raising-hand:',
    code: 'U+1F64B U+200D U+2642 U+FE0F',
    img: '1f64b-200d-2642-fe0f.png',
  },
  '🙋': {
    name: 'happy person raising one hand',
    dotName: ':raising_hand:',
    code: 'U+1F64B',
    img: '1f64b.png',
  },
  '🙌': {
    name: 'person raising both hands in celebration',
    dotName: ':raised_hands:',
    code: 'U+1F64C',
    img: '1f64c.png',
  },
  '🙍‍♀️': {
    name: '',
    dotName: ':woman-frowning:',
    code: 'U+1F64D U+200D U+2640 U+FE0F',
    img: '1f64d-200d-2640-fe0f.png',
  },
  '🙍‍♂️': {
    name: '',
    dotName: ':man-frowning:',
    code: 'U+1F64D U+200D U+2642 U+FE0F',
    img: '1f64d-200d-2642-fe0f.png',
  },
  '🙍': {
    name: 'person frowning',
    dotName: ':person_frowning:',
    code: 'U+1F64D',
    img: '1f64d.png',
  },
  '🙎‍♀️': {
    name: '',
    dotName: ':woman-pouting:',
    code: 'U+1F64E U+200D U+2640 U+FE0F',
    img: '1f64e-200d-2640-fe0f.png',
  },
  '🙎‍♂️': {
    name: '',
    dotName: ':man-pouting:',
    code: 'U+1F64E U+200D U+2642 U+FE0F',
    img: '1f64e-200d-2642-fe0f.png',
  },
  '🙎': {
    name: 'person with pouting face',
    dotName: ':person_with_pouting_face:',
    code: 'U+1F64E',
    img: '1f64e.png',
  },
  '🙏': {
    name: 'person with folded hands',
    dotName: ':pray:',
    code: 'U+1F64F',
    img: '1f64f.png',
  },
  '🚀': {
    name: 'rocket',
    dotName: ':rocket:',
    code: 'U+1F680',
    img: '1f680.png',
  },
  '🚁': {
    name: 'helicopter',
    dotName: ':helicopter:',
    code: 'U+1F681',
    img: '1f681.png',
  },
  '🚂': {
    name: 'steam locomotive',
    dotName: ':steam_locomotive:',
    code: 'U+1F682',
    img: '1f682.png',
  },
  '🚃': {
    name: 'railway car',
    dotName: ':railway_car:',
    code: 'U+1F683',
    img: '1f683.png',
  },
  '🚄': {
    name: 'high-speed train',
    dotName: ':bullettrain_side:',
    code: 'U+1F684',
    img: '1f684.png',
  },
  '🚅': {
    name: 'high-speed train with bullet nose',
    dotName: ':bullettrain_front:',
    code: 'U+1F685',
    img: '1f685.png',
  },
  '🚆': {
    name: 'train',
    dotName: ':train2:',
    code: 'U+1F686',
    img: '1f686.png',
  },
  '🚇': {
    name: 'metro',
    dotName: ':metro:',
    code: 'U+1F687',
    img: '1f687.png',
  },
  '🚈': {
    name: 'light rail',
    dotName: ':light_rail:',
    code: 'U+1F688',
    img: '1f688.png',
  },
  '🚉': {
    name: 'station',
    dotName: ':station:',
    code: 'U+1F689',
    img: '1f689.png',
  },
  '🚊': {
    name: 'tram',
    dotName: ':tram:',
    code: 'U+1F68A',
    img: '1f68a.png',
  },
  '🚋': {
    name: 'tram car',
    dotName: ':train:',
    code: 'U+1F68B',
    img: '1f68b.png',
  },
  '🚌': {
    name: 'bus',
    dotName: ':bus:',
    code: 'U+1F68C',
    img: '1f68c.png',
  },
  '🚍': {
    name: 'oncoming bus',
    dotName: ':oncoming_bus:',
    code: 'U+1F68D',
    img: '1f68d.png',
  },
  '🚎': {
    name: 'trolleybus',
    dotName: ':trolleybus:',
    code: 'U+1F68E',
    img: '1f68e.png',
  },
  '🚏': {
    name: 'bus stop',
    dotName: ':busstop:',
    code: 'U+1F68F',
    img: '1f68f.png',
  },
  '🚐': {
    name: 'minibus',
    dotName: ':minibus:',
    code: 'U+1F690',
    img: '1f690.png',
  },
  '🚑': {
    name: 'ambulance',
    dotName: ':ambulance:',
    code: 'U+1F691',
    img: '1f691.png',
  },
  '🚒': {
    name: 'fire engine',
    dotName: ':fire_engine:',
    code: 'U+1F692',
    img: '1f692.png',
  },
  '🚓': {
    name: 'police car',
    dotName: ':police_car:',
    code: 'U+1F693',
    img: '1f693.png',
  },
  '🚔': {
    name: 'oncoming police car',
    dotName: ':oncoming_police_car:',
    code: 'U+1F694',
    img: '1f694.png',
  },
  '🚕': {
    name: 'taxi',
    dotName: ':taxi:',
    code: 'U+1F695',
    img: '1f695.png',
  },
  '🚖': {
    name: 'oncoming taxi',
    dotName: ':oncoming_taxi:',
    code: 'U+1F696',
    img: '1f696.png',
  },
  '🚗': {
    name: 'automobile',
    dotName: ':car:',
    code: 'U+1F697',
    img: '1f697.png',
  },
  '🚘': {
    name: 'oncoming automobile',
    dotName: ':oncoming_automobile:',
    code: 'U+1F698',
    img: '1f698.png',
  },
  '🚙': {
    name: 'recreational vehicle',
    dotName: ':blue_car:',
    code: 'U+1F699',
    img: '1f699.png',
  },
  '🚚': {
    name: 'delivery truck',
    dotName: ':truck:',
    code: 'U+1F69A',
    img: '1f69a.png',
  },
  '🚛': {
    name: 'articulated lorry',
    dotName: ':articulated_lorry:',
    code: 'U+1F69B',
    img: '1f69b.png',
  },
  '🚜': {
    name: 'tractor',
    dotName: ':tractor:',
    code: 'U+1F69C',
    img: '1f69c.png',
  },
  '🚝': {
    name: 'monorail',
    dotName: ':monorail:',
    code: 'U+1F69D',
    img: '1f69d.png',
  },
  '🚞': {
    name: 'mountain railway',
    dotName: ':mountain_railway:',
    code: 'U+1F69E',
    img: '1f69e.png',
  },
  '🚟': {
    name: 'suspension railway',
    dotName: ':suspension_railway:',
    code: 'U+1F69F',
    img: '1f69f.png',
  },
  '🚠': {
    name: 'mountain cableway',
    dotName: ':mountain_cableway:',
    code: 'U+1F6A0',
    img: '1f6a0.png',
  },
  '🚡': {
    name: 'aerial tramway',
    dotName: ':aerial_tramway:',
    code: 'U+1F6A1',
    img: '1f6a1.png',
  },
  '🚢': {
    name: 'ship',
    dotName: ':ship:',
    code: 'U+1F6A2',
    img: '1f6a2.png',
  },
  '🚣‍♀️': {
    name: '',
    dotName: ':woman-rowing-boat:',
    code: 'U+1F6A3 U+200D U+2640 U+FE0F',
    img: '1f6a3-200d-2640-fe0f.png',
  },
  '🚣‍♂️': {
    name: '',
    dotName: ':man-rowing-boat:',
    code: 'U+1F6A3 U+200D U+2642 U+FE0F',
    img: '1f6a3-200d-2642-fe0f.png',
  },
  '🚣': {
    name: 'rowboat',
    dotName: ':rowboat:',
    code: 'U+1F6A3',
    img: '1f6a3.png',
  },
  '🚤': {
    name: 'speedboat',
    dotName: ':speedboat:',
    code: 'U+1F6A4',
    img: '1f6a4.png',
  },
  '🚥': {
    name: 'horizontal traffic light',
    dotName: ':traffic_light:',
    code: 'U+1F6A5',
    img: '1f6a5.png',
  },
  '🚦': {
    name: 'vertical traffic light',
    dotName: ':vertical_traffic_light:',
    code: 'U+1F6A6',
    img: '1f6a6.png',
  },
  '🚧': {
    name: 'construction sign',
    dotName: ':construction:',
    code: 'U+1F6A7',
    img: '1f6a7.png',
  },
  '🚨': {
    name: 'police cars revolving light',
    dotName: ':rotating_light:',
    code: 'U+1F6A8',
    img: '1f6a8.png',
  },
  '🚩': {
    name: 'triangular flag on post',
    dotName: ':triangular_flag_on_post:',
    code: 'U+1F6A9',
    img: '1f6a9.png',
  },
  '🚪': {
    name: 'door',
    dotName: ':door:',
    code: 'U+1F6AA',
    img: '1f6aa.png',
  },
  '🚫': {
    name: 'no entry sign',
    dotName: ':no_entry_sign:',
    code: 'U+1F6AB',
    img: '1f6ab.png',
  },
  '🚬': {
    name: 'smoking symbol',
    dotName: ':smoking:',
    code: 'U+1F6AC',
    img: '1f6ac.png',
  },
  '🚭': {
    name: 'no smoking symbol',
    dotName: ':no_smoking:',
    code: 'U+1F6AD',
    img: '1f6ad.png',
  },
  '🚮': {
    name: 'put litter in its place symbol',
    dotName: ':put_litter_in_its_place:',
    code: 'U+1F6AE',
    img: '1f6ae.png',
  },
  '🚯': {
    name: 'do not litter symbol',
    dotName: ':do_not_litter:',
    code: 'U+1F6AF',
    img: '1f6af.png',
  },
  '🚰': {
    name: 'potable water symbol',
    dotName: ':potable_water:',
    code: 'U+1F6B0',
    img: '1f6b0.png',
  },
  '🚱': {
    name: 'non-potable water symbol',
    dotName: ':non-potable_water:',
    code: 'U+1F6B1',
    img: '1f6b1.png',
  },
  '🚲': {
    name: 'bicycle',
    dotName: ':bike:',
    code: 'U+1F6B2',
    img: '1f6b2.png',
  },
  '🚳': {
    name: 'no bicycles',
    dotName: ':no_bicycles:',
    code: 'U+1F6B3',
    img: '1f6b3.png',
  },
  '🚴‍♀️': {
    name: '',
    dotName: ':woman-biking:',
    code: 'U+1F6B4 U+200D U+2640 U+FE0F',
    img: '1f6b4-200d-2640-fe0f.png',
  },
  '🚴‍♂️': {
    name: '',
    dotName: ':man-biking:',
    code: 'U+1F6B4 U+200D U+2642 U+FE0F',
    img: '1f6b4-200d-2642-fe0f.png',
  },
  '🚴': {
    name: 'bicyclist',
    dotName: ':bicyclist:',
    code: 'U+1F6B4',
    img: '1f6b4.png',
  },
  '🚵‍♀️': {
    name: '',
    dotName: ':woman-mountain-biking:',
    code: 'U+1F6B5 U+200D U+2640 U+FE0F',
    img: '1f6b5-200d-2640-fe0f.png',
  },
  '🚵‍♂️': {
    name: '',
    dotName: ':man-mountain-biking:',
    code: 'U+1F6B5 U+200D U+2642 U+FE0F',
    img: '1f6b5-200d-2642-fe0f.png',
  },
  '🚵': {
    name: 'mountain bicyclist',
    dotName: ':mountain_bicyclist:',
    code: 'U+1F6B5',
    img: '1f6b5.png',
  },
  '🚶‍♀️': {
    name: '',
    dotName: ':woman-walking:',
    code: 'U+1F6B6 U+200D U+2640 U+FE0F',
    img: '1f6b6-200d-2640-fe0f.png',
  },
  '🚶‍♂️': {
    name: '',
    dotName: ':man-walking:',
    code: 'U+1F6B6 U+200D U+2642 U+FE0F',
    img: '1f6b6-200d-2642-fe0f.png',
  },
  '🚶': {
    name: 'pedestrian',
    dotName: ':walking:',
    code: 'U+1F6B6',
    img: '1f6b6.png',
  },
  '🚷': {
    name: 'no pedestrians',
    dotName: ':no_pedestrians:',
    code: 'U+1F6B7',
    img: '1f6b7.png',
  },
  '🚸': {
    name: 'children crossing',
    dotName: ':children_crossing:',
    code: 'U+1F6B8',
    img: '1f6b8.png',
  },
  '🚹': {
    name: 'mens symbol',
    dotName: ':mens:',
    code: 'U+1F6B9',
    img: '1f6b9.png',
  },
  '🚺': {
    name: 'womens symbol',
    dotName: ':womens:',
    code: 'U+1F6BA',
    img: '1f6ba.png',
  },
  '🚻': {
    name: 'restroom',
    dotName: ':restroom:',
    code: 'U+1F6BB',
    img: '1f6bb.png',
  },
  '🚼': {
    name: 'baby symbol',
    dotName: ':baby_symbol:',
    code: 'U+1F6BC',
    img: '1f6bc.png',
  },
  '🚽': {
    name: 'toilet',
    dotName: ':toilet:',
    code: 'U+1F6BD',
    img: '1f6bd.png',
  },
  '🚾': {
    name: 'water closet',
    dotName: ':wc:',
    code: 'U+1F6BE',
    img: '1f6be.png',
  },
  '🚿': {
    name: 'shower',
    dotName: ':shower:',
    code: 'U+1F6BF',
    img: '1f6bf.png',
  },
  '🛀': {
    name: 'bath',
    dotName: ':bath:',
    code: 'U+1F6C0',
    img: '1f6c0.png',
  },
  '🛁': {
    name: 'bathtub',
    dotName: ':bathtub:',
    code: 'U+1F6C1',
    img: '1f6c1.png',
  },
  '🛂': {
    name: 'passport control',
    dotName: ':passport_control:',
    code: 'U+1F6C2',
    img: '1f6c2.png',
  },
  '🛃': {
    name: 'customs',
    dotName: ':customs:',
    code: 'U+1F6C3',
    img: '1f6c3.png',
  },
  '🛄': {
    name: 'baggage claim',
    dotName: ':baggage_claim:',
    code: 'U+1F6C4',
    img: '1f6c4.png',
  },
  '🛅': {
    name: 'left luggage',
    dotName: ':left_luggage:',
    code: 'U+1F6C5',
    img: '1f6c5.png',
  },
  '🛋️': {
    name: '',
    dotName: ':couch_and_lamp:',
    code: 'U+1F6CB U+FE0F',
    img: '1f6cb-fe0f.png',
  },
  '🛌': {
    name: 'sleeping accommodation',
    dotName: ':sleeping_accommodation:',
    code: 'U+1F6CC',
    img: '1f6cc.png',
  },
  '🛍️': {
    name: '',
    dotName: ':shopping_bags:',
    code: 'U+1F6CD U+FE0F',
    img: '1f6cd-fe0f.png',
  },
  '🛎️': {
    name: '',
    dotName: ':bellhop_bell:',
    code: 'U+1F6CE U+FE0F',
    img: '1f6ce-fe0f.png',
  },
  '🛏️': {
    name: '',
    dotName: ':bed:',
    code: 'U+1F6CF U+FE0F',
    img: '1f6cf-fe0f.png',
  },
  '🛐': {
    name: 'place of worship',
    dotName: ':place_of_worship:',
    code: 'U+1F6D0',
    img: '1f6d0.png',
  },
  '🛑': {
    name: 'octagonal sign',
    dotName: ':octagonal_sign:',
    code: 'U+1F6D1',
    img: '1f6d1.png',
  },
  '🛒': {
    name: 'shopping trolley',
    dotName: ':shopping_trolley:',
    code: 'U+1F6D2',
    img: '1f6d2.png',
  },
  '🛕': {
    name: 'hindu temple',
    dotName: ':hindu_temple:',
    code: 'U+1F6D5',
    img: '1f6d5.png',
  },
  '🛖': {
    name: 'hut',
    dotName: ':hut:',
    code: 'U+1F6D6',
    img: '1f6d6.png',
  },
  '🛗': {
    name: 'elevator',
    dotName: ':elevator:',
    code: 'U+1F6D7',
    img: '1f6d7.png',
  },
  '🛠️': {
    name: '',
    dotName: ':hammer_and_wrench:',
    code: 'U+1F6E0 U+FE0F',
    img: '1f6e0-fe0f.png',
  },
  '🛡️': {
    name: '',
    dotName: ':shield:',
    code: 'U+1F6E1 U+FE0F',
    img: '1f6e1-fe0f.png',
  },
  '🛢️': {
    name: '',
    dotName: ':oil_drum:',
    code: 'U+1F6E2 U+FE0F',
    img: '1f6e2-fe0f.png',
  },
  '🛣️': {
    name: '',
    dotName: ':motorway:',
    code: 'U+1F6E3 U+FE0F',
    img: '1f6e3-fe0f.png',
  },
  '🛤️': {
    name: '',
    dotName: ':railway_track:',
    code: 'U+1F6E4 U+FE0F',
    img: '1f6e4-fe0f.png',
  },
  '🛥️': {
    name: '',
    dotName: ':motor_boat:',
    code: 'U+1F6E5 U+FE0F',
    img: '1f6e5-fe0f.png',
  },
  '🛩️': {
    name: '',
    dotName: ':small_airplane:',
    code: 'U+1F6E9 U+FE0F',
    img: '1f6e9-fe0f.png',
  },
  '🛫': {
    name: 'airplane departure',
    dotName: ':airplane_departure:',
    code: 'U+1F6EB',
    img: '1f6eb.png',
  },
  '🛬': {
    name: 'airplane arriving',
    dotName: ':airplane_arriving:',
    code: 'U+1F6EC',
    img: '1f6ec.png',
  },
  '🛰️': {
    name: '',
    dotName: ':satellite:',
    code: 'U+1F6F0 U+FE0F',
    img: '1f6f0-fe0f.png',
  },
  '🛳️': {
    name: '',
    dotName: ':passenger_ship:',
    code: 'U+1F6F3 U+FE0F',
    img: '1f6f3-fe0f.png',
  },
  '🛴': {
    name: 'scooter',
    dotName: ':scooter:',
    code: 'U+1F6F4',
    img: '1f6f4.png',
  },
  '🛵': {
    name: 'motor scooter',
    dotName: ':motor_scooter:',
    code: 'U+1F6F5',
    img: '1f6f5.png',
  },
  '🛶': {
    name: 'canoe',
    dotName: ':canoe:',
    code: 'U+1F6F6',
    img: '1f6f6.png',
  },
  '🛷': {
    name: 'sled',
    dotName: ':sled:',
    code: 'U+1F6F7',
    img: '1f6f7.png',
  },
  '🛸': {
    name: 'flying saucer',
    dotName: ':flying_saucer:',
    code: 'U+1F6F8',
    img: '1f6f8.png',
  },
  '🛹': {
    name: 'skateboard',
    dotName: ':skateboard:',
    code: 'U+1F6F9',
    img: '1f6f9.png',
  },
  '🛺': {
    name: 'auto rickshaw',
    dotName: ':auto_rickshaw:',
    code: 'U+1F6FA',
    img: '1f6fa.png',
  },
  '🛻': {
    name: 'pickup truck',
    dotName: ':pickup_truck:',
    code: 'U+1F6FB',
    img: '1f6fb.png',
  },
  '🛼': {
    name: 'roller skate',
    dotName: ':roller_skate:',
    code: 'U+1F6FC',
    img: '1f6fc.png',
  },
  '🟠': {
    name: 'large orange circle',
    dotName: ':large_orange_circle:',
    code: 'U+1F7E0',
    img: '1f7e0.png',
  },
  '🟡': {
    name: 'large yellow circle',
    dotName: ':large_yellow_circle:',
    code: 'U+1F7E1',
    img: '1f7e1.png',
  },
  '🟢': {
    name: 'large green circle',
    dotName: ':large_green_circle:',
    code: 'U+1F7E2',
    img: '1f7e2.png',
  },
  '🟣': {
    name: 'large purple circle',
    dotName: ':large_purple_circle:',
    code: 'U+1F7E3',
    img: '1f7e3.png',
  },
  '🟤': {
    name: 'large brown circle',
    dotName: ':large_brown_circle:',
    code: 'U+1F7E4',
    img: '1f7e4.png',
  },
  '🟥': {
    name: 'large red square',
    dotName: ':large_red_square:',
    code: 'U+1F7E5',
    img: '1f7e5.png',
  },
  '🟦': {
    name: 'large blue square',
    dotName: ':large_blue_square:',
    code: 'U+1F7E6',
    img: '1f7e6.png',
  },
  '🟧': {
    name: 'large orange square',
    dotName: ':large_orange_square:',
    code: 'U+1F7E7',
    img: '1f7e7.png',
  },
  '🟨': {
    name: 'large yellow square',
    dotName: ':large_yellow_square:',
    code: 'U+1F7E8',
    img: '1f7e8.png',
  },
  '🟩': {
    name: 'large green square',
    dotName: ':large_green_square:',
    code: 'U+1F7E9',
    img: '1f7e9.png',
  },
  '🟪': {
    name: 'large purple square',
    dotName: ':large_purple_square:',
    code: 'U+1F7EA',
    img: '1f7ea.png',
  },
  '🟫': {
    name: 'large brown square',
    dotName: ':large_brown_square:',
    code: 'U+1F7EB',
    img: '1f7eb.png',
  },
  '🤌': {
    name: 'pinched fingers',
    dotName: ':pinched_fingers:',
    code: 'U+1F90C',
    img: '1f90c.png',
  },
  '🤍': {
    name: 'white heart',
    dotName: ':white_heart:',
    code: 'U+1F90D',
    img: '1f90d.png',
  },
  '🤎': {
    name: 'brown heart',
    dotName: ':brown_heart:',
    code: 'U+1F90E',
    img: '1f90e.png',
  },
  '🤏': {
    name: 'pinching hand',
    dotName: ':pinching_hand:',
    code: 'U+1F90F',
    img: '1f90f.png',
  },
  '🤐': {
    name: 'zipper-mouth face',
    dotName: ':zipper_mouth_face:',
    code: 'U+1F910',
    img: '1f910.png',
  },
  '🤑': {
    name: 'money-mouth face',
    dotName: ':money_mouth_face:',
    code: 'U+1F911',
    img: '1f911.png',
  },
  '🤒': {
    name: 'face with thermometer',
    dotName: ':face_with_thermometer:',
    code: 'U+1F912',
    img: '1f912.png',
  },
  '🤓': {
    name: 'nerd face',
    dotName: ':nerd_face:',
    code: 'U+1F913',
    img: '1f913.png',
  },
  '🤔': {
    name: 'thinking face',
    dotName: ':thinking_face:',
    code: 'U+1F914',
    img: '1f914.png',
  },
  '🤕': {
    name: 'face with head-bandage',
    dotName: ':face_with_head_bandage:',
    code: 'U+1F915',
    img: '1f915.png',
  },
  '🤖': {
    name: 'robot face',
    dotName: ':robot_face:',
    code: 'U+1F916',
    img: '1f916.png',
  },
  '🤗': {
    name: 'hugging face',
    dotName: ':hugging_face:',
    code: 'U+1F917',
    img: '1f917.png',
  },
  '🤘': {
    name: 'sign of the horns',
    dotName: ':the_horns:',
    code: 'U+1F918',
    img: '1f918.png',
  },
  '🤙': {
    name: 'call me hand',
    dotName: ':call_me_hand:',
    code: 'U+1F919',
    img: '1f919.png',
  },
  '🤚': {
    name: 'raised back of hand',
    dotName: ':raised_back_of_hand:',
    code: 'U+1F91A',
    img: '1f91a.png',
  },
  '🤛': {
    name: 'left-facing fist',
    dotName: ':left-facing_fist:',
    code: 'U+1F91B',
    img: '1f91b.png',
  },
  '🤜': {
    name: 'right-facing fist',
    dotName: ':right-facing_fist:',
    code: 'U+1F91C',
    img: '1f91c.png',
  },
  '🤝': {
    name: 'handshake',
    dotName: ':handshake:',
    code: 'U+1F91D',
    img: '1f91d.png',
  },
  '🤞': {
    name: 'hand with index and middle fingers crossed',
    dotName: ':crossed_fingers:',
    code: 'U+1F91E',
    img: '1f91e.png',
  },
  '🤟': {
    name: 'i love you hand sign',
    dotName: ':i_love_you_hand_sign:',
    code: 'U+1F91F',
    img: '1f91f.png',
  },
  '🤠': {
    name: 'face with cowboy hat',
    dotName: ':face_with_cowboy_hat:',
    code: 'U+1F920',
    img: '1f920.png',
  },
  '🤡': {
    name: 'clown face',
    dotName: ':clown_face:',
    code: 'U+1F921',
    img: '1f921.png',
  },
  '🤢': {
    name: 'nauseated face',
    dotName: ':nauseated_face:',
    code: 'U+1F922',
    img: '1f922.png',
  },
  '🤣': {
    name: 'rolling on the floor laughing',
    dotName: ':rolling_on_the_floor_laughing:',
    code: 'U+1F923',
    img: '1f923.png',
  },
  '🤤': {
    name: 'drooling face',
    dotName: ':drooling_face:',
    code: 'U+1F924',
    img: '1f924.png',
  },
  '🤥': {
    name: 'lying face',
    dotName: ':lying_face:',
    code: 'U+1F925',
    img: '1f925.png',
  },
  '🤦‍♀️': {
    name: '',
    dotName: ':woman-facepalming:',
    code: 'U+1F926 U+200D U+2640 U+FE0F',
    img: '1f926-200d-2640-fe0f.png',
  },
  '🤦‍♂️': {
    name: '',
    dotName: ':man-facepalming:',
    code: 'U+1F926 U+200D U+2642 U+FE0F',
    img: '1f926-200d-2642-fe0f.png',
  },
  '🤦': {
    name: 'face palm',
    dotName: ':face_palm:',
    code: 'U+1F926',
    img: '1f926.png',
  },
  '🤧': {
    name: 'sneezing face',
    dotName: ':sneezing_face:',
    code: 'U+1F927',
    img: '1f927.png',
  },
  '🤨': {
    name: 'face with one eyebrow raised',
    dotName: ':face_with_raised_eyebrow:',
    code: 'U+1F928',
    img: '1f928.png',
  },
  '🤩': {
    name: 'grinning face with star eyes',
    dotName: ':star-struck:',
    code: 'U+1F929',
    img: '1f929.png',
  },
  '🤪': {
    name: 'grinning face with one large and one small eye',
    dotName: ':zany_face:',
    code: 'U+1F92A',
    img: '1f92a.png',
  },
  '🤫': {
    name: 'face with finger covering closed lips',
    dotName: ':shushing_face:',
    code: 'U+1F92B',
    img: '1f92b.png',
  },
  '🤬': {
    name: 'serious face with symbols covering mouth',
    dotName: ':face_with_symbols_on_mouth:',
    code: 'U+1F92C',
    img: '1f92c.png',
  },
  '🤭': {
    name: 'smiling face with smiling eyes and hand covering mouth',
    dotName: ':face_with_hand_over_mouth:',
    code: 'U+1F92D',
    img: '1f92d.png',
  },
  '🤮': {
    name: 'face with open mouth vomiting',
    dotName: ':face_vomiting:',
    code: 'U+1F92E',
    img: '1f92e.png',
  },
  '🤯': {
    name: 'shocked face with exploding head',
    dotName: ':exploding_head:',
    code: 'U+1F92F',
    img: '1f92f.png',
  },
  '🤰': {
    name: 'pregnant woman',
    dotName: ':pregnant_woman:',
    code: 'U+1F930',
    img: '1f930.png',
  },
  '🤱': {
    name: 'breast-feeding',
    dotName: ':breast-feeding:',
    code: 'U+1F931',
    img: '1f931.png',
  },
  '🤲': {
    name: 'palms up together',
    dotName: ':palms_up_together:',
    code: 'U+1F932',
    img: '1f932.png',
  },
  '🤳': {
    name: 'selfie',
    dotName: ':selfie:',
    code: 'U+1F933',
    img: '1f933.png',
  },
  '🤴': {
    name: 'prince',
    dotName: ':prince:',
    code: 'U+1F934',
    img: '1f934.png',
  },
  '🤵‍♀️': {
    name: '',
    dotName: ':woman_in_tuxedo:',
    code: 'U+1F935 U+200D U+2640 U+FE0F',
    img: '1f935-200d-2640-fe0f.png',
  },
  '🤵‍♂️': {
    name: '',
    dotName: ':man_in_tuxedo:',
    code: 'U+1F935 U+200D U+2642 U+FE0F',
    img: '1f935-200d-2642-fe0f.png',
  },
  '🤵': {
    name: 'man in tuxedo',
    dotName: ':man_in_tuxedo:',
    code: 'U+1F935',
    img: '1f935.png',
  },
  '🤶': {
    name: 'mother christmas',
    dotName: ':mrs_claus:',
    code: 'U+1F936',
    img: '1f936.png',
  },
  '🤷‍♀️': {
    name: '',
    dotName: ':woman-shrugging:',
    code: 'U+1F937 U+200D U+2640 U+FE0F',
    img: '1f937-200d-2640-fe0f.png',
  },
  '🤷‍♂️': {
    name: '',
    dotName: ':man-shrugging:',
    code: 'U+1F937 U+200D U+2642 U+FE0F',
    img: '1f937-200d-2642-fe0f.png',
  },
  '🤷': {
    name: 'shrug',
    dotName: ':shrug:',
    code: 'U+1F937',
    img: '1f937.png',
  },
  '🤸‍♀️': {
    name: '',
    dotName: ':woman-cartwheeling:',
    code: 'U+1F938 U+200D U+2640 U+FE0F',
    img: '1f938-200d-2640-fe0f.png',
  },
  '🤸‍♂️': {
    name: '',
    dotName: ':man-cartwheeling:',
    code: 'U+1F938 U+200D U+2642 U+FE0F',
    img: '1f938-200d-2642-fe0f.png',
  },
  '🤸': {
    name: 'person doing cartwheel',
    dotName: ':person_doing_cartwheel:',
    code: 'U+1F938',
    img: '1f938.png',
  },
  '🤹‍♀️': {
    name: '',
    dotName: ':woman-juggling:',
    code: 'U+1F939 U+200D U+2640 U+FE0F',
    img: '1f939-200d-2640-fe0f.png',
  },
  '🤹‍♂️': {
    name: '',
    dotName: ':man-juggling:',
    code: 'U+1F939 U+200D U+2642 U+FE0F',
    img: '1f939-200d-2642-fe0f.png',
  },
  '🤹': {
    name: 'juggling',
    dotName: ':juggling:',
    code: 'U+1F939',
    img: '1f939.png',
  },
  '🤺': {
    name: 'fencer',
    dotName: ':fencer:',
    code: 'U+1F93A',
    img: '1f93a.png',
  },
  '🤼‍♀️': {
    name: '',
    dotName: ':woman-wrestling:',
    code: 'U+1F93C U+200D U+2640 U+FE0F',
    img: '1f93c-200d-2640-fe0f.png',
  },
  '🤼‍♂️': {
    name: '',
    dotName: ':man-wrestling:',
    code: 'U+1F93C U+200D U+2642 U+FE0F',
    img: '1f93c-200d-2642-fe0f.png',
  },
  '🤼': {
    name: 'wrestlers',
    dotName: ':wrestlers:',
    code: 'U+1F93C',
    img: '1f93c.png',
  },
  '🤽‍♀️': {
    name: '',
    dotName: ':woman-playing-water-polo:',
    code: 'U+1F93D U+200D U+2640 U+FE0F',
    img: '1f93d-200d-2640-fe0f.png',
  },
  '🤽‍♂️': {
    name: '',
    dotName: ':man-playing-water-polo:',
    code: 'U+1F93D U+200D U+2642 U+FE0F',
    img: '1f93d-200d-2642-fe0f.png',
  },
  '🤽': {
    name: 'water polo',
    dotName: ':water_polo:',
    code: 'U+1F93D',
    img: '1f93d.png',
  },
  '🤾‍♀️': {
    name: '',
    dotName: ':woman-playing-handball:',
    code: 'U+1F93E U+200D U+2640 U+FE0F',
    img: '1f93e-200d-2640-fe0f.png',
  },
  '🤾‍♂️': {
    name: '',
    dotName: ':man-playing-handball:',
    code: 'U+1F93E U+200D U+2642 U+FE0F',
    img: '1f93e-200d-2642-fe0f.png',
  },
  '🤾': {
    name: 'handball',
    dotName: ':handball:',
    code: 'U+1F93E',
    img: '1f93e.png',
  },
  '🤿': {
    name: 'diving mask',
    dotName: ':diving_mask:',
    code: 'U+1F93F',
    img: '1f93f.png',
  },
  '🥀': {
    name: 'wilted flower',
    dotName: ':wilted_flower:',
    code: 'U+1F940',
    img: '1f940.png',
  },
  '🥁': {
    name: 'drum with drumsticks',
    dotName: ':drum_with_drumsticks:',
    code: 'U+1F941',
    img: '1f941.png',
  },
  '🥂': {
    name: 'clinking glasses',
    dotName: ':clinking_glasses:',
    code: 'U+1F942',
    img: '1f942.png',
  },
  '🥃': {
    name: 'tumbler glass',
    dotName: ':tumbler_glass:',
    code: 'U+1F943',
    img: '1f943.png',
  },
  '🥄': {
    name: 'spoon',
    dotName: ':spoon:',
    code: 'U+1F944',
    img: '1f944.png',
  },
  '🥅': {
    name: 'goal net',
    dotName: ':goal_net:',
    code: 'U+1F945',
    img: '1f945.png',
  },
  '🥇': {
    name: 'first place medal',
    dotName: ':first_place_medal:',
    code: 'U+1F947',
    img: '1f947.png',
  },
  '🥈': {
    name: 'second place medal',
    dotName: ':second_place_medal:',
    code: 'U+1F948',
    img: '1f948.png',
  },
  '🥉': {
    name: 'third place medal',
    dotName: ':third_place_medal:',
    code: 'U+1F949',
    img: '1f949.png',
  },
  '🥊': {
    name: 'boxing glove',
    dotName: ':boxing_glove:',
    code: 'U+1F94A',
    img: '1f94a.png',
  },
  '🥋': {
    name: 'martial arts uniform',
    dotName: ':martial_arts_uniform:',
    code: 'U+1F94B',
    img: '1f94b.png',
  },
  '🥌': {
    name: 'curling stone',
    dotName: ':curling_stone:',
    code: 'U+1F94C',
    img: '1f94c.png',
  },
  '🥍': {
    name: 'lacrosse stick and ball',
    dotName: ':lacrosse:',
    code: 'U+1F94D',
    img: '1f94d.png',
  },
  '🥎': {
    name: 'softball',
    dotName: ':softball:',
    code: 'U+1F94E',
    img: '1f94e.png',
  },
  '🥏': {
    name: 'flying disc',
    dotName: ':flying_disc:',
    code: 'U+1F94F',
    img: '1f94f.png',
  },
  '🥐': {
    name: 'croissant',
    dotName: ':croissant:',
    code: 'U+1F950',
    img: '1f950.png',
  },
  '🥑': {
    name: 'avocado',
    dotName: ':avocado:',
    code: 'U+1F951',
    img: '1f951.png',
  },
  '🥒': {
    name: 'cucumber',
    dotName: ':cucumber:',
    code: 'U+1F952',
    img: '1f952.png',
  },
  '🥓': {
    name: 'bacon',
    dotName: ':bacon:',
    code: 'U+1F953',
    img: '1f953.png',
  },
  '🥔': {
    name: 'potato',
    dotName: ':potato:',
    code: 'U+1F954',
    img: '1f954.png',
  },
  '🥕': {
    name: 'carrot',
    dotName: ':carrot:',
    code: 'U+1F955',
    img: '1f955.png',
  },
  '🥖': {
    name: 'baguette bread',
    dotName: ':baguette_bread:',
    code: 'U+1F956',
    img: '1f956.png',
  },
  '🥗': {
    name: 'green salad',
    dotName: ':green_salad:',
    code: 'U+1F957',
    img: '1f957.png',
  },
  '🥘': {
    name: 'shallow pan of food',
    dotName: ':shallow_pan_of_food:',
    code: 'U+1F958',
    img: '1f958.png',
  },
  '🥙': {
    name: 'stuffed flatbread',
    dotName: ':stuffed_flatbread:',
    code: 'U+1F959',
    img: '1f959.png',
  },
  '🥚': {
    name: 'egg',
    dotName: ':egg:',
    code: 'U+1F95A',
    img: '1f95a.png',
  },
  '🥛': {
    name: 'glass of milk',
    dotName: ':glass_of_milk:',
    code: 'U+1F95B',
    img: '1f95b.png',
  },
  '🥜': {
    name: 'peanuts',
    dotName: ':peanuts:',
    code: 'U+1F95C',
    img: '1f95c.png',
  },
  '🥝': {
    name: 'kiwifruit',
    dotName: ':kiwifruit:',
    code: 'U+1F95D',
    img: '1f95d.png',
  },
  '🥞': {
    name: 'pancakes',
    dotName: ':pancakes:',
    code: 'U+1F95E',
    img: '1f95e.png',
  },
  '🥟': {
    name: 'dumpling',
    dotName: ':dumpling:',
    code: 'U+1F95F',
    img: '1f95f.png',
  },
  '🥠': {
    name: 'fortune cookie',
    dotName: ':fortune_cookie:',
    code: 'U+1F960',
    img: '1f960.png',
  },
  '🥡': {
    name: 'takeout box',
    dotName: ':takeout_box:',
    code: 'U+1F961',
    img: '1f961.png',
  },
  '🥢': {
    name: 'chopsticks',
    dotName: ':chopsticks:',
    code: 'U+1F962',
    img: '1f962.png',
  },
  '🥣': {
    name: 'bowl with spoon',
    dotName: ':bowl_with_spoon:',
    code: 'U+1F963',
    img: '1f963.png',
  },
  '🥤': {
    name: 'cup with straw',
    dotName: ':cup_with_straw:',
    code: 'U+1F964',
    img: '1f964.png',
  },
  '🥥': {
    name: 'coconut',
    dotName: ':coconut:',
    code: 'U+1F965',
    img: '1f965.png',
  },
  '🥦': {
    name: 'broccoli',
    dotName: ':broccoli:',
    code: 'U+1F966',
    img: '1f966.png',
  },
  '🥧': {
    name: 'pie',
    dotName: ':pie:',
    code: 'U+1F967',
    img: '1f967.png',
  },
  '🥨': {
    name: 'pretzel',
    dotName: ':pretzel:',
    code: 'U+1F968',
    img: '1f968.png',
  },
  '🥩': {
    name: 'cut of meat',
    dotName: ':cut_of_meat:',
    code: 'U+1F969',
    img: '1f969.png',
  },
  '🥪': {
    name: 'sandwich',
    dotName: ':sandwich:',
    code: 'U+1F96A',
    img: '1f96a.png',
  },
  '🥫': {
    name: 'canned food',
    dotName: ':canned_food:',
    code: 'U+1F96B',
    img: '1f96b.png',
  },
  '🥬': {
    name: 'leafy green',
    dotName: ':leafy_green:',
    code: 'U+1F96C',
    img: '1f96c.png',
  },
  '🥭': {
    name: 'mango',
    dotName: ':mango:',
    code: 'U+1F96D',
    img: '1f96d.png',
  },
  '🥮': {
    name: 'moon cake',
    dotName: ':moon_cake:',
    code: 'U+1F96E',
    img: '1f96e.png',
  },
  '🥯': {
    name: 'bagel',
    dotName: ':bagel:',
    code: 'U+1F96F',
    img: '1f96f.png',
  },
  '🥰': {
    name: 'smiling face with smiling eyes and three hearts',
    dotName: ':smiling_face_with_3_hearts:',
    code: 'U+1F970',
    img: '1f970.png',
  },
  '🥱': {
    name: 'yawning face',
    dotName: ':yawning_face:',
    code: 'U+1F971',
    img: '1f971.png',
  },
  '🥲': {
    name: 'smiling face with tear',
    dotName: ':smiling_face_with_tear:',
    code: 'U+1F972',
    img: '1f972.png',
  },
  '🥳': {
    name: 'face with party horn and party hat',
    dotName: ':partying_face:',
    code: 'U+1F973',
    img: '1f973.png',
  },
  '🥴': {
    name: 'face with uneven eyes and wavy mouth',
    dotName: ':woozy_face:',
    code: 'U+1F974',
    img: '1f974.png',
  },
  '🥵': {
    name: 'overheated face',
    dotName: ':hot_face:',
    code: 'U+1F975',
    img: '1f975.png',
  },
  '🥶': {
    name: 'freezing face',
    dotName: ':cold_face:',
    code: 'U+1F976',
    img: '1f976.png',
  },
  '🥷': {
    name: 'ninja',
    dotName: ':ninja:',
    code: 'U+1F977',
    img: '1f977.png',
  },
  '🥸': {
    name: 'disguised face',
    dotName: ':disguised_face:',
    code: 'U+1F978',
    img: '1f978.png',
  },
  '🥺': {
    name: 'face with pleading eyes',
    dotName: ':pleading_face:',
    code: 'U+1F97A',
    img: '1f97a.png',
  },
  '🥻': {
    name: 'sari',
    dotName: ':sari:',
    code: 'U+1F97B',
    img: '1f97b.png',
  },
  '🥼': {
    name: 'lab coat',
    dotName: ':lab_coat:',
    code: 'U+1F97C',
    img: '1f97c.png',
  },
  '🥽': {
    name: 'goggles',
    dotName: ':goggles:',
    code: 'U+1F97D',
    img: '1f97d.png',
  },
  '🥾': {
    name: 'hiking boot',
    dotName: ':hiking_boot:',
    code: 'U+1F97E',
    img: '1f97e.png',
  },
  '🥿': {
    name: 'flat shoe',
    dotName: ':womans_flat_shoe:',
    code: 'U+1F97F',
    img: '1f97f.png',
  },
  '🦀': {
    name: 'crab',
    dotName: ':crab:',
    code: 'U+1F980',
    img: '1f980.png',
  },
  '🦁': {
    name: 'lion face',
    dotName: ':lion_face:',
    code: 'U+1F981',
    img: '1f981.png',
  },
  '🦂': {
    name: 'scorpion',
    dotName: ':scorpion:',
    code: 'U+1F982',
    img: '1f982.png',
  },
  '🦃': {
    name: 'turkey',
    dotName: ':turkey:',
    code: 'U+1F983',
    img: '1f983.png',
  },
  '🦄': {
    name: 'unicorn face',
    dotName: ':unicorn_face:',
    code: 'U+1F984',
    img: '1f984.png',
  },
  '🦅': {
    name: 'eagle',
    dotName: ':eagle:',
    code: 'U+1F985',
    img: '1f985.png',
  },
  '🦆': {
    name: 'duck',
    dotName: ':duck:',
    code: 'U+1F986',
    img: '1f986.png',
  },
  '🦇': {
    name: 'bat',
    dotName: ':bat:',
    code: 'U+1F987',
    img: '1f987.png',
  },
  '🦈': {
    name: 'shark',
    dotName: ':shark:',
    code: 'U+1F988',
    img: '1f988.png',
  },
  '🦉': {
    name: 'owl',
    dotName: ':owl:',
    code: 'U+1F989',
    img: '1f989.png',
  },
  '🦊': {
    name: 'fox face',
    dotName: ':fox_face:',
    code: 'U+1F98A',
    img: '1f98a.png',
  },
  '🦋': {
    name: 'butterfly',
    dotName: ':butterfly:',
    code: 'U+1F98B',
    img: '1f98b.png',
  },
  '🦌': {
    name: 'deer',
    dotName: ':deer:',
    code: 'U+1F98C',
    img: '1f98c.png',
  },
  '🦍': {
    name: 'gorilla',
    dotName: ':gorilla:',
    code: 'U+1F98D',
    img: '1f98d.png',
  },
  '🦎': {
    name: 'lizard',
    dotName: ':lizard:',
    code: 'U+1F98E',
    img: '1f98e.png',
  },
  '🦏': {
    name: 'rhinoceros',
    dotName: ':rhinoceros:',
    code: 'U+1F98F',
    img: '1f98f.png',
  },
  '🦐': {
    name: 'shrimp',
    dotName: ':shrimp:',
    code: 'U+1F990',
    img: '1f990.png',
  },
  '🦑': {
    name: 'squid',
    dotName: ':squid:',
    code: 'U+1F991',
    img: '1f991.png',
  },
  '🦒': {
    name: 'giraffe face',
    dotName: ':giraffe_face:',
    code: 'U+1F992',
    img: '1f992.png',
  },
  '🦓': {
    name: 'zebra face',
    dotName: ':zebra_face:',
    code: 'U+1F993',
    img: '1f993.png',
  },
  '🦔': {
    name: 'hedgehog',
    dotName: ':hedgehog:',
    code: 'U+1F994',
    img: '1f994.png',
  },
  '🦕': {
    name: 'sauropod',
    dotName: ':sauropod:',
    code: 'U+1F995',
    img: '1f995.png',
  },
  '🦖': {
    name: 't-rex',
    dotName: ':t-rex:',
    code: 'U+1F996',
    img: '1f996.png',
  },
  '🦗': {
    name: 'cricket',
    dotName: ':cricket:',
    code: 'U+1F997',
    img: '1f997.png',
  },
  '🦘': {
    name: 'kangaroo',
    dotName: ':kangaroo:',
    code: 'U+1F998',
    img: '1f998.png',
  },
  '🦙': {
    name: 'llama',
    dotName: ':llama:',
    code: 'U+1F999',
    img: '1f999.png',
  },
  '🦚': {
    name: 'peacock',
    dotName: ':peacock:',
    code: 'U+1F99A',
    img: '1f99a.png',
  },
  '🦛': {
    name: 'hippopotamus',
    dotName: ':hippopotamus:',
    code: 'U+1F99B',
    img: '1f99b.png',
  },
  '🦜': {
    name: 'parrot',
    dotName: ':parrot:',
    code: 'U+1F99C',
    img: '1f99c.png',
  },
  '🦝': {
    name: 'raccoon',
    dotName: ':raccoon:',
    code: 'U+1F99D',
    img: '1f99d.png',
  },
  '🦞': {
    name: 'lobster',
    dotName: ':lobster:',
    code: 'U+1F99E',
    img: '1f99e.png',
  },
  '🦟': {
    name: 'mosquito',
    dotName: ':mosquito:',
    code: 'U+1F99F',
    img: '1f99f.png',
  },
  '🦠': {
    name: 'microbe',
    dotName: ':microbe:',
    code: 'U+1F9A0',
    img: '1f9a0.png',
  },
  '🦡': {
    name: 'badger',
    dotName: ':badger:',
    code: 'U+1F9A1',
    img: '1f9a1.png',
  },
  '🦢': {
    name: 'swan',
    dotName: ':swan:',
    code: 'U+1F9A2',
    img: '1f9a2.png',
  },
  '🦣': {
    name: 'mammoth',
    dotName: ':mammoth:',
    code: 'U+1F9A3',
    img: '1f9a3.png',
  },
  '🦤': {
    name: 'dodo',
    dotName: ':dodo:',
    code: 'U+1F9A4',
    img: '1f9a4.png',
  },
  '🦥': {
    name: 'sloth',
    dotName: ':sloth:',
    code: 'U+1F9A5',
    img: '1f9a5.png',
  },
  '🦦': {
    name: 'otter',
    dotName: ':otter:',
    code: 'U+1F9A6',
    img: '1f9a6.png',
  },
  '🦧': {
    name: 'orangutan',
    dotName: ':orangutan:',
    code: 'U+1F9A7',
    img: '1f9a7.png',
  },
  '🦨': {
    name: 'skunk',
    dotName: ':skunk:',
    code: 'U+1F9A8',
    img: '1f9a8.png',
  },
  '🦩': {
    name: 'flamingo',
    dotName: ':flamingo:',
    code: 'U+1F9A9',
    img: '1f9a9.png',
  },
  '🦪': {
    name: 'oyster',
    dotName: ':oyster:',
    code: 'U+1F9AA',
    img: '1f9aa.png',
  },
  '🦫': {
    name: 'beaver',
    dotName: ':beaver:',
    code: 'U+1F9AB',
    img: '1f9ab.png',
  },
  '🦬': {
    name: 'bison',
    dotName: ':bison:',
    code: 'U+1F9AC',
    img: '1f9ac.png',
  },
  '🦭': {
    name: 'seal',
    dotName: ':seal:',
    code: 'U+1F9AD',
    img: '1f9ad.png',
  },
  '🦮': {
    name: 'guide dog',
    dotName: ':guide_dog:',
    code: 'U+1F9AE',
    img: '1f9ae.png',
  },
  '🦯': {
    name: 'probing cane',
    dotName: ':probing_cane:',
    code: 'U+1F9AF',
    img: '1f9af.png',
  },
  '🦴': {
    name: 'bone',
    dotName: ':bone:',
    code: 'U+1F9B4',
    img: '1f9b4.png',
  },
  '🦵': {
    name: 'leg',
    dotName: ':leg:',
    code: 'U+1F9B5',
    img: '1f9b5.png',
  },
  '🦶': {
    name: 'foot',
    dotName: ':foot:',
    code: 'U+1F9B6',
    img: '1f9b6.png',
  },
  '🦷': {
    name: 'tooth',
    dotName: ':tooth:',
    code: 'U+1F9B7',
    img: '1f9b7.png',
  },
  '🦸‍♀️': {
    name: '',
    dotName: ':female_superhero:',
    code: 'U+1F9B8 U+200D U+2640 U+FE0F',
    img: '1f9b8-200d-2640-fe0f.png',
  },
  '🦸‍♂️': {
    name: '',
    dotName: ':male_superhero:',
    code: 'U+1F9B8 U+200D U+2642 U+FE0F',
    img: '1f9b8-200d-2642-fe0f.png',
  },
  '🦸': {
    name: 'superhero',
    dotName: ':superhero:',
    code: 'U+1F9B8',
    img: '1f9b8.png',
  },
  '🦹‍♀️': {
    name: '',
    dotName: ':female_supervillain:',
    code: 'U+1F9B9 U+200D U+2640 U+FE0F',
    img: '1f9b9-200d-2640-fe0f.png',
  },
  '🦹‍♂️': {
    name: '',
    dotName: ':male_supervillain:',
    code: 'U+1F9B9 U+200D U+2642 U+FE0F',
    img: '1f9b9-200d-2642-fe0f.png',
  },
  '🦹': {
    name: 'supervillain',
    dotName: ':supervillain:',
    code: 'U+1F9B9',
    img: '1f9b9.png',
  },
  '🦺': {
    name: 'safety vest',
    dotName: ':safety_vest:',
    code: 'U+1F9BA',
    img: '1f9ba.png',
  },
  '🦻': {
    name: 'ear with hearing aid',
    dotName: ':ear_with_hearing_aid:',
    code: 'U+1F9BB',
    img: '1f9bb.png',
  },
  '🦼': {
    name: 'motorized wheelchair',
    dotName: ':motorized_wheelchair:',
    code: 'U+1F9BC',
    img: '1f9bc.png',
  },
  '🦽': {
    name: 'manual wheelchair',
    dotName: ':manual_wheelchair:',
    code: 'U+1F9BD',
    img: '1f9bd.png',
  },
  '🦾': {
    name: 'mechanical arm',
    dotName: ':mechanical_arm:',
    code: 'U+1F9BE',
    img: '1f9be.png',
  },
  '🦿': {
    name: 'mechanical leg',
    dotName: ':mechanical_leg:',
    code: 'U+1F9BF',
    img: '1f9bf.png',
  },
  '🧀': {
    name: 'cheese wedge',
    dotName: ':cheese_wedge:',
    code: 'U+1F9C0',
    img: '1f9c0.png',
  },
  '🧁': {
    name: 'cupcake',
    dotName: ':cupcake:',
    code: 'U+1F9C1',
    img: '1f9c1.png',
  },
  '🧂': {
    name: 'salt shaker',
    dotName: ':salt:',
    code: 'U+1F9C2',
    img: '1f9c2.png',
  },
  '🧃': {
    name: 'beverage box',
    dotName: ':beverage_box:',
    code: 'U+1F9C3',
    img: '1f9c3.png',
  },
  '🧄': {
    name: 'garlic',
    dotName: ':garlic:',
    code: 'U+1F9C4',
    img: '1f9c4.png',
  },
  '🧅': {
    name: 'onion',
    dotName: ':onion:',
    code: 'U+1F9C5',
    img: '1f9c5.png',
  },
  '🧆': {
    name: 'falafel',
    dotName: ':falafel:',
    code: 'U+1F9C6',
    img: '1f9c6.png',
  },
  '🧇': {
    name: 'waffle',
    dotName: ':waffle:',
    code: 'U+1F9C7',
    img: '1f9c7.png',
  },
  '🧈': {
    name: 'butter',
    dotName: ':butter:',
    code: 'U+1F9C8',
    img: '1f9c8.png',
  },
  '🧉': {
    name: 'mate drink',
    dotName: ':mate_drink:',
    code: 'U+1F9C9',
    img: '1f9c9.png',
  },
  '🧊': {
    name: 'ice cube',
    dotName: ':ice_cube:',
    code: 'U+1F9CA',
    img: '1f9ca.png',
  },
  '🧋': {
    name: 'bubble tea',
    dotName: ':bubble_tea:',
    code: 'U+1F9CB',
    img: '1f9cb.png',
  },
  '🧍‍♀️': {
    name: '',
    dotName: ':woman_standing:',
    code: 'U+1F9CD U+200D U+2640 U+FE0F',
    img: '1f9cd-200d-2640-fe0f.png',
  },
  '🧍‍♂️': {
    name: '',
    dotName: ':man_standing:',
    code: 'U+1F9CD U+200D U+2642 U+FE0F',
    img: '1f9cd-200d-2642-fe0f.png',
  },
  '🧍': {
    name: 'standing person',
    dotName: ':standing_person:',
    code: 'U+1F9CD',
    img: '1f9cd.png',
  },
  '🧎‍♀️': {
    name: '',
    dotName: ':woman_kneeling:',
    code: 'U+1F9CE U+200D U+2640 U+FE0F',
    img: '1f9ce-200d-2640-fe0f.png',
  },
  '🧎‍♂️': {
    name: '',
    dotName: ':man_kneeling:',
    code: 'U+1F9CE U+200D U+2642 U+FE0F',
    img: '1f9ce-200d-2642-fe0f.png',
  },
  '🧎': {
    name: 'kneeling person',
    dotName: ':kneeling_person:',
    code: 'U+1F9CE',
    img: '1f9ce.png',
  },
  '🧏‍♀️': {
    name: '',
    dotName: ':deaf_woman:',
    code: 'U+1F9CF U+200D U+2640 U+FE0F',
    img: '1f9cf-200d-2640-fe0f.png',
  },
  '🧏‍♂️': {
    name: '',
    dotName: ':deaf_man:',
    code: 'U+1F9CF U+200D U+2642 U+FE0F',
    img: '1f9cf-200d-2642-fe0f.png',
  },
  '🧏': {
    name: 'deaf person',
    dotName: ':deaf_person:',
    code: 'U+1F9CF',
    img: '1f9cf.png',
  },
  '🧐': {
    name: 'face with monocle',
    dotName: ':face_with_monocle:',
    code: 'U+1F9D0',
    img: '1f9d0.png',
  },
  '🧑‍🌾': {
    name: '',
    dotName: ':farmer:',
    code: 'U+1F9D1 U+200D U+1F33E',
    img: '1f9d1-200d-1f33e.png',
  },
  '🧑‍🍳': {
    name: '',
    dotName: ':cook:',
    code: 'U+1F9D1 U+200D U+1F373',
    img: '1f9d1-200d-1f373.png',
  },
  '🧑‍🍼': {
    name: '',
    dotName: ':person_feeding_baby:',
    code: 'U+1F9D1 U+200D U+1F37C',
    img: '1f9d1-200d-1f37c.png',
  },
  '🧑‍🎄': {
    name: '',
    dotName: ':mx_claus:',
    code: 'U+1F9D1 U+200D U+1F384',
    img: '1f9d1-200d-1f384.png',
  },
  '🧑‍🎓': {
    name: '',
    dotName: ':student:',
    code: 'U+1F9D1 U+200D U+1F393',
    img: '1f9d1-200d-1f393.png',
  },
  '🧑‍🎤': {
    name: '',
    dotName: ':singer:',
    code: 'U+1F9D1 U+200D U+1F3A4',
    img: '1f9d1-200d-1f3a4.png',
  },
  '🧑‍🎨': {
    name: '',
    dotName: ':artist:',
    code: 'U+1F9D1 U+200D U+1F3A8',
    img: '1f9d1-200d-1f3a8.png',
  },
  '🧑‍🏫': {
    name: '',
    dotName: ':teacher:',
    code: 'U+1F9D1 U+200D U+1F3EB',
    img: '1f9d1-200d-1f3eb.png',
  },
  '🧑‍🏭': {
    name: '',
    dotName: ':factory_worker:',
    code: 'U+1F9D1 U+200D U+1F3ED',
    img: '1f9d1-200d-1f3ed.png',
  },
  '🧑‍💻': {
    name: '',
    dotName: ':technologist:',
    code: 'U+1F9D1 U+200D U+1F4BB',
    img: '1f9d1-200d-1f4bb.png',
  },
  '🧑‍💼': {
    name: '',
    dotName: ':office_worker:',
    code: 'U+1F9D1 U+200D U+1F4BC',
    img: '1f9d1-200d-1f4bc.png',
  },
  '🧑‍🔧': {
    name: '',
    dotName: ':mechanic:',
    code: 'U+1F9D1 U+200D U+1F527',
    img: '1f9d1-200d-1f527.png',
  },
  '🧑‍🔬': {
    name: '',
    dotName: ':scientist:',
    code: 'U+1F9D1 U+200D U+1F52C',
    img: '1f9d1-200d-1f52c.png',
  },
  '🧑‍🚀': {
    name: '',
    dotName: ':astronaut:',
    code: 'U+1F9D1 U+200D U+1F680',
    img: '1f9d1-200d-1f680.png',
  },
  '🧑‍🚒': {
    name: '',
    dotName: ':firefighter:',
    code: 'U+1F9D1 U+200D U+1F692',
    img: '1f9d1-200d-1f692.png',
  },
  '🧑‍🤝‍🧑': {
    name: '',
    dotName: ':people_holding_hands:',
    code: 'U+1F9D1 U+200D U+1F91D U+200D U+1F9D1',
    img: '1f9d1-200d-1f91d-200d-1f9d1.png',
  },
  '🧑‍🦯': {
    name: '',
    dotName: ':person_with_probing_cane:',
    code: 'U+1F9D1 U+200D U+1F9AF',
    img: '1f9d1-200d-1f9af.png',
  },
  '🧑‍🦰': {
    name: '',
    dotName: ':red_haired_person:',
    code: 'U+1F9D1 U+200D U+1F9B0',
    img: '1f9d1-200d-1f9b0.png',
  },
  '🧑‍🦱': {
    name: '',
    dotName: ':curly_haired_person:',
    code: 'U+1F9D1 U+200D U+1F9B1',
    img: '1f9d1-200d-1f9b1.png',
  },
  '🧑‍🦲': {
    name: '',
    dotName: ':bald_person:',
    code: 'U+1F9D1 U+200D U+1F9B2',
    img: '1f9d1-200d-1f9b2.png',
  },
  '🧑‍🦳': {
    name: '',
    dotName: ':white_haired_person:',
    code: 'U+1F9D1 U+200D U+1F9B3',
    img: '1f9d1-200d-1f9b3.png',
  },
  '🧑‍🦼': {
    name: '',
    dotName: ':person_in_motorized_wheelchair:',
    code: 'U+1F9D1 U+200D U+1F9BC',
    img: '1f9d1-200d-1f9bc.png',
  },
  '🧑‍🦽': {
    name: '',
    dotName: ':person_in_manual_wheelchair:',
    code: 'U+1F9D1 U+200D U+1F9BD',
    img: '1f9d1-200d-1f9bd.png',
  },
  '🧑‍⚕️': {
    name: '',
    dotName: ':health_worker:',
    code: 'U+1F9D1 U+200D U+2695 U+FE0F',
    img: '1f9d1-200d-2695-fe0f.png',
  },
  '🧑‍⚖️': {
    name: '',
    dotName: ':judge:',
    code: 'U+1F9D1 U+200D U+2696 U+FE0F',
    img: '1f9d1-200d-2696-fe0f.png',
  },
  '🧑‍✈️': {
    name: '',
    dotName: ':pilot:',
    code: 'U+1F9D1 U+200D U+2708 U+FE0F',
    img: '1f9d1-200d-2708-fe0f.png',
  },
  '🧑': {
    name: 'adult',
    dotName: ':adult:',
    code: 'U+1F9D1',
    img: '1f9d1.png',
  },
  '🧒': {
    name: 'child',
    dotName: ':child:',
    code: 'U+1F9D2',
    img: '1f9d2.png',
  },
  '🧓': {
    name: 'older adult',
    dotName: ':older_adult:',
    code: 'U+1F9D3',
    img: '1f9d3.png',
  },
  '🧔': {
    name: 'bearded person',
    dotName: ':bearded_person:',
    code: 'U+1F9D4',
    img: '1f9d4.png',
  },
  '🧕': {
    name: 'person with headscarf',
    dotName: ':person_with_headscarf:',
    code: 'U+1F9D5',
    img: '1f9d5.png',
  },
  '🧖‍♀️': {
    name: '',
    dotName: ':woman_in_steamy_room:',
    code: 'U+1F9D6 U+200D U+2640 U+FE0F',
    img: '1f9d6-200d-2640-fe0f.png',
  },
  '🧖‍♂️': {
    name: '',
    dotName: ':man_in_steamy_room:',
    code: 'U+1F9D6 U+200D U+2642 U+FE0F',
    img: '1f9d6-200d-2642-fe0f.png',
  },
  '🧖': {
    name: 'person in steamy room',
    dotName: ':person_in_steamy_room:',
    code: 'U+1F9D6',
    img: '1f9d6.png',
  },
  '🧗‍♀️': {
    name: '',
    dotName: ':woman_climbing:',
    code: 'U+1F9D7 U+200D U+2640 U+FE0F',
    img: '1f9d7-200d-2640-fe0f.png',
  },
  '🧗‍♂️': {
    name: '',
    dotName: ':man_climbing:',
    code: 'U+1F9D7 U+200D U+2642 U+FE0F',
    img: '1f9d7-200d-2642-fe0f.png',
  },
  '🧗': {
    name: 'person climbing',
    dotName: ':person_climbing:',
    code: 'U+1F9D7',
    img: '1f9d7.png',
  },
  '🧘‍♀️': {
    name: '',
    dotName: ':woman_in_lotus_position:',
    code: 'U+1F9D8 U+200D U+2640 U+FE0F',
    img: '1f9d8-200d-2640-fe0f.png',
  },
  '🧘‍♂️': {
    name: '',
    dotName: ':man_in_lotus_position:',
    code: 'U+1F9D8 U+200D U+2642 U+FE0F',
    img: '1f9d8-200d-2642-fe0f.png',
  },
  '🧘': {
    name: 'person in lotus position',
    dotName: ':person_in_lotus_position:',
    code: 'U+1F9D8',
    img: '1f9d8.png',
  },
  '🧙‍♀️': {
    name: '',
    dotName: ':female_mage:',
    code: 'U+1F9D9 U+200D U+2640 U+FE0F',
    img: '1f9d9-200d-2640-fe0f.png',
  },
  '🧙‍♂️': {
    name: '',
    dotName: ':male_mage:',
    code: 'U+1F9D9 U+200D U+2642 U+FE0F',
    img: '1f9d9-200d-2642-fe0f.png',
  },
  '🧙': {
    name: 'mage',
    dotName: ':mage:',
    code: 'U+1F9D9',
    img: '1f9d9.png',
  },
  '🧚‍♀️': {
    name: '',
    dotName: ':female_fairy:',
    code: 'U+1F9DA U+200D U+2640 U+FE0F',
    img: '1f9da-200d-2640-fe0f.png',
  },
  '🧚‍♂️': {
    name: '',
    dotName: ':male_fairy:',
    code: 'U+1F9DA U+200D U+2642 U+FE0F',
    img: '1f9da-200d-2642-fe0f.png',
  },
  '🧚': {
    name: 'fairy',
    dotName: ':fairy:',
    code: 'U+1F9DA',
    img: '1f9da.png',
  },
  '🧛‍♀️': {
    name: '',
    dotName: ':female_vampire:',
    code: 'U+1F9DB U+200D U+2640 U+FE0F',
    img: '1f9db-200d-2640-fe0f.png',
  },
  '🧛‍♂️': {
    name: '',
    dotName: ':male_vampire:',
    code: 'U+1F9DB U+200D U+2642 U+FE0F',
    img: '1f9db-200d-2642-fe0f.png',
  },
  '🧛': {
    name: 'vampire',
    dotName: ':vampire:',
    code: 'U+1F9DB',
    img: '1f9db.png',
  },
  '🧜‍♀️': {
    name: '',
    dotName: ':mermaid:',
    code: 'U+1F9DC U+200D U+2640 U+FE0F',
    img: '1f9dc-200d-2640-fe0f.png',
  },
  '🧜‍♂️': {
    name: '',
    dotName: ':merman:',
    code: 'U+1F9DC U+200D U+2642 U+FE0F',
    img: '1f9dc-200d-2642-fe0f.png',
  },
  '🧜': {
    name: 'merperson',
    dotName: ':merperson:',
    code: 'U+1F9DC',
    img: '1f9dc.png',
  },
  '🧝‍♀️': {
    name: '',
    dotName: ':female_elf:',
    code: 'U+1F9DD U+200D U+2640 U+FE0F',
    img: '1f9dd-200d-2640-fe0f.png',
  },
  '🧝‍♂️': {
    name: '',
    dotName: ':male_elf:',
    code: 'U+1F9DD U+200D U+2642 U+FE0F',
    img: '1f9dd-200d-2642-fe0f.png',
  },
  '🧝': {
    name: 'elf',
    dotName: ':elf:',
    code: 'U+1F9DD',
    img: '1f9dd.png',
  },
  '🧞‍♀️': {
    name: '',
    dotName: ':female_genie:',
    code: 'U+1F9DE U+200D U+2640 U+FE0F',
    img: '1f9de-200d-2640-fe0f.png',
  },
  '🧞‍♂️': {
    name: '',
    dotName: ':male_genie:',
    code: 'U+1F9DE U+200D U+2642 U+FE0F',
    img: '1f9de-200d-2642-fe0f.png',
  },
  '🧞': {
    name: 'genie',
    dotName: ':genie:',
    code: 'U+1F9DE',
    img: '1f9de.png',
  },
  '🧟‍♀️': {
    name: '',
    dotName: ':female_zombie:',
    code: 'U+1F9DF U+200D U+2640 U+FE0F',
    img: '1f9df-200d-2640-fe0f.png',
  },
  '🧟‍♂️': {
    name: '',
    dotName: ':male_zombie:',
    code: 'U+1F9DF U+200D U+2642 U+FE0F',
    img: '1f9df-200d-2642-fe0f.png',
  },
  '🧟': {
    name: 'zombie',
    dotName: ':zombie:',
    code: 'U+1F9DF',
    img: '1f9df.png',
  },
  '🧠': {
    name: 'brain',
    dotName: ':brain:',
    code: 'U+1F9E0',
    img: '1f9e0.png',
  },
  '🧡': {
    name: 'orange heart',
    dotName: ':orange_heart:',
    code: 'U+1F9E1',
    img: '1f9e1.png',
  },
  '🧢': {
    name: 'billed cap',
    dotName: ':billed_cap:',
    code: 'U+1F9E2',
    img: '1f9e2.png',
  },
  '🧣': {
    name: 'scarf',
    dotName: ':scarf:',
    code: 'U+1F9E3',
    img: '1f9e3.png',
  },
  '🧤': {
    name: 'gloves',
    dotName: ':gloves:',
    code: 'U+1F9E4',
    img: '1f9e4.png',
  },
  '🧥': {
    name: 'coat',
    dotName: ':coat:',
    code: 'U+1F9E5',
    img: '1f9e5.png',
  },
  '🧦': {
    name: 'socks',
    dotName: ':socks:',
    code: 'U+1F9E6',
    img: '1f9e6.png',
  },
  '🧧': {
    name: 'red gift envelope',
    dotName: ':red_envelope:',
    code: 'U+1F9E7',
    img: '1f9e7.png',
  },
  '🧨': {
    name: 'firecracker',
    dotName: ':firecracker:',
    code: 'U+1F9E8',
    img: '1f9e8.png',
  },
  '🧩': {
    name: 'jigsaw puzzle piece',
    dotName: ':jigsaw:',
    code: 'U+1F9E9',
    img: '1f9e9.png',
  },
  '🧪': {
    name: 'test tube',
    dotName: ':test_tube:',
    code: 'U+1F9EA',
    img: '1f9ea.png',
  },
  '🧫': {
    name: 'petri dish',
    dotName: ':petri_dish:',
    code: 'U+1F9EB',
    img: '1f9eb.png',
  },
  '🧬': {
    name: 'dna double helix',
    dotName: ':dna:',
    code: 'U+1F9EC',
    img: '1f9ec.png',
  },
  '🧭': {
    name: 'compass',
    dotName: ':compass:',
    code: 'U+1F9ED',
    img: '1f9ed.png',
  },
  '🧮': {
    name: 'abacus',
    dotName: ':abacus:',
    code: 'U+1F9EE',
    img: '1f9ee.png',
  },
  '🧯': {
    name: 'fire extinguisher',
    dotName: ':fire_extinguisher:',
    code: 'U+1F9EF',
    img: '1f9ef.png',
  },
  '🧰': {
    name: 'toolbox',
    dotName: ':toolbox:',
    code: 'U+1F9F0',
    img: '1f9f0.png',
  },
  '🧱': {
    name: 'brick',
    dotName: ':bricks:',
    code: 'U+1F9F1',
    img: '1f9f1.png',
  },
  '🧲': {
    name: 'magnet',
    dotName: ':magnet:',
    code: 'U+1F9F2',
    img: '1f9f2.png',
  },
  '🧳': {
    name: 'luggage',
    dotName: ':luggage:',
    code: 'U+1F9F3',
    img: '1f9f3.png',
  },
  '🧴': {
    name: 'lotion bottle',
    dotName: ':lotion_bottle:',
    code: 'U+1F9F4',
    img: '1f9f4.png',
  },
  '🧵': {
    name: 'spool of thread',
    dotName: ':thread:',
    code: 'U+1F9F5',
    img: '1f9f5.png',
  },
  '🧶': {
    name: 'ball of yarn',
    dotName: ':yarn:',
    code: 'U+1F9F6',
    img: '1f9f6.png',
  },
  '🧷': {
    name: 'safety pin',
    dotName: ':safety_pin:',
    code: 'U+1F9F7',
    img: '1f9f7.png',
  },
  '🧸': {
    name: 'teddy bear',
    dotName: ':teddy_bear:',
    code: 'U+1F9F8',
    img: '1f9f8.png',
  },
  '🧹': {
    name: 'broom',
    dotName: ':broom:',
    code: 'U+1F9F9',
    img: '1f9f9.png',
  },
  '🧺': {
    name: 'basket',
    dotName: ':basket:',
    code: 'U+1F9FA',
    img: '1f9fa.png',
  },
  '🧻': {
    name: 'roll of paper',
    dotName: ':roll_of_paper:',
    code: 'U+1F9FB',
    img: '1f9fb.png',
  },
  '🧼': {
    name: 'bar of soap',
    dotName: ':soap:',
    code: 'U+1F9FC',
    img: '1f9fc.png',
  },
  '🧽': {
    name: 'sponge',
    dotName: ':sponge:',
    code: 'U+1F9FD',
    img: '1f9fd.png',
  },
  '🧾': {
    name: 'receipt',
    dotName: ':receipt:',
    code: 'U+1F9FE',
    img: '1f9fe.png',
  },
  '🧿': {
    name: 'nazar amulet',
    dotName: ':nazar_amulet:',
    code: 'U+1F9FF',
    img: '1f9ff.png',
  },
  '🩰': {
    name: 'ballet shoes',
    dotName: ':ballet_shoes:',
    code: 'U+1FA70',
    img: '1fa70.png',
  },
  '🩱': {
    name: 'one-piece swimsuit',
    dotName: ':one-piece_swimsuit:',
    code: 'U+1FA71',
    img: '1fa71.png',
  },
  '🩲': {
    name: 'briefs',
    dotName: ':briefs:',
    code: 'U+1FA72',
    img: '1fa72.png',
  },
  '🩳': {
    name: 'shorts',
    dotName: ':shorts:',
    code: 'U+1FA73',
    img: '1fa73.png',
  },
  '🩴': {
    name: 'thong sandal',
    dotName: ':thong_sandal:',
    code: 'U+1FA74',
    img: '1fa74.png',
  },
  '🩸': {
    name: 'drop of blood',
    dotName: ':drop_of_blood:',
    code: 'U+1FA78',
    img: '1fa78.png',
  },
  '🩹': {
    name: 'adhesive bandage',
    dotName: ':adhesive_bandage:',
    code: 'U+1FA79',
    img: '1fa79.png',
  },
  '🩺': {
    name: 'stethoscope',
    dotName: ':stethoscope:',
    code: 'U+1FA7A',
    img: '1fa7a.png',
  },
  '🪀': {
    name: 'yo-yo',
    dotName: ':yo-yo:',
    code: 'U+1FA80',
    img: '1fa80.png',
  },
  '🪁': {
    name: 'kite',
    dotName: ':kite:',
    code: 'U+1FA81',
    img: '1fa81.png',
  },
  '🪂': {
    name: 'parachute',
    dotName: ':parachute:',
    code: 'U+1FA82',
    img: '1fa82.png',
  },
  '🪃': {
    name: 'boomerang',
    dotName: ':boomerang:',
    code: 'U+1FA83',
    img: '1fa83.png',
  },
  '🪄': {
    name: 'magic wand',
    dotName: ':magic_wand:',
    code: 'U+1FA84',
    img: '1fa84.png',
  },
  '🪅': {
    name: 'pinata',
    dotName: ':pinata:',
    code: 'U+1FA85',
    img: '1fa85.png',
  },
  '🪆': {
    name: 'nesting dolls',
    dotName: ':nesting_dolls:',
    code: 'U+1FA86',
    img: '1fa86.png',
  },
  '🪐': {
    name: 'ringed planet',
    dotName: ':ringed_planet:',
    code: 'U+1FA90',
    img: '1fa90.png',
  },
  '🪑': {
    name: 'chair',
    dotName: ':chair:',
    code: 'U+1FA91',
    img: '1fa91.png',
  },
  '🪒': {
    name: 'razor',
    dotName: ':razor:',
    code: 'U+1FA92',
    img: '1fa92.png',
  },
  '🪓': {
    name: 'axe',
    dotName: ':axe:',
    code: 'U+1FA93',
    img: '1fa93.png',
  },
  '🪔': {
    name: 'diya lamp',
    dotName: ':diya_lamp:',
    code: 'U+1FA94',
    img: '1fa94.png',
  },
  '🪕': {
    name: 'banjo',
    dotName: ':banjo:',
    code: 'U+1FA95',
    img: '1fa95.png',
  },
  '🪖': {
    name: 'military helmet',
    dotName: ':military_helmet:',
    code: 'U+1FA96',
    img: '1fa96.png',
  },
  '🪗': {
    name: 'accordion',
    dotName: ':accordion:',
    code: 'U+1FA97',
    img: '1fa97.png',
  },
  '🪘': {
    name: 'long drum',
    dotName: ':long_drum:',
    code: 'U+1FA98',
    img: '1fa98.png',
  },
  '🪙': {
    name: 'coin',
    dotName: ':coin:',
    code: 'U+1FA99',
    img: '1fa99.png',
  },
  '🪚': {
    name: 'carpentry saw',
    dotName: ':carpentry_saw:',
    code: 'U+1FA9A',
    img: '1fa9a.png',
  },
  '🪛': {
    name: 'screwdriver',
    dotName: ':screwdriver:',
    code: 'U+1FA9B',
    img: '1fa9b.png',
  },
  '🪜': {
    name: 'ladder',
    dotName: ':ladder:',
    code: 'U+1FA9C',
    img: '1fa9c.png',
  },
  '🪝': {
    name: 'hook',
    dotName: ':hook:',
    code: 'U+1FA9D',
    img: '1fa9d.png',
  },
  '🪞': {
    name: 'mirror',
    dotName: ':mirror:',
    code: 'U+1FA9E',
    img: '1fa9e.png',
  },
  '🪟': {
    name: 'window',
    dotName: ':window:',
    code: 'U+1FA9F',
    img: '1fa9f.png',
  },
  '🪠': {
    name: 'plunger',
    dotName: ':plunger:',
    code: 'U+1FAA0',
    img: '1faa0.png',
  },
  '🪡': {
    name: 'sewing needle',
    dotName: ':sewing_needle:',
    code: 'U+1FAA1',
    img: '1faa1.png',
  },
  '🪢': {
    name: 'knot',
    dotName: ':knot:',
    code: 'U+1FAA2',
    img: '1faa2.png',
  },
  '🪣': {
    name: 'bucket',
    dotName: ':bucket:',
    code: 'U+1FAA3',
    img: '1faa3.png',
  },
  '🪤': {
    name: 'mouse trap',
    dotName: ':mouse_trap:',
    code: 'U+1FAA4',
    img: '1faa4.png',
  },
  '🪥': {
    name: 'toothbrush',
    dotName: ':toothbrush:',
    code: 'U+1FAA5',
    img: '1faa5.png',
  },
  '🪦': {
    name: 'headstone',
    dotName: ':headstone:',
    code: 'U+1FAA6',
    img: '1faa6.png',
  },
  '🪧': {
    name: 'placard',
    dotName: ':placard:',
    code: 'U+1FAA7',
    img: '1faa7.png',
  },
  '🪨': {
    name: 'rock',
    dotName: ':rock:',
    code: 'U+1FAA8',
    img: '1faa8.png',
  },
  '🪰': {
    name: 'fly',
    dotName: ':fly:',
    code: 'U+1FAB0',
    img: '1fab0.png',
  },
  '🪱': {
    name: 'worm',
    dotName: ':worm:',
    code: 'U+1FAB1',
    img: '1fab1.png',
  },
  '🪲': {
    name: 'beetle',
    dotName: ':beetle:',
    code: 'U+1FAB2',
    img: '1fab2.png',
  },
  '🪳': {
    name: 'cockroach',
    dotName: ':cockroach:',
    code: 'U+1FAB3',
    img: '1fab3.png',
  },
  '🪴': {
    name: 'potted plant',
    dotName: ':potted_plant:',
    code: 'U+1FAB4',
    img: '1fab4.png',
  },
  '🪵': {
    name: 'wood',
    dotName: ':wood:',
    code: 'U+1FAB5',
    img: '1fab5.png',
  },
  '🪶': {
    name: 'feather',
    dotName: ':feather:',
    code: 'U+1FAB6',
    img: '1fab6.png',
  },
  '🫀': {
    name: 'anatomical heart',
    dotName: ':anatomical_heart:',
    code: 'U+1FAC0',
    img: '1fac0.png',
  },
  '🫁': {
    name: 'lungs',
    dotName: ':lungs:',
    code: 'U+1FAC1',
    img: '1fac1.png',
  },
  '🫂': {
    name: 'people hugging',
    dotName: ':people_hugging:',
    code: 'U+1FAC2',
    img: '1fac2.png',
  },
  '🫐': {
    name: 'blueberries',
    dotName: ':blueberries:',
    code: 'U+1FAD0',
    img: '1fad0.png',
  },
  '🫑': {
    name: 'bell pepper',
    dotName: ':bell_pepper:',
    code: 'U+1FAD1',
    img: '1fad1.png',
  },
  '🫒': {
    name: 'olive',
    dotName: ':olive:',
    code: 'U+1FAD2',
    img: '1fad2.png',
  },
  '🫓': {
    name: 'flatbread',
    dotName: ':flatbread:',
    code: 'U+1FAD3',
    img: '1fad3.png',
  },
  '🫔': {
    name: 'tamale',
    dotName: ':tamale:',
    code: 'U+1FAD4',
    img: '1fad4.png',
  },
  '🫕': {
    name: 'fondue',
    dotName: ':fondue:',
    code: 'U+1FAD5',
    img: '1fad5.png',
  },
  '🫖': {
    name: 'teapot',
    dotName: ':teapot:',
    code: 'U+1FAD6',
    img: '1fad6.png',
  },
  '‼️': {
    name: 'double exclamation mark',
    dotName: ':bangbang:',
    code: 'U+203C U+FE0F',
    img: '203c-fe0f.png',
  },
  '⁉️': {
    name: 'exclamation question mark',
    dotName: ':interrobang:',
    code: 'U+2049 U+FE0F',
    img: '2049-fe0f.png',
  },
  '™️': {
    name: 'trade mark sign',
    dotName: ':tm:',
    code: 'U+2122 U+FE0F',
    img: '2122-fe0f.png',
  },
  ℹ️: {
    name: 'information source',
    dotName: ':information_source:',
    code: 'U+2139 U+FE0F',
    img: '2139-fe0f.png',
  },
  '↔️': {
    name: 'left right arrow',
    dotName: ':left_right_arrow:',
    code: 'U+2194 U+FE0F',
    img: '2194-fe0f.png',
  },
  '↕️': {
    name: 'up down arrow',
    dotName: ':arrow_up_down:',
    code: 'U+2195 U+FE0F',
    img: '2195-fe0f.png',
  },
  '↖️': {
    name: 'north west arrow',
    dotName: ':arrow_upper_left:',
    code: 'U+2196 U+FE0F',
    img: '2196-fe0f.png',
  },
  '↗️': {
    name: 'north east arrow',
    dotName: ':arrow_upper_right:',
    code: 'U+2197 U+FE0F',
    img: '2197-fe0f.png',
  },
  '↘️': {
    name: 'south east arrow',
    dotName: ':arrow_lower_right:',
    code: 'U+2198 U+FE0F',
    img: '2198-fe0f.png',
  },
  '↙️': {
    name: 'south west arrow',
    dotName: ':arrow_lower_left:',
    code: 'U+2199 U+FE0F',
    img: '2199-fe0f.png',
  },
  '↩️': {
    name: 'leftwards arrow with hook',
    dotName: ':leftwards_arrow_with_hook:',
    code: 'U+21A9 U+FE0F',
    img: '21a9-fe0f.png',
  },
  '↪️': {
    name: 'rightwards arrow with hook',
    dotName: ':arrow_right_hook:',
    code: 'U+21AA U+FE0F',
    img: '21aa-fe0f.png',
  },
  '⌚': {
    name: 'watch',
    dotName: ':watch:',
    code: 'U+231A',
    img: '231a.png',
  },
  '⌛': {
    name: 'hourglass',
    dotName: ':hourglass:',
    code: 'U+231B',
    img: '231b.png',
  },
  '⌨️': {
    name: '',
    dotName: ':keyboard:',
    code: 'U+2328 U+FE0F',
    img: '2328-fe0f.png',
  },
  '⏏️': {
    name: '',
    dotName: ':eject:',
    code: 'U+23CF U+FE0F',
    img: '23cf-fe0f.png',
  },
  '⏩': {
    name: 'black right-pointing double triangle',
    dotName: ':fast_forward:',
    code: 'U+23E9',
    img: '23e9.png',
  },
  '⏪': {
    name: 'black left-pointing double triangle',
    dotName: ':rewind:',
    code: 'U+23EA',
    img: '23ea.png',
  },
  '⏫': {
    name: 'black up-pointing double triangle',
    dotName: ':arrow_double_up:',
    code: 'U+23EB',
    img: '23eb.png',
  },
  '⏬': {
    name: 'black down-pointing double triangle',
    dotName: ':arrow_double_down:',
    code: 'U+23EC',
    img: '23ec.png',
  },
  '⏭️': {
    name: '',
    dotName: ':black_right_pointing_double_triangle_with_vertical_bar:',
    code: 'U+23ED U+FE0F',
    img: '23ed-fe0f.png',
  },
  '⏮️': {
    name: '',
    dotName: ':black_left_pointing_double_triangle_with_vertical_bar:',
    code: 'U+23EE U+FE0F',
    img: '23ee-fe0f.png',
  },
  '⏯️': {
    name: '',
    dotName: ':black_right_pointing_triangle_with_double_vertical_bar:',
    code: 'U+23EF U+FE0F',
    img: '23ef-fe0f.png',
  },
  '⏰': {
    name: 'alarm clock',
    dotName: ':alarm_clock:',
    code: 'U+23F0',
    img: '23f0.png',
  },
  '⏱️': {
    name: '',
    dotName: ':stopwatch:',
    code: 'U+23F1 U+FE0F',
    img: '23f1-fe0f.png',
  },
  '⏲️': {
    name: '',
    dotName: ':timer_clock:',
    code: 'U+23F2 U+FE0F',
    img: '23f2-fe0f.png',
  },
  '⏳': {
    name: 'hourglass with flowing sand',
    dotName: ':hourglass_flowing_sand:',
    code: 'U+23F3',
    img: '23f3.png',
  },
  '⏸️': {
    name: '',
    dotName: ':double_vertical_bar:',
    code: 'U+23F8 U+FE0F',
    img: '23f8-fe0f.png',
  },
  '⏹️': {
    name: '',
    dotName: ':black_square_for_stop:',
    code: 'U+23F9 U+FE0F',
    img: '23f9-fe0f.png',
  },
  '⏺️': {
    name: '',
    dotName: ':black_circle_for_record:',
    code: 'U+23FA U+FE0F',
    img: '23fa-fe0f.png',
  },
  'Ⓜ️': {
    name: 'circled latin capital letter m',
    dotName: ':m:',
    code: 'U+24C2 U+FE0F',
    img: '24c2-fe0f.png',
  },
  '▪️': {
    name: 'black small square',
    dotName: ':black_small_square:',
    code: 'U+25AA U+FE0F',
    img: '25aa-fe0f.png',
  },
  '▫️': {
    name: 'white small square',
    dotName: ':white_small_square:',
    code: 'U+25AB U+FE0F',
    img: '25ab-fe0f.png',
  },
  '▶️': {
    name: 'black right-pointing triangle',
    dotName: ':arrow_forward:',
    code: 'U+25B6 U+FE0F',
    img: '25b6-fe0f.png',
  },
  '◀️': {
    name: 'black left-pointing triangle',
    dotName: ':arrow_backward:',
    code: 'U+25C0 U+FE0F',
    img: '25c0-fe0f.png',
  },
  '◻️': {
    name: 'white medium square',
    dotName: ':white_medium_square:',
    code: 'U+25FB U+FE0F',
    img: '25fb-fe0f.png',
  },
  '◼️': {
    name: 'black medium square',
    dotName: ':black_medium_square:',
    code: 'U+25FC U+FE0F',
    img: '25fc-fe0f.png',
  },
  '◽': {
    name: 'white medium small square',
    dotName: ':white_medium_small_square:',
    code: 'U+25FD',
    img: '25fd.png',
  },
  '◾': {
    name: 'black medium small square',
    dotName: ':black_medium_small_square:',
    code: 'U+25FE',
    img: '25fe.png',
  },
  '☀️': {
    name: 'black sun with rays',
    dotName: ':sunny:',
    code: 'U+2600 U+FE0F',
    img: '2600-fe0f.png',
  },
  '☁️': {
    name: 'cloud',
    dotName: ':cloud:',
    code: 'U+2601 U+FE0F',
    img: '2601-fe0f.png',
  },
  '☂️': {
    name: '',
    dotName: ':umbrella:',
    code: 'U+2602 U+FE0F',
    img: '2602-fe0f.png',
  },
  '☃️': {
    name: '',
    dotName: ':snowman:',
    code: 'U+2603 U+FE0F',
    img: '2603-fe0f.png',
  },
  '☄️': {
    name: '',
    dotName: ':comet:',
    code: 'U+2604 U+FE0F',
    img: '2604-fe0f.png',
  },
  '☎️': {
    name: 'black telephone',
    dotName: ':phone:',
    code: 'U+260E U+FE0F',
    img: '260e-fe0f.png',
  },
  '☑️': {
    name: 'ballot box with check',
    dotName: ':ballot_box_with_check:',
    code: 'U+2611 U+FE0F',
    img: '2611-fe0f.png',
  },
  '☔': {
    name: 'umbrella with rain drops',
    dotName: ':umbrella_with_rain_drops:',
    code: 'U+2614',
    img: '2614.png',
  },
  '☕': {
    name: 'hot beverage',
    dotName: ':coffee:',
    code: 'U+2615',
    img: '2615.png',
  },
  '☘️': {
    name: '',
    dotName: ':shamrock:',
    code: 'U+2618 U+FE0F',
    img: '2618-fe0f.png',
  },
  '☝️': {
    name: 'white up pointing index',
    dotName: ':point_up:',
    code: 'U+261D U+FE0F',
    img: '261d-fe0f.png',
  },
  '☠️': {
    name: '',
    dotName: ':skull_and_crossbones:',
    code: 'U+2620 U+FE0F',
    img: '2620-fe0f.png',
  },
  '☢️': {
    name: '',
    dotName: ':radioactive_sign:',
    code: 'U+2622 U+FE0F',
    img: '2622-fe0f.png',
  },
  '☣️': {
    name: '',
    dotName: ':biohazard_sign:',
    code: 'U+2623 U+FE0F',
    img: '2623-fe0f.png',
  },
  '☦️': {
    name: '',
    dotName: ':orthodox_cross:',
    code: 'U+2626 U+FE0F',
    img: '2626-fe0f.png',
  },
  '☪️': {
    name: '',
    dotName: ':star_and_crescent:',
    code: 'U+262A U+FE0F',
    img: '262a-fe0f.png',
  },
  '☮️': {
    name: '',
    dotName: ':peace_symbol:',
    code: 'U+262E U+FE0F',
    img: '262e-fe0f.png',
  },
  '☯️': {
    name: '',
    dotName: ':yin_yang:',
    code: 'U+262F U+FE0F',
    img: '262f-fe0f.png',
  },
  '☸️': {
    name: '',
    dotName: ':wheel_of_dharma:',
    code: 'U+2638 U+FE0F',
    img: '2638-fe0f.png',
  },
  '☹️': {
    name: '',
    dotName: ':white_frowning_face:',
    code: 'U+2639 U+FE0F',
    img: '2639-fe0f.png',
  },
  '☺️': {
    name: 'white smiling face',
    dotName: ':relaxed:',
    code: 'U+263A U+FE0F',
    img: '263a-fe0f.png',
  },
  '♀️': {
    name: '',
    dotName: ':female_sign:',
    code: 'U+2640 U+FE0F',
    img: '2640-fe0f.png',
  },
  '♂️': {
    name: '',
    dotName: ':male_sign:',
    code: 'U+2642 U+FE0F',
    img: '2642-fe0f.png',
  },
  '♈': {
    name: 'aries',
    dotName: ':aries:',
    code: 'U+2648',
    img: '2648.png',
  },
  '♉': {
    name: 'taurus',
    dotName: ':taurus:',
    code: 'U+2649',
    img: '2649.png',
  },
  '♊': {
    name: 'gemini',
    dotName: ':gemini:',
    code: 'U+264A',
    img: '264a.png',
  },
  '♋': {
    name: 'cancer',
    dotName: ':cancer:',
    code: 'U+264B',
    img: '264b.png',
  },
  '♌': {
    name: 'leo',
    dotName: ':leo:',
    code: 'U+264C',
    img: '264c.png',
  },
  '♍': {
    name: 'virgo',
    dotName: ':virgo:',
    code: 'U+264D',
    img: '264d.png',
  },
  '♎': {
    name: 'libra',
    dotName: ':libra:',
    code: 'U+264E',
    img: '264e.png',
  },
  '♏': {
    name: 'scorpius',
    dotName: ':scorpius:',
    code: 'U+264F',
    img: '264f.png',
  },
  '♐': {
    name: 'sagittarius',
    dotName: ':sagittarius:',
    code: 'U+2650',
    img: '2650.png',
  },
  '♑': {
    name: 'capricorn',
    dotName: ':capricorn:',
    code: 'U+2651',
    img: '2651.png',
  },
  '♒': {
    name: 'aquarius',
    dotName: ':aquarius:',
    code: 'U+2652',
    img: '2652.png',
  },
  '♓': {
    name: 'pisces',
    dotName: ':pisces:',
    code: 'U+2653',
    img: '2653.png',
  },
  '♟️': {
    name: '',
    dotName: ':chess_pawn:',
    code: 'U+265F U+FE0F',
    img: '265f-fe0f.png',
  },
  '♠️': {
    name: 'black spade suit',
    dotName: ':spades:',
    code: 'U+2660 U+FE0F',
    img: '2660-fe0f.png',
  },
  '♣️': {
    name: 'black club suit',
    dotName: ':clubs:',
    code: 'U+2663 U+FE0F',
    img: '2663-fe0f.png',
  },
  '♥️': {
    name: 'black heart suit',
    dotName: ':hearts:',
    code: 'U+2665 U+FE0F',
    img: '2665-fe0f.png',
  },
  '♦️': {
    name: 'black diamond suit',
    dotName: ':diamonds:',
    code: 'U+2666 U+FE0F',
    img: '2666-fe0f.png',
  },
  '♨️': {
    name: 'hot springs',
    dotName: ':hotsprings:',
    code: 'U+2668 U+FE0F',
    img: '2668-fe0f.png',
  },
  '♻️': {
    name: 'black universal recycling symbol',
    dotName: ':recycle:',
    code: 'U+267B U+FE0F',
    img: '267b-fe0f.png',
  },
  '♾️': {
    name: '',
    dotName: ':infinity:',
    code: 'U+267E U+FE0F',
    img: '267e-fe0f.png',
  },
  '♿': {
    name: 'wheelchair symbol',
    dotName: ':wheelchair:',
    code: 'U+267F',
    img: '267f.png',
  },
  '⚒️': {
    name: '',
    dotName: ':hammer_and_pick:',
    code: 'U+2692 U+FE0F',
    img: '2692-fe0f.png',
  },
  '⚓': {
    name: 'anchor',
    dotName: ':anchor:',
    code: 'U+2693',
    img: '2693.png',
  },
  '⚔️': {
    name: '',
    dotName: ':crossed_swords:',
    code: 'U+2694 U+FE0F',
    img: '2694-fe0f.png',
  },
  '⚕️': {
    name: '',
    dotName: ':medical_symbol:',
    code: 'U+2695 U+FE0F',
    img: '2695-fe0f.png',
  },
  '⚖️': {
    name: '',
    dotName: ':scales:',
    code: 'U+2696 U+FE0F',
    img: '2696-fe0f.png',
  },
  '⚗️': {
    name: '',
    dotName: ':alembic:',
    code: 'U+2697 U+FE0F',
    img: '2697-fe0f.png',
  },
  '⚙️': {
    name: '',
    dotName: ':gear:',
    code: 'U+2699 U+FE0F',
    img: '2699-fe0f.png',
  },
  '⚛️': {
    name: '',
    dotName: ':atom_symbol:',
    code: 'U+269B U+FE0F',
    img: '269b-fe0f.png',
  },
  '⚜️': {
    name: '',
    dotName: ':fleur_de_lis:',
    code: 'U+269C U+FE0F',
    img: '269c-fe0f.png',
  },
  '⚠️': {
    name: 'warning sign',
    dotName: ':warning:',
    code: 'U+26A0 U+FE0F',
    img: '26a0-fe0f.png',
  },
  '⚡': {
    name: 'high voltage sign',
    dotName: ':zap:',
    code: 'U+26A1',
    img: '26a1.png',
  },
  '⚧️': {
    name: '',
    dotName: ':transgender_symbol:',
    code: 'U+26A7 U+FE0F',
    img: '26a7-fe0f.png',
  },
  '⚪': {
    name: 'medium white circle',
    dotName: ':white_circle:',
    code: 'U+26AA',
    img: '26aa.png',
  },
  '⚫': {
    name: 'medium black circle',
    dotName: ':black_circle:',
    code: 'U+26AB',
    img: '26ab.png',
  },
  '⚰️': {
    name: '',
    dotName: ':coffin:',
    code: 'U+26B0 U+FE0F',
    img: '26b0-fe0f.png',
  },
  '⚱️': {
    name: '',
    dotName: ':funeral_urn:',
    code: 'U+26B1 U+FE0F',
    img: '26b1-fe0f.png',
  },
  '⚽': {
    name: 'soccer ball',
    dotName: ':soccer:',
    code: 'U+26BD',
    img: '26bd.png',
  },
  '⚾': {
    name: 'baseball',
    dotName: ':baseball:',
    code: 'U+26BE',
    img: '26be.png',
  },
  '⛄': {
    name: 'snowman without snow',
    dotName: ':snowman_without_snow:',
    code: 'U+26C4',
    img: '26c4.png',
  },
  '⛅': {
    name: 'sun behind cloud',
    dotName: ':partly_sunny:',
    code: 'U+26C5',
    img: '26c5.png',
  },
  '⛈️': {
    name: '',
    dotName: ':thunder_cloud_and_rain:',
    code: 'U+26C8 U+FE0F',
    img: '26c8-fe0f.png',
  },
  '⛎': {
    name: 'ophiuchus',
    dotName: ':ophiuchus:',
    code: 'U+26CE',
    img: '26ce.png',
  },
  '⛏️': {
    name: '',
    dotName: ':pick:',
    code: 'U+26CF U+FE0F',
    img: '26cf-fe0f.png',
  },
  '⛑️': {
    name: '',
    dotName: ':helmet_with_white_cross:',
    code: 'U+26D1 U+FE0F',
    img: '26d1-fe0f.png',
  },
  '⛓️': {
    name: '',
    dotName: ':chains:',
    code: 'U+26D3 U+FE0F',
    img: '26d3-fe0f.png',
  },
  '⛔': {
    name: 'no entry',
    dotName: ':no_entry:',
    code: 'U+26D4',
    img: '26d4.png',
  },
  '⛩️': {
    name: '',
    dotName: ':shinto_shrine:',
    code: 'U+26E9 U+FE0F',
    img: '26e9-fe0f.png',
  },
  '⛪': {
    name: 'church',
    dotName: ':church:',
    code: 'U+26EA',
    img: '26ea.png',
  },
  '⛰️': {
    name: '',
    dotName: ':mountain:',
    code: 'U+26F0 U+FE0F',
    img: '26f0-fe0f.png',
  },
  '⛱️': {
    name: '',
    dotName: ':umbrella_on_ground:',
    code: 'U+26F1 U+FE0F',
    img: '26f1-fe0f.png',
  },
  '⛲': {
    name: 'fountain',
    dotName: ':fountain:',
    code: 'U+26F2',
    img: '26f2.png',
  },
  '⛳': {
    name: 'flag in hole',
    dotName: ':golf:',
    code: 'U+26F3',
    img: '26f3.png',
  },
  '⛴️': {
    name: '',
    dotName: ':ferry:',
    code: 'U+26F4 U+FE0F',
    img: '26f4-fe0f.png',
  },
  '⛵': {
    name: 'sailboat',
    dotName: ':boat:',
    code: 'U+26F5',
    img: '26f5.png',
  },
  '⛷️': {
    name: '',
    dotName: ':skier:',
    code: 'U+26F7 U+FE0F',
    img: '26f7-fe0f.png',
  },
  '⛸️': {
    name: '',
    dotName: ':ice_skate:',
    code: 'U+26F8 U+FE0F',
    img: '26f8-fe0f.png',
  },
  '⛹️‍♀️': {
    name: '',
    dotName: ':woman-bouncing-ball:',
    code: 'U+26F9 U+FE0F U+200D U+2640 U+FE0F',
    img: '26f9-fe0f-200d-2640-fe0f.png',
  },
  '⛹️‍♂️': {
    name: '',
    dotName: ':man-bouncing-ball:',
    code: 'U+26F9 U+FE0F U+200D U+2642 U+FE0F',
    img: '26f9-fe0f-200d-2642-fe0f.png',
  },
  '⛹️': {
    name: '',
    dotName: ':person_with_ball:',
    code: 'U+26F9 U+FE0F',
    img: '26f9-fe0f.png',
  },
  '⛺': {
    name: 'tent',
    dotName: ':tent:',
    code: 'U+26FA',
    img: '26fa.png',
  },
  '⛽': {
    name: 'fuel pump',
    dotName: ':fuelpump:',
    code: 'U+26FD',
    img: '26fd.png',
  },
  '✂️': {
    name: 'black scissors',
    dotName: ':scissors:',
    code: 'U+2702 U+FE0F',
    img: '2702-fe0f.png',
  },
  '✅': {
    name: 'white heavy check mark',
    dotName: ':white_check_mark:',
    code: 'U+2705',
    img: '2705.png',
  },
  '✈️': {
    name: 'airplane',
    dotName: ':airplane:',
    code: 'U+2708 U+FE0F',
    img: '2708-fe0f.png',
  },
  '✉️': {
    name: 'envelope',
    dotName: ':email:',
    code: 'U+2709 U+FE0F',
    img: '2709-fe0f.png',
  },
  '✊': {
    name: 'raised fist',
    dotName: ':fist:',
    code: 'U+270A',
    img: '270a.png',
  },
  '✋': {
    name: 'raised hand',
    dotName: ':hand:',
    code: 'U+270B',
    img: '270b.png',
  },
  '✌️': {
    name: 'victory hand',
    dotName: ':v:',
    code: 'U+270C U+FE0F',
    img: '270c-fe0f.png',
  },
  '✍️': {
    name: '',
    dotName: ':writing_hand:',
    code: 'U+270D U+FE0F',
    img: '270d-fe0f.png',
  },
  '✏️': {
    name: 'pencil',
    dotName: ':pencil2:',
    code: 'U+270F U+FE0F',
    img: '270f-fe0f.png',
  },
  '✒️': {
    name: 'black nib',
    dotName: ':black_nib:',
    code: 'U+2712 U+FE0F',
    img: '2712-fe0f.png',
  },
  '✔️': {
    name: 'heavy check mark',
    dotName: ':heavy_check_mark:',
    code: 'U+2714 U+FE0F',
    img: '2714-fe0f.png',
  },
  '✖️': {
    name: 'heavy multiplication x',
    dotName: ':heavy_multiplication_x:',
    code: 'U+2716 U+FE0F',
    img: '2716-fe0f.png',
  },
  '✝️': {
    name: '',
    dotName: ':latin_cross:',
    code: 'U+271D U+FE0F',
    img: '271d-fe0f.png',
  },
  '✡️': {
    name: '',
    dotName: ':star_of_david:',
    code: 'U+2721 U+FE0F',
    img: '2721-fe0f.png',
  },
  '✨': {
    name: 'sparkles',
    dotName: ':sparkles:',
    code: 'U+2728',
    img: '2728.png',
  },
  '✳️': {
    name: 'eight spoked asterisk',
    dotName: ':eight_spoked_asterisk:',
    code: 'U+2733 U+FE0F',
    img: '2733-fe0f.png',
  },
  '✴️': {
    name: 'eight pointed black star',
    dotName: ':eight_pointed_black_star:',
    code: 'U+2734 U+FE0F',
    img: '2734-fe0f.png',
  },
  '❄️': {
    name: 'snowflake',
    dotName: ':snowflake:',
    code: 'U+2744 U+FE0F',
    img: '2744-fe0f.png',
  },
  '❇️': {
    name: 'sparkle',
    dotName: ':sparkle:',
    code: 'U+2747 U+FE0F',
    img: '2747-fe0f.png',
  },
  '❌': {
    name: 'cross mark',
    dotName: ':x:',
    code: 'U+274C',
    img: '274c.png',
  },
  '❎': {
    name: 'negative squared cross mark',
    dotName: ':negative_squared_cross_mark:',
    code: 'U+274E',
    img: '274e.png',
  },
  '❓': {
    name: 'black question mark ornament',
    dotName: ':question:',
    code: 'U+2753',
    img: '2753.png',
  },
  '❔': {
    name: 'white question mark ornament',
    dotName: ':grey_question:',
    code: 'U+2754',
    img: '2754.png',
  },
  '❕': {
    name: 'white exclamation mark ornament',
    dotName: ':grey_exclamation:',
    code: 'U+2755',
    img: '2755.png',
  },
  '❗': {
    name: 'heavy exclamation mark symbol',
    dotName: ':exclamation:',
    code: 'U+2757',
    img: '2757.png',
  },
  '❣️': {
    name: '',
    dotName: ':heavy_heart_exclamation_mark_ornament:',
    code: 'U+2763 U+FE0F',
    img: '2763-fe0f.png',
  },
  '❤️': {
    name: 'heavy black heart',
    dotName: ':heart:',
    code: 'U+2764 U+FE0F',
    img: '2764-fe0f.png',
  },
  '➕': {
    name: 'heavy plus sign',
    dotName: ':heavy_plus_sign:',
    code: 'U+2795',
    img: '2795.png',
  },
  '➖': {
    name: 'heavy minus sign',
    dotName: ':heavy_minus_sign:',
    code: 'U+2796',
    img: '2796.png',
  },
  '➗': {
    name: 'heavy division sign',
    dotName: ':heavy_division_sign:',
    code: 'U+2797',
    img: '2797.png',
  },
  '➡️': {
    name: 'black rightwards arrow',
    dotName: ':arrow_right:',
    code: 'U+27A1 U+FE0F',
    img: '27a1-fe0f.png',
  },
  '➰': {
    name: 'curly loop',
    dotName: ':curly_loop:',
    code: 'U+27B0',
    img: '27b0.png',
  },
  '➿': {
    name: 'double curly loop',
    dotName: ':loop:',
    code: 'U+27BF',
    img: '27bf.png',
  },
  '⤴️': {
    name: 'arrow pointing rightwards then curving upwards',
    dotName: ':arrow_heading_up:',
    code: 'U+2934 U+FE0F',
    img: '2934-fe0f.png',
  },
  '⤵️': {
    name: 'arrow pointing rightwards then curving downwards',
    dotName: ':arrow_heading_down:',
    code: 'U+2935 U+FE0F',
    img: '2935-fe0f.png',
  },
  '⬅️': {
    name: 'leftwards black arrow',
    dotName: ':arrow_left:',
    code: 'U+2B05 U+FE0F',
    img: '2b05-fe0f.png',
  },
  '⬆️': {
    name: 'upwards black arrow',
    dotName: ':arrow_up:',
    code: 'U+2B06 U+FE0F',
    img: '2b06-fe0f.png',
  },
  '⬇️': {
    name: 'downwards black arrow',
    dotName: ':arrow_down:',
    code: 'U+2B07 U+FE0F',
    img: '2b07-fe0f.png',
  },
  '⬛': {
    name: 'black large square',
    dotName: ':black_large_square:',
    code: 'U+2B1B',
    img: '2b1b.png',
  },
  '⬜': {
    name: 'white large square',
    dotName: ':white_large_square:',
    code: 'U+2B1C',
    img: '2b1c.png',
  },
  '⭐': {
    name: 'white medium star',
    dotName: ':star:',
    code: 'U+2B50',
    img: '2b50.png',
  },
  '⭕': {
    name: 'heavy large circle',
    dotName: ':o:',
    code: 'U+2B55',
    img: '2b55.png',
  },
  '〰️': {
    name: 'wavy dash',
    dotName: ':wavy_dash:',
    code: 'U+3030 U+FE0F',
    img: '3030-fe0f.png',
  },
  '〽️': {
    name: 'part alternation mark',
    dotName: ':part_alternation_mark:',
    code: 'U+303D U+FE0F',
    img: '303d-fe0f.png',
  },
  '㊗️': {
    name: 'circled ideograph congratulation',
    dotName: ':congratulations:',
    code: 'U+3297 U+FE0F',
    img: '3297-fe0f.png',
  },
  '㊙️': {
    name: 'circled ideograph secret',
    dotName: ':secret:',
    code: 'U+3299 U+FE0F',
    img: '3299-fe0f.png',
  },
};
