import C, {apply} from 'consistencss';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {profile} from '../App';
import {BASE_PIXEL, colors, deviceWidth, gradGold, isWide} from '../design/gStyles';
import {buildingsMap, unitsMap} from '../utils/sets';
import {Chat, LevelStar} from './Box';
import EmojiSelector, {Categories} from './EmojiSelector';
import {CardCarrousel, CircWrapper, FullModal} from './Modals';
import {TrackBar} from './ProgressBar';
import {WrapMoji} from './WrapMoji';

export default observer(
    ({
         currProfile,
         showOps = false,
         emoji = currProfile.emoji,
         flag = currProfile.flag,
         ExtraChat,
         wrapStyle,
         persistChat = false,
         chatMsg,
         ModalComp,
         onPressBuildings,
         onPressWorld,
         onPressSettings,
         onPressLevel,
         onPressUnits,
     }) => {
        const hasOps = onPressBuildings || onPressWorld || onPressSettings || onPressUnits;
        const [expandOps, setExpandOps] = useState(showOps);
        const [showModal, setShowModal] = useState(false);
        const [currChat, setCurrChat] = useState(chatMsg);
        const [showFlag, setShowFlag] = useState(false);
        const subModalsOpen = /*expandOps &&*/ profile.buildModal.show || profile.unitModal.show;
        useEffect(() => setCurrChat(chatMsg), [chatMsg]);
        return (
            <View style={[C.row, C.itemsCenter, wrapStyle]}>
                <CircWrapper
                    icon={emoji} /*={!subModalsOpen || currChat}*/
                    /*big*/ onPress={() => {
                    if (ModalComp) {
                        setShowModal(!showModal);
                    }
                    if (hasOps) {
                        setExpandOps(!expandOps);
                        profile.buildModal.closeModal();
                        profile.unitModal.closeModal();
                    }
                    if (chatMsg && !persistChat) {
                        setCurrChat(null);
                    }
                    //hasOps ? setExpandOps(!expandOps) : ModalComp ? setShowModal(!showModal) : setCurrChat(null);
                }}
                />
                {
                    /*!expandOps &&*/ <View>
                    {/**Level*/}
                    <LevelStar wrapStyle={[C.absolute, C.top1, C.right_2]} level={currProfile.level} size={1}/>

                    {/**Flag*/}
                    <WrapMoji
                        emoji={flag}
                        onPress={() => {
                            currProfile.flagModal.showModal();
                            setShowFlag(true);
                        }}
                        sizeNum={1}
                        forceText={false}
                        wrapStyle={apply(C.absolute, C.top_12, C.right_2)}
                    />

                    <TrackBar
                        height={isWide ? 8 : 8}
                        maxWidth={18 * BASE_PIXEL}
                        wrapStyle={[C.top10, C.absolute, C.left_17]}
                        progress={currProfile.score / currProfile.remainingScore}
                        grad={gradGold}
                        colBord={colors.paleGrey}
                    />
                </View>
                }

                {/**Option bubbles */}
                {!currChat && expandOps && (
                    /*hasOps && !expandOps && */ <View style={[C.row, C.ml4, C.ml4, C.itemsCenter]}>
                        {onPressBuildings && (
                            <CardCarrousel
                                icon={'🏰'}
                                name={'Build'}
                                modal={profile.buildModal}
                                otherModal={profile.unitModal}
                                list={buildingsMap}
                                onSet={onPressBuildings}
                            />
                        )}
                        {onPressUnits && (
                            <CardCarrousel
                                icon={'🥷🏻'}
                                name={'Units'}
                                modal={profile.unitModal}
                                otherModal={profile.buildModal}
                                list={unitsMap}
                                onSet={onPressUnits}
                            />
                        )}
                        {onPressWorld && <CardCarrousel icon={'🌍'} name={'World'} onSet={onPressWorld}/>}
                        {onPressLevel && <CardCarrousel icon={'🎖'} name={'Level'} onSet={onPressLevel}/>}
                        {onPressSettings && <CardCarrousel icon={'📜'} name={'Settings'} onSet={onPressSettings}/>}
                    </View>
                )}

                {/**chat Screen*/}
                {currChat && (
                    <Chat
                        text={chatMsg}
                        ExtraChat={ExtraChat}
                        onPress={() => {
                            hasOps && setExpandOps(true);
                            if (!persistChat) {
                                setCurrChat(null);
                            }
                        }}
                    />
                )}

                {/**Extra Modal*/}
                {ModalComp && showModal && (
                    <FullModal isFull title={'Choose your lineage'} afterClose={() => setShowModal(false)}
                               visible={showModal}>
                        {ModalComp}
                    </FullModal>
                )}

                {/**Flag modal*/}
                {showFlag && (
                    <FullModal afterClose={() => setShowFlag(false)} visible={showFlag} title={'Set flag...'}>
                        <EmojiSelector
                            showHistory={false}
                            showTabs={false}
                            showSectionTitles={false}
                            columns={Math.floor((deviceWidth * 0.8) / 50)}
                            category={Categories.flags}
                            onEmojiSelected={(newEmoji) => {
                                profile.setFlag(newEmoji);
                                setShowFlag(false);
                            }}
                        />
                    </FullModal>
                )}
            </View>
        );
    },
);
