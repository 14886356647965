import {useNavigation, useRoute} from '@react-navigation/core';
import C, {apply} from 'consistencss';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {FlatList, ScrollView, Text, TextInput, TouchableOpacity, View} from 'react-native';
import useSound from 'use-sound';
import {profile} from '../App';
import Box, {Spinner, StatsMap} from '../comp/Box';
import {CardModal} from '../comp/Card';
import Cell from '../comp/Cell';
import {EmojiCompat} from '../comp/WrapMoji';
import {bgColor, bordColor, colors, isIOS, isWeb, isWide, onTop, shadow, sounds} from '../design/gStyles';
import Game from '../layouts/Game';
import {COMBO_SIZE, INIT_MOVES} from '../stores/HarvestStore';
import {memojiRef} from '../stores/profileStore';
import {LevelSelector, levelsMap} from '../utils/levels';
import {farm} from '../utils/sets';
import {blinkDelay} from '../utils/utils';
import {font, fonts} from '../design/fonts';

const MemojiGame = observer(() => {
    const route = useRoute();
    const practiceMode = route.params?.practiceMode || true;
    const harvestList = {set: farm}; //route.params?.harvestList || elements;

    const {currMemoji} = profile;
    const {lastHarvest, collected, currLevel, currentBoard, maxCombo, strike, hints, moves} = currMemoji;

    const [showHint, setShowHint] = useState(true);
    const [shouldBlink, setShouldBlink] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [level, setLevel] = useState(4);

    const navigation = useNavigation();
    const {soundsOn} = profile;

    /**Sound*/
    const [playOK] = useSound(sounds.combo2, {volume: soundsOn ? 0.85 : 0});
    const [playWrong] = useSound(sounds.wrong, {volume: soundsOn ? 0.85 : 0});

    const showHelp = () =>
        blinkDelay({
            setBlink: setShowHint,
            /*onAfter: () => playBg(),*/
            time: level * 700,
        });

    useEffect(() => {
        showHelp();
        currMemoji.reset(level);
    }, [level]);

    const levelUp = collected.length >= currentBoard.cells.length / 2;
    const blockingEvent = /*showInfo ||*/ moves <= 0 || /*shouldBlink ||*/ levelUp;

    return (
        <ScrollView contentContainerStyle={[C.itemsCenter, C.minhFull]}>
            <View style={apply(C.row, C.itemsCenter)}>
                <View style={C.wFull}>
                    <StatsMap currProfile={profile} showLevel={false}>
                        {/**Remaining Moves*/}
                        <Box
                            icon={'💣'}
                            /*onPress={() => setLevel(9)}*/
                            text={moves}
                            progressGrad={[colors.blue, colors.grass]}
                            progress={moves >= INIT_MOVES ? 1 : moves / INIT_MOVES}
                            highlightIcon={moves < 5}
                            value={'moves'}
                        />
                    </StatsMap>
                </View>
            </View>

            {/**Emoji memory pairs*/}
            <View style={[C.alignCenter]}>
                <FlatList
                    horizontal
                    initialScrollIndex={0}
                    style={C.py1}
                    keyExtractor={({item, index}) => index}
                    data={collected}
                    renderItem={({item}) => (
                        <Box
                            key={item[0]}
                            horiz={collected.length < 5}
                            /*highlightIcon={shouldBlink && item[0] === currFind.lastHarvest.icon}*/
                            icon={item[0]}
                            text={item[1]}
                        />
                    )}
                />
            </View>

            {showHint && (
                <View style={[C.row, C.itemsCenter]}>
                    <Spinner list={['⌛️', '⏳']}/>
                    <Text style={[fonts.subtitle, C.mx2]}>Remember as much as you can!</Text>
                    <EmojiCompat emoji={'🧠'} sizeNum={3}/>
                </View>
            )}

            {/**Board*/}
            {currentBoard.cells && (
                <FlatList
                    data={currentBoard.cells}
                    keyExtractor={({id}) => id}
                    numColumns={4} //level <= 4 ? 4 : Math.round(level / 2)}
                    style={apply(C.my6, blockingEvent && C.opacity25, {flexGrow: 0})}
                    contentContainerStyle={[isWide && C.p2]}
                    extraData={currentBoard.currCell.active}
                    scrollEnabled={false}
                    renderItem={({item, index}) => (
                        <Cell
                            key={item.id}
                            size={4}
                            withFlex={!isWeb}
                            withTransp={false}
                            cShadow={shadow(isWeb ? colors.white : colors.blue, 3)}
                            cBord={bordColor(colors.water, isIOS ? 0.5 : 1)}
                            bg={!isIOS && shouldBlink && index === currentBoard.currCellId ? colors.water + '20' : colors.white}
                            wrapStyle={C.radius3}
                            index={index}
                            onPress={() => {
                                if (!showHint && !item.active) {
                                    playOK();
                                    currentBoard.setCurrent(index);
                                    currentBoard.currCell.toggleActive(true);
                                    blinkDelay({
                                        setBlink: setShouldBlink,
                                        onAfter: () => currMemoji.matchPair(item.icon, index),
                                        time: 500,
                                    });
                                } else {
                                    playWrong();
                                }
                            }}
                            item={item}
                            icon={showHint || item.active ? item.icon : '🐾'}
                        />
                    )}
                />
            )}


            {/**Bonus*/}
            {practiceMode && !blockingEvent && (
                <View style={apply(C.row, C.mb2)}>
                    {/**Shuffle*/}
                    {/*<Box
            isBig
            icon={'🔄'}
            col={colors.blueGrey}
            value={'Shuffle'}
            opac={shuffles <= 0}
            onPress={() => currFind.shuffle()}
            text={shuffles}
          />*/}

                    {/**Shuffle*/}
                    <Box
                        isBig
                        icon={'👀'}
                        col={colors.blueGrey}
                        value={'Hint'}
                        opac={hints <= 0}
                        onPress={() => {
                            showHelp();
                            currMemoji.addHint();
                        }}
                        text={hints}
                    />

                    {/**Strike*/}
                    {strike > 0 && (
                        <Box
                            isBig
                            icon={'🔥'}
                            col={colors.blueGrey}
                            value={'Strike'}
                            opac={hints <= 0}
                            onPress={() => {
                                showHelp();
                                currMemoji.addHint();
                            }}
                            text={strike}
                        />
                    )}
                </View>
            )}

            <LevelSelector
                currLevel={level}
                levels={levelsMap.memoji}
                onSet={(level) => {
                    setLevel(level - 1);
                }}
            />

            {/**Overlapping Info Pannel*/}
            {blockingEvent && (
                <CardModal
                    Content={
                        levelUp ? (
                            <View style={[C.itemsCenter, C.flex, C.p4, onTop]}>
                                <EmojiCompat emoji={'🎉'}/>
                                {profile.score > level * 10 &&
                                    <Text style={[fonts.mainTitle, C.textGold]}>New record!</Text>}
                                {<Text style={font.h1}>⭐️ Level {level}</Text>}
                                {strike > 0 && <Text style={[font.h2, C.my2]}>💥 Strike {strike}</Text>}
                                <Text style={[fonts.title2]}>
                                    {_.range(lastHarvest.size).map((item) => (
                                        <EmojiCompat emoji={lastHarvest.icon}/>
                                    ))}
                                </Text>
                                <View style={[C.row, C.itemsCenter]}>
                                    <Text style={[fonts.subtitle, C.textFlame, C.justifyCenter, C.itemsCenter]}>
                                        + {profile.score}
                                    </Text>
                                    <EmojiCompat emoji={'🔥'} sizeNum={1}/>
                                </View>

                                {lastHarvest.isCombo && (
                                    <Text style={fonts.subtitle} /*onPress={() => currFind.shuffle()}*/>
                                        <EmojiCompat emoji={'💣'} sizeNum={1}/> + {lastHarvest.size - COMBO_SIZE} moves
                                    </Text>
                                )}
                                <TouchableOpacity
                                    style={[C.row, C.my2, C.p2, bgColor(colors.gold + '30'), C.radius2, C.itemsCenter, C.selfCenter, C.shadowMd]}
                                    onPress={() => setLevel(level + 1)}>
                                    <EmojiCompat emoji={'💪'} sizeNum={1}/>
                                    <Text style={[fonts.title2, C.ml2, C.textBlue]}>Play Level {level + 1}</Text>
                                </TouchableOpacity>
                            </View>
                        ) : (
                            <View>
                                <Text style={[fonts.title1, C.textFlame]}>💥 Game Over... 😭</Text>
                                <View style={[C.row, C.my2, C.itemsCenter, C.selfCenter]}>
                                    <EmojiCompat emoji={'🔥'} sizeNum={0}/>
                                    <Text style={[fonts.body1, C.textBlueGrey, C.mx2]}>Score</Text>
                                    <Text style={[fonts.subtitle]}>{profile.score}</Text>
                                </View>
                                <View style={[C.row, C.my2, C.itemsCenter, C.selfCenter]}>
                                    <EmojiCompat emoji={'⭐️'} sizeNum={0}/>
                                    <Text style={[fonts.body1, C.textBlueGrey, C.mx2]}>Level</Text>
                                    <Text style={[fonts.subtitle]}>{profile.level}</Text>
                                </View>

                                <Text style={[fonts.title2, C.textBlue, C.mt2]}>Save progress?</Text>
                                <View style={apply(bgColor(colors.paleGreyTwo), C.itemsCenter, C.radius2, C.row, C.m4)}>
                                    <EmojiCompat emoji={'📜'} sizeNum={0}/>

                                    <TextInput
                                        onChangeText={(text) => profile.setName(text)}
                                        /*onSubmitEditing={() => profile.submitMail()}*/
                                        value={profile.username}
                                        style={[C.px2, C.pt1, fonts.body2, C.textBlue]}
                                        placeholder={'Carlos Smith'}
                                    />
                                    {profile.username && (
                                        <Text onPress={() => profile.setName('')} style={C.mx2}>
                                            {profile.loading ? '⏳' : profile.isMailValid ? '✅' : '❌'}
                                        </Text>
                                    )}
                                </View>

                                <TouchableOpacity
                                    style={[C.row, C.my2, C.p1, C.radius2, C.itemsCenter, C.selfCenter, C.shadowMd]}
                                    onPress={() => {
                                        setLevel(1);
                                        /*showHelp();*/
                                    }}>
                                    <EmojiCompat emoji={'🔄'} sizeNum={1}/>
                                    <Text style={[fonts.title2, C.ml2, C.textBlue]}>Play Again</Text>
                                </TouchableOpacity>
                            </View>
                        )
                    }
                />
            )}

        </ScrollView>
    );
});

export default () => (
    <Game
        GameComp={<MemojiGame/>}
        scoreRef={memojiRef}
        emoji={'🧠'}
        title={'Memoji'}
        subtitle={'Memorize and match the emoji pairs'}
    />
);
