import {useNavigation} from '@react-navigation/core';
import C, {apply} from 'consistencss';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';

//import {isAndroid, isIOS, isMacOs, isMobile, isWindows} from 'react-device-detect';
import {FlatList, ScrollView, Text, TextInput, View} from 'react-native';
import {profile} from '../App';

import {LineageWrapper, Spinner} from '../comp/Box';
import {Card} from '../comp/Card';
import EmojiBubble from '../comp/EmojiBubble';
import {EmojiCompat} from '../comp/WrapMoji';
import {bgColor, colors, isWide} from '../design/gStyles';
import {HarvestLevelSelector} from '../utils/levels';
import {availWorlds} from '../utils/sets';
import {fonts} from '../design/fonts';

export default observer(() => {
    const {navigate} = useNavigation();
    // Set an initializing state whilst Firebase connects
    const [initializing, setInitializing] = useState(true);

    // Handle user state changes
    /*const onAuthStateChanged = useCallback(
      (newUser) => {
        setUser(newUser);
        profile.setUser(newUser);
        if (initializing) {
          setInitializing(false);
        }
      },
      [initializing],
    );*/

    /*useEffect(() => {
      const provider = new firebase.auth.GoogleAuthProvider();

      // auth().signInAnonymously().then('user signed in,..');
      firebase.auth().signInWithPopup(provider);
      const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
      return subscriber; // unsubscribe on unmount
    }, [onAuthStateChanged]);*/

    return (
        <ScrollView contentContainerStyle={apply(C.itemsCenter, C.py4, C.bgWhite)}>
            {/*<View style={[C.row, C.itemsCenter, C.my3]}>
          <EmojiCompat emoji={'🌏'} sizeNum={1} />
          <Text style={[fonts.mainTitle, C.ml3]}>Planet Moji</Text>
          <EmojiCompat emoji={'😜'} sizeNum={1} />
        </View>*/}

            {profile.username && (
                <Text style={[fonts.title1, C.textBlue, C.m4, C.maxwHalf, C.alignCenter, C.capitalize]}>
                    {'👋 Welcome ' + profile.username}
                </Text>
            )}

            {/**Emoji setter*/}
            <EmojiBubble ModalComp={<LineageWrapper currProfile={profile}/>} currProfile={profile}/>

            {/**Text Input*/}
            <View style={apply(bgColor(colors.paleGreyTwo), C.itemsCenter, C.radius2, C.row, C.p2, C.m8)}>
                <EmojiCompat emoji={'📜'} sizeNum={1}/>

                <TextInput
                    onChangeText={(text) => profile.setName(text)}
                    /*onSubmitEditing={() => profile.submitMail()}*/
                    value={profile.username}
                    style={[C.px2, C.pt1, fonts.body1, C.textBlue]}
                    placeholder={'Carlos Smith'}
                />
                {profile.username && (
                    <Text onPress={() => profile.setName('')} style={C.mx2}>
                        {profile.loading ? '⏳' : profile.isMailValid ? '✅' : '❌'}
                    </Text>
                )}
            </View>

            {/**Adventure*/}
            <View style={[C.row, C.itemsCenter, C.mt4]}>
                <Spinner iconSize={C.font6}/>
                <Text style={[fonts.title2, C.ml2]}>Start your adventure...</Text>
            </View>
            <FlatList
                horizontal /*={isWide}*/
                initialScrollIndex={0}
                style={[!isWide && C.selfStart]}
                keyExtractor={({index}) => index}
                contentContainerStyle={[C.itemsCenter]}
                data={availWorlds}
                renderItem={({item, index}) => (
                    <Card
                        {...item}
                        onPress={() => {
                            navigate(item.screen);
                        }}
                    />
                )}
            />

            {/**Practice Mode*/}
            <Text style={[fonts.title2, C.mt4]}>🎳 Practice mode</Text>
            <HarvestLevelSelector currLevel={profile.level}/>
        </ScrollView>
    );
});
