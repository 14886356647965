import _ from 'lodash';
import {makeAutoObservable} from 'mobx';
import {nanoid} from 'nanoid/non-secure';
import {profile} from '../App';
import {zoomLatLength} from '../utils/MapUtils';
import {buildingsMap, natureToResource, terrains, unitsMap} from '../utils/sets';
import {pickRandom} from '../utils/utils';
import {boardsMap} from './boardStore';

export const UnitStore = (unitIcon = '👩‍🌾', pos, isEvil = false) =>
  makeAutoObservable({
    id: nanoid(6),
    unit: unitsMap[unitIcon],
    icon: unitIcon,
    cellPos: pos,
    isEvil: isEvil,
    life: unitsMap[unitIcon]?.skills['❤️'] || 100,
    map: null,
    get isAssigned() {
      return this.map && this.cellPos;
    },
    get lifePerc() {
      return this.life / this.unit.skills['❤️'];
    },
    impactAttack(attack = 0) {
      const impact = attack - this.unit.skills['🛡'];
      if (impact > 0) {
        this.life -= impact;
      }
    },
    assign(map, newPos) {
      this.map = map;
      this.cellPos = newPos;
    },
    move(newPos) {
      this.cellPos = newPos;
    },
    /* redLife(amount) {
      this.currLife -= amount;
    },*/
  });
export const BuildingStore = (buildIcon, pos, isEvil = false, flag = profile.flag) =>
  makeAutoObservable({
    id: nanoid(6),
    building: buildingsMap[buildIcon],
    icon: buildIcon,
    cellPos: pos,
    flag: flag,
    isEvil: isEvil,
    map: null,
    get isAssigned() {
      return this.map && this.cellPos;
    },
    assign(map, newPos) {
      this.map = map;
      this.cellPos = newPos;
    },
    move(newPos) {
      this.cellPos = newPos;
    },
    redLife(amount) {
      this.currLife -= amount;
    },
  });

export const CellStore = ({
  id,
  icon,
  boardMap = boardsMap.WORLD,
  unit,
  building,
  img,
  bg,
  badge,
  side = 8,
  active = false,
  flag,
  isEvil = false,
  /*complSeconds = Math.floor(Math.random() * 10),*/
}) =>
  makeAutoObservable({
    id: id,
    icon: icon || boardMap.icon(id), //(boardMap.terrain && (() => pickRandom(boardMap.terrain.icons, 1))),
    isEvil: isEvil,
    unit: unit,
    building: building,
    badge: badge,
    active: active,
    /*initialCord: initialCord,*/
    flag: flag,
    img: img,
    bg: bg,
    isEmpty: false,
    isPressable: !icon /*iconGenerator(id, isPressable)*/,
    get scaleFactor() {
      return boardMap.minZoom / 3;
    },
    /*get metersPerPx() {
      return zoomLatLength(boardMap.minZoom); //lengthLat / zoomToMeter[boardMap.minZoom]; //(156543.03392 * Math.cos((boardMap.initCord.latitude * Math.PI) / 180)) / Math.pow(2, boardMap.minZoom);
    },*/
    get coord() {
      return {
        latitude: boardMap.initCord.latitude + Math.floor(this.id / side) * zoomLatLength(boardMap.minZoom), // * lengthLat * 10) / zoomToMeter[boardMap.minZoom] /*1.4*/,
        longitude:
          boardMap.initCord.longitude +
          (this.id % side) * zoomLatLength(boardMap.minZoom) /*lengthLat * 10) / zoomToMeter[boardMap.minZoom]*/,
        /*this.metersPerPx) /
            lengthLat*/
      };
    },
    get isUnit() {
      return !_.isEmpty(this.unit);
    },
    get isBuilding() {
      return !_.isEmpty(this.building);
    },
    get yPos() {
      return Math.floor(this.id / side);
    },
    get xPos() {
      return this.id % side;
    },
    get isNature() {
      return !this.isUnit && !this.isBuilding && this.icon;
    },
    get ourBuilding() {
      return !!this.building && this.isEvil;
    },

    /**Timer*/
    /*complSeconds: complSeconds,
    get taskExpiry() {
      return moment().add(/!*id % 3 === 0 ? 0 :*!/ this.complSeconds, 'seconds');
    },
    get taskReady() {
      return moment(this.taskExpiry).diff(moment(), 'seconds', true) > 0;
    },
    get badge() {
      return this.taskReady;
    },*/
    get availResources() {
      return /*this.taskReady*/ this.id % 3 === 0 && natureToResource(this.icon)?.icon;
    },
    toggleActive(newState) {
      this.active = newState || !this.active;
    },
    setIcon(newIcon) {
      this.icon = newIcon;
    },
    setEvil(isCurrEvil = true) {
      this.isEvil = isCurrEvil;
    },
    setUnit(newUnit, isNewEvil = newUnit.isEvil, newFlag = profile.flag) {
      this.unit = newUnit;
      this.icon = newUnit.icon;
      this.isEvil = isNewEvil;
      this.flag = newFlag;
    },
    clearCell() {
      this.unit = undefined;
      this.icon = '';
      this.isEvil = false;
    },
    setBuilding(newBuild = true, isNewEvil = newBuild.isEvil, newFlag = newBuild.flag) {
      this.building = newBuild;
      this.icon = newBuild.icon;
      this.isEvil = isNewEvil;
      this.flag = newFlag;
    },
    /**@deprecated*/
    setTerrain(newTerrain = terrains['🌲']) {
      this.bg = newTerrain.bg;
      this.icon = pickRandom(newTerrain.icons, 0.3);
      this.img = newTerrain.img;
    },
  });
