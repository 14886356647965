import C, {apply} from 'consistencss';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {FlatList, Image, ScrollView, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {profile} from '../App';
import {
    badgeWrapperSm,
    BASE_PIXEL,
    bgColor,
    bordColor,
    cell,
    colors,
    deviceWidth,
    isIOS,
    isNarrow,
    isWeb,
    isWide,
    shadow,
    textColor,
    textSize,
} from '../design/gStyles';
import {safeBack} from '../routes';
import {characters, planets} from '../utils/sets';
import {numFormat, respImgSize} from '../utils/utils';
import EmojiBubble from './EmojiBubble';
import {TrackBar} from './ProgressBar';
import {EmojiCompat, WrapMoji} from './WrapMoji';
import {fonts} from '../design/fonts';

const Box = ({
                 small = false,
                 icon,
                 value,
                 opac = false,
                 progress,
                 progressGrad = [colors.flame, colors.salmon, colors.fire, colors.sand],
                 text,
                 border = true,
                 horiz = true,
                 bg = true,
                 onPress,
                 highlightIcon = false,
             }) => {
    return (
        <TouchableOpacity
            onPress={!opac ? onPress : () => {
            }}
            key={icon}
            style={apply(
                C.mx2,
                C.itemsCenter,
                (border || highlightIcon) &&
                bordColor(highlightIcon ? colors.fire : colors.paleGrey, !horiz ? 0 : highlightIcon ? 2 : 1),
                bg && C.bgWhite,
                C.justifyBetween,
                C.shadowMd,
                !border && !horiz && C.w18,
                !horiz && C.px1,
                opac && C.opacity60,
                shadow(highlightIcon ? colors.fire : colors.sand, highlightIcon && 8),
                C.radius2,
            )}>
            <View style={[horiz && [C.row, C.px1], small && C.p1, C.itemsCenter]}>
                <EmojiCompat
                    extraStyle={[C.selfCenter, horiz && C.mr1]}
                    sizeNum={small ? 0 : highlightIcon ? 2 : isNarrow ? 0 : 1}
                    emoji={icon}
                />
                <View>
                    <View style={horiz ? [C.row, C.justifyEnd] : C.mb1}>
                        <Text numberOfLines={1} style={apply(highlightIcon ? fonts.title2 : fonts.body2, C.selfCenter)}>
                            {text}
                        </Text>
                        <Text numberOfLines={1}
                              style={apply(fonts.body2, C.textBlueGrey, C.selfCenter, horiz ? C.ml1 : C.mt_1)}>
                            {value}
                        </Text>
                    </View>
                </View>
            </View>
            {progress && (
                <TrackBar
                    height={6}
                    maxWidth={24 * BASE_PIXEL}
                    /*wrapStyle={C.top_1}*/
                    progress={progress}
                    colGrad={progressGrad}
                    colBord={colors.paleGrey}
                />
            )}
        </TouchableOpacity>
    );
};
export const Chat = ({icon, ExtraChat, onPress, text, bg = true, highlightIcon}) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            key={icon}
            style={apply(
                C.ml4,
                C.itemsCenter,
                bordColor(colors.paleGrey, 2),
                C.shadowMd,
                bg && C.bgWhite,
                C.justifyBetween,
                shadow(highlightIcon ? colors.fire : colors.sand, highlightIcon && 8),
                C.radius2,
            )}>
            <View style={[C.row, C.p2, C.itemsCenter, {maxWidth: deviceWidth * 0.65}]}>
                {icon && (
                    <Text
                        style={apply(highlightIcon ? C.font30 : fonts.subtitle, ExtraChat && C.maxwQuarter, C.selfCenter)}>
                        {icon}
                    </Text>
                )}
                <Text style={apply(fonts.body2, C.alignCenter, C.selfCenter, ExtraChat && C.maxw20)}>{text}</Text>
                <Text style={[C.row, C.alignCenter, {maxWidth: deviceWidth * 0.4}]}>{ExtraChat}</Text>
                {/*<CloseButton onPress={() => onPress()} />*/}
            </View>
        </TouchableOpacity>
    );
};
export const Spinner = ({
                            speed = 500,
                            onPress,
                            autoStop = 2000,
                            size = 1,
                            level,
                            pagIcon,
                            wrapStyle,
                            list = planets,
                        }) => {
    const [num, setNum] = useState(0);
    useEffect(() => {
        const interval = setTimeout(() => {
            setNum(num === list.length - 1 ? 0 : num + 1);
        }, speed);
        return () => clearTimeout(interval);
    }, [list.length, num, speed]);

    return (
        <View style={[wrapStyle, C.itemsCenter]}>
            {/**Spinner*/}
            <WrapMoji
                onPress={onPress || (() => {
                })}
                emoji={list[num]}
                sizeNum={size}
                extraStyle={[level && num > level && C.opacity60]}
            />
            {pagIcon && <LevelStar level={num + 1}/>}
        </View>
    );
};

export const LineageList = ({onSet, currProfile = profile}) => {
    const [gender, setGender] = useState(currProfile.gender);
    return Object.entries(characters).map(([key, values], row) => (
        <View style={[C.row, C.itemsCenter]}>
            <FlatList
                scrollEnabled={false}
                style={key === gender && [C.shadowMd, C.radius4, C.p1, bordColor(colors.paleGreyThree, 2)]}
                ListHeaderComponent={
                    <View style={[C.bgPaleGrey, C.radius2, C.m1]}>
                        <EmojiCompat extraStyle={[C.p1, C.selfCenter, C.alignCenter]} sizeNum={0} emoji={key}/>
                    </View>
                }
                keyExtractor={({index}) => index}
                data={values}
                renderItem={({item, index}) => (
                    <TouchableOpacity
                        style={[C.itemsCenter, C.px2, index >= profile.level && C.opacity20]}
                        onPress={() => {
                            onSet && onSet();
                            setGender(key);
                            currProfile.setGender(key);
                        }}>
                        {/*{row === 0 && <Text style={[fonts.body1]}>⭐️ {index + 1}</Text>}*/}
                        {/*<Text style={[C.font10]}>{item}</Text>*/}
                        <EmojiCompat forceText={false} emoji={item}/>
                    </TouchableOpacity>
                )}
            />
        </View>
    ));
};

export const LineageWrapper = ({currProfile = profile}) => (
    <ScrollView contentContainerStyle={[C.itemsCenter, C.pb8]}>
        <View style={C.my6}>
            <EmojiBubble currProfile={currProfile}/>
        </View>
        {/**Name input*/}
        <View style={apply(bgColor(colors.paleGreyTwo), C.itemsCenter, C.radius2, C.row, C.mt6)}>
            <EmojiCompat emoji={'📜'} sizeNum={0}/>

            <TextInput
                onChangeText={(text) => profile.setName(text)}
                /*onSubmitEditing={() => profile.submitMail()}*/
                value={profile.username}
                style={[C.px2, C.pt1, fonts.body2, C.fontBlue]}
                placeholder={'Carlos Smith'}
            />
            {profile.username && (
                <Text onPress={() => profile.setName('')} style={C.mx2}>
                    {profile.loading ? '⏳' : profile.isMailValid ? '✅' : '❌'}
                </Text>
            )}
        </View>
        <View style={[C.row, C.justifyCenter]}>
            <View style={[C.p2, C.mx2, C.radius2, C.bgPaleGreyTwo, C.mt4]}>
                <Text style={[C.alignCenter, fonts.digit2]}>Level</Text>
                {_.range(10).map((item, index) => (
                    <LevelStar level={index + 1} wrapStyle={[C.p1, C.mt1]}/>
                ))}
            </View>
            {/**Lineage*/}
            <LineageList currProfile={currProfile}/>
        </View>
    </ScrollView>
);

export const CharacterInfo = () => (
    <FlatList
        horizontal
        pagingEnabled
        snapToAlignment={'center'}
        snapToInterval={1}
        style={C.my4}
        snapToEnd
        keyExtractor={({index}) => index}
        data={Object.keys(characters)}
        renderItem={({item, index}) => (
            <TouchableOpacity
                style={[
                    C.m2,
                    C.itemsCenter,
                    C.shadowMd,
                    item === profile.gender && bordColor(colors.paleGreyTwo, 2),
                    C.radius24 /*, index > profile.level && C.opacity20*/,
                ]}
                onPress={() => {
                    /*setShowMojis(false);*/
                    profile.setGender(item);
                }}>
                {/*{row === 0 && <Text style={[fonts.body1]}>⭐️ {index + 1}</Text>}*/}
                <Text style={[C.font6]}>{item}</Text>
                {item === profile.gender ? (
                    <Spinner speed={900} list={characters[item]} level={profile.level} iconSize={C.font14}
                             pagIcon={'⭐️'}/>
                ) : (
                    <Text style={[C.font14]}>{characters[item][profile.level]}</Text>
                )}
            </TouchableOpacity>
        )}
    />
);

export const LevelStar = ({level = 1, size = 1, wrapStyle, onPress, icon = '⭐️'}) => (
    <TouchableOpacity onPress={onPress} style={[C.itemsCenter, C.justifyCenter, wrapStyle]}>
        <EmojiCompat emoji={icon} sizeNum={size}/>
        <Text style={apply(C.absolute, C.selfCenter, respImgSize[size].text, size < 1 && {top: 5})}>{level}</Text>
    </TouchableOpacity>
);

export const ImgIcon = ({icon, size = textSize.XL}) =>
    typeof icon === 'string' ? <Text style={size}>{icon}</Text> : <Image source={icon} style={apply(cell.Lg)}/>;
export const Tag = ({text, wrapStyle, icon, horiz = true, col = colors.sand, onPress, children}) => (
    <TouchableOpacity
        onPress={onPress}
        style={apply(
            fonts.subtitle,
            C.m4,
            C.p2,
            horiz && C.row,
            C.itemsCenter,
            shadow(col, 9),
            C.radius2,
            C.px1,
            bordColor(col),
            wrapStyle,
        )}>
        {icon && <EmojiCompat emoji={icon} sizeNum={0}/>}
        <Text onPress={onPress} style={apply(fonts.subtitle, C.mx2)}>
            {text}
        </Text>
        {children}
    </TouchableOpacity>
);

export const Button = ({text, col = colors.blue, onPress, children, disabled = false}) => (
    <TouchableOpacity
        key={text}
        onPress={!disabled ? onPress : undefined}
        style={apply(
            isWide ? C.m4 : C.m2,
            C.itemsCenter,
            C.row,
            disabled && C.opacity30,
            C.shadowMd,
            /*C.bgPaleGreyTwo,*/
            shadow(col, 3),
            C.radius2,
            isWide ? C.p2 : C.p1,
        )}>
        {children}
        {text && (
            <Text onPress={onPress} style={apply(fonts.subtitle, C.textBlue, C.mx2)}>
                {text}
            </Text>
        )}
    </TouchableOpacity>
);
export const Column = ({
                           icon,
                           text,
                           val,
                           toShadow = false,
                           col = colors.black,
                           isBig = false,
                           onPress = () => {
                           },
                           opac,
                       }) => (
    <TouchableOpacity
        key={text}
        onPress={!opac ? onPress : () => {
        }}
        style={apply(opac && C.opacity60, C.py1, C.radius2, C.itemsCenter, C.justifyBetween, toShadow && shadow(col, 4))}>
        <EmojiCompat emoji={icon} sizeNum={isBig ? 1 : 0} extraStyle={C.maxh1}/>
        <Text style={apply(fonts.title2)}>{text}</Text>
        <Text style={apply(isBig ? [fonts.subtitleSmall] : fonts.body2, textColor(col))}>{val}</Text>
    </TouchableOpacity>
);

export const GInput = ({placeHolder = 'Julius Caesar', onSet = (text) => profile.setName(text)}) => {
    const [text, setText] = useState('');
    const handleText = () => onSet() || setText(text);
    return (<Button>
        <EmojiCompat emoji={'📜'} sizeNum={0}/>

        <TextInput
            onChangeText={handleText}
            /*onSubmitEditing={() => profile.submitMail()}*/
            value={profile.username}
            style={[C.px2, C.ml2, C.p1, fonts.body2]}
            placeholder={placeHolder}
        />
        {profile.username && (
            <Text onPress={() => profile.setName('')} style={C.mx2}>
                {profile.loading ? '⏳' : profile.isMailValid ? '🏹' : '❌'}
            </Text>
        )}
    </Button>);
};

export const Badge = ({
                          text, left, bottom, top, right, isBig = false, onPress = () => {
    },
                      }) => (
    <TouchableOpacity
        key={text}
        onPress={onPress}
        style={apply(
            badgeWrapperSm,
            /*C.bgWhite,
              C.radius12,
            C.shadowLg,
            C.itemsCenter,*/
            isBig ? cell.Sm : [C.w5, C.h5],
            C.absolute,
            top && (isBig ? C.top_5 : C.top0),
            left && (isBig ? C.left_5 : C.left0),
            right && (isBig ? C.right_5 : C.right0),
            bottom && (isBig ? C.bottom_5 : C.bottom0),
            shadow(colors.water, 3),
        )}>
        <Text
            style={[isBig ? textSize.L : textSize.Sm, !isWeb && C.top_1, !isWeb && C.shadowMd, shadow(colors.water, 3)]}>
            {text}
        </Text>
    </TouchableOpacity>
);

export const VertInfo = ({
                             text, val, descr, isBig = false, onPress = () => {
    },
                         }) => (
    <TouchableOpacity onPress={onPress} key={text} style={apply(C.mx1, C.itemsCenter)}>
        <Text style={apply(isBig ? textSize.L : textSize.Sm, C.my2, shadow(colors.water))}>{text}</Text>
        {descr && <Tag col={colors.water} text={descr + ' ' + val}/>}
        {/*<Tag text={text} />*/}
        {/*<Text style={apply(isBig ? textSize.Md : textSize.Xs)}>{val}</Text>*/}
    </TouchableOpacity>
);
export const StatsMap = ({currProfile, showLevel = true, showPopulation = false, children}) => (
    <View style={apply(C.row, C.m4, C.selfCenter, C.itemsCenter)}>
        <Box
            icon={'🔥'}
            progress={currProfile.score / currProfile.remainingScore}
            text={currProfile.scoreForm}
            border={colors.fire}
            value={'/ ' + currProfile.remainingScoreForm}
        />
        {showLevel && <Box icon={'⭐️'} text={currProfile.level} value={'/10'}/>}
        {showPopulation &&
            <Box icon={'👨‍👩‍👧‍👦'} text={currProfile.currPopulation} value={'/' + currProfile.maxPopulation}/>}
        {children}
    </View>
);

export const ResourcesMap = ({list = profile.resources, withBord = false, highlightIcon}) => (
    <View style={apply(C.row, C.selfCenter, C.radius2, C.my4)}>
        {Object.entries(list)
            .filter(([icon, value]) => !!value)
            .map(([icon, value]) => (
                <Box icon={icon} small text={numFormat(value)} border={withBord}
                     highlightIcon={highlightIcon === icon}/>
            ))}
    </View>
);

export const CloseButton = ({navigate, onPress = () => safeBack(navigate), onPressSound, sound = true, wrapStyle}) => (
    <TouchableOpacity
        style={apply(
            C.absolute,
            C.shadowMd,
            isNarrow ? C.right2 : C.right4,
            isIOS ? C.top20 : C.top12,
            textSize.Md,
            C.row,
            C.px2,
            C.bgWhite,
            C.radius8,
            shadow(colors.salmon, 2),
            wrapStyle,
        )}>
        {onPressSound && <WrapMoji emoji={sound ? '🔊' : '🔇'} onPress={onPressSound} sizeNum={1}/>}
        {onPress && <WrapMoji emoji={'✖️'} onPress={onPress} sizeNum={1}/>}
    </TouchableOpacity>
);

/*const Tabs = ({tabs = Object.values(battleModes), setMode, currentTab = 0}) => (
  <View style={apply(C.row, C.mt4, C.justifySpaced, C.itemsCenter)}>
    {tabs.map(({title, Comp}, index) => (
      <TouchableOpacity
        onPress={() => setMode(index)}
        style={apply(
          C.flex,
          C.mx1,
          C.p2,
          topBorder,
          bgColor(index !== currentTab ? colors.black12 : colors.white),
          darkShadow,
          //bordColor(index === currentTab ? colors.wood : colors.black40, 1),
        )}>
        <Text numberOfLines={1} style={apply(fonts.subtitle)}>
          {title}
        </Text>
      </TouchableOpacity>
    ))}
  </View>
);*/

export default Box;
