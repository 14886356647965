import {useRoute} from '@react-navigation/core';
import C, {apply} from 'consistencss';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {FlatList, ScrollView, Text, TextInput, TouchableOpacity, View} from 'react-native';
import useSound from 'use-sound';
import {profile} from '../App';
import Box, {StatsMap} from '../comp/Box';
import {CardModal} from '../comp/Card';
import Cell from '../comp/Cell';
import {EmojiCompat} from '../comp/WrapMoji';
import {bgColor, bordColor, colors, isIOS, isWeb, isWide, shadow, sounds} from '../design/gStyles';
import Game from '../layouts/Game';
import {fonts} from '../design/fonts';

import {CHESS_SIZE} from '../stores/boardStore';
import {COMBO_SIZE, INIT_MOVES} from '../stores/HarvestStore';
import {comboRef} from '../stores/profileStore';
import {LevelSelector, levelsMap} from '../utils/levels';
import {elements} from '../utils/sets';
import {blinkDelay, fireAlert} from '../utils/utils';

const HitMoji = observer(() => {
    const route = useRoute();
    const initLevel = route.params?.level || 1;
    const practiceMode = route.params?.practiceMode || true;
    const harvestList = route.params?.harvestList || elements;
    const isResource = route.params?.isResource || false;

    const {currHarvest} = profile;
    const {lastHarvest, sortCollected, currentBoard, bombs, bombIcon, moves, shuffles} = currHarvest;

    const [shouldBlink, setShouldBlink] = useState(false);
    const [bombMode, setBombMode] = useState(false);

    /*const navigation = useNavigation();*/
    const {soundsOn, volume} = profile;

    const [level, setLevel] = useState(initLevel);
    const currSet = levelsMap.hit[level];

    /**Sound*/
    const [playbackRate, setPlaybackRate] = useState(0.75);
    const [playOK] = useSound(sounds.combo2, {sprite: playbackRate, volume});
    const [playGlass] = useSound(sounds.glass, {volume});
    const [playBg] = useSound(sounds.bg, {volume});
    //const [playLevel] = useSound(sounds.combo, {volume});
    const [playWrong] = useSound(sounds.wrong, {volume});

    useEffect(() => soundsOn && playBg(), []);

    useEffect(() => currHarvest.shuffle(level), [level]);

    const nextLevelMoves = level + 3;
    const levelUp = sortCollected.length >= nextLevelMoves;
    const blockingEvent = moves <= 0 || shouldBlink || levelUp;

    const tryCollect = (index, icon) => {
        let comboLength = currentBoard.matchRecursiveAdjIds.length + 2;
        currentBoard.setCurrent(index);
        /**Decrease the moves even if not matching 😜*/
        currHarvest.addMoves();
        if (currentBoard.validMatch) {
            /**Which is the collected resource to highLight*/
            setPlaybackRate(comboLength / 10 /*playbackRate + 0.1*/);
            if (soundsOn) {
                playOK();
            }
            currHarvest.onCollect(icon, comboLength);
            blinkDelay({
                setBlink: setShouldBlink,
                onAfter: () => currentBoard.reassignCells({currIcon: icon, newIcons: currSet}),
                time: comboLength > COMBO_SIZE ? 800 : 400,
            });
        } else {
            soundsOn && playWrong();
        }
    };

    return (
        <ScrollView contentContainerStyle={[C.itemsCenter, C.minhFull, isIOS && C.py6]}>
            <View style={apply(C.row, C.itemsCenter)}>
                <View style={C.wFull}>
                    <StatsMap currProfile={profile} showLevel={false}>
                        {/**Remaining Moves*/}
                        <Box
                            icon={'💣'}
                            text={moves}
                            progressGrad={[colors.blue, colors.grass]}
                            progress={moves >= INIT_MOVES * level ? 1 : moves / (INIT_MOVES * level)}
                            highlightIcon={moves < 5}
                            value={'moves'}
                        />
                    </StatsMap>
                </View>
            </View>

            <View style={[C.alignCenter]}>
                <FlatList
                    horizontal
                    initialScrollIndex={0}
                    style={C.py1}
                    keyExtractor={({item, index}) => index}
                    data={sortCollected}
                    renderItem={({item}) => (
                        <Box
                            key={item[0]}
                            horiz={sortCollected.length < 5}
                            highlightIcon={shouldBlink && item[0] === currHarvest.lastHarvest.icon}
                            icon={item[0]}
                            text={item[1]}
                        />
                    )}
                />
            </View>

            {currentBoard.cells && (
                <FlatList
                    data={currentBoard.cells}
                    keyExtractor={({id}) => id}
                    numColumns={CHESS_SIZE}
                    style={apply(C.my6, blockingEvent && C.opacity25, {flexGrow: 0})}
                    contentContainerStyle={[isWide && C.p2]}
                    extraData={currentBoard.currCellId}
                    scrollEnabled={false}
                    renderItem={({item, index}) => {
                        const highlight = (shouldBlink && currentBoard.iconMatches(index)) || (bombMode && item.icon === bombIcon);
                        return (
                            <Cell
                                key={item.id}
                                /*size={cell.Md}
                                  iconSize={textSize.L}*/
                                withFlex={!isWeb}
                                withTransp={false}
                                cShadow={shadow(highlight ? colors.sand : isWeb ? colors.white : colors.blue, highlight ? 15 : 3)}
                                cBord={bordColor(highlight ? colors.fire : colors.water, isIOS ? /*highlight ? 3 :*/ 0.5 : 1)}
                                bg={!isIOS /*&& !isWeb*/ && highlight ? colors.fire + '60' : colors.white}
                                wrapStyle={C.radius3}
                                index={index}
                                onPress={() => {
                                    if (moves > 0) {
                                        tryCollect(index, item.icon);
                                    } else {
                                        fireAlert(
                                            '🕳 is not a Resource',
                                            '⚠️ You will miss moves 💥 if you press resources with less than 3 matching cells arround',
                                        );
                                    }
                                }} //currentBoard.setCurrent(index)}
                                item={item}
                            />
                        );
                    }}
                />
            )}
            {/**Overlapping Info Pannel*/}
            {blockingEvent && (
                <CardModal
                    Content={
                        levelUp ? (
                            <View>
                                <EmojiCompat emoji={'🎉'}/>
                                {profile.score > level * 10 && <Text style={[fonts.mainTitle]}>New record!</Text>}
                                {<Text style={fonts.title1}>⭐️ Level {level + 1}</Text>}
                                {/*
                  {strike > 0 && <Text style={fonts.title1}>💥 Strike {strike}</Text>}
*/}
                                <Text style={[fonts.title2]}>
                                    {_.range(lastHarvest.size).map((item) => (
                                        <EmojiCompat emoji={lastHarvest.icon}/>
                                    ))}
                                </Text>
                                <Text style={[fonts.subtitle, C.textFlame]}>
                                    + {profile.score} <EmojiCompat emoji={'🔥'} sizeNum={1}/>
                                </Text>
                                {lastHarvest.isCombo && (
                                    <Text style={fonts.subtitle} /*onPress={() => currMemoji.shuffle()}*/>
                                        <EmojiCompat emoji={'💣'} sizeNum={1}/> + {lastHarvest.size - COMBO_SIZE} moves
                                    </Text>
                                )}
                                <TouchableOpacity
                                    style={[C.row, C.my2, C.p1, C.radius2, C.itemsCenter, C.selfCenter, C.shadowMd]}
                                    onPress={() => {
                                        currHarvest.reset(level);
                                        //setLevel(level + 1);
                                    }}>
                                    <EmojiCompat emoji={'💪'} sizeNum={1}/>
                                    <Text style={[fonts.title2, C.ml2, C.textBlue]}>Play Level {level + 1}</Text>
                                </TouchableOpacity>
                            </View>
                        ) : shouldBlink ? (
                            <View>
                                {/*{profile.level !== level && <Text style={fonts.title1}>⭐️ Level {profile.level}</Text>}*/}
                                {lastHarvest.isCombo && <Text style={fonts.title1}>💥 Strike {lastHarvest.size}</Text>}
                                <Text style={[fonts.title2]}>
                                    {_.range(lastHarvest.size).map((item) => (
                                        <EmojiCompat emoji={lastHarvest.icon}/>
                                    ))}
                                </Text>
                                <Text style={[fonts.subtitle, C.textFlame]}>
                                    + {lastHarvest.score} <EmojiCompat emoji={'🔥'} sizeNum={1}/>
                                </Text>
                                {lastHarvest.isCombo && (
                                    <Text style={fonts.subtitle} /*onPress={() => currHarvest.shuffle()}*/>
                                        <EmojiCompat emoji={'💣'} sizeNum={1}/> + {lastHarvest.size - COMBO_SIZE} moves
                                    </Text>
                                )}
                            </View>
                        ) : (
                            <View>
                                <Text style={[fonts.title1, C.textFlame]}>💥 Game Over... 😭</Text>
                                <View style={[C.row, C.my2, C.itemsCenter, C.selfCenter]}>
                                    <EmojiCompat emoji={'🔥'} sizeNum={0}/>
                                    <Text style={[fonts.body1, C.textBlueGrey, C.mx2]}>Score</Text>
                                    <Text style={[fonts.subtitle]}>
                                        {currHarvest.score} / {profile.score}
                                    </Text>
                                </View>
                                <View style={[C.row, C.my2, C.itemsCenter, C.selfCenter]}>
                                    <EmojiCompat emoji={'⭐️'} sizeNum={0}/>
                                    <Text style={[fonts.body1, C.textBlueGrey, C.mx2]}>Level</Text>
                                    <Text style={[fonts.subtitle]}>{profile.level}</Text>
                                </View>

                                <Text style={[fonts.title2, C.textBlue, C.mt2]}>Save progress?</Text>
                                <View style={apply(bgColor(colors.paleGreyTwo), C.itemsCenter, C.radius2, C.row, C.m4)}>
                                    <EmojiCompat emoji={'📜'} sizeNum={0}/>

                                    <TextInput
                                        onChangeText={(text) => profile.setName(text)}
                                        /*onSubmitEditing={() => profile.submitMail()}*/
                                        value={profile.username}
                                        style={[C.px2, C.pt1, fonts.body2, C.textBlue]}
                                        placeholder={'Carlos Smith'}
                                    />
                                    {profile.username && (
                                        <Text onPress={() => profile.setName('')} style={C.mx2}>
                                            {profile.loading ? '⏳' : profile.isMailValid ? '✅' : '❌'}
                                        </Text>
                                    )}
                                </View>

                                <TouchableOpacity
                                    style={[C.row, C.my2, C.p1, C.radius2, C.itemsCenter, C.selfCenter, C.shadowMd]}
                                    onPress={() => currHarvest.shuffle(level /*harvestList.set, isResource*/)}>
                                    <EmojiCompat emoji={'🔄'} sizeNum={1}/>
                                    <Text style={[fonts.title2, C.ml2, C.textBlue]}>Play Again</Text>
                                </TouchableOpacity>
                            </View>
                        )
                    }
                />
            )}

            {/**Bonus*/}
            {practiceMode && (
                <View style={apply(C.row, C.mb2)}>
                    {/**Shuffle*/}
                    <Box
                        isBig
                        icon={'🔄'}
                        col={colors.blueGrey}
                        value={'Shuffle'}
                        opac={shuffles <= 0}
                        onPress={() => currHarvest.shuffle()}
                        text={shuffles}
                    />
                    {/**Bombs*/}
                    <Box
                        col={colors.blueGrey}
                        isBig
                        opac={bombs <= 0}
                        onPress={() => {
                            if (bombs > 0) {
                                soundsOn && playGlass();
                                blinkDelay({setBlink: setBombMode, onAfter: () => currHarvest.bomb(), time: 1000});
                            }
                        }}
                        icon={bombIcon}
                        toShadow
                        value={'Destroy'}
                        text={bombs}
                    />
                </View>
            )}

            <LevelSelector currLevel={level} levels={levelsMap.hit} onSet={(level) => setLevel(level - 1)}/>
        </ScrollView>
    );
});

export default () => (
    <Game
        GameComp={<HitMoji/>}
        scoreRef={comboRef}
        emoji={'💥'}
        title={'HitMoji'}
        subtitle={'Match icons properly...'}
    />
);
