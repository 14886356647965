import C from 'consistencss';
import React from 'react';
import {isIOS, isMacOs, osVersion} from 'react-device-detect';
import {Image, Text, TouchableOpacity} from 'react-native';
import {bordColor, colors} from '../design/gStyles';
import {emojiPNG, respImgSize} from '../utils/utils';

const osMajor = osVersion.split('.')[0];
const MAC_OS_VERSION = 11;
const IOS_VERSION = 14;
export const hasDecentIcons = (isIOS && osMajor >= IOS_VERSION) || (isMacOs && osMajor >= MAC_OS_VERSION);
export const EmojiCompatImg = ({emoji, code, sizeNum = 2, extraStyle}) => {
    const {img, fallbackImg} = emojiPNG({emoji, code});
    /*const [currImg, setCurrImg] = useState(img);
    useMemo(() => {}, [currImg, emoji]);*/
    return (
        <Image
            /*onError={() => setCurrImg(fallbackImg)}*/
            defaultSource={{uri: fallbackImg}}
            style={[respImgSize[sizeNum].img, C.my1, extraStyle]}
            source={{uri: img}}
        />
    );
};
export const EmojiCompat = ({emoji, code, sizeNum = 2, extraStyle, forceText = true}) =>
    /* hasDecentIcons || forceText ?*/ (
    <Text style={[respImgSize[sizeNum].font, extraStyle]}>{emoji}</Text>
); /*: (
        <EmojiCompatImg emoji={emoji} sizeNum={sizeNum} code={code} extraStyle={extraStyle}/>
    );*/

export const WrapMoji = ({wrapStyle, circBg, extraStyle, onPress, emoji, sizeNum = 2, forceText = false}) => {
    const circWrap = circBg && [
        C.bgWhite,
        bordColor(colors.paleGreyTwo, 2),
        C.shadowMd,
        C.itemsCenter,
        C.contentCenter,
        sizeNum === 4 ? C.px2 : C.px1,
        C.m2,
        C.radius16,
        /*respImgSize[sizeNum].wrap,*/
    ];
    return (
        <TouchableOpacity style={[circWrap, wrapStyle]} onPress={onPress ? onPress : undefined}>
            <EmojiCompat emoji={emoji} extraStyle={extraStyle} sizeNum={sizeNum} forceText={forceText}/>
        </TouchableOpacity>
    );
};
