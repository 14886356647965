import {useRoute} from '@react-navigation/core';
import C, {apply} from 'consistencss';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {FlatList, ScrollView, Text, TextInput, TouchableOpacity, View} from 'react-native';
import useSound from 'use-sound';
import {profile} from '../App';
import Box, {StatsMap} from '../comp/Box';
import {CardModal} from '../comp/Card';
import Cell from '../comp/Cell';
import {EmojiCompat} from '../comp/WrapMoji';
import {bgColor, bordColor, colors, deviceWidth, isIOS, isWeb, isWide, shadow, sounds} from '../design/gStyles';
import Game from '../layouts/Game';
import {fonts} from '../design/fonts';

import {COMBO_SIZE} from '../stores/HarvestStore';
import {findMojiRef} from '../stores/profileStore';
import {LevelSelector, levelsMap} from '../utils/levels';
import {allIcons} from '../utils/sets';
import {blinkDelay, pickRandom} from '../utils/utils';

const FindMoji = observer(() => {
    const route = useRoute();
    const practiceMode = route.params?.practiceMode || true;

    const {currFind, volume} = profile;
    const {lastHarvest, collected, shuffles, currLevel, currentBoard, maxCombo, strike, hints, moves} = currFind;

    const [shouldBlink, setShouldBlink] = useState(false);
    const [level, setLevel] = useState(1);
    const [target, setTarget] = useState('🍉');

    /**Sound*/
    const [playOK] = useSound(sounds.combo2, {volume});
    const [playWrong] = useSound(sounds.wrong, {volume});

    const showHelp = () =>
        blinkDelay({
            setBlink: setShouldBlink,
            time: 1000,
        });

    useEffect(() => {
        currFind.shuffle(level, target);
    }, [target, level]);

    const nextLevelMoves = level + 3;
    const levelUp = collected.length >= nextLevelMoves;
    const blockingEvent = /*showInfo ||*/ moves <= 0 || /*shouldBlink ||*/ levelUp;

    return (
        <ScrollView contentContainerStyle={[C.itemsCenter, C.minhFull, C.wFull, isIOS && C.py6]}>
            {/**Controls*/}
            <View style={apply(C.row, C.itemsCenter)}>
                <View style={C.wFull}>
                    <StatsMap currProfile={profile} showLevel={false}>
                        {/**Remaining Moves*/}
                        <Box
                            icon={'🎯'}
                            text={nextLevelMoves - collected.length}
                            progressGrad={[colors.blue, colors.grass]}
                            progress={(collected.length + 1) / nextLevelMoves}
                            highlightIcon={moves < 5}
                            value={'missing'}
                        />
                    </StatsMap>
                </View>
            </View>

            {/**History*/}
            <View style={[C.alignCenter, C.row, C.maxwFull]}>
                <FlatList
                    horizontal
                    style={[C.py1, {maxWidth: deviceWidth * 0.8}]}
                    keyExtractor={({item, index}) => index}
                    data={collected}
                    extraData={target}
                    renderItem={({item}, index) => (
                        <Box
                            key={item[0]}
                            highlightIcon={item === target}
                            horiz={collected.length < 5}
                            /*highlightIcon={shouldBlink && item[0] === currMemoji.lastHarvest.icon}*/
                            icon={item}
                            text={collected.length < 1 && 'Find'}
                        />
                    )}
                />
                <Box highlightIcon icon={target} text={collected.length < 1 && 'Find'}/>
            </View>

            {/**Board*/}
            {currentBoard.cells && (
                <FlatList
                    data={currentBoard.cells}
                    keyExtractor={({id}) => id}
                    numColumns={Math.floor(deviceWidth / (32 + (isWide ? 5 : -5))) - 8} //level <= 4 ? 4 : Math.round(level / 2)}
                    style={apply(C.my2, C.p4, C.ml_4, blockingEvent && C.opacity25, {flexGrow: 0})}
                    contentContainerStyle={[isWide && C.p2]}
                    extraData={currentBoard.currCell.active}
                    scrollEnabled={false}
                    renderItem={({item, index}) => (
                        <Cell
                            key={item.id}
                            size={1}
                            withFlex={!isWeb}
                            withTransp={false}
                            cShadow={shadow(isWeb ? colors.white : colors.blue, 3)}
                            cBord={bordColor(colors.water, isIOS ? 0.5 : 1)}
                            bg={!isIOS && shouldBlink && item.icon === target ? colors.lip + '60' : colors.white}
                            wrapStyle={[
                                C.radius2,
                                {
                                    left: Math.random() * level * 2,
                                    top: Math.random() * level * 2,
                                },
                            ]}
                            index={index}
                            onPress={() => {
                                if (item.icon === target) {
                                    currentBoard.setCell({
                                        icon: pickRandom(levelsMap.find[level], 1),
                                        overwrite: true,
                                        id: item.id,
                                    });

                                    setTarget(pickRandom(allIcons, 1));
                                    currFind.addFind(target, level);
                                    playOK();
                                } else {
                                    playWrong();
                                }
                            }}
                            item={item}
                            icon={item.icon}
                        />
                    )}
                />
            )}
            {/**Bonus*/}
            {practiceMode && !blockingEvent && (
                <View style={apply(C.row, C.mb2, C.itemsCenter)}>
                    {/**Shuffle*/}
                    <Box
                        isBig
                        icon={'🔄'}
                        col={colors.blueGrey}
                        value={'Shuffle'}
                        opac={shuffles <= 0}
                        onPress={
                            shuffles > 0 &&
                            (() => {
                                currFind.setShuffles();
                                currFind.shuffle();
                            })
                        }
                        text={shuffles}
                    />

                    {/**Hint*/}
                    <Box
                        isBig
                        icon={'👀'}
                        col={colors.blueGrey}
                        value={'Hint'}
                        opac={hints <= 0}
                        onPress={
                            hints > 0 &&
                            (() => {
                                showHelp();
                                currFind.addHint();
                            })
                        }
                        text={hints}
                    />
                </View>
            )}

            <LevelSelector
                currLevel={level}
                levels={levelsMap.find}
                onSet={(level) => {
                    setLevel(level - 1);
                }}
            />

            {/**Overlapping Info Pannel*/}
            {blockingEvent && (
                <CardModal
                    Content={
                        levelUp ? (
                            <View>
                                <EmojiCompat emoji={'🎉'}/>
                                {profile.score > level * 10 && <Text style={[fonts.mainTitle]}>New record!</Text>}
                                {<Text style={fonts.title1}>⭐️ Level {level + 1}</Text>}
                                {strike > 0 && <Text style={fonts.title1}>💥 Strike {strike}</Text>}
                                <Text style={[fonts.title2]}>
                                    {_.range(lastHarvest.size).map((item) => (
                                        <EmojiCompat emoji={lastHarvest.icon}/>
                                    ))}
                                </Text>
                                <Text style={[fonts.subtitle, C.textFlame]}>
                                    + {profile.score} <EmojiCompat emoji={'🔥'} sizeNum={1}/>
                                </Text>
                                {lastHarvest.isCombo && (
                                    <Text style={fonts.subtitle} /*onPress={() => currMemoji.shuffle()}*/>
                                        <EmojiCompat emoji={'💣'} sizeNum={1}/> + {lastHarvest.size - COMBO_SIZE} moves
                                    </Text>
                                )}
                                <TouchableOpacity
                                    style={[C.row, C.my2, C.p1, C.radius2, C.itemsCenter, C.selfCenter, C.shadowMd]}
                                    onPress={() => {
                                        currFind.cleanCollect(level);
                                        setLevel(level + 1);
                                    }}>
                                    <EmojiCompat emoji={'💪'} sizeNum={1}/>
                                    <Text style={[fonts.title2, C.ml2, C.textBlue]}>Play Level {level + 1}</Text>
                                </TouchableOpacity>
                            </View>
                        ) : (
                            <View>
                                <Text style={[fonts.title1, C.textFlame]}>💥 Game Over... 😭</Text>
                                <View style={[C.row, C.my2, C.itemsCenter, C.selfCenter]}>
                                    <EmojiCompat emoji={'🔥'} sizeNum={0}/>
                                    <Text style={[fonts.body1, C.textBlueGrey, C.mx2]}>Score</Text>
                                    <Text style={[fonts.subtitle]}>
                                        {currFind.score} / {profile.score}
                                    </Text>
                                </View>
                                <View style={[C.row, C.my2, C.itemsCenter, C.selfCenter]}>
                                    <EmojiCompat emoji={'⭐️'} sizeNum={0}/>
                                    <Text style={[fonts.body1, C.textBlueGrey, C.mx2]}>Level</Text>
                                    <Text style={[fonts.subtitle]}>{profile.level}</Text>
                                </View>

                                <Text style={[fonts.title2, C.textBlue, C.mt2]}>Save progress?</Text>
                                <View style={apply(bgColor(colors.paleGreyTwo), C.itemsCenter, C.radius2, C.row, C.m4)}>
                                    <EmojiCompat emoji={'📜'} sizeNum={0}/>

                                    <TextInput
                                        onChangeText={(text) => profile.setName(text)}
                                        /*onSubmitEditing={() => profile.submitMail()}*/
                                        value={profile.username}
                                        style={[C.px2, C.pt1, fonts.body2, C.textBlue]}
                                        placeholder={'Carlos Smith'}
                                    />
                                    {profile.username && (
                                        <Text onPress={() => profile.setName('')} style={C.mx2}>
                                            {profile.loading ? '⏳' : profile.isMailValid ? '✅' : '❌'}
                                        </Text>
                                    )}
                                </View>

                                <TouchableOpacity
                                    style={[C.row, C.my2, C.p1, C.radius2, C.itemsCenter, C.selfCenter, C.shadowMd]}
                                    onPress={() => {
                                        setLevel(1);
                                        /*showHelp();*/
                                    }}>
                                    <EmojiCompat emoji={'🔄'} sizeNum={1}/>
                                    <Text style={[fonts.title2, C.ml2, C.textBlue]}>Play Again</Text>
                                </TouchableOpacity>
                            </View>
                        )
                    }
                />
            )}
        </ScrollView>
    );
});

export default () => (
    <Game
        GameComp={<FindMoji/>}
        scoreRef={findMojiRef}
        emoji={'👀'}
        title={'FindMoji'}
        subtitle={'Find the right emoji as fast as you can...'}
    />
);
