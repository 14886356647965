import C, {apply} from 'consistencss';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FlatList, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import Modal from 'react-native-modal';
import {profile} from '../App';
import {deviceHeight, deviceWidth, isIOS, isSmall, isWeb, isWide} from '../design/gStyles';
import {font, fonts} from '../design/fonts';
import {buildingsMap, unitsMap} from '../utils/sets';
import AddCard from './AddCard';
import {Badge} from './Box';
import EmojiSelector, {Categories} from './EmojiSelector';
import {WrapMoji} from './WrapMoji';

export const CircWrapper = ({big = false, badge = false, icon, onPress}) => (
    <WrapMoji circBg onPress={onPress} emoji={icon} sizeNum={big ? 4 : 2}/>
);

export const CollapsableCards = ({onPressProfile, icon = '🧙‍', onPressUnits, onPressSettings, onPressBuildings}) => {
    const [showRecruit, setShowRecruit] = useState(false);
    const [showBuilds, setShowBuilds] = useState(false);
    const [showIcons, setShowIcons] = useState(false);
    return (
        <>
            <TouchableOpacity
                onPress={() => onPressProfile(!showRecruit)}
                style={[
                    C.absolute,
                    C.itemsCenter,
                    C.left0,
                    C.row,
                    !isWeb && {top: deviceHeight * /*isSmall ? 0.4 :*/ (isWide ? 0.82 : 0.85)},
                ]}>
                <CircWrapper
                    icon={profile.emoji}
                    big
                    badge={!showIcons}
                    onPress={() => {
                        onPressProfile();
                        setShowIcons(!showIcons);
                    }}
                />
                <Badge text={profile.flag} bottom right isBig/>
                {showIcons && (
                    <>
                        {onPressBuildings && <CircWrapper icon={'🏰'} onPress={() => profile.modal.showModal()}/>}
                        {onPressUnits && <CircWrapper icon={'🥷🏻'} onPress={onPressUnits}/>}
                        {onPressSettings && <CircWrapper icon={'📜'} onPress={onPressSettings}/>}
                    </>
                )}
            </TouchableOpacity>

            {/**Flag modal*/}
            <FullModal modal={profile.flagModal}>
                <EmojiSelector
                    showHistory={false}
                    showTabs={false}
                    showSectionTitles={false}
                    columns={Math.floor((deviceWidth * 0.8) / 50)}
                    category={Categories.flags}
                    onEmojiSelected={(emoji) => {
                        profile.setFlag(emoji);
                        profile.flagModal.closeModal();
                    }}
                />
            </FullModal>

            {/*<FullModal modal={profile.modal}>
        <>
          <Text>rehore </Text>
          <FlatList
            data={Object.keys(showRecruit ? unitsMap : buildingsMap)}
            keyExtractor={(item) => item.key}
            horizontal
            extraData={profile.level}
            renderItem={({item, index}) => (
              <AddCard
                list={buildingsMap}
                isBuilding
                index={index}
                item={item}
                onSet={() => {
                  showRecruit ? onPressUnits(item) : onPressBuildings(item);
                  showRecruit ? setShowRecruit(false) : setShowBuilds(false);
                }}
              />
            )}
          />
        </>
      </FullModal>*/}

            {showIcons && (showRecruit || showBuilds) && (
                <FlatList
                    data={Object.keys(showRecruit ? unitsMap : buildingsMap)}
                    style={apply(C.top4, C.flex, {top: deviceHeight * (isSmall ? 0.4 : 0.5)})}
                    keyExtractor={(item) => item.key}
                    horizontal
                    extraData={profile.level}
                    renderItem={({item, index}) => (
                        <AddCard
                            list={buildingsMap}
                            isBuilding
                            index={index}
                            item={item}
                            onSet={() => {
                                showRecruit ? onPressUnits(item) : onPressBuildings(item);
                                showRecruit ? setShowRecruit(false) : setShowBuilds(false);
                            }}
                        />
                    )}
                />
            )}
        </>
    );
};

export const CardCarrousel = observer(({icon = '🏰', name, list, onSet, modal, otherModal}) => {
    /*const [showCards, setShowCards] = useState(false);*/
    const {level} = profile;
    const showBig = modal?.show || false;
    return (
        <>
            {list && modal.show && (
                <FlatList
                    data={Object.entries(list)}
                    style={apply(isWide ? C.top_40 : C.top_36, C.flex, C.left_30, C.absolute, {width: deviceWidth * 0.98})}
                    keyExtractor={(item) => item.key}
                    horizontal
                    extraData={level}
                    renderItem={({item, index}) => (
                        <AddCard
                            stats={item[1]}
                            isBuilding
                            index={index}
                            unitIcon={item[0]}
                            onSet={() => {
                                onSet(item[0]);
                                modal.closeModal();
                            }}
                        />
                    )}
                />
            )}
            <View>
                <CircWrapper
                    icon={icon}
                    big={showBig}
                    onPress={
                        modal
                            ? () => {
                                modal.toggleModal();
                                otherModal.closeModal();
                            }
                            : onSet
                    }
                />
                {name && !showBig && (
                    <Text style={[C.absolute, C.selfCenter, C.top18, fonts.subtitle, C.textWhite]}>{name}</Text>
                )}
            </View>
        </>
    );
});

export const FullModal = observer(({
                                       modal, visible = false, afterClose = () => {
    }, isFull = true, children, title,
                                   }) => {
    const [showModal, setShowModal] = useState(modal ? modal.show : visible);
    const closeModal = () => {
        if (modal) {
            modal.closeModal();
        }
        setShowModal(false);
        afterClose && afterClose();
    };

    return (
        <Modal
            onDismiss={() => closeModal()}
            onBackButtonPress={() => closeModal()}
            isVisible={showModal}
            visible={showModal}
            transparent /*={!(isIOS && isFull)}*/
            onSwipeComplete={() => closeModal()}
            presentationStyle={isIOS && isFull && 'pageSheet'}
            onRequestClose={() => closeModal()}
            onBackdropPress={() => closeModal()}>
            <View
                style={[
                    C.bgWhite,
                    C.radius6,
                    isWeb && isFull && C.minhHalf,
                    isWide ? C.m_5 : C.mx_2,
                    isFull && C.flex,
                    isFull && {height: deviceHeight * (isFull ? 0.9 : 0.6)},
                ]}>
                {/**Header*/}
                <View style={[C.row, C.bgPaleGrey, C.itemsCenter, C.radius2, C.p3, C.justifyBetween]}>
                    {title && <Text style={apply(font.h2, C.alignCenter, C.flex, C.capitalize)}>{title}</Text>}
                    <Text onPress={() => closeModal()} style={apply(fonts.title1, C.absolute, C.right4, C.top4)}>
                        X
                    </Text>
                </View>
                {/*<CloseButton onPress={() => setShowModal(false)} />*/}
                {isWeb ? <ScrollView contentContainerStyle={[C.itemsCenter]}>{children}</ScrollView> : children}
            </View>
        </Modal>
    );
});
