import C from 'consistencss';
import React, {useState} from 'react';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';
import {Pressable} from 'react-native-web-hover';
import {profile} from '../App';
import {Button, LevelStar, Spinner} from '../comp/Box';
import {EmojiCompat} from '../comp/WrapMoji';
import {bordColor, colors, deviceWidth, hover, isWide} from '../design/gStyles';
import {fonts} from '../design/fonts';
import {useNavigation} from '@react-navigation/native';

import {screens} from '../routes';
import {
    activities,
    clockhours,
    clothing,
    cloudymood,
    colorful,
    colorfullbooks,
    covid,
    cuteAnimals,
    dayNight,
    drinks,
    elements,
    field,
    filmset,
    fruits,
    handsigns,
    holidays,
    horoscope,
    hugeAnimals,
    insects,
    invert,
    organs,
    party,
    pokerset,
    professions,
    randomhats,
    reptiles,
    rodents,
    roughAnimals,
    shoes,
    skyelements,
    summer,
    sweet,
    unitsMap,
    vegetables,
    waterAnimals,
    winter,
} from './sets';
import {respImgSize} from './utils';

export const levelsMap = {
    chess: Object.keys(unitsMap),
    hit: [
        skyelements,
        elements,
        covid,
        clockhours,
        fruits,
        organs,
        drinks,
        horoscope,
        vegetables,
        field,
        waterAnimals,
        roughAnimals,
        handsigns,
    ],
    find: [
        invert,
        insects,
        reptiles,
        rodents,
        cuteAnimals,
        roughAnimals,
        waterAnimals,
        hugeAnimals,
        clothing,
        party,
        sweet,
        summer,
        holidays,
        winter,
        colorful,
        professions,
        activities,
    ],
    memoji: [
        cloudymood,
        insects,
        filmset,
        pokerset,
        randomhats,
        shoes,
        colorfullbooks,
        cuteAnimals,
        handsigns,
        horoscope,
        dayNight,
        clockhours,
        roughAnimals,
        hugeAnimals,
        waterAnimals,
    ],
};

export const LevelSelector = ({onSet, currLevel, bonus = [], levels}) => (
    <View style={[C.my3, C.minwFull, C.px4, C.alignCenter, C.itemsCenter]}>
        <Text style={[fonts.title2, C.alignCenter]}>Level</Text>
        <FlatList
            data={bonus.concat(levels)}
            horizontal
            style={[{maxWidth: deviceWidth * 0.9}]}
            renderItem={({item, index}) => (
                <Pressable
                    key={index}
                    style={({hovered, focused, pressed}) => [
                        C.radius10,
                        currLevel === index && bordColor(colors.water, 2),
                        C.px2,
                        C.my2,
                        hovered && hover.buttonHovered,
                        focused && hover.buttonFocused,
                        pressed && hover.buttonPressed,
                    ]}
                    onPress={() => onSet(index - bonus.length)}>
                    {({hovered, focused, pressed}) => (
                        <>
                            {hovered && Array.isArray(item) ? (
                                <Spinner /*onPress={() => onSet(index - bonus.length)}*/ size={3} speed={800}
                                                                                         list={item.slice(1)}/>
                            ) : (
                                <EmojiCompat
                                    /*onPress={() => onSet(index - bonus.length)}*/
                                    emoji={Array.isArray(item) ? item[0] : item}
                                />
                            )}
                            <LevelStar
                                size={1}
                                wrapStyle={[C.absolute, hovered ? C.top8 : C.top6, C.right_2]}
                                onPress={() => onSet(index - bonus.length + 1)}
                                level={index - bonus.length + 1}
                            />
                            <TouchableOpacity
                                style={[C.absolute, respImgSize[3].wrap]}
                                onPress={() => onSet(index - bonus.length + 1)}
                            />
                        </>
                    )}
                </Pressable>
            )}
        />
    </View>
);

export const BonusLevelSelector = ({iconSet, title, icon}) => {
    const {navigate} = useNavigation();

    return (
        <TouchableOpacity
            style={[C.row, /*C.mb8,*/ C.selfLeft, C.itemsCenter /*, C.shadowMd, C.radius4*/]}
            onPress={() => {
                navigate(screens.Collect.name, {
                    harvestList: {set: iconSet},
                    isResource: false,
                    /* level: profile.level,*/
                    practiceMode: true,
                });
            }}>
            <EmojiCompat emoji={icon} sizeNum={1}/>
            <Button
                onPress={() => {
                    navigate(screens.Collect.name, {
                        harvestList: {set: iconSet},
                        isResource: false,
                        practiceMode: true,
                    });
                }}
                col={colors.blue}>
                <View style={[C.px1, C.row, C.itemsCenter]}>
                    {iconSet.map((item) => (
                        <EmojiCompat emoji={item} sizeNum={0} extraStyle={isWide && C.mx2}/>
                    ))}
                </View>
            </Button>
            {isWide && <Text style={[fonts.subtitle, C.textBlue, C.alignCenter]}>{title}</Text>}
        </TouchableOpacity>
    );
};

export const HarvestLevelSelector = ({small, size, onSet, currLevel = 1, list = levelsMap.hit}) => {
    const [showMore, setShowMore] = useState(false);
    const {navigate} = useNavigation();
    return (
        <FlatList
            data={size && !showMore ? list.slice(0, size) : list}
            /*contentContainerStyle={[C.alignLeft, C.selfLeft, C.ml_10, {width: deviceWidth * 0.6}]}*/
            size={size}
            ListFooterComponent={
                size && (
                    <Text style={[fonts.body1, C.textBlue, C.alignCenter]} onPress={() => setShowMore(!showMore)}>
                        {!showMore ? '+ Show more...' : '- Show less...'}
                    </Text>
                )
            }
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
                <View style={[C.row /*!small && (showMore ? C.left20 : C.left10)]*/]}>
                    {<LevelStar level={index + 1} size={1} textSize={C.font4}/>}
                    <Button
                        disabled={index >= currLevel}
                        onPress={() => {
                            navigate(screens.Collect.name, {
                                harvestList: {set: item},
                                isResource: false,
                                level: index,
                                practiceMode: true,
                            });
                            profile.currHarvest.setLevel(index + 1);
                            onSet && onSet();
                        }}
                        col={colors.blue}>
                        <View style={[!small && C.px1, C.row, C.itemsCenter]}>
                            {item.map((item) => (
                                <EmojiCompat emoji={item} sizeNum={0} extraStyle={isWide && C.mx2}/>
                            ))}
                        </View>
                    </Button>
                </View>
            )}
        />
    );
};
