import {colors, imgs, isWeb} from '../design/gStyles';

export const resources = ['💎', '🪵', '🥩', '🪨'];
export const harvest = ['🔥', '🕳', ...resources];
//export const elements = ['🌿', '💦', '🔥', '🪵', '💨', '🪨'];
export const covid = ['😷', /*'🏥',*/ /*'🫁',*/ /*'🚑',*/ '👩🏼‍⚕️', '💉', '🦠', '🧻', /*'🌡',*/ '💊'];
export const drinks = ['🍺', '🍷', '🧊️', '🍸', '🥃', /*'🧋',*/ '🥛', /*'🥤',*/ '🍹'];

export const topEmojis = ['😜', '💥', '🥺', '❤️', '🔥', '😍', '💣'];
export const ships = ['🛶', '🚤', '🛥', '🛳', '⛵️', '⛴', '🚢'];
export const transport = ['🚴‍', '🛵', '🏍', '🛥', '🚗', '🛩', '🚀', '🚁', '🛸', '🚣‍'];
/**🧬🧫🦠🥚🪱🐛🐍🦎🐊🦖🦕🦄*/
export const evolution = ['🧬', '🦠', '🥚', '🪱', '🐛', '🐍', '🦎', ' 🐊', '🦖', '🦕', '🦄'];
export const invert = ['🦟', '🪱', '🐛', '🐜'];
//export const insects = ['🐝', '🐞', '🪰', '🪲', '🦋'];
export const reptiles = ['🐍', '🦂', '🦎', '🐊', '🦐', '🦞'];
export const rodents = ['🐀', '🐁', '🦫', '🦡', '🐿', '🦔', '🦝'];
//export const cuteAnimals = ['🐶', '🐱', '🐹', '🐻', '🐼', '🐨', '🐸', '🐷'];
export const roughAnimals = ['🐗', '🐅', '🐺', '🦁', '🦊', '🦣', '🦧', '🦏', '🐯', '🐆'];
export const waterAnimals = ['🐙', '🐳', '🐬', '🦈', '🐟', '🐡', '🦞', '🦐', '🐠', '🦭'];
export const hugeAnimals = ['🦖', '🦍', '🦧', '🐘', '🦏', '🦣', '🦬', '🐉', '🦛', '🦒', '🐫', '🐎'];
/*export const fruits = ['🍑', '🫐', '🍓', '🥝', '🥥', '🍏', '🍌', '🍉'];
export const flowers = ['🌹', '🌺', '🌸', '🌼', '🌻', '🍀', '🌷'];*/

export const trees = ['🌴', '🌳', '🌵', '🌲', '🎋', '🌿', '🪵'];
export const nature = ['🌊', '🌪'];
export const water = ['🌊', '🏝', '🏖' /*'🕳'*/];
export const field = ['🌾', '🌻', '🌱', '🌿', '🌺', '🌷', '🌼', '🌸', '🌹', '🏵'];
//export const flowers = ['🌻', '🌺', '🌹', '🌷', '🌼', '🌸', '🏵'];
export const mountains = ['🏔', '🌋', '🗻', '⛰', '🏜']; // ⛏
export const farm = ['🐓', '🐄', '🐖', '🐇', '🐏', '🦃', '🐐', '🐴']; // ⛏
export const workshops = ['🏕', '🪓', '🌾'];
export const rocks = ['💎', '🗿', '🪨', '🪙', '🏅', '⚱️'];
export const meat = ['🥩', '🥓', '🥩', '🍗', '🥛', '🧀', '🥚'];
export const cultivos = ['🏕', '🌱', '🌾'];
export const vegetables = ['🥦', '🥔', '🥕', '🥬', '🌽', '🥑', '🍆', '🍅', '🫑'];
export const warriors = ['🥷🏻', '🧎🏻‍️', '🧙', '🏹', '🗼', '👸', '🤴'];
export const planets = ['🌎', '🌏', '🌍'];
export const tools = ['🔧', '🔨', '🛠', '⛏', '🛡', '⚔️', '️🗡', '🔪', '🪓'];
/*export const warriors2 = ['🏰', '🏇', '🤺', '👸', '🤴', '🥷🏻'];
export const warriors3 = ['🧜🏻‍️', '🧞', '️🦹🏼‍', '️👩🏼‍🚒', '👷‍', '️🧕', '🥷🏻'];*/
//export const fruits = ['🍊', '🫐', '🥭', '🥥', '🍉', '🍌', '🍑', '🍏'];
export const animals = ['🐄', '🐎', '🐘', '🐓'];
export const skills = ['🛡', '⚔️', '️🪓', '⛏', '❤️', '🤼‍'];

export const clothing = ['🥼', '👚', '👖', '🩳', '👗', '👙', '👔', '🧥', '🦺', '👕', '🩲', '👙']; //12
export const party = ['🥳', '💃', '🕺', '🥂', '🎶', '🎈', '🎊', '🎉', '🎈', '🪅', '👔', '💄', '🎤']; // 13
export const sweet = ['🍦', '🍪', '🍰', '🍩', '🧁', '🍬', '🍫', '🍨', '🥧', '🍬', '🍭', '🍫', '🍮', '🍧']; // 14
export const summer = ['😎', '☀️', '🌞', '👙', '🍉', '🕶', '⛱', '🩳', '🧴', '🏝', '🏖', '🍹', '🩲', '👓', '🎧']; // 15
export const holidays = ['👑', '❤️', '🌸', '🐰', '🎃', '👻', '☀️', '⛱', '🎊', '🎅', '🎄', '🦃', '🍁', '☘️', '☠️', '☃️']; // 16
export const winter = [
    '🥶',
    '❄️',
    '⛷',
    '☃️',
    '🌨',
    '🧣',
    '⛄️',
    '🧦',
    '🏂',
    '⛸',
    '🧤',
    '🛷',
    '🎿',
    '🥌',
    '🎅',
    '🀩',
    '🤶',
]; // 17
export const colorful = [
    '💩',
    '☎️',
    '🎀',
    '⭐️',
    '🔥',
    '💎',
    '🧿',
    '🔋',
    '🔮',
    '📷',
    '🧩',
    '🦷',
    '🧽',
    '🧸',
    '🧲',
    '📒',
    '🧶',
    '🧻',
]; // 18
export const professions = [
    '👮',
    '👷',
    '🕵️',
    '🧑‍🌾',
    '🧑‍🍳',
    '🧑‍🏫',
    '🧑‍💻',
    '🧑‍🔧',
    '🧑‍🔬',
    '🧑‍🎨',
    '🧑‍🚒',
    '🧑‍✈️',
    '🧑‍⚖️',
    '💂',
    '🧑‍🏭',
    '🧑‍⚕️',
    '🧑‍💼',
    '🧑‍🚀',
    '🤵',
]; // 19
export const activities = [
    '🧗',
    '⛷️',
    '🏌️',
    '🚣',
    '🏇',
    '⛹️',
    '🏋️',
    '🤾',
    '🚴',
    '🏊',
    '🤼',
    '🏂',
    '🤸',
    '🏄',
    '🤽',
    '🤾',
    '🤹',
    '🧘',
    '🤺',
    '🚵',
]; // 20

export const setsV2 = {
    patatas: ['🤏'],
    cosa: ['🍊', '🫐', '🥭', '🥥', '🍉', '🍌', '🍑', '🍏'], //verjtegjre
    cosa2: ['🍊', '🫐', '🥭', '🥥', '🍉', '🍌', '🍑', '🍏'],
    /*cosa: ['🍊', '🫐', '🥭', '🥥', '🍉', '🍌', '🍑', '🍏'],
    cosa: ['🍊', '🫐', '🥭', '🥥', '🍉', '🍌', '🍑', '🍏'],
    cosa: ['🍊', '🫐', '🥭', '🥥', '🍉', '🍌', '🍑', '🍏'],
    cosa: ['🍊', '🫐', '🥭', '🥥', '🍉', '🍌', '🍑', '🍏'],
    cosa: ['🍊', '🫐', '🥭', '🥥', '🍉', '🍌', '🍑', '🍏'],*/
};

/**Version 2 Levels*/
//COLLECT SERIES:
/*
    export const covid = ['😷','🏥','🚑','👩🏼‍⚕️', '💉', '🦠', '🧻', /!*'🌡',*!/ '💊']; ESPECIAL POR COVID
export const drinks = ['🍺', '🍷', '🧊️', '🍸', '🥃', /!*'🥂', /!*'🍾',*!/ '🍹']; ESPECIAL PARTY MOOD
*/
export const skyelements = ['💫', '🌞', '🌧', '🌚', '🪐', '🌎']; /*NIVEL 1*/
export const elements = ['🌿', '💦', '🔥', '🪵', '💨', '🪨']; /*NIVEL 2*/
export const fruits = ['🍑', '🫐', '🍓', '🥝', '🥥', '🍏', '🍌']; /*NIVEL 3*/
export const organs = ['👀', '🫀', '🫁', '🧠', '👅', '🦴', '👃🏼']; /*NIVEL .**/
export const flowers = ['🌹', '🌺', '🌸', '🌼', '🌻', '🍀', '🌷']; /*NIVEL .**/
export const sportballs = ['⚽️', '🏀', '🏈', '🎾', '⚾️', '🎱', '🏐'];
/*NIVEL .**/
6;
export const flyinganimals = ['🦋', '🦅', '🐝', '🦇', '🕊', '🦜', '🦉']; /*NIVEL*.*/
export const wateranimals = ['🐙', '🐳', '🐬', '🐡', '🦞', '🦐', '🐠', '🦭']; /*NIVEL*.*/
//MEMOJI SERIES:
export const insects = ['🐝', '🐞', '🪰', '🪲', '🦋']; /*NIVEL*.*/
export const sex = ['🍑', '🍆', '🔥', '❤️'];
export const cuteAnimals = ['🐶', '🐱', '🐹', '🐻', '🐼', '🐨', '🐸', '🐷']; /*NIVEL*.*/
export const randomhats = ['👒', '🧢', '🎩', '⛑', '👑', '🎓']; /*NIVEL*.*/
export const shoes = ['👠', '👢', '👟', '🩴', '🥾', '🥿']; /*NIVEL*.*/
export const colorfullbooks = ['📙', '📘', '📗', '📕', '📔', '📓']; /*NIVEL*.*/
export const filmset = ['🎥', '📽', '📷', '📸', '📹']; /*NIVEL*.*/
export const pokerset = ['♠️', '♣️', '♥️', '♦️', '🃏']; /*NIVEL*.*/
export const handsigns = ['👌', '🙌', '🤞', '🤘', '🤙', '👍', '✌️', '🖖', '🤟', '☝️']; /*NIVEL*.*/
export const horoscope = ['♈️', '♉️', '♊️', '♋️', '♌️', '♍️', '♎️', '♏️']; /*NIVEL*.*/
export const cloudymood = ['🌤', '⛅️', '🌧', '🌦', '🌩']; /*NIVEL*.*/
export const dayNight = ['🌑', '🌒', '🌓', '🌔', '🌕', '🌖', '🌗', '🌘']; /*NIVEL*.*/
export const clockhours = ['🕒', '🕕', '🕜', '🕦', '🕐', '🕢']; /*NIVEL*.*/

export const characters = {
    '🚹': ['👶🏻', '🧒🏻', '🧔🏻', '👨🏼‍🌾', '🧝', '💂🏽‍♂️', '🥷🏻', '🧙', '🤴🏻', '🧑‍🚀' /*, '🧖🏻‍'*/],
    '🚺': ['👶🏼', '👧🏼', '👩🏼', '👩🏼‍🌾', '🧝‍♀️️️', '🧚‍♀️', '🧙🏼‍♀️', '🦹🏼‍♀️️️️', '👸🏼', '👽' /*, '🧖🏼‍♀️'*/],
    '🪶': ['🥚', '🐣', '🐥', '🦜', '🦆', '🦤', '🐓️️', '🦉️️', '🦅', '🕊'],
    '🐭': ['🐀', '🐁', '🐿', '🦡', '🐇', '🦨', '🦫', '🐒', '🦧', '🦍'],
    '🐈': ['🐈‍⬛', '🐕', '🐺', '🦌', '🐎', '🐃', '🦬', '🐘', '🦣', '🦄'],
};

export const availWorlds = [
    {
        isBig: true,
        index: 1,
        icon: '🌍',
        screen: 'Planet',
        level: '🥉',
        emojis: ['🛖', '🏛', '🔥', '🗿'],
        title: 'Europe',
        subtitle: 'Prehistory',
    },
    {
        isBig: true,
        index: 2,
        icon: '🌏',
        blocked: true,
        /*screen: 'Planet',*/
        level: '🥈',
        emojis: ['🏯', '⛩', '🏺', '📜'],
        title: 'Asia',
        subtitle: 'Ancient world',
    },
    {
        isBig: true,
        index: 3,
        blocked: true,
        icon: '🌎',
        /*screen: 'Planet',*/
        level: '🥇',
        emojis: ['🗽', '🚀', '👨‍🚀', '🛰'],
        title: 'America ',
        subtitle: 'Modern ages',
    },
];

export const terrains = {
    '🌊': {bg: colors.water, icons: water, img: imgs.water},
    '🪨': {bg: colors.ground, icons: nature.concat(trees), img: imgs.ground},
    '🌲': {
        bg: colors.grass,
        icons: trees,
        img: isWeb
            ? {uri: 'https://www.the3rdsequence.com/texturedb/download/1/texture/jpg/256/green+grass-256x256.jpg'}
            : imgs.grass,
    },
    '🏝': {bg: colors.sand, icons: water, img: isWeb ? imgs.grassText : imgs.ground},
};

export const resMapping = {
    '🪓': ['🌲', '🌴', '🌳', '🌵'],
    '⛏': ['🏔', '🌋', '🗻'],
    '🧺': ['🌾'],
};

export const natureToResource = (icon) => {
    if (mountains.includes(icon)) {
        return {icon: '⛏', set: rocks, res: '🪨'};
    } else if (farm.includes(icon)) {
        return {icon: '🍖', set: meat, res: '🥩'};
    } else if (trees.includes(icon)) {
        return {icon: '️🪓', set: trees, res: '🪵'};
    } else if (flowers.includes(icon)) {
        return {icon: '🧺', set: vegetables, res: '🥩'};
    } /*else {
    return {icon: '️🪓', set: trees};
  }*/
};

/**Cost: ['💎', '🪵', '🥩', '🪨'];*/
export const unitsMap = {
    '🧕': {
        level: 1,
        cost: {'💎': 0, '🪵': 0, '️🥩': 3, '🪨': 0},
        score: 5,
        skills: {'❤️': 10, '🛡': 0, '⚔️': 1, '⚡️‍': 1},
        collect: {'️🪓': 3, '⛏': 3},
    },
    '👨‍🌾': {
        level: 1,
        cost: {'💎': 0, '🪵': 0, '️🥩': 10, '🪨': 0},
        score: 30,
        skills: {'❤️': 20, '🛡': 1, '⚔️': 2, '⚡️‍': 1},
        collect: {'️🪓': 5, '⛏': 5, '🪚': 2, '🧺': 2},
    },
    '🧝‍': {
        level: 2,
        cost: {'💎': 0, '🪵': 10, '️🥩': 10, '🪨': 0},
        score: 60,
        skills: {'❤️': 30, '🛡': 2, '⚡️‍': 1, '🏹': 10},
    },
    '🧟': {
        level: 2,
        cost: {'💎': 5, '🪵': 10, '️🥩': 50, '🪨': 0},
        score: 100,
        skills: {'❤️': 10, '🛡': 2, '⚔️': 2, '⚡️‍': 3, '🏹': 1},
    },
    '🧙🏼‍‍️': {
        level: 3,
        cost: {'💎': 20, '🪵': 10, '️🥩': 30, '🪨': 0},
        score: 10,
        skills: {'❤️': 50, '🛡': 2, '⚔️': 2, '⚡️‍': 3, '🏹': 1},
    },
    '💂🏾‍': {
        level: 3,
        cost: {'💎': 0, '🪵': 0, '️🥩': 3, '🪨': 0},
        score: 10,
        skills: {'❤️': 50, '🛡': 2, '⚔️': 5, '⚡️‍': 1},
    },
    '🐴': {
        level: 3,
        cost: {'💎': 0, '🪵': 0, '️🥩': 3, '🪨': 0},
        score: 10,
        skills: {'❤️': 20, '🛡': 3, '⚔️': 1, '🏹': 1, '⚡️‍': 3},
    },
    '🥷🏻': {
        level: 4,
        cost: {'💎': 80, '🪵': 0, '️🥩': 20, '🪨': 0},
        score: 250,
        skills: {'❤️': 20, '🛡': 5, '⚔️': 5, '🏹': 1, '⚡️‍': 3},
    },
    '🦹🏻': {
        level: 4,
        cost: {'💎': 30, '🪵': 10, '️🥩': 10, '🪨': 0},
        score: 30,
        skills: {'❤️': 50, '🛡': 5, '⚔️': 5, '🏹': 1, '⚡️‍': 3},
    },
    '🧞‍': {
        level: 5,
        cost: {'💎': 99, '🪵': 0, '️🥩': 50, '🪨': 0},
        score: 200,
        skills: {'❤️': 80, '🛡': 10, '⚔️': 10, '⚡️‍': 3},
    },
    '🏰': {
        level: 5,
        cost: {'💎': 80, '🪵': 0, '️🥩': 20, '🪨': 0},
        score: 500,
        skills: {'❤️': 50, '🛡': 20, '🏹': 10, '⚡️‍': 0},
    },
    '🐘': {
        level: 6,
        cost: {'💎': 10, '🪵': 10, '️🥩': 150, '🪨': 0},
        score: 10,
        skills: {'❤️': 150, '🛡': 30, '⚔️': 10, '🏹': 5, '⚡️‍': 2},
    },
    '🤴🏻': {
        level: 7,
        cost: {'💎': 10, '🪵': 10, '️🥩': 150, '🪨': 0},
        score: 500,
        skills: {'❤️': 100, '🛡': 30, '⚔️': 10, '🏹': 3, '⚡️‍': 1},
    },
};
/**Cost: ['💎', '🪵', '🥩', '🪨'];*/
export const buildings = ['🗿', imgs.barrack, imgs.farm, imgs.yard, '⛺️', '🛖', '🏛', '🏰'];
export const buildingsMap = {
    '🔥': {
        level: 1,
        cost: {'💎': 0, '🪵': 10, '️🥩': 0, '🪨': 0},
        score: 4,
        skills: {'❤️': 1, '👨‍👩‍👧‍👦': 1},
    },
    '⛺️': {
        level: 1,
        cost: {'💎': 0, '🪵': 10, '️🥩': 10, '🪨': 0},
        score: 10,
        skills: {'❤️': 10, '👨‍👩‍👧‍👦': 3},
    },
    '🛖': {
        level: 2,
        cost: {'💎': 0, '🪵': 50, '️🥩': 10, '🪨': 5},
        score: 30,
        skills: {'❤️': 20, '👨‍👩‍👧‍👦': 5},
    },
    '🗿': {
        level: 2,
        cost: {'💎': 0, '🪵': 0, '️🥩': 0, '🪨': 100},
        score: 20,
        skills: {'❤️': 1, '👨‍👩‍👧‍👦': 1},
    },
    '🎪': {
        level: 3,
        cost: {'💎': 0, '🪵': 50, '️🥩': 10, '🪨': 5},
        score: 30,
        skills: {'❤️': 20, '👨‍👩‍👧‍👦': 5},
    },
    '🛕': {
        level: 1,
        cost: {'💎': 0, '🪵': 20, '️🥩': 10, '🪨': 0},
        score: 15,
        skills: {'❤️': 10, '🧺': 5},
    },
    '🏚': {
        level: 3,
        cost: {'💎': 0, '🪵': 100, '️🥩': 10, '🪨': 5},
        score: 30,
        skills: {'❤️': 20, '👨‍👩‍👧‍👦': 8},
    },
    '🗼': {
        level: 3,
        cost: {'💎': 0, '🪵': 100, '️🥩': 0, '🪨': 20},
        skills: {'❤️': 100, '👨‍👩‍👧‍👦': 1, '🏹': 15},
        score: 50,
    },
    '🕌': {
        level: 4,
        cost: {'💎': 0, '🪵': 100, '️🥩': 0, '🪨': 200},
        skills: {'❤️': 100, '👨‍👩‍👧‍👦': 3},
        score: 50,
    },
    '🏛': {
        level: 4,
        cost: {'💎': 0, '🪵': 10, '️🥩': 0, '🪨': 100},
        score: 75,
        skills: {'❤️': 100, '👨‍👩‍👧‍👦': 20},
    },
    '🏰': {
        level: 5,
        cost: {'💎': 100, '🪵': 20, '️🥩': 0, '🪨': 200},
        skills: {'❤️': 150, '👨‍👩‍👧‍👦': 25},
        score: 250,
    },
    '🏭': {
        level: 6,
        cost: {'💎': 100, '🪵': 20, '️🥩': 0, '🪨': 200},
        skills: {'❤️': 150, '👨‍👩‍👧‍👦': 25},
        score: 250,
    },
    '🏢': {
        level: 6,
        cost: {'💎': 100, '🪵': 20, '️🥩': 0, '🪨': 200},
        skills: {'❤️': 150, '👨‍👩‍👧‍👦': 25},
        score: 250,
    },
};

export const allIcons = trees.concat(
    water,
    fruits,
    covid,
    planets,
    drinks,
    resources,
    Object.keys(unitsMap),
    tools,
    Object.keys(buildingsMap),
    mountains,
    rocks,
    vegetables,
    farm,
    warriors,
    ...Object.values(characters),
);
