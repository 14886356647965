import React from 'react';
import {colors} from './gStyles';


const gradBgColor = colors.blue + '30';

export const HeaderWave = ({col = gradBgColor, opacity = 0.5, wrapStyle}) => (
    <svg id="wave" style={wrapStyle} data-name="wave" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1080.33 272.88">
        <title>waves</title>
        <path
            fill={col}
            fill-opacity={opacity}
            d="M1079.59,260C858.22,303.19,599.33,233.9,311.33,164.9,219,142.79,83.82,276.5.74,260V85.75C92.91,119,210.22-24.9,332.33,17.9c311,109,587.81,123.41,747.26,67.85Z"
        />
        <path
            fill={col}
            fill-opacity={opacity}
            d="M1.51,32.71C128.92,74.28,232.62,81.13,303.07,79.89,510.69,76.26,604.44-.58,814.31,0c114.12.32,206.91,23.38,266,42.07q-.75,87.11-1.51,174.21C989.29,170.58,877,132,747.55,132.84c-146.49.91-217.38,51.49-392.45,76.29C260.9,222.47,141.65,227.79,0,206.92Q.75,119.82,1.51,32.71Z"
        />
    </svg>
);
/**@deprecated*/
export const HeaderShape = ({col = gradBgColor, wrapStyle}) => (
    <svg xmlns="http://www.w3.org/2000/svg" style={wrapStyle} viewBox="0 0 1440 320">
        <path
            fill={col}
            fill-opacity=".3"
            d="M0,192L48,208C96,224,192,256,288,250.7C384,245,480,203,576,186.7C672,171,768,181,864,165.3C960,149,1056,107,1152,74.7C1248,43,1344,21,1392,10.7L1440,0L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        />
    </svg>
);
export const HeaderShape2 = ({col = gradBgColor, wrapStyle}) => (
    <svg xmlns="http://www.w3.org/2000/svg" style={wrapStyle} viewBox="0 0 1440 320">
        <path
            fill={col}
            fill-opacity=".3"
            d="M0,128L80,144C160,160,320,192,480,170.7C640,149,800,75,960,64C1120,53,1280,107,1360,133.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        />
    </svg>
);
export const FooterShape = ({col = gradBgColor, opacity = 0.2, wrapStyle}) => (
    <svg xmlns="http://www.w3.org/2000/svg" style={wrapStyle} viewBox="0 0 1440 320">
        <path
            fill={col}
            fill-opacity={opacity}
            d="M0,128L80,144C160,160,320,192,480,170.7C640,149,800,75,960,64C1120,53,1280,107,1360,133.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        />
    </svg>
);
export const FooterShape2 = ({col = gradBgColor, opacity = 0.2, wrapStyle}) => (
    <svg xmlns="http://www.w3.org/2000/svg" style={wrapStyle} viewBox="0 0 1440 320">
        <path
            fill={col}
            fill-opacity={opacity}
            d="M0,224L40,213.3C80,203,160,181,240,154.7C320,128,400,96,480,101.3C560,107,640,149,720,181.3C800,213,880,235,960,250.7C1040,267,1120,277,1200,256C1280,235,1360,181,1400,154.7L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        />
    </svg>
);
export const BgShape = ({col = gradBgColor, wrapStyle}) => (
    <svg viewBox="0 0 200 200" style={wrapStyle} xmlns="http://www.w3.org/2000/svg">
        <path
            fill={col}
            d="M29.4,-17.6C40.9,3,55,21.5,50.6,34.9C46.1,48.4,23.1,56.9,1.3,56.1C-20.5,55.4,-41,45.4,-51,28.7C-61.1,12,-60.9,-11.5,-50.6,-31.3C-40.4,-51.2,-20.2,-67.4,-5.6,-64.2C8.9,-60.9,17.9,-38.2,29.4,-17.6Z"
            transform="translate(100 100)"
        />
    </svg>
);

export const BgShapeHoriz = ({col = gradBgColor, wrapStyle}) => (
    <svg viewBox="0 0 200 200" style={wrapStyle} xmlns="http://www.w3.org/2000/svg">
        <path
            fill={col}
            d="M43.7,-31.7C58.8,-28.6,74.8,-14.3,79.4,4.6C84,23.6,77.4,47.1,62.3,49.9C47.1,52.6,23.6,34.5,9.1,25.4C-5.4,16.3,-10.7,16.2,-23.2,13.4C-35.6,10.7,-55.2,5.4,-61.4,-6.2C-67.5,-17.7,-60.4,-35.4,-47.9,-38.5C-35.4,-41.6,-17.7,-30,-1.7,-28.3C14.3,-26.6,28.6,-34.7,43.7,-31.7Z"
            transform="translate(100 100)"
        />
    </svg>
);
