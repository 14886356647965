import C from 'consistencss';
import _ from 'lodash';
import {Alert} from 'react-native';
import {colors, EMOJI_IMAGE, isWeb, isWide} from '../design/gStyles';
import {CHESS_SIZE} from '../stores/boardStore';
import {emojiCodes} from './emojiCodes';

const emojiMap = require('emoji-datasource-apple/emoji.json');

export const emojiPNGMap = {
    '🍓': '1f353.png',
    '🏛': '1f3db-fe0f.png',
    '🏜️': '1f3dc-fe0f.png',
    '⛴': '26f4-fe0f.png',
    '❤️‍🔥': '2764-fe0f-200d-1f525.png',
};

export const emojiNameMap = {
    '⚔': 'crossed_swords',
    /* '❤️‍🔥': 'heart_on_fire',*/
    '🏔': 'snow_capped_mountain',
    '🏜': 'desert',
    '🪓': 'axe',
    '🪚': 'carpentry_saw',
    '🛠': 'hammer_and_wrench',
    '🛡': 'shield',
    '🗡': 'dagger_knife',
    '⛰': 'mountain',
    '⛏': 'pick',
    '🎪': 'circus_tent',
    '🏛': 'classical_building',
    '🧝': 'elf',
    '👨‍🚀': 'male-astronaut',
    '🛰': 'satellite',
    '🛸': 'flying_saucer',
    '🍓': 'strawberry',
    '🏚': 'derelict_house_building',
    '🏚️': 'derelict_house_building',
    '🕳': 'hole',
    '🧑‍💻': 'technologist',
    '💥': 'boom',
    '✖️': 'heavy_multiplication_x',
    '🔊': 'loud_sound',
    '🔇': 'mute',
    '👨‍🌾': 'male-farmer',
    '👩‍🌾': 'female-farmer',
    '🧝‍': 'elf',
    '🧝‍♀️': 'female_elf',
    '🧚‍♀️': 'fairy',
    '🧊': 'ice_cube',
    '🧞‍': 'male_genie',
    '🧑‍🚀': 'astronaut',
    '🏜️': 'desert',
    '🕊': 'dove_of_peace',
    '🐿': 'chipmunk',
    '️⛴': 'ferry',
    '🏵': 'rosette',
};

export const emojiPNG = ({emoji}) => {
    if (emoji) {
        /**Fallback 1 -  Manual icons*/
        const code = emojiNameMap[emoji]; // emojiCodes[emoji]?.dotName?.split(':')[1]; //emojiNameMap[emoji];
        /**Fallback 2 -  Extracted map*/
        const imgCode = code ? emojiMap.filter(({short_name}) => short_name === code)[0]?.image : emojiCodes[emoji]?.img; // emojiNameMap[emoji];
        /**Fallback 3 -  Extracted map*/
        let append = emoji.toString().codePointAt(2);
        const imgCode2 = emoji.toString().codePointAt(0).toString(16) + (append ? '-' + append.toString(16) : '') + '.png';
        const fallback = emoji.toString().codePointAt(0).toString(16) + '.png';
        const imgUri = imgCode || imgCode2;
        console.log('converting to img: ', EMOJI_IMAGE + imgUri);
        return {img: EMOJI_IMAGE + imgUri, fallbackImg: EMOJI_IMAGE + fallback};
    } else {
        return {img: EMOJI_IMAGE + emojiPNGMap[' ']};
    }
};

export const respImgSize = [
    /*0*/ {font: C.font5, text: C.font3, img: [C.w6, C.h6], wrap: [C.w8, C.h8]},
    /*1*/ {font: C.font8, text: C.font4, img: [C.w8, C.h8], wrap: [C.w10, C.h10]},
    /*2*/ {font: C.font10, text: C.font7, img: [C.w10, C.h10], wrap: [C.w12, C.h12]},
    /*3*/ {
        font: /*isWide ?*/ C.font12 /*: C.font11*/,
        text: /*isWide ?*/ C.font9 /*: C.font8*/,
        img: [isWide ? [C.w12, C.h12] : [C.w11, C.h11]],
        wrap: [C.w13, C.h13],
    },
    /*4*/ {font: C.font15, text: C.font12, img: [C.w15, C.h15], wrap: [C.w18, C.h18]},
];

export const fireAlert = (title, msg) => (isWeb ? alert(title + msg) : Alert.alert(title, msg));

export const objAdd = (obj, icon, quant = 1) => (obj.hasOwnProperty(icon) ? (obj[icon] += quant) : (obj[icon] = quant));
export const shuffleList = (list = []) => {
    let currentIndex = list.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = list[currentIndex];
        list[currentIndex] = list[randomIndex];
        list[randomIndex] = temporaryValue;
    }

    return list;
};

export const pickRandom = (list = [], uncertainty = 0.4, withIndex = false) => {
    const index = Math.floor((Math.random() * list.length) / uncertainty);
    return withIndex ? {icon: list[index], index} : list[index];
};

export const numFormat = (num, digits = 1) => {
    const si = [
        {value: 1, symbol: ''},
        {value: 1e3, symbol: 'k'},
        {value: 1e6, symbol: 'M'},
        {value: 1e9, symbol: 'G'},
        {value: 1e12, symbol: 'T'},
        {value: 1e15, symbol: 'P'},
        {value: 1e18, symbol: 'E'},
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i = si.length - 1;
    for (i; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const blinkDelay = ({setBlink, onAfter, time = 600, content = true}) => {
    setBlink(content);
    setTimeout(() => {
        if (onAfter) {
            onAfter();
        }
        setBlink(null);
    }, time);
};
export const webScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};
export const checkAdjacents = (icon, size = CHESS_SIZE) => [];

/*
export const getAdjacentsIds = (id, size = CHESS_SIZE) => [
  id, // current
  (id % size) - size + 1 !== 0 && id + 1, //right
  id % size !== 0 && id - 1, //left
  id - size, //top
  id + size, //bottom
];
*/

/**Base operations*/
export const withinBoard = (id, extraCond = true, size = CHESS_SIZE) => {
    if (id >= 0 && id < size * size) {
        return id;
    }
}; /*(id >= 0 && id < size * size ? id : 0)*/

export const up = (id, size = CHESS_SIZE) => withinBoard(id - size);
export const down = (id, size = CHESS_SIZE) => withinBoard(id + size);
export const left = (id, size = CHESS_SIZE) => withinBoard(id - 1);
export const right = (id, size = CHESS_SIZE) => withinBoard(id + 1);

const avoidLeft = (id, size = CHESS_SIZE) => id % size !== 0;
const avoidRight = (id, size = CHESS_SIZE) => (id % size) - size + 1 !== 0;

export const getAdjacentsIds = (id, size = CHESS_SIZE, withSelf = true) => [
    withSelf && id, // current
    up(id, size), //top
    down(id, size), //bottom
    avoidRight(id) && right(id, size), //right
    avoidLeft(id) && left(id, size), //left
];

export const getAdjacentDiagIds = (id, size = CHESS_SIZE, withDiag = true, withSelf = true) =>
    !withDiag
        ? getAdjacentsIds(id, size, withSelf)
        : [
            ...getAdjacentsIds(id, size),
            avoidRight(id) && down(right(id)) /*id - size - 1,*/, //bottom right
            avoidRight(id) && up(right(id)) /*id % size !== 0 &&*/ /*id + size - 1,*/, //top left
            avoidLeft(id) && left(down(id)) /*id % size !== 0 &&*/ /*id - size + 1,*/, //bottom left
            avoidLeft(id) && left(up(id)), //id + size + 1, //top right
        ];

/** Board as a set of cells*/
export const matchingAdjacentIds = (id, size, board, withDiag) =>
    getAdjacentDiagIds(id, size, withDiag).filter((adjId) => board[adjId]?.icon === board[id]?.icon);
// Conjunto grande        =>   Subconjunto

export const matchRecursiveAdjCells = (id, size, board, withDiag = false, acum = [id]) =>
    acum.concat(
        _.difference(matchingAdjacentIds(id, size, board, withDiag), acum).flatMap(
            (adjId) =>
                adjId > 0 && adjId < size * size && matchRecursiveAdjCells(adjId, size, board, withDiag, acum.concat(adjId)),
        ),
    );

/*todo most efficient method 🤔 ?
   export const efficientMatch = (id, size, board, acum = matchingAdjacentIds(id, size, board)) =>
  acum.flatMap(
    (adjId) => !acum.includes(adjId) && matchingRecursiveAdjacentIds(adjId, size, board, acum.concat(adjId)),
  );*/
// Conjunto grande        =>   Subconjunto

export const factorial = (x, acum = 1) => (x ? factorial(x - 1, x * acum) : acum);

export const unique = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);

export const chessColor = (id) => (parseInt(id / CHESS_SIZE + id, 10) % 2 === 0 ? colors.sand : colors.groundSand);

/*
export const emojiMatch = (text, cat = 'Animals & Nature') =>
  Object.values(emojiList[cat]).filter(({keywords}) => keywords.includes(text));
*/

/*
export const allIcons = () => {
  let res = [];
  Object.keys(emojiList).map((key) => {
    res.push(...Object.values(emojiList[key]));
  });
  return res;
};
*/

/*
export const emojiFullMatch = (text) => allIcons().filter(({keywords}) => keywords.includes(text));
*/

/*export const categories = Object.keys(emojiList);*/
