import C from 'consistencss';
import _ from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {ActivityIndicator, FlatList, Text, TouchableOpacity, View} from 'react-native';
import {bgColor, colors, isWide} from '../design/gStyles';
import {font, fonts} from '../design/fonts';

import {comboRef} from '../stores/profileStore';
import {characters} from '../utils/sets';
import {numFormat} from '../utils/utils';
import {LevelStar, Spinner} from './Box';
import {EmojiCompat} from './WrapMoji';

export const filterHeader = [
    {filter: 'id', name: 'name', icon: '👨‍'},
    {filter: 'level', name: 'level', icon: '⭐️'},
    {filter: 'score', icon: '🔥'},
    {filter: 'size', name: 'combo', icon: '💥'},
    {filter: 'timeStamp', name: 'Time', icon: '⏳'},
];
const rowCenter = [C.alignCenter, C.flex, C.row, C.itemsCenter, C.selfCenter, C.contentCenter];

export const ScoreRow = ({horiz = true, index = 1, setCurrItem, scoreItem, currItem}) => {
    const {score, collected, level = 1, name, flag, icon, size, time, gender = '🚺'} = scoreItem;

    //const totCollected = collected ? Object.values(collected).reduce((accum, curr) => (accum += curr), 0) : 0;
    return (
        <TouchableOpacity
            onPress={() => (horiz ? setCurrItem(currItem ? null : scoreItem) : {})} //blinkDelay({setBlink: setCurrItem, content: scoreItem, time: 2000}) : {})}
            style={[
                horiz && C.row,
                C.px4,
                index % 2 === 0 && [bgColor(colors.blue + '20'), C.radius2],
                isWide ? C.mx2 : C.mx1,
                C.justifyBetween,
                C.itemsCenter,
            ]}>
            {/**Profile Img*/}
            <View style={[rowCenter, C.justifyLeft]}>
                <EmojiCompat
                    imgStyle={[C.w8, C.h6, C.mx2]}
                    sizeNum={1}
                    emoji={
                        characters.hasOwnProperty(gender)
                            ? characters[gender === '♂' ? '🚹' : gender === '♀️' ? '🚺' : gender][level || 1]
                            : '👤'
                    }
                />
                {name && !name.includes('New') && (
                    <Text numberOfLines={1} style={[fonts.body1, C.capitalize, C.ml2, C.alignCenter]}>
                        {name}
                    </Text>
                )}
                <EmojiCompat
                    /*imgStyle={[C.w8, C.h6, C.mx2]}*/
                    sizeNum={0}
                    emoji={flag}
                />
            </View>

            {/**Level*/}
            <View style={[rowCenter, C.justifyCenter, C.itemsCenter]}>
                <LevelStar level={level || 1}/>
                {/*<Text style={[fonts.body2, C.textBlueGrey, isWide && C.ml2, C.alignCenter]}>{totCollected}</Text>*/}
            </View>
            {/**Score*/}
            <Text style={[fonts.body1, rowCenter]}>{numFormat(score || 0, 3)}</Text>

            {/**Combo*/}
            <View style={[rowCenter, C.justifyCenter]}>
                <Text style={[font.h3, C.mr2, C.alignCenter]}>{size}</Text>
                <EmojiCompat emoji={icon} sizeNum={1} extraStyle={[isWide ? C.mr2 : C.mr1]}/>
            </View>

            {/**Time*/}
            <Text style={[fonts.body2, C.textBlueGrey, rowCenter]}>{moment(time).fromNow(!isWide)}</Text>

            {/**Collected*/}
            {!horiz && collected && (
                <View style={[C.row, C.itemsCenter]}>
                    {Object.entries(collected).map(([key, val]) => (
                        <Text style={[C.itemsCenter, isWide && C.mx1]}>
                            <EmojiCompat emoji={key} sizeNum={0} extraStyle={[isWide && C.mr2]}/> {val}
                        </Text>
                    ))}
                </View>
            )}
        </TouchableOpacity>
    );
};
export const HeaderComp = ({currFilter, filter, onPress, name, sortDesc = false, icon, center = false}) => {
    const isCurrFilter = currFilter === filter;
    return (
        <TouchableOpacity
            style={[C.flex, C.row, C.itemsCenter, center && [C.selfCenter, C.bgBlue20, C.m1, C.p1, C.radius4, C.itemsCenter, C.alignCenter, C.justifyCenter]]}
            onPress={onPress}>
            {isWide && <EmojiCompat emoji={icon} sizeNum={0}/>}
            <Text
                numberOfLines={1}
                style={[
                    font.h3, C.capitalize,
                    C.alignLeft,
                    isCurrFilter && C.textBlue,
                    isWide && C.mx2,
                ]}>
                {name || filter}
            </Text>

            {isCurrFilter && <EmojiCompat emoji={!sortDesc ? '🔼' : '🔽'} sizeNum={0}/>}
        </TouchableOpacity>
    );
};

export default ({dbRef = comboRef}) => {
    const [loading, setLoading] = useState(true);
    /*const [autoScroll, setAutoScroll] = useState(false);*/
    const [topScore, setTopScore] = useState([]);
    const [sortBy, setSortBy] = useState(filterHeader[2].filter);
    const [sortDesc, setSortDesc] = useState(true);
    const [currItem, setCurrItem] = useState(null);
    const [limit, setLimit] = useState(10);

    const fetchTopScore = () =>
        dbRef
            .orderBy(sortBy, sortDesc ? 'desc' : 'asc')
            .limit(limit)
            .onSnapshot((querySnapshot) => {
                const currScore = [];
                setLoading(true);
                querySnapshot.forEach((doc) => {
                    const {score, size, flag, icon, collected, gender, level, highCombo, timeStamp, id} = doc.data();
                    currScore.push({
                        score,
                        flag,
                        size,
                        icon,
                        collected,
                        gender,
                        level,
                        highCombo,
                        time: timeStamp,
                        name: id,
                        mail: doc.id,
                    });
                });
                setTopScore(currScore);
                setLoading(false);
            });

    useEffect(() => {
        const subscribeScore = fetchTopScore();
        return [subscribeScore];
    }, [limit, sortBy, sortDesc]);

    return (
        <View style={[C.my4, C.wFull]}>
            <View style={[C.row, C.my4, C.selfCenter, C.itemsCenter]}>
                <EmojiCompat emoji={'🏆'} extraStyle={[C.mr2]} sizeNum={1}/>
                <Text style={[fonts.title2]}>Top {limit} Users</Text>
            </View>
            {loading ? (
                <Spinner size={4} extraStyle={[C.minh15]}/>
            ) : (
                <FlatList
                    style={!!currItem && [C.opacity40]}
                    data={topScore}
                    keyExtractor={(item, index) => index}
                    ListFooterComponent={
                        loading ? (
                            <ActivityIndicator style={[C.py6]} color={colors.blue}/>
                        ) : (
                            <TouchableOpacity
                                onPress={() => {
                                    setLimit(limit + 10);
                                    /*setAutoScroll(!autoScroll);*/
                                }}
                                style={[C.alignCenter, bgColor(colors.blue + '30'), fonts.body1, C.p2, C.radius2, C.m2, C.textBlue]}>
                                + Show 10 more...
                            </TouchableOpacity>
                        )
                    }
                    /*onEndReachedThreshold={0.001}
                    onEndReached={() => {
                        if (autoScroll) {
                            setLimit(limit + 10);
                            setAutoScroll(false);
                        }
                    }}*/
                    ListEmptyComponent={<Spinner size={4}/>}
                    ListHeaderComponent={
                        <View style={[C.row, C.m2, C.px2]}>
                            {filterHeader.map(({filter, name, icon}) => (
                                <HeaderComp
                                    filter={filter}
                                    name={name}
                                    center
                                    icon={icon}
                                    sortDesc={sortDesc}
                                    currFilter={sortBy}
                                    onPress={() => (filter === sortBy ? setSortDesc(!sortDesc) : setSortBy(filter))}
                                />
                            ))}
                        </View>
                    }
                    renderItem={({item, index}) =>
                        item && item?.score > 0 &&
                        <ScoreRow scoreItem={item} index={index} currItem={!!currItem} setCurrItem={setCurrItem}/>
                    }
                />
            )}
            {!!currItem && (
                <View
                    style={[
                        C.row,
                        C.itemsCenter,
                        C.flex,
                        C.bgWhite,
                        C.shadowMd,
                        C.p3,
                        C.radius4,
                        C.topQuarter,
                        C.selfCenter,
                        C.absolute,
                    ]}>
                    <View>
                        {filterHeader.map(({filter, name, icon}) => (
                            <View style={[C.mb2]}>
                                <HeaderComp
                                    filter={filter}
                                    name={name}
                                    icon={icon}
                                    sortDesc={sortDesc}
                                    currFilter={sortBy}
                                    onPress={() => (filter === sortBy ? setSortDesc(!sortDesc) : setSortBy(filter))}
                                />
                            </View>
                        ))}
                        {currItem.collected && !_.isEmpty(currItem.collected) && (
                            <Text style={[font.h3]}>
                                <EmojiCompat emoji={'🧺'} sizeNum={0}/> Collected
                            </Text>
                        )}
                    </View>
                    <ScoreRow scoreItem={currItem} horiz={false}/>
                </View>
            )}
        </View>
    );
};
