import _ from 'lodash';
import {makeAutoObservable} from 'mobx';
import {nanoid} from 'nanoid/non-secure';
import {profile} from '../App';
import {BASE_PIXEL, deviceHeight, deviceWidth, isBig, isWeb, isWide} from '../design/gStyles';
import {barcelona, laussane, lleida, troy} from '../utils/MapUtils';
import {buildingsMap, evolution, farm, flowers, harvest, mountains, roughAnimals, trees, unitsMap} from '../utils/sets';
import {
    chessColor,
    getAdjacentDiagIds,
    getAdjacentsIds,
    left,
    matchRecursiveAdjCells,
    pickRandom,
    shuffleList,
    unique,
} from '../utils/utils';
import {BuildingStore, CellStore, UnitStore} from './cellStore';
import {DEFAULT_FLAG} from './profileStore';

export const xCells = (cellSize = 13) => Math.ceil(deviceWidth / (cellSize * BASE_PIXEL));
export const yCells = (cellSize = 13) => Math.ceil((deviceHeight * 0.9) / (cellSize * BASE_PIXEL));

export const WORLD_SIZE = 13; //isWeb ? /*xCells * xCells*/ 13 : 13; // xCells * yCells; //
export const VILLAGE_SIZE = 13; // /*isWeb ?*/ xCells(VILLAGE_CELL_SIZE) * yCells(VILLAGE_CELL_SIZE) * 1.2;
export const CHESS_SIZE = 8;

export const modalStore = (show = false) =>
    makeAutoObservable({
        show: show,
        id: nanoid(6),
        isMain: false,
        showModal() {
            this.show = true;
        },
        closeModal() {
            this.show = false;
        },
        toggleModal() {
            this.show = !this.show;
        },
    });
export const boardsMap = {
    WORLD: {
        size: WORLD_SIZE,
        initCord: lleida,
        minZoom: isWeb ? 5 : 7,
        maxZoom: 9,
        id: 1,
        startingCell: 27,
        icon: () => pickRandom(mountains, isWide ? 0.3 : 0.2),
    },
    VILLAGE: {
        initCord: laussane,
        minZoom: isWide ? 14 : 15,
        maxZoom: isWide ? 15 : 16,
        /*todo  side: 13,*/
        /*terrain: 'terrain',*/
        /* minZoom: isWide ? 10 : 9, //todo [12, 13] apply ZoomFactors => //isWeb ? 16 : isPhoneBrowser ? 16 : 17,
            maxZoom: isWide ? 13 : 11, //isWeb ? 19 : 17,*/
        size: VILLAGE_SIZE,
        id: 2,
        icon: () => pickRandom(trees.concat(flowers, farm, trees, Object.keys(buildingsMap)), isBig ? 0.2 : 0.3),
    },
    BATTLE: {
        initCord: troy,
        minZoom: isWide ? 14 : 15,
        maxZoom: isWide ? 15 : 16,
        terrain: 'terrain',
        /*minZoom: isWide ? 10 : 9,
            maxZoom: isWide ? 13 : 11,*/
        id: 3,
        bg: (cellId) => chessColor(cellId),
        icon: (cellId) => cellId > 16 && cellId < 48 && pickRandom(/*trees.concat(*/ mountains /*)*/, 0.3),
    },

    /**Harvest*/
    HARVEST: {
        id: 4,
        icon: () => pickRandom(harvest, 1),
        /*collectList: harvest,*/
    },
    MEMOJI: {
        id: 5,
        icon: () => pickRandom(roughAnimals, 1),
        /*collectList: cuteAnimals,*/
        size: 8,
    },
    EVOJI: {
        id: 5,
        icon: () => pickRandom(evolution.slice(0, 1), 0.2),
        /*collectList: cuteAnimals,*/
        size: 8,
    },
    RECRUIT: {
        id: 6,
        icon: () => pickRandom(Object.keys(unitsMap), 1),
        withDiag: true,
        /*collectList: Object.keys(unitsMap),*/
    },
};

const initStore = (size, boardMap, harvestList, pickAll = false) => {
    const list = harvestList && pickAll ? shuffleList(harvestList.concat(harvestList)) : harvestList;
    return _.range(pickAll ? harvestList.length * 2 : size * size).map((j) =>
        CellStore({
            id: j,
            boardMap,
            side: size,
            initialCord: boardMap.initCord,
            icon: list && pickAll ? list[j] : pickRandom(list, 1),
        }),
    );
};
export const BoardStore = (
    boardMap = boardsMap.WORLD,
    harvestList,
    size = CHESS_SIZE,
    diagMatch = false,
    pickAll = false,
) =>
    makeAutoObservable({
        size: size, //boardMap.size, // 8x8
        terrain: boardMap.terrain,
        initCord: boardMap.initCord || barcelona,
        zoomMin: boardMap.minZoom || 8,
        zoomMax: boardMap.maxZoom || 11,
        cells: initStore(size, boardMap, harvestList, pickAll),
        currCellId: boardMap.startingCell || 0, //size * size - 1,
        findNextEmpty(id) {
            let pos = id;
            while (!!this.cells[pos].icon && pos >= 0) {
                pos--;
                if (pos < 0) {
                    pos = size * size - 1;
                }
            }
            return pos;
        },
        setCell({
                    icon,
                    id = this.currCellId,
                    overwrite = true,
                    /*terrain = terrains['🌲']*/
                    unitIcon,
                    buildIcon,
                    isEvil = false,
                    flag = !isEvil ? DEFAULT_FLAG : profile.flag,
                }) {
            let pos = id;
            if (!overwrite) {
                pos = this.findNextEmpty(id);
            }
            // overwrite ? id : this.findNextEmpty(id);
            const currCell = this.cells[pos];
            console.log('setting', icon, id, currCell, unitIcon, pos, id, overwrite); // this.findNextEmpty(id));
            if (icon) {
                currCell.setIcon(icon);
            }
            if (unitIcon) {
                currCell.setUnit(UnitStore(unitIcon, pos, isEvil));
            }
            if (buildIcon) {
                currCell?.setBuilding(BuildingStore(buildIcon, pos, isEvil, flag));
            }
            return pos;
        },

        iconMatches(cellIndex) {
            return this.matchRecursiveAdjIds.includes(cellIndex);
        },

        recruit(matchIcon) {
            const comboSize = this.matchRecursiveAdjIds.length;
            profile.addUnit(matchIcon, comboSize - 2);
            this.reassignCells({newIcons: Object.keys(unitsMap), currIcon: matchIcon});
            /**todo this.remMoves--;*/
        },

        get currCell() {
            return this.cells[this.currCellId];
        },
        get adjacentIds() {
            return getAdjacentsIds(this.currCellId, size);
        },
        get adjacentDiagIds() {
            return getAdjacentDiagIds(this.currCellId, size);
        },
        setCurrent(cellId = 0) {
            this.currCellId = cellId > 0 ? cellId : 0;
        },

        get matchRecursiveAdjIds() {
            return unique(
                matchRecursiveAdjCells(
                    this.currCellId,
                    size,
                    this.cells,
                    diagMatch || boardMap.withDiag || this.currCell.icon === '🔥',
                ),
            );
        },
        get validMatch() {
            return this.matchRecursiveAdjIds.length > 2;
        },

        /**Units*/
        get isCurrUnit() {
            return !!this.currCell.unit;
        },
        get isCurrEvil() {
            return this.currCell.isEvil;
        },
        addUnits({num = 16, isEvil = true, currLevel = this.level}) {
            const availUnits = Object.entries(unitsMap)
                .filter(([key, {level}]) => level <= currLevel)
                .map(([key, val]) => key);

            for (let i = 0; i < num; i++) {
                this.setCell({
                    unitIcon: pickRandom(availUnits, 1),
                    overwrite: false,
                    id: !isEvil ? 63 : 15,
                    isEvil,
                });
            }
        },
        get randomEnemy() {
            return pickRandom(
                this.cells.filter(({isUnit, isEvil}) => isUnit && isEvil),
                1,
            );
        },
        moveCells(direction, size = 4) {
            this.cells.forEach((cell, index) => {
                const {id, icon, xPos} = cell;

                let newXpos = xPos;
                if (icon) {
                    do {
                        const newId = left(id, size);
                        newXpos = newId % size;
                        this.moveToCell(left(id, size), cell);
                    } while (newXpos > 0);
                }
            });
        },
        reassignCells({cells = this.matchRecursiveAdjIds, newIcons = harvest, currIcon = '🔥'}) {
            console.log('Reassigning cells: ', cells, newIcons, currIcon);
            const filterSet = newIcons.filter((icon) => icon !== currIcon && icon !== '🕳');
            cells.forEach((currCellId) => this.cells?.[currCellId]?.setIcon(pickRandom(filterSet, 1))); //this.setCell({icon: '💎', id: currCellId}));
        },
        randomMove() {
            const enemies = this.cells.filter(({unit, isEvil}) => unit && isEvil);
            const cellId = pickRandom(enemies, 1).id;
            const availMoves = getAdjacentDiagIds(cellId, size, true, false);
            const emptySpots = availMoves.filter(
                (currId) => !!currId && currId >= 0 && currId < 64 && currId !== cellId && !this.cells[currId]?.icon,
            );
            if (!_.isEmpty(emptySpots)) {
                this.moveToCell(pickRandom(emptySpots, 1), this.cells[cellId], true);
                return true;
            } else {
                return false;
            }
        },
        moveToCell(dest, origin = this.currCell, isEvil = false) {
            console.log('trying to move to ', dest, ' with ', origin);
            /**move to new location*/
            this.setCell({
                unitIcon: /*isEvil &&*/ origin.icon,
                icon: !isEvil && origin.icon,
                overwrite: true,
                id: dest,
                isEvil: isEvil,
            });
            /**Remove previous*/
            origin.clearCell();
            this.setCurrent(dest.id);
        },
    });
