// Firebase App (the core Firebase SDK) is always required and
import 'firebase/analytics';
// must be listed before other Firebase SDKs
import firebase from 'firebase/app';

// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDGqyetrFYD9IOLMQLImK4MCAqzcJHikaQ',
  authDomain: 'planetmoji-266fe.firebaseapp.com',
  projectId: 'planetmoji-266fe',
  storageBucket: 'planetmoji-266fe.appspot.com',
  messagingSenderId: '496978682219',
  appId: '1:496978682219:web:84443b8d6746d65b8fec2e',
  measurementId: 'G-V3MEPBKG4S',
};

firebase.initializeApp(firebaseConfig);
export const gAnalytics = firebase.analytics();

export const db = firebase.firestore();

export const gFire = {firebase};
