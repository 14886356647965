import {useNavigation} from '@react-navigation/core';
import C, {apply} from 'consistencss';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import {profile} from '../App';
import {FightCard} from '../comp/AddCard';
import {CloseButton, ResourcesMap, Spinner} from '../comp/Box';
import {CardModal} from '../comp/Card';

import Cell from '../comp/Cell';
import EmojiBubble from '../comp/EmojiBubble';
import {FullModal} from '../comp/Modals';
import {SpinIcon} from '../comp/ProgressBar';
import {bordColor, colors, isIOS, isWide, shadow, textSize} from '../design/gStyles';
import Game from '../layouts/Game';
import {screens} from '../routes';
import {CHESS_SIZE} from '../stores/boardStore';
import {LevelSelector, levelsMap} from '../utils/levels';
import {unitsMap} from '../utils/sets';
import {chessColor} from '../utils/utils';

const ChessMoji = observer(({route}) => {
    const currentBoard = profile.boards.battleMap;
    const [showUnits, setShowUnits] = useState(false);
    const [enemyLevel, setEnemyLevel] = useState(null);
    const [showInfo, setShowInfo] = useState(!enemyLevel);
    const [myTurn, setMyTurn] = useState(true);
    const [attack, setAttack] = useState(false);
    const [moveMode, setMoveMode] = useState(false);
    /** todo const initPos = route?.params?.harvestList || athensShift;*/
    const {navigate, goBack, canGoBack} = useNavigation();
    /*useEffect(() => {
      if (!currentBoard.cells[63].icon) {
        currentBoard.addUnits({currLevel: enemyLevel});
        currentBoard.addUnits({currLevel: profile.level, isEvil: false});
        /!*profile.addEnemies(enemyLevel);
        profile.addOwnUnits();*!/
      }
    }, [enemyLevel]);*/

    const enemyMove = () => {
        setTimeout(() => {
            let couldMove = false;
            let i = 100;
            do {
                couldMove = currentBoard.randomMove();
                i--;
            } while (!couldMove && i > 0);
            setMyTurn(true);
            /**Defaults the move*/
            currentBoard.setCurrent();
        }, 1500);
    };

    const blockingEvent = (showInfo && !enemyLevel) || attack; //moves <= 0 || shouldBlink;
    return (
        <ScrollView contentContainerStyle={[C.itemsCenter, C.minhFull, C.wFull, isIOS && C.py6]}>
            <ResourcesMap list={profile.resources}/>
            {/**Overlapping Info Pannel*/}
            {blockingEvent && (
                <CardModal
                    Content={
                        showInfo ? (<View style={[{zIndex: 500}]}>
                                <LevelSelector
                                    levels={levelsMap.chess}
                                    onSet={(level) => {
                                        setShowInfo(false);
                                        setEnemyLevel(level - 1);
                                        currentBoard.addUnits({currLevel: level});
                                        currentBoard.addUnits({currLevel: profile.level, isEvil: false});
                                    }}
                                />
                            </View>
                        ) : (
                            <View
                                style={[C.row, C.itemsCenter, /*bordColor(colors.wood, 2), C.radius2,*/ C.p2, C.justifyCenter]}>
                                <CloseButton wrapStyle={C.top0} onPress={() => setAttack(false)}/>
                                {/**Evil unit*/}
                                <View style={[C.itemsCenter, C.top_10]}>
                                    <SpinIcon textStyle={[textSize.L, shadow(colors.salmon, 7), C.p4]}/>
                                    {attack.evil && (
                                        <FightCard
                                            unit={attack.evil}
                                            currLife={attack.evil?.life}
                                            onSet={() => currentBoard.currCell.unit.impactAttack(unitsMap[attack.evil.icon].skills['⚔️'])}
                                            border={colors.enemy}
                                        />
                                    )}
                                </View>

                                <Spinner list={['🤼‍♀️', '🤼‍♂️']} size={3} wrapStyle={C.mx4}/>

                                {/**Own*/}
                                <View style={[C.itemsCenter, C.contentCenter, C.bottom_10]}>
                                    {attack.own && (
                                        <FightCard
                                            unit={attack.own}
                                            currLife={attack.own?.life}
                                            own
                                            onSet={() => currentBoard.currCell.unit.impactAttack(unitsMap[attack.evil.icon].skills['⚔️'])}
                                        />
                                    )}
                                    <SpinIcon textStyle={[textSize.L, shadow(colors.blue, 7), C.m2]}/>
                                </View>
                            </View>
                        )
                    }
                />
            )}
            {/**Chess board*/}
            {/* <MapWrapper boardMap={currentBoard} zoomMin={currentBoard.zoomMin} zoomMax={currentBoard.zoomMax} initPos={troy}>*/}
            <FlatList
                data={currentBoard.cells}
                keyExtractor={({id}) => id}
                numColumns={CHESS_SIZE}
                style={apply(C.my6, blockingEvent && C.opacity25, {flexGrow: 0})}
                contentContainerStyle={[bordColor(colors.wood + '80', 3), C.radius2, isWide && C.m2]}
                extraData={currentBoard.currCell}
                scrollEnabled={false}
                renderItem={({item, index}) => {
                    const availMove =
                        /*moveMode &&*/
                        !attack &&
                        myTurn &&
                        currentBoard.isCurrUnit &&
                        !item.icon &&
                        !currentBoard.isCurrEvil &&
                        currentBoard.adjacentDiagIds.includes(index);

                    const availAttack =
                        /*moveMode &&*/
                        !attack &&
                        myTurn &&
                        currentBoard.isCurrUnit &&
                        item.isEvil &&
                        !currentBoard.isCurrEvil &&
                        currentBoard.adjacentDiagIds.includes(index);
                    const isSel = currentBoard.currCellId === index;
                    const highlightColor = availMove
                        ? colors.grass
                        : item.unit
                            ? item.isEvil
                                ? colors.enemy /*'red'*/ /*colors.enemy*/
                                : colors.water /*+ '90'*/
                            : 'transparent';

                    const onPress = () => {
                        if (availMove) {
                            /**Move*/
                            currentBoard.moveToCell(index, undefined, false);
                            setMyTurn(false);
                            currentBoard.setCurrent(index);
                            setAttack(false);
                            enemyMove();
                            profile.unitModal.closeModal();
                        } else if (isSel) {
                            setMoveMode(!moveMode);
                        } else if (availAttack) {
                            setAttack({evil: item.unit, own: currentBoard.currCell.unit});
                        } else {
                            currentBoard.setCurrent(index);
                            setShowUnits(true);
                            /* if (!item.icon) {
                                profile.unitModal.toggleModal();
                              } else {
                                profile.unitModal.closeModal();
                              }*/
                            /*setMoveMode(false);*/
                            setAttack(false);
                        }
                    };
                    return (
                        <Cell
                            iconOpacity={availMove && 0.4}
                            availAttack={availAttack && '⚔️'}
                            /*size={isWide ? cell.Lg : cell.Sm}
                            iconSize={isWide ? textSize.L : textSize.L}*/
                            cShadow={shadow(highlightColor, 8)}
                            onPress={onPress}
                            bg={
                                /*item.isNature
                                    ? 'transparent'
                                    :*/ item.unit
                                    ? item.isEvil
                                        ? colors.enemy + '20'
                                        : colors.blue + '20'
                                    : availMove
                                        ? colors.grass /*(!item.unit && !item.icon) || item.unit
                    ? item.isEvil
                      ? colors.enemy + '20'
                      : colors.blue + '20'
                    : */
                                        : /* !item.unit && !item.icon*/
                                        chessColor(index) + '40' /*'transparent'*/ /*: availAttack && 'red'*/
                                /* ? colors.greenDark + '20'
                                      : colors.groundSand + '30'
                                    : 'transparent' */
                            }
                            cBord={bordColor(
                                item.unit
                                    ? item.isEvil
                                        ? colors.enemy
                                        : colors.blue
                                    : availMove
                                        ? colors.greenDark
                                        : availAttack
                                            ? colors.enemy
                                            : isSel
                                                ? colors.grass
                                                : colors.white,
                                isSel ? (item.isNature ? 0 : 3) : availAttack || availMove ? 2 : item.unit && 1,
                            )}
                            index={index}
                            icon={availMove ? currentBoard.currCell.icon : item.icon}
                            /* wrapStyle={C.radius2}*/
                            currCellId={currentBoard.currCellId}
                            item={item}
                        />
                    );
                }}
            />


            {
                /* <FlatList
                    data={Object.entries(unitsMap)}
                    style={apply(C.my2, C.absolute, C.top40)}
                    keyExtractor={(item) => item.key}
                    horizontal
                    contentContainerStyle={[C.itemsCenter, C.absolute, C.top40]}
                    initialScrollIndex={0}
                    extraData={profile.level}
                    renderItem={({item, index}) => (
                      <AddCard
                        stats={item[1]}
                        isBuilding
                        index={index}
                        unitIcon={item[0]}
                        onSet={() => {
                          profile.buyUnit(item[0]);
                          /!*modal.closeModal();*!/
                        }}
                      />
                    )}
                  />*/
                /*  ))}*/

                /*!!myTurn && !!attack &&*/ <FullModal title={'⚔️ Fight!'} visible={false /*!!myTurn && !!attack*/}>
                <View
                    style={[C.row, C.mt14, C.itemsCenter, /*bordColor(colors.wood, 2), C.radius2,*/ C.p2, C.justifyCenter]}>
                    {/**Evil unit*/}
                    <View style={[C.itemsCenter, C.top_10]}>
                        {myTurn ? (
                            <SpinIcon
                                onResult={() => {
                                    attack.evil.life = attack.evil.life / 2;
                                    setMyTurn(!myTurn);
                                }}
                                textStyle={[textSize.L, shadow(colors.salmon, 7), C.p4]}
                            />
                        ) : (
                            <View style={[C.p4, C.radius6, C.bgBlue]}/>
                        )}
                        {attack.evil && (
                            <FightCard
                                unit={attack.evil}
                                currLife={attack.evil?.life}
                                onSet={() => currentBoard.currCell.unit.impactAttack(unitsMap[attack.evil.icon].skills['⚔️'])}
                                border={colors.enemy}
                            />
                        )}
                    </View>

                    <Spinner list={['🤼‍♀️', '🤼‍♂️']} size={3} wrapStyle={C.mx4}/>

                    {/**Own*/}
                    <View style={[C.itemsCenter, C.contentCenter, C.bottom_10]}>
                        {attack.own && (
                            <FightCard
                                unit={attack.own}
                                currLife={attack.own?.life}
                                own
                                onSet={() => currentBoard.currCell.unit.impactAttack(unitsMap[attack.evil.icon].skills['⚔️'])}
                            />
                        )}
                        {!myTurn && (
                            <SpinIcon
                                onResult={() => {
                                    attack.own.life = attack.own.life / 2;
                                    setMyTurn(!myTurn);
                                }}
                                textStyle={[textSize.L, shadow(colors.blue, 7), C.m2]}
                            />
                        )}
                    </View>
                </View>
            </FullModal>
            }

            <EmojiBubble
                showOps
                chatMsg={!myTurn && 'Your oponent is 🤔 thinking...'}
                onPressWorld={() => navigate(screens.Planet.name)}
                /*onPressLevel={() => setShowInfo(!showInfo)}*/
                onPressUnits={(item) => profile.buyUnit(item)}

                currProfile={profile}
                emoji={profile.emoji}
                flag={profile.flag}
            />
        </ScrollView>
    );
});

export default () => (
    <Game
        GameComp={<ChessMoji/>}
        emoji={'♟️'}
        title={'ChessMoji'}
        subtitle={'Move and attack with your units to defeat the opponent'}
    />
);
