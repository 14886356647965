import C from 'consistencss';
import GoogleMapReact from 'google-map-react';
import React, {useRef, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import MapView, {Marker} from 'react-native-maps';
import {WrapMoji} from '../comp/WrapMoji';
import {colors, deviceHeight, deviceWidth, fullAbs, isIOS, isNarrow, isWeb, isWide} from '../design/gStyles';

export const monegros = {latitude: 41.0767553, longitude: 0.4198262};
export const greekBeach = {latitude: 37.0126013, longitude: 25.0586163};
export const laussane = {latitude: 46.819962, longitude: 6.523597};
export const melilla = {latitude: 35.292522, longitude: -1.936471};
export const barcelona = {latitude: 41.3851, longitude: 2.1734};
export const montRebei = {latitude: 41.5122013, longitude: 0.624124};
export const lleida = {latitude: 41.6176, longitude: 0.62};
export const paris = {latitude: 48.8566, longitude: 2.3522};
export const troy = {latitude: 39.75, longitude: 26.238889};
export const athens = {latitude: 37.994212, longitude: 24.7567281};

export const lengthLat = 111320; //km
export const lengthLong = 111.32; //km
export const zoomToMeter = {
    20: 1128.49722,
    19: 2256.99444,
    18: 4513.98888,
    17: 9027.977761,
    16: 18055.95552,
    15: 36111.91104,
    14: 72223.82209,
    13: 144447.6442,
    12: 288895.2884,
    11: 577790.5767,
    10: 1155581.153,
    9: 2311162.307,
    8: 4622324.614,
    7: 9244649.227,
    6: 18489298.45,
    5: 36978596.91,
    4: 73957193.82,
    3: 147914387.6,
    2: 295828775.3,
    1: 591657550.5,
};

/**Todo add .dotenv*/
const API_KEY = 'AIzaSyDk8TAf-3K3XDHmv2D0TqtusXc9cdp_poY';
const webMarker = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 18,
    height: 18,
    zIndex: 1,
};
export const MapCellWrapper = ({children, onPress, coord = barcelona}) => {
    return isWeb ? (
        React.cloneElement(children, {onPress})
    ) : (
        <Marker onPress={onPress} coordinate={{...coord}}>
            {children}
        </Marker>
    );
};

/*todo improve scale system :
   export const zoomFactors = {
  continent: {zoomMin: 4, zoomMax: 8, scale: 2.4, offset: {lat: 1.8, long: 2.5}},
  village: {zoomMin: 4, zoomMax: 8, scale: 2.4, offset: {lat: 1.8, long: 2.5}},
  battle: {zoomMin: 4, zoomMax: 8, scale: 2.4, offset: {lat: 1.8, long: 2.5}},
};*/

export const zoomLatLength = (zoom) =>
    /*zoom > 13
      ? 0.0008
      : */ zoom > 12
    ? isWide
        ? 0.006
        : 0.0022
    : zoom < 9
        ? isWide
            ? 2.8
            : 2.3
        : isWide
            ? 0.11
            : isNarrow
                ? 0.13
                : isIOS
                    ? 0.14
                    : 0.16;
export const MapWrapper = ({
                               boardMap,
                               children,
                               zoomMax = 6,
                               onPress,
                               zoomMin = 4,
                               initPos = laussane,
                               wrapStyle = {height: '100vh', width: '100%'},
                           }) => {
    const posOffset = {
        latitude:
            initPos.latitude +
            zoomLatLength(zoomMin) * (zoomMin > 11 ? (isWide ? 3.3 : 2) : zoomMin < 9 ? 1.8 : isWide ? 3 : 2.5),
        longitude:
            initPos.longitude +
            zoomLatLength(zoomMin) * (zoomMin > 11 ? (isWide ? 2.8 : 3.5) : zoomMin < 9 ? 2.6 : isNarrow ? 3.4 : 3),
    };
    const [showCompass, setShowCompass] = useState(false);
    const mapRef = useRef(null);

    return isWeb ? (
        <TouchableOpacity activeOpacity={1} style={wrapStyle} onPress={onPress}>
            <GoogleMapReact
                ref={(map) => (mapRef.current = map)}
                bootstrapURLKeys={{key: API_KEY}}
                yesIWantToUseGoogleMapApiInternals
                onCenterChange={() => {
                    setShowCompass(true);
                }}
                options={(maps) => ({
                    styles: mapNew,
                    /*mapTypeId: /!*boardMap?.terrain ||*!/ maps.MapTypeId.SATELLITE,*/
                    streetViewControl: false,
                    scaleControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'greedy',
                    /* disableDoubleClickZoom: true,*/
                    minZoom: zoomMin,
                    maxZoom: zoomMax,
                    mapTypeControl: false,
                    zoomControl: false,
                    /*clickableIcons: false,*/
                })} // straight out of something like snazzymaps}
                defaultCenter={{lat: posOffset.latitude, lng: posOffset.longitude}}
                defaultZoom={zoomMin}>
                {children}
            </GoogleMapReact>
        </TouchableOpacity>
    ) : (
        <>
            <MapView
                /*provider={'google'}*/
                ref={(map) => (mapRef.current = map)}
                onPress={onPress}
                mapType={'satellite'}
                customMapStyle={mapNew}
                showCompass={true}
                zoomTapEnabled={true}
                showsBuildings={false}
                showsPointsOfInterest={false}
                loadingEnabled={true}
                tintColor={colors.fire}
                /*showsCompass={false}*/
                goFullScreen={false}
                onRegionChange={(region) => {
                    setShowCompass(true);
                }}
                showLeftPanel={true}
                showDownPanel={true}
                downPanelInRight={false}
                showsTraffic={false}
                zoomControlEnabled={false}
                maxZoomLevel={zoomMax - (zoomMin < 9 ? 3 : 1)}
                minZoomLevel={zoomMin - (zoomMin < 9 ? 3 : 1)}
                showsScale={false}
                showsIndoorLevelPicker={false}
                style={fullAbs}
                initialRegion={posOffset}>
                {children}
            </MapView>

            {setShowCompass && (
                <View style={[C.absolute, C.bottom2, C.right2]}>
                    <WrapMoji
                        emoji={'🧭'}
                        circBg
                        sizeNum={1}
                        onPress={() => {
                            mapRef.current.animateToRegion(
                                {
                                    ...posOffset,
                                    latitudeDelta: 0.00522 * 10000,
                                    longitudeDelta: (deviceWidth / deviceHeight) * 0.00522 * 10000,
                                },
                                1000,
                            );
                        }}
                    />
                </View>
            )}
        </>
    );
};

export const waterCellsEurope = [
    25,
    26,
    34,
    39,
    40,
    41,
    42,
    47,
    48,
    50,
    51,
    53,
    54,
    55,
    58,
    59,
    61,
    62,
    63,
    67,
    68,
    69,
    70,
    71,
    74,
    75,
    76,
    77,
    78,
    79,
    83,
    84,
    85,
    86,
    87,
    91,
    92,
    93,
    94,
    95,
    96,
    97,
    98,
    99,
    100,
    102,
    104,
    105,
    106,
    107,
    110,
    112,
    113,
    114,
    121,
];

export const mapNew = [
    {
        stylers: [
            {
                color: '#827c4f',
            },
        ],
    },
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#ebe3cd',
            },
        ],
    },
    {
        elementType: 'geometry.fill',
        stylers: [
            {
                weight: 1,
            },
        ],
    },
    {
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#523735',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#f5f1e6',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#c9b2a6',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#dcd2be',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ae9e90',
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffcc00',
            },
        ],
    },
    {
        featureType: 'administrative.neighborhood',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape',
        stylers: [
            {
                color: '#cabd58',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        stylers: [
            {
                color: '#201e0e',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dfd2ae',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: colors.a,
            },
            {
                saturation: -50,
            },
            {
                lightness: 25,
            },
        ],
    },
    {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: colors.b,
            },
            {
                saturation: -20,
            },
            {
                lightness: 60,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: colors.c,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#93817c',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: colors.d,
            },
            {
                saturation: -70,
            },
            {
                lightness: 5,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#447530',
            },
        ],
    },
    {
        featureType: 'road',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f1e6',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#fdfcf8',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f8c967',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#e9bc62',
            },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e98d58',
            },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#db8555',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#806b63',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dfd2ae',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#8f7d77',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#ebe3cd',
            },
        ],
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dfd2ae',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#3366cc',
            },
            {
                saturation: -40,
            },
            {
                lightness: 5,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#92998d',
            },
        ],
    },
];
