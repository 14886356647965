import gFire from 'firebase';
import {makeAutoObservable} from 'mobx';
import {profile} from '../App';
import {isWeb} from '../design/gStyles';
import {cuteAnimals, hugeAnimals, insects, invert, reptiles, rodents, roughAnimals, waterAnimals} from '../utils/sets';
import {boardsMap, BoardStore} from './boardStore';
import {INIT_MOVES} from './HarvestStore';
import {memojiRef} from './profileStore';

const boardInitState = {score: 0, currLevel: 1, moves: 8};

export const memojiLevels = [invert, insects, reptiles, rodents, cuteAnimals, roughAnimals, waterAnimals, hugeAnimals];

export const EvojiStore = ({score, moves, currLevel, allowDiag} = boardInitState) =>
    makeAutoObservable({
        /*score: score,
        addScore(newScore) {
          this.score += newScore;
        },*/
        currLevel: currLevel,
        setLevel(level = 5) {
            this.currLevel = level + 1;
        },
        /*fixIcons: false,
        get iconsSet() {
          return this.fixIcons ? this.fixIcons : levelsList[profile.level - 1];
        },*/

        /**Bonus*/
        moves: moves,
        addMoves(mov = -1) {
            this.moves += mov;
        },

        /**Shuffle*/
        hints: 1,
        addHint(hints = -1) {
            this.hints += hints;
        },
        /*shuffles: 1,
        shuffle() {
          if (this.shuffles > 0) {
            this.currentBoard = BoardStore(
              boardsMap.MEMOJI,
              this.iconsSet /!*this.fixIcons levelsList[this.currLevel - 1]*!/,
              CHESS_SIZE,
              allowDiag,
              true,
            );
            this.shuffles--;
          }
        },*/

        /**Map*/
        currentBoard: BoardStore(boardsMap.EVOJI, undefined, 4, false, false),

        /**History & Analytics candidates*/
        currentEmoji: null,
        strike: 0,
        prevIndex: null,
        swipeLeft(all = true) {
            this.currentBoard.moveCells();
            /*this.currentBoard.cells.map(({id, icon}) => {
              if (icon) {
                /!* move;*!/
              }
            });*/
        },
        matchPair(emoji, index) {
            if (this.currentEmoji) {
                //Second time ✅
                if (this.currentEmoji === emoji) {
                    this.currentBoard.cells[index].toggleActive(true);
                    this.prevIndex = null;
                    this.strike++;
                    profile.addScore(this.strike * 2);
                    this.addMoves(1);
                    this.collected.push(emoji);
                } else {
                    //Fail ❌
                    this.addMoves();
                    this.strike = 0;
                    this.currentBoard.cells[this.prevIndex].toggleActive(false);
                    this.currentBoard.currCell.toggleActive(false);
                }
                this.currentEmoji = null;
            } else {
                //First time ✅
                this.currentEmoji = emoji;
                this.prevIndex = index;

                this.currentBoard.cells[index].toggleActive(true);
            }
        },
        collected: [],
        lastHarvest: {}, //score, icon, size, isCombo
        maxCombo: {size: 0, icon: ''}, //icon, size

        /**Analytics, not yet configured on Mobile*/
        sendAnalytics(icon) {
            if (isWeb) {
                const analytics = {
                    id: profile.username || 'New User v3',
                    img: profile?.img || '',
                    gender: profile.gender,
                    flag: profile.flag,
                    icon: icon,
                    collected: this.collected,
                    level: this.currLevel,
                    score: profile.score,
                    size: this.strike,
                    timeStamp: new Date().getTime(),
                };
                gFire.analytics().logEvent('Memoji', analytics);
                memojiRef.add(analytics);
            }
        },

        reset(level) {
            this.sendAnalytics(this.currentEmoji?.icon || '');
            this.currentEmoji = '';
            this.collected = [];
            this.hints = 1;
            this.strike = 0;
            this.currLevel = level;
            this.moves = INIT_MOVES;
            this.currentBoard = BoardStore(
                boardsMap.MEMOJI,
                memojiLevels[level % memojiLevels.length],
                level + 1,
                false,
                true,
            );
        },
    });
