/*
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
*/
/*import {firebase} from './stores/firebase';*/
import {createNavigationContainerRef, NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import React, {useRef} from 'react';
import {imgs, isWeb, isWide} from './design/gStyles';
import Battle from './screens/Battle';
import Evoji from './screens/Evoji';
import FindMoji from './screens/FindMoji';
import Collect from './screens/HitMoji';
import MainMap from './screens/MainMap';
import Memoji from './screens/Memoji';
import Onboarding from './screens/Onboarding';
import Profile from './screens/Profile';
import Recruit from './screens/Recruit';
import Village from './screens/Village';
import Welcome from './screens/Welcome';
import {gAnalytics} from './stores/firebase';
import {buildingsMap, cuteAnimals, fruits, topEmojis, unitsMap} from './utils/sets';
import {View} from 'react-native';
import {HarvestLevelSelector, levelsMap} from './utils/levels';
import {profile} from './App';
import {EmojiCompat} from './comp/WrapMoji';
import C from 'consistencss';
import EmojiBubble from './comp/EmojiBubble';
import {GInput, LineageWrapper} from './comp/Box';

export const navigationRef = createNavigationContainerRef();

export function navigate(name, params) {
    if (navigationRef.isReady()) {
        // Perform navigation if the react navigation is ready to handle actions
        navigationRef.navigate(name, params);
    } else {
        // You can decide what to do if react navigation is not ready
        // You can ignore this, or add these actions to a queue you can call later
    }
}

export const screens = {
    Onboarding: {icon: '😜', Comp: Onboarding, name: 'emoji-games'},
    Welcome: {icon: '😜', Comp: Welcome, name: 'welcome'},
    SexMoji: {icon: '🧠', Comp: Memoji, name: 'sexMoji'},
    Memoji: {icon: '🧠', Comp: Memoji, name: 'meMoji'},
    FindMoji: {icon: '👀', Comp: FindMoji, name: 'findMoji'},
    Evoji: {icon: '🧬', Comp: Evoji, name: 'evoji'},
    Planet: {icon: '🌍', Comp: MainMap, name: 'planet'},
    Village: {icon: '🏘', Comp: Village, name: 'MojiLand'},
    Collect: {icon: '💥', Comp: Collect, name: 'hitMoji'},
    Recruit: {icon: '🏋️‍', Comp: Recruit, name: 'recruit'},
    Battle: {icon: '⚔️', Comp: Battle, name: 'battle'},
    Profile: {icon: '🧝‍', Comp: Profile, name: 'profile'},
};
const linking = {
    prefixes: [
        /* your linking prefixes */
        ['https://planetmoji.com', 'https://www.planetmoji.com', 'planetmoji://'],
    ],
    config: {
        /* configuration for matching screens with paths */
        screens: {
            Onboarding: 'main',
            Memoji: 'memoji',
            Evoji: 'evoji',
            Welcome: 'welcome',
            Planet: 'conquer',
            Village: 'village',
            Collect: 'collect',
            Recruit: 'recruit',
            Battle: 'battle',
            Profile: 'profile',
            NotFound: '*',
        },
    },
};

export const safeBack = ({canGoBack, goBack}) =>
    canGoBack() ? goBack() : navigate(isWeb ? screens.Onboarding.name : screens.Welcome.name);
export const tabs = [screens.Planet, screens.Village, screens.Collect, screens.Battle, screens.Profile];
const Stack = createStackNavigator();

export const Navigator = () => {
    /*const navigationRef = useRef();*/
    const routeNameRef = useRef();
    return (
        <NavigationContainer
            linking={linking}
            ref={navigationRef}
            onReady={() => (routeNameRef.current = navigationRef.current.getCurrentRoute().name)}
            onStateChange={async () => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef.current.getCurrentRoute().name;

                if (previousRouteName !== currentRouteName) {
                    await gAnalytics.logEvent('screen_' + currentRouteName, {
                        screen_name: currentRouteName,
                        screen_class: currentRouteName,
                    });
                }

                // Save the current route name for later comparison
                routeNameRef.current = currentRouteName;
            }}>
            <Stack.Navigator
                initialRouteName={isWeb ? screens.Onboarding.name : screens.Planet.name}
                screenOptions={({route, navigation}) => ({
                    headerShown: false,
                    gestureEnabled: true,
                    cardOverlayEnabled: true,
                })}>
                {Object.values(screens).map(({name, icon, badge, Comp}, index) => (
                    <Stack.Screen key={name} name={name} options={{title: '🌍 PlanetMoji | ' + icon + name}}
                                  component={Comp}/>
                ))}
            </Stack.Navigator>
        </NavigationContainer>
    );
};
export const topGames = [
    /*{
        icon: '❤️‍🔥',
        emojis: sex,
        title: 'SexMoji',
        text: 'Truth or dare eMoji Questions',
        cta: 'Play',
        screen: screens.Memoji.name,
        instagram: 'https://www.instagram.com/guessmoji/',
        multiPlayer: false,
        img: imgs.mockCollect,
    },*/ {
        icon: '🧠',
        emojis: cuteAnimals,
        title: 'MeMoji',
        text: 'Dare your memory finding pairs',
        cta: 'Play',
        screen: screens.Memoji.name,
        instagram: 'https://www.instagram.com/guessmoji/',
        multiPlayer: false,
        img: imgs.mockCollect,
    },
    {
        icon: '👀',
        emojis: topEmojis,
        title: 'FindMoji',
        text: 'Spot the right eMoji faster than others',
        screen: screens.FindMoji.name,
        multiPlayer: false,
        img: imgs.mockCollect,
    },
    {
        icon: '💥',
        emojis: fruits,
        title: 'HitMoji',
        text: 'Match 3 or more eMojis',
        multiPlayer: false,
        /*cta: 'Practice',*/
        ExtraComp: () => (
            <View>
                {/**Covid*/}
                {/*
          <BonusLevelSelector iconSet={covid} title={'Fight COVID'}  icon={'🦠'} />
*/}
                {/**Friday*/}
                {/*
          <BonusLevelSelector iconSet={drinks} title={'Friday mood'}  icon={'🍹'} />
*/}
                {/**Other levels*/}
                <HarvestLevelSelector
                    list={levelsMap.find}
                    size={isWide ? 4 : 3}
                    small={!isWide}
                    currLevel={profile.level}
                />
            </View>
        ), //() => fruits.map((item) => <EmojiCompat emoji={item} sizeNum={isWide ? 1 : 0} />),
        screen: screens.Collect.name,
        img: imgs.mockCollect,
    },
    /*{
        icon: '🧬',
        emojis: evolution.slice(1),
        title: 'Evoji',
        text: 'Follow the evolution path',
        cta: 'Play',
        screen: screens.Memoji.name,
        multiPlayer: false,
        img: imgs.mockCollect,
    },*/
    /*{
        icon: '🍹️',
        emojis: drinks.slice(1),
        title: 'GuessMoji',
        multiPlayer: true,
        text: 'Who can better mimic your eMoji?',
        ExtraComp: () => Object.keys(unitsMap).map((item) => <EmojiCompat emoji={item} sizeNum={isWide ? 1 : 0}/>),
        screen: screens.Battle.name,
        img: imgs.mockFight,
    },*/
    /*{
        icon: '⛵',
        emojis: ships,
        title: 'SinkMoji',
        multiPlayer: true,
        text: 'Discover the hidden fleet',
        ExtraComp: () => Object.keys(unitsMap).map((item) => <EmojiCompat emoji={item} sizeNum={isWide ? 1 : 0}/>),
        screen: screens.Battle.name,
        img: imgs.mockFight,
    },*/
    {
        icon: '♟️',
        emojis: Object.keys(unitsMap),
        title: 'ChessMoji',
        text: 'Take your strategy to the battle field',
        ExtraComp: () => Object.keys(unitsMap).map((item) => <EmojiCompat emoji={item} sizeNum={isWide ? 1 : 0}/>),
        screen: screens.Battle.name,
        img: imgs.mockFight,
    },
];

export const conquestWorlds = [
    {
        icon: '💥',
        emojis: fruits,
        title: 'HitMoji',
        text: 'Match 3 or more eMojis',
        /*cta: 'Practice',*/
        ExtraComp: () => (
            <View>
                {/**Covid*/}
                {/*  <BonusLevelSelector iconSet={covid} title={'Fight COVID'}  icon={'🦠'} />*/}
                {/**Friday*/}
                {/*<BonusLevelSelector iconSet={drinks} title={'Friday mood'}  icon={'🍹'} />*/}
                {/**Other levels*/}
                <HarvestLevelSelector
                    list={levelsMap.hit}
                    size={4}
                    small={!isWide}
                    currLevel={10} //profile.level}
                />
            </View>
        ), //() => fruits.map((item) => <EmojiCompat emoji={item} sizeNum={isWide ? 1 : 0} />),
        screen: screens.Collect.name,
        img: imgs.mockCollect,
    },
    {
        icon: '🌍',
        code: 'crossed_swords',
        emojis: Object.keys(buildingsMap),
        title: 'WorldMoji',
        text: 'Raise your empire, spread your lineage',
        /*cta: 'Play demo',*/
        screen: screens.Planet.name,
        ExtraComp: () => (
            <View style={[C.itemsCenter]}>
                <EmojiBubble currProfile={profile} ModalComp={<LineageWrapper currProfile={profile}/>}/>
                <GInput/>
            </View>
        ),
        img: imgs.mockMap,
    },
    {
        icon: '🛖',
        title: 'MojiLand',
        emojis: Object.keys(buildingsMap).slice(4, 8),
        text: 'Expand your village from the stone age',
        ExtraComp: () => Object.keys(buildingsMap).map((item) => <EmojiCompat emoji={item}
                                                                              sizeNum={isWide ? 1 : 0}/>),
        screen: screens.Village.name,
        img: imgs.mockVillage,
    },
    {
        icon: '🥷',
        emojis: Object.keys(unitsMap),
        title: 'ChessMoji',
        text: 'Take your strategy to the battle field',
        ExtraComp: () => Object.keys(unitsMap).map((item) => <EmojiCompat emoji={item} sizeNum={isWide ? 1 : 0}/>),
        screen: screens.Battle.name,
        img: imgs.mockFight,
    },
];
