import {useNavigation} from '@react-navigation/core';
import C, {apply} from 'consistencss';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FlatList, ScrollView, Text, TextInput, View} from 'react-native';
import {profile} from '../App';

import Box, {LineageWrapper, ResourcesMap, Tag} from '../comp/Box';
import EmojiBubble from '../comp/EmojiBubble';
import {TrackBar} from '../comp/ProgressBar';
import {EmojiCompat} from '../comp/WrapMoji';
import {bgColor, colors, gradGold, isWeb, shadow, textSize} from '../design/gStyles';
import Game from '../layouts/Game';
import {screens} from '../routes';
import {fonts} from '../design/fonts';

import {comboRef} from '../stores/profileStore';
import {LevelSelector} from '../utils/levels';
import {characters, tools} from '../utils/sets';

const Profile = observer(() => {
    const levProgress = profile.level / 10;
    const levColor = gradGold(levProgress).toString();
    const {goBack, navigate} = useNavigation();
    const sortCollected = Object.entries(profile.currHarvest.collected).sort((a, b) => b[1] - a[1]);
    return (
        <ScrollView contentContainerStyle={apply(C.itemsCenter)}>
            {/**Emoji setter*/}
            <EmojiBubble
                ModalComp={<LineageWrapper currProfile={profile}/>}
                currProfile={profile}
                emoji={profile.emoji}
                flag={profile.flag}
            />
            <View style={apply(bgColor(colors.paleGreyTwo), C.itemsCenter, C.radius2, C.row, C.mt6)}>
                <EmojiCompat emoji={'📜'} sizeNum={0}/>

                <TextInput
                    onChangeText={(text) => profile.setName(text)}
                    /*onSubmitEditing={() => profile.submitMail()}*/
                    value={profile.username}
                    style={[C.px2, C.pt1, fonts.body2, C.textBlue]}
                    placeholder={'Carlos Smith'}
                />
                {profile.username && (
                    <Text onPress={() => profile.setName('')} style={C.mx2}>
                        {profile.loading ? '⏳' : profile.isMailValid ? '✅' : '❌'}
                    </Text>
                )}
            </View>
            <LevelSelector currLevel={profile.level} levels={characters[profile.gender]}/>
            {/**Level*/}
            {/*<View style={[C.itemsCenter]}>
        <View style={apply(C.row, C.my5, C.itemsCenter)}>
          <Box icon={'⭐️️'} text={profile.level} value={'/ 10'} border={false} />
          <TrackBar
            progress={levProgress}
            colGrad={[colors.sand, colors.grass]}
            colAccent={levColor}
            colBord={colors.sand}
            maxWidth={deviceWidth * 0.7}
            height={12}
          />
        </View>*/}
            {/**Resoruces*/}
            <Text style={apply(fonts.title2, C.mt8, C.mb2, C.textLeft)}>Resources </Text>
            <ResourcesMap/>
            {/**Collected*/}
            <View style={[C.row, C.mt8, C.itemsCenter]}>
                <EmojiCompat emoji={'🧺'} sizeNum={1}/>
                <Text style={apply(fonts.title2, C.ml2, C.textLeft)}>Collected</Text>
            </View>
            <FlatList
                horizontal
                initialScrollIndex={0}
                style={C.py1}
                keyExtractor={({item, index}) => index}
                data={sortCollected}
                renderItem={({item}) => (
                    <Box
                        onPress={() => navigate(screens.Collect.name)}
                        key={item[0]}
                        horiz={sortCollected.length < 5}
                        icon={item[0]}
                        text={item[1]}
                    />
                )}
            />
            {/**Buildings*/}
            <View style={[C.row, C.mt8, C.itemsCenter]}>
                <EmojiCompat emoji={'🏰'} sizeNum={1}/>
                <Text style={apply(fonts.title2, C.ml2, C.textLeft)}>Buildings</Text>
            </View>
            <FlatList
                horizontal
                data={Object.entries(profile.buildingsList)}
                ListEmptyComponent={<Tag text={'Build'} icon={'🏰'}/>}
                keyExtractor={(item) => item[0]}
                renderItem={({item}) => (
                    <View style={C.m4}>
                        <Text style={textSize.L}>{item[0]}</Text>
                        {item[1] > 1 && <Text style={fonts.subtitle}>{item[1]}</Text>}
                        {/*<Badge text={'⚔️'} left top />*/}
                    </View>
                )}
            />
            {/**Units*/}
            <View style={[C.row, C.mt8, C.itemsCenter]}>
                <EmojiCompat emoji={'🥷🏻'} sizeNum={1}/>
                <Text style={apply(fonts.title2, C.ml2, C.textLeft)}>Units</Text>
            </View>
            <FlatList
                horizontal
                data={Object.entries(profile.units)}
                keyExtractor={(item) => item[0]}
                ListEmptyComponent={
                    <Tag text={'Recruit'} icon={'🏋️‍'} col={colors.blue}
                         onPress={() => navigate(screens.Recruit.name)}/>
                }
                renderItem={({item}) => (
                    <View style={C.m4}>
                        <Text style={textSize.Md}>{item[0]}</Text>
                        <TrackBar progress={levProgress}/>
                        {item[1] > 1 && <Text style={fonts.subtitle}>{item[1]}</Text>}
                    </View>
                )}
            />
            {/**Skills*/}
            <View style={[C.row, C.mt8, C.itemsCenter]}>
                <EmojiCompat emoji={'🧪'} sizeNum={1}/>
                <Text style={apply(fonts.title2, C.ml2, C.textLeft)}>Skills</Text>
            </View>
            <FlatList
                horizontal
                data={tools}
                keyExtractor={(item) => item[0]}
                renderItem={({item}) => (
                    <View style={[C.m4, C.shadowMd, C.px1, C.radius5, shadow(colors.blue)]}>
                        <EmojiCompat emoji={item}/>
                        {/*<TrackBar progress={levProgress} />
            {item[1] > 1 && <Text style={fonts.subtitle}>{item[1]}</Text>}*/}
                    </View>
                )}
            />
            <Tag
                text={'Reset'}
                wrapStyle={C.mt4}
                col={colors.enemy}
                icon={'🔄'}
                onPress={() => {
                    profile.reset();
                    navigate(isWeb ? screens.Onboarding.name : screens.Welcome.name);
                }}
            />
        </ScrollView>
    );
});

export default () => (
    <Game
        GameComp={<Profile/>}
        emoji={'👥️'}
        scoreRef={comboRef}
        title={profile.username || 'Profile'}
        subtitle={'Set your resources'}
    />
);
