import C, {apply} from 'consistencss';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {profile} from '../App';
import {BASE_PIXEL, bordColor, colors, gradLife, shadow} from '../design/gStyles';
import {Column, LevelStar} from './Box';
import {TrackBar} from './ProgressBar';
import {EmojiCompat} from './WrapMoji';
import {fonts} from '../design/fonts';

export default ({
                    unitIcon,
                    stats,
                    isBuilding = false,
                    border,
                    own = false,
                    isFight = false,
                    currLife = stats?.skills['❤️'] || 10,
                    onSet = profile.buyUnit,
                }) => {
    /*const {level, cost, score, skills} = stats;*/
    const tooExpensive =
        stats?.cost && Object.entries(stats?.cost).some(([res, currCost], index) => currCost > profile.resources[res] || 0);
    const isReady = profile.level >= stats?.level;
    const forbidden = !isReady || tooExpensive || (!isBuilding && profile.populationExceeded);
    const highlightColor = border || (forbidden ? colors.blueGrey : colors.blue);
    return (
        <View
            style={apply(
                C.m1,
                own ? C.p2 : C.p1,
                C.bgWhite,
                C.radius2,
                bordColor(highlightColor, 1),
                C.shadowMd,
                shadow(highlightColor, 2),
            )}>
            <TouchableOpacity
                activeOpacity={0.4}
                style={apply(!own && forbidden && C.opacity20, C.itemsCenter)}
                onPress={() => !forbidden && onSet(unitIcon)}>
                <View style={apply(C.row, C.itemsCenter, C.justifyBetween, C.minw14, isFight && C.mb2)}>
                    {stats.level && <LevelStar level={stats.level} size={0} wrapStyle={{top: -2}}/>}
                    <Text style={[fonts.digit2]}>
                        ❤️{isFight && currLife + ' /'} {stats?.skills['❤️']}
                    </Text>
                </View>
                {isFight && (
                    <TrackBar
                        maxWidth={20 * BASE_PIXEL}
                        colAccent={gradLife(currLife / stats?.skills['❤️']).toString()}
                        progress={currLife / stats?.skills['❤️']}
                    />
                )}
                <EmojiCompat emoji={unitIcon} extraStyle={C.m1} sizeNum={2}/>
                {/**Skills*/}
                <View style={C.row}>
                    {Object.entries(stats?.skills).map(
                        ([skill, amount]) => amount > 0 && skill !== '❤️' && <Column text={skill} val={amount}/>,
                    )}
                </View>

                {!currLife && (
                    <View style={C.row}>
                        {Object.entries(stats?.cost).map(
                            ([res, resCost]) => resCost > 0 && <Column text={res} col={colors.salmon} val={resCost}/>,
                        )}
                    </View>
                )}
            </TouchableOpacity>
        </View>
    );
};

export const FightCard = ({unit, own = false, currLife = 10, onPress}) => {
    const {icon} = unit;
    const {level, skills} = unit?.unit;
    const highlightColor = !own ? colors.enemy : colors.blue;
    return (
        <View
            style={apply(
                C.m1,
                own ? C.p2 : C.p1,
                C.bgWhite,
                C.radius2,
                bordColor(highlightColor, 3),
                shadow(highlightColor, 2),
            )}>
            <TouchableOpacity activeOpacity={0.4} style={apply(C.itemsCenter)} onPress={onPress}>
                <View style={[C.row]}>
                    <View style={apply(C.mb2)}>
                        <View style={[C.row, C.itemsCenter, C.selfEnd]}>
                            {level && <LevelStar level={level} size={0} wrapStyle={{top: -2}}/>}
                            <Text style={fonts.caption}>
                                {currLife + ' /'} {skills['❤️']}
                            </Text>
                            <EmojiCompat emoji={'❤️'} sizeNum={0}/>
                        </View>
                        <TrackBar
                            maxWidth={20 * BASE_PIXEL}
                            colAccent={gradLife(currLife / skills['❤️']).toString()}
                            progress={currLife / skills['❤️']}
                        />
                    </View>
                </View>

                <EmojiCompat emoji={icon} extraStyle={C.m1} sizeNum={2}/>
                <View style={C.row}>
                    {Object.entries(skills).map(
                        ([skill, amount]) => amount > 0 && skill !== '❤️' && <Column text={skill} val={amount}/>,
                    )}
                </View>
            </TouchableOpacity>
        </View>
    );
};
