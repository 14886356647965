import C from 'consistencss';
import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {Pressable} from 'react-native-web-hover';
import {bgColor, colors, hover, imgs, isWide, shadow, URLS} from '../design/gStyles';
import {respImgSize} from '../utils/utils';
import {EmojiCompat} from './WrapMoji';
import {fonts} from '../design/fonts';

export const CardModal = ({Content, topPos = C.topQuarter}) => (
    <View
        style={[C.absolute, {zIndex: 500}, C.bgWhite, C.shadowMd, C.radius2, C.p3, C.alignCenter, topPos]}>{Content}</View>
);

export const Card = ({
                         icon,
                         instagram,
                         fixIcons = !isWide,
                         multiPlayer,
                         emojis,
                         text,
                         title,
                         subtitle,
                         onPress,
                         level,
                         blocked,
                     }) => (
    <View style={[C.minh60, C.itemsCenter, C.justifyCenter]}>
        <Pressable
            style={({hovered, focused, pressed}) => [
                !blocked && C.p2,
                C.m4,
                C.w50,
                C.shadowMd,
                bgColor(colors.white /*+ '30'*/),
                C.radius2,
                C.itemsCenter,
                shadow(colors.blue, 3),
                blocked && C.opacity50,
                hovered && hover.buttonHovered,
                focused && hover.buttonFocused,
                pressed && hover.buttonPressed,
            ]}
            onPress={onPress}>
            {({hovered, focused, pressed}) => (
                <>
                    <EmojiCompat emoji={icon} sizeNum={4}/>
                    <View style={[C.row, C.itemsCenter]}>
                        {(hovered || fixIcons) && emojis && <EmojiCompat emoji={emojis[0]} sizeNum={1}/>}
                        <Text style={[fonts.cta1, C.mx2]}>{title}</Text>
                        {(hovered || fixIcons) && emojis && <EmojiCompat emoji={emojis[1]} sizeNum={1}/>}
                    </View>
                    {/*<View style={[C.row]}>
            {hovered && !fixIcons && emojis.slice(0, 8).map((item) => <EmojiCompat emoji={item} sizeNum={0} />)}
          </View>*/}
                    <View style={[C.row, C.itemsCenter]}>
                        {(hovered || fixIcons) && emojis && <EmojiCompat emoji={emojis[2]} sizeNum={0}/>}
                        <Text style={[fonts.paragraph, C.mt2, C.mx2, C.alignCenter]}>{subtitle || text}</Text>
                        {(hovered || fixIcons) && emojis && <EmojiCompat emoji={emojis[3]} sizeNum={0}/>}
                    </View>
                </>
            )}
        </Pressable>
        {instagram && <TouchableOpacity style={[C.top4, C.absolute, C.right6, C.p2]}
                                        onPress={() => window.open(URLS.instaGuess, '_blank')}>
            <Image style={[respImgSize[1].img, C.radius2]} source={imgs.insta}/>
        </TouchableOpacity>}
        {/*{level && <EmojiCompat extraStyle={[C.top3, C.absolute, C.right3]} emoji={level} />}*/}
        {multiPlayer && (
            <EmojiCompat sizeNum={1} extraStyle={[C.top4, C.absolute, C.left6, C.top4]}
                         emoji={/*multiPlayer ?*/ '👥' /*: '👤'*/}/>
        )}
    </View>
);
