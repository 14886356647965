import C, {apply} from 'consistencss';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {badgeWrapper, bgColor, bordColor, colors, gradLife, shadow, textSize} from '../design/gStyles';
import {respImgSize} from '../utils/utils';
import {TrackBar} from './ProgressBar';
import {EmojiCompat, WrapMoji} from './WrapMoji';

export default ({
                    item,
                    index,
                    availAttack,
                    icon = item.icon,
                    currCellId,
                    opacity = currCellId === index ? 1 : 0.85, //|| !withTransp ? 1 : 0.85,
                    iconOpacity = 1,
                    /**sizes*/
                    size = 2,
                    iconSize = textSize.L,

                    /**wrap styles*/
                    bg = item.bg,
                    cBord = currCellId === index && bordColor(colors.water, 2),
                    cShadow = shadow(!!item.unit || (!!item.building && (item.isEvil ? 'red' : 'blue'))),
                    wrapStyle,

                    /**Booleans*/
                    showLife = currCellId === index && item.unit,
                    showRes = false,
                    showFlag = false,
                    withFlex = false,
                    onPress,
                }) => {
    return (
        <TouchableOpacity activeOpacity={0.7} style={C.itemsCenter} onPress={onPress} opacity={opacity}>
            <View
                style={apply(
                    C.itemsCenter,
                    iconOpacity && {opacity: iconOpacity},
                    respImgSize[size].wrap,
                    C.px1,
                    bgColor(bg),
                    cBord,
                    wrapStyle,
                )}>
                {<EmojiCompat sizeNum={size} emoji={icon}/>}
            </View>
            {showLife && <TrackBar grad={gradLife} progress={item.unit.lifePerc} wrapStyle={[C.absolute, {top: -2}]}/>}
            {/**Resource Collect*/}
            {showRes && item.availResources && (
                <WrapMoji emoji={item.availResources} onPress={onPress} sizeNum={0}
                          wrapStyle={[badgeWrapper, C.left0]}/>
            )}
            {/**Badge*/}
            {availAttack && (
                <WrapMoji
                    onPress={onPress}
                    emoji={availAttack}
                    sizeNum={0}
                    circBg
                    wrapStyle={[C.absolute, C.top_1, C.right0]}
                />
            )}
            {/**Flag*/}
            {showFlag && item.flag && (
                <WrapMoji
                    onPress={onPress}
                    emoji={item.flag}
                    forceText={false}
                    sizeNum={1}
                    wrapStyle={[C.absolute, C.top_4, C.left0]}
                />
            )}
        </TouchableOpacity>
    );
};
