import _ from 'lodash';
import {makeAutoObservable} from 'mobx';
import {Alert} from 'react-native';
import {profile} from '../App';
import {buildingsMap, characters, unitsMap} from '../utils/sets';
import {numFormat, objAdd, pickRandom} from '../utils/utils';
import {boardsMap, BoardStore, modalStore, VILLAGE_SIZE, WORLD_SIZE} from './boardStore';
import {EvojiStore} from './EvojiStore';
import {FindStore} from './FindStore';
import {db} from './firebase';
import {HarvestStore} from './HarvestStore';
import {MemojiStore} from './MemojiStore';

/**todo  rem
 * const initResources = () =>
 resources.reduce((item, accum, index) => {
    accum[item] = new ResourceStore(index, item, 150);
  }, {});*/
/*const provider = new firebase.auth.GoogleAuthProvider();*/
export const comboRef = db.collection('combos');
export const sexMojiRef = db.collection('sexmoji');
export const memojiRef = db.collection('memoji');
export const findMojiRef = db.collection('findMoji');
export const maxScoreRef = db.collection('maxScore');

export const DEFAULT_FLAG = '🇪🇸';

/*export const AuthStore = (isIA = false, level = 1) =>
  makeAutoObservable({
    user: null,
    async googleSignin() {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /!** @type {firebase.auth.OAuthCredential} *!/
          const credential = result.credential;

          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          const credential = error.credential;
        });
    },
  });*/

export const profileSchema = {
  flag: true,
  username: true,
  /*level: true,*/
  gender: true,
  score: true,
  /*todo
     board: {
    type: 'object',
    schema: {
      a: true,
      b: true,
    },
  },*/
  /*someArray: {
    type: 'list',
    schema: {
      c: true,
      d: true,
    },
  },*/
};

export const ProfileStore = () =>
  makeAutoObservable({
    /**Auth*/
    /*uid: '' /!*nanoid(12)*!/,*/
    /*setUser(user) {
      this.uid = user;
    },*/
    get isMailValid() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.username).toLowerCase());
    },
    username: null,
    img: '',
    setName(name) {
      this.username = name;
    },

    soundsOn: true,
    get volume() {
      return this.soundsOn ? 0.85 : 0;
    },
    toggleSounds() {
      this.soundsOn = !this.soundsOn;
    },
    gender: Object.keys(characters)[1],
    setGender(gender) {
      this.gender = gender;
    },
    get emoji() {
      return characters[this.gender || 0][this.level - 1];
    },
    flag: DEFAULT_FLAG,
    setFlag(emoji) {
      this.flag = emoji;
    },

    reset() {
      this.score = 1;
      this.flag = DEFAULT_FLAG;
      this.gender = Object.keys(characters)[1];
      this.resources = {};
      this.units = {};
    },

    /**Stats*/
    score: 1,
    addScore(newScore) {
      this.score += newScore;
    },
    get scoreForm() {
      return numFormat(this.score);
    },
    get remainingScore() {
      return Math.pow(10, this.level);
    },
    get remainingScoreForm() {
      return numFormat(this.remainingScore);
    },
    get level() {
      return Math.floor(Math.log10(this.score)) + 1;
    },

    /**Resources*/
    resources: {'💎': 150, '🪵': 150, '🥩': 150, '🪨': 150},
    addResource(matchIcon, comboSize = 2) {
      this.resources[matchIcon] += comboSize;
    },

    modal: modalStore(),

    /**Units*/
    units: {},
    enemy: {},

    buildingsList: [],
    flagModal: modalStore(),

    /**Units & Buildings*/
    unitModal: modalStore(),
    buildModal: modalStore(),

    /**Boards*/
    boards: {
      worldMap: BoardStore(boardsMap.WORLD, WORLD_SIZE),
      villageMap: BoardStore(boardsMap.VILLAGE, VILLAGE_SIZE),
      recruitMap: BoardStore(boardsMap.RECRUIT),
      harvestMap: BoardStore(boardsMap.HARVEST),
      /*fruitsMap: BoardStore(boardsMap.FRUITS),
      toolsMap: BoardStore(boardsMap.TOOLS),*/
      battleMap: BoardStore(boardsMap.BATTLE),
    },

    /**Population*/
    currPopulation: 0,
    maxPopulation: 5,
    get populationExceeded() {
      return this.currPopulation >= this.maxPopulation;
    },

    /**Harvesting*/
    currHarvest: HarvestStore(),
    currMemoji: MemojiStore(),
    currEvoji: EvojiStore(),
    currFind: FindStore(),

    /**Buildings*/
    buyBuilding(buildIcon = buildingsMap['⛺️'], board = this.boards.worldMap) {
      objAdd(this.buildingsList, buildIcon);
      const {cost, score, skills} = buildingsMap[buildIcon];

      /**Deduce resources*/
      Object.entries(cost).map(([res, amount]) => amount > 0 && (this.resources[res] -= amount));
      /**Update score & population*/
      this.score += score;
      if (skills['👨‍👩‍👧‍👦'] > 0) {
        this.maxPopulation += skills['👨‍👩‍👧‍👦'];
      }

      /**Add to board*/
      //board.setCellIcon(buildIcon, false, undefined, true);
      board.setCell({buildIcon, overwrite: false, flag: profile.flag});
    },

    /**Units*/
    buyUnit(unitIcon, board = this.boards.battleMap) {
      if (this.populationExceeded) {
        Alert.alert(
          'Population exceded: ' + this.currPopulation + ' / ' + this.maxPopulation,
          'Please get more score so you can achieve next population level',
        );
      } else {
        this.addUnit(unitIcon);
        const {cost, score} = unitsMap[unitIcon];
        /**Spend resources*/
        Object.entries(cost).map(([res, currCost]) => (this.resources[res] -= currCost));
        /**Update score*/
        this.score += score;

        /**Add to board*/
        board.setCurrent(board.setCell({unitIcon: unitIcon, overwrite: false}));
      }
    },
    addUnit(unit, quant = 1) {
      this.currPopulation += quant;
      objAdd(this.units, unit, quant);
    },
    addUnitsBoard(board = this.boards.battleMap) {
      Object.entries(this.units).map(([key, value]) =>
        _.range(value).map((item) => board.setCell({unitIcon: key, overwrite: false})),
      );
    },
    addEnemies(num = 16) {
      for (let i = 0; i < num; i++) {
        this.boards.battleMap.setCell({
          unitIcon: pickRandom(Object.keys(unitsMap), 1),
          overwrite: false,
          id: 15,
          isEvil: true,
        });
      }
    },
    addOwnUnits(num = 16, currLevel = this.level) {
      const availUnits = Object.entries(unitsMap)
        .filter(([key, {level}]) => level <= currLevel)
        .map(([key, val]) => key);

      for (let i = 0; i < num; i++) {
        this.boards.battleMap.setCell({
          unitIcon: pickRandom(availUnits, 1),
          overwrite: false,
          id: 63,
          isEvil: false,
        });
      }
    },
    addBoardUnits(num = 16, isEvil = true, currLevel = this.level) {
      const availUnits = Object.entries(unitsMap)
        .filter(([key, {level}]) => level <= currLevel)
        .map(([key, val]) => key);

      for (let i = 0; i < num; i++) {
        this.boards.battleMap.setCell({
          unitIcon: pickRandom(availUnits, 1),
          overwrite: false,
          id: isEvil ? 63 : 15,
          isEvil,
        });
      }
    },
  });
