import chroma from 'chroma-js';
import C, {apply, boxShadow} from 'consistencss';
import React from 'react';
import {Dimensions, Platform} from 'react-native';
import {isTablet} from 'react-native-device-info';

export const deviceHeight = Dimensions.get('window').height;
export const deviceWidth = Dimensions.get('window').width;

export const BASE_PIXEL = 4;
export const colors = {
    /**New Palette*/
    gold: '#ffcc00',
    ocean: '#3366cc',
    grassDark: '#66cc33',
    lip: '#cc3366',

    blue60: '#adbbdd',
    blue: '#355eb9',
    blue20: '#355eb920',
    black: '#020202',
    black40: 'rgba(0, 0, 0, 0.4)',
    wood: '#804000',
    salmon: '#c14e2b',
    flame: '#c14e2b',
    enemy: '#c12a48',
    fire: '#db8d2d',
    salmon40: '#c14e2b20',
    blueGrey: '#9399ac',
    grass: '#baf2b5',
    blueGreen: '#006637',
    greenDark: '#276622',
    green: '#418a6e',
    water: '#60a6d0',
    ground: '#d5db7a',
    groundSand: '#bc9c4f',
    sand: '#efd9a2',
    sand30: '#efd9a230',
    white: '#ffffff',
    /**Cards*/
    paleGrey: '#fcfaf6',
    paleGreyTwo: '#f8efdd',
    paleGreyThree: '#dee0e6',
    black12: 'rgba(0, 0, 0, 0.12)',
    greyDark: '#121212',
    grey: '#252c40',

    /**Emoji*/
    a: '#F9DF89',
    b: '#D69622',
    c: '#A16025',
    d: '#F6E358',
    e: '#F3F3FB',

    /**Palette*/
    /*oceanOld: '#3573d2',*/
    oceanBg: 'rgba(53,94,185,0.25)',
    dessert: '#e9e1c4',
    darkGreen: '#377441',
    crayola: '#a2b3c7',
};

export const gradBgColor = colors.gold + '60';

export const gradGold = chroma.scale([colors.sand, colors.groundSand, colors.flame]);
export const gradFull = chroma.scale(Object.values(colors));
export const grad = chroma.scale([colors.blueGreen, colors.blue, colors.salmon, 'red']);
export const gradLife = chroma.scale([colors.fire, colors.salmon, colors.grass, colors.green]);
export const gradFire = chroma.scale([colors.fire, colors.salmon, colors.flame, colors.sand]);

export const bgColor = (color = colors.blueGrey) => ({backgroundColor: color});
export const bordColor = (color = colors.blueGrey, width = 1) => ({
    borderColor: color,
    borderWidth: width,
});
export const textColor = (color) => ({color: color});

/**Responsiveness*/
export const isWeb = Platform.OS === 'web';
export const isTabl = isTablet();
export const isSmall = isWeb && deviceWidth < 450;
export const isBig = isTabl; //|| (isWeb && deviceWidth > 450);
export const isLarge = isTabl || (isWeb && deviceWidth > 600);
export const isWide = isTabl || (isWeb && deviceWidth > 450);
export const isNarrow = isWeb && deviceWidth < 380;
export const isPhoneBrowser = isWeb && deviceWidth < 480;
export const isIOS = Platform.OS === 'ios';

export const defFont = {fontFamily: 'Papyrus'};
export const bubblePos = [
    C.absolute,
    C.justifyEnd,
    isWeb ? (isWide ? C.bottom5 : !isNarrow ? [C.bottom36, C.left2] : [C.bottom6, C.left1]) : [C.bottom4, C.left2],
];

export const below = {zIndex: -99};
export const onTop = {zIndex: 99};

/**@deprecated*/
export const textSize = {
    Xs: [isWide ? C.font4 : C.font3, defFont],
    Sm: [isWide ? C.font6 : C.font4, defFont],
    Md: [isWide ? C.font9 : C.font8, defFont],
    L: [isWide ? C.font14 : !isNarrow ? C.font10 : C.font10, defFont],
    XL: [isWide ? C.font26 : C.font13, defFont],
};
/**@deprecated*/
export const cell = {
    Xs: apply(isWide ? [C.w10, C.w10, C.flex, C.minh10] : [C.w8, C.h8]), // 6 & 4
    Sm: apply(isWide ? [C.w16, C.w16, C.flex, C.minh16] : [C.w12, C.h12]), // 17 & 12
    Md: apply(isWide ? [C.w18, C.w18, C.flex, C.minh18] : isNarrow ? [C.maxw12, C.maxh12] : [C.w13, C.h13]), // 22 & 14
    Lg: apply(isWide ? [C.w20, C.w20, C.flex, C.minh20] : [C.w14, C.h14]), // 20 & 13
    BUBBLE: apply(isWide ? [C.w22, C.w22, C.flex, C.minh22] : isNarrow ? [C.w16, C.h16] : [C.w13, C.h13]), // 22 & 14
    XL: apply(isWide ? [C.w24, C.w24, C.flex, C.minh24] : [C.w20, C.h20]), // 24 & 14
};

export const fullAbs = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
};
export const absCenter = [C.absolute, isIOS && C.mt8, C.itemsCenter, C.top0, C.selfCenter];

export const darkShadow = boxShadow(1, 0, 1, 8, colors.black40, 1);
export const shadow = (col = colors.paleGreyThree, blur = 5, opacity = 1) =>
    /*isWeb ? bgColor(col + '50') :*/ isIOS && boxShadow(blur, 1, 1, blur, col, opacity);

export const topBorder = {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
};

export const sounds = {
    bg: 'http://commondatastorage.googleapis.com/codeskulptor-demos/pyman_assets/ateapill.ogg',
    match: 'http://codeskulptor-demos.commondatastorage.googleapis.com/descent/gotitem.mp3',
    combo: 'http://commondatastorage.googleapis.com/codeskulptor-assets/week7-bounce.m4a',
    combo2: 'http://commondatastorage.googleapis.com/codeskulptor-assets/week7-brrring.m4a',
    wrong: 'https://rpg.hamsterrepublic.com/wiki-images/d/d7/Oddbounce.ogg',
    sword: 'https://freesound.org/people/FunWithSound/sounds/361485/',
    glass: 'http://www.superluigibros.com/downloads/sounds/SNES/SMRPG/wav/smrpg_battle_vigorup.wav',
    level: 'https://www.fesliyanstudios.com/play-mp3/6599',
};

/**Hover*/
export const hover = {
    buttonHovered: {
        backgroundColor: colors.blue + '40',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
    buttonFocused: {
        backgroundColor: colors.blue + '50',
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
        elevation: 10,
    },
    buttonPressed: {
        backgroundColor: colors.blue + '70',
        shadowColor: colors.blue,
        shadowOffset: {
            width: 0,
            height: 9,
        },
        shadowOpacity: 0.5,
        shadowRadius: 12.35,
        elevation: 19,
    },
};
export const URLS = {
    insta: 'https://www.instagram.com/planet.moji/',
    instaGuess: 'https://www.instagram.com/guessmoji/',
    linkedn: 'https://www.linkedin.com/company/planetmoji/',
    guillesierra: 'https://www.guillesierra.com/',
};

export const EMOJI_IMAGE = 'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/7.0.2/img/apple/64/';
/**Todo */ export const EMOJI_IMAGE_XL =
    'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/6.0.0/img/apple/128/';
export const EMOJI_IMAGE_SHEET =
    'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource/6.0.0/img/google/sheets/32.png';
export const imgs = {
    appStore: {uri: 'https://miro.medium.com/max/700/1*V9-OPWpauGEi-JMp05RC_A.png'},
    insta: {
        uri:
            'https://cdn.iconscout.com/icon/free/png-256/instagram-1868978-1583142.png',
    },
    linkdn: {
        uri:
            'https://cdn.iconscout.com/icon/free/png-64/linkedin-162-498418.png',
    },
    guessMoji: {uri: 'https://imgur.com/JHYkihX.png'},
    gPlay: {
        uri:
            'https://media.musclegrid.io/fleetwoodmartialarts.com/uploads/2019/07/05204510/google-play-badge-logo-png-transparent-1024x303.png',
    },
    logo: {uri: 'https://i.imgur.com/RkLvTKl.png'},
    logoHoriz: {uri: 'https://imgur.com/W4DZ3T1.png'},
    mock: isWeb
        ? {uri: 'https://www.guillesierra.com/img/background/planetmojiMock.png'}
        : require('../assets/onbMockup.png'),
    mockMap: {uri: 'https://imgur.com/tJh7SSc.png'},
    fightVideo: 'https://i.imgur.com/QUHKP8i.mp4',
    mockVillage: {uri: 'https://imgur.com/HUo6wgy.png'},
    mockFight: {uri: 'https://i.imgur.com/mbGSDdV.png'},
    mockCollect: {uri: 'https://i.imgur.com/8jfl7CF.png'},
    mock2: require('../assets/mockup2.png'),
    mock3: require('../assets/mockup.png'),
    europe: require('../assets/europe.png'),
    europeWest: require('../assets/europeSp.png'),

    uri: {america: 'https://fvmstatic.s3.amazonaws.com/maps/m/WRLD-SA-01-0002.png'},
    cat: {
        uri:
            'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Mapa_provincial_de_Catalunya.svg/816px-Mapa_provincial_de_Catalunya.svg.png',
    },
    europeWeb: {
        uri: 'https://www.pngkey.com/png/full/205-2054388_792px-blank-map-europe-no-borders-europe-map.png',
    },
    grass: require('../assets/grass.png'),
    farm2: require('../assets/farm.png'),
    barrack: require('../assets/barrack.png'),
    farm: require('../assets/farm2.png'),
    yard: require('../assets/yard.png'),
    yard2: require('../assets/img_1.png'),
    yard3: require('../assets/grass2.png'),
    ground2: {uri: 'https://images.template.net/wp-content/uploads/2017/01/25064122/Seamless-Soil-Texture.jpg'},
    grassGround: {uri: 'https://www.3dxo.com/images/textures/l/wildgrass_2_ur_1024.png'},
    ground: {
        uri:
            'https://img.freepik.com/free-photo/fertile-loam-soil-suitable-planting-soil-texture_38663-528.jpg?size=626&ext=jpg',
    },
    water: {uri: 'https://forums.submit.shutterstock.com/gallery/image/8278-waterpng'},
    water2: {uri: 'https://toppng.com/uploads/preview/water-texture-water-11562884439s4ftxqmf62.png'},
    water3: {uri: 'https://www.3dxo.com/images/textures/s/water_079_c_0085.jpg'},
    grassCut: {uri: 'https://i.ibb.co/PQCv9LJ/grassCut.png'},
    grassText: {uri: 'https://opengameart.org/sites/default/files/oga-textures/79629/256x%20grass%20overlay.png'},
    earth: {uri: 'https://storage3d.com/storage/2007.11/007a5a96f055a4cbef45c5783e6746e0.jpg'},
    grassText2: {
        uri:
            'https://4.bp.blogspot.com/-dd72o32H0NY/Vga4y-I_FEI/AAAAAAAAIKc/URHoGS6B9AM/s1600/Grass%2BSeptember%2Bseamless%2Btexture%2B%25281%2Bof%2B1%2529.jpg',
    },
    treeText: {uri: 'https://opengameart.org/sites/default/files/oga-textures/79629/256x%20grass%20overlay.png'},
};

export const badgeWrapperSm = apply(C.itemsCenter, C.contentCenter, cell.Xs, C.bgWhite, C.shadowMd, C.radius6);
export const badgeWrapperMd = apply(C.itemsCenter, C.contentCenter, cell.Sm, C.bgWhite, C.shadowMd, C.radius8);
export const badgeWrapperLg = apply(C.itemsCenter, C.contentCenter, cell.XL, C.bgWhite, C.shadowMd, C.radius12);
export const badgeWrapper = apply(
    bgColor(colors.paleGreyThree),
    C.radius4,
    C.w5,
    C.h5,
    C.itemsCenter,
    C.justifyCenter,
    shadow(colors.wood, 1),
    C.absolute,
    /* shadow(colors.sand),*/
    C.right1,
    C.top1,
);
