import React from 'react';
import {colors, isBig, isSmall, isWeb} from './gStyles';
import C, {apply} from 'consistencss';

export const titleFont = 'Baloo2'; //isWeb ? 'Geometos | Aquatico' : 'Avenir';
export const bodyFont = 'Karla';//isWeb ? 'Nunito' : 'Open Sans';
export const familyTitle = {fontFamily: titleFont};
export const familyBody = {fontFamily: bodyFont};
export const subtitleFont = isWeb ? 'Aquatico' : 'Avenir';

export const font = {
    h1: apply(familyTitle, C.font8, C.line10, C.weightMedium),
    h1thin: apply(familyTitle, C.font8, C.line10),
    h2: apply(familyTitle, C.font6, C.line8, C.weightMedium),
    h2thin: apply(familyTitle, C.font6, C.line8),
    h3: apply(familyTitle, C.font4, C.line6, C.weightMedium),
    body: apply(familyBody, C.font4, C.line6),
    bodyBold: apply(familyBody, C.font4, C.line6),
    caption: apply(familyBody, C.font3, C.line4),
    captionBold: apply(familyBody, C.font3, C.line4),
    badge: apply(familyBody, C.uppercase, C.weightBold, C.line3, {letterSpacing: 0.4, fontSize: 10}),
};


export const strikeThrough = {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
};

/**Fonts @deprecated, use @font instead*/
export const fonts = {
    mainTitle: {
        fontFamily: titleFont,
        fontSize: 36,
        fontWeight: '500',
        lineHeight: 43.2,
    },
    heroTitle: {
        fontFamily: titleFont,
        fontSize: isSmall ? 28 : 48,
        lineHeight: isSmall ? 38 : 58,
        fontWeight: '500',
    },
    cta1: {
        fontFamily: titleFont,
        fontSize: 20,
        fontWeight: '600',
        lineHeight: 30,
    },
    title1: {
        fontFamily: titleFont,
        fontSize: 20,
        fontWeight: '800',
        lineHeight: 24,
    },
    highlight: {
        fontFamily: bodyFont,
        fontSize: 20,
    },
    textBold: {
        fontFamily: bodyFont,
        fontSize: 16,
        fontWeight: 'bold',
    },
    body1: {
        fontFamily: bodyFont,
        fontSize: 16,
        lineHeight: 22.4,
    },
    subtitle: {
        fontFamily: bodyFont,
        fontSize: 14,
        lineHeight: 19.6,
    },
    littleText: {
        fontFamily: bodyFont,
        fontSize: 10,
        fontWeight: 'bold',
        lineHeight: 14,
    },

    /**Old times...*/
    /*mainTitle: {
        fontFamily: titleFont,
        textTransform: 'uppercase',
        fontSize: isBig ? 32 : 30,
        fontWeight: '900',
        fontStyle: 'normal',
        letterSpacing: 2,
        color: colors.greyDark,
    },*/
    paragraph: {
        fontFamily: bodyFont,
        fontSize: 18,
        /*fontWeight: '900',*/
        fontStyle: 'normal',
        /*letterSpacing: -1,*/
        color: colors.grey,
    },
    title: {
        fontFamily: titleFont,
        fontSize: 26,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        letterSpacing: -1,
        color: colors.greyDark,
    },
    /*title1: {
        fontFamily: titleFont,
        textTransform: 'uppercase',
        fontSize: isBig ? 28 : 24,
        fontWeight: 'bold',
        fontStyle: 'normal',
        letterSpacing: -1,
        color: colors.greyDark,
    },*/
    title2: {
        fontFamily: titleFont,
        /*textTransform: 'uppercase',*/
        fontSize: isBig ? 20 : 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        letterSpacing: 2,
        color: colors.greyDark,
    },
    /*subtitle: {
        fontFamily: subtitleFont,
        fontSize: isBig ? 20 : 16,
        fontWeight: '600',
        fontStyle: 'normal',
        letterSpacing: 1,
        textAlign: 'center',
        color: colors.greyDark,
    },*/
    subtitleSmall: {
        fontFamily: subtitleFont, //'Papyrus', //'Avenir Next',
        fontSize: 11,
        fontWeight: 'bold',
        fontStyle: 'normal',
        /*letterSpacing: -1,*/
        textAlign: 'center',
        color: colors.greyDark,
    },
    caption: {
        fontFamily: subtitleFont,
        fontSize: isBig ? 13 : 11,
        fontWeight: '500',
        fontStyle: 'normal',
        letterSpacing: 0,
        /*color: colors.black,*/
    },
    digit: {
        fontFamily: subtitleFont,
        fontSize: 14,
        fontWeight: '500',
        fontStyle: 'normal',
        letterSpacing: 3,
        /*color: colors.black,*/
    },
    digit2: {
        fontFamily: subtitleFont,
        fontSize: 12,
        fontWeight: '400',
        fontStyle: 'normal',
        letterSpacing: 2,
        /*  color: colors.black,*/
    },
    /*body1: {
        fontFamily: bodyFont,
        fontSize: isBig ? 20 : 16,
        /!*fontWeight: '500',*!/
        fontStyle: 'normal',
        /!* letterSpacing: 3,*!/
        color: colors.greyDark,
    },*/
    body2: {
        fontFamily: bodyFont,
        fontSize: isBig ? 16 : 14,
        /*fontWeight: '500',*/
        fontStyle: 'normal',
        /*letterSpacing: 3,*/
        color: colors.greyDark,
    },
    input: {
        fontFamily: subtitleFont,
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        letterSpacing: 0,
        color: colors.blueGrey,
    },
};
