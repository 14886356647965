import gFire from 'firebase';
import {makeAutoObservable} from 'mobx';
import {profile} from '../App';
import {isWeb} from '../design/gStyles';
import {levelsMap} from '../utils/levels';
import {boardsMap, BoardStore} from './boardStore';
import {INIT_MOVES} from './HarvestStore';
import {findMojiRef} from './profileStore';

const boardInitState = {score: 0, currLevel: 1, moves: 8};

export const FindStore = ({score, moves, currLevel, allowDiag} = boardInitState) =>
    makeAutoObservable({
        currLevel: currLevel,
        setLevel(level = 5) {
            this.currLevel = level + 1;
        },

        collected: [],

        /**Bonus*/
        moves: moves,
        addMoves(mov = -1) {
            this.moves += mov;
        },

        /**Shuffle*/
        hints: 1,
        addHint(hints = -1) {
            this.hints += hints;
        },
        currentBoard: BoardStore(boardsMap.MEMOJI, levelsMap.find[0], 12, false, false),
        shuffles: 1,
        setShuffles() {
            this.shuffles--;
        },
        shuffle(level = this.currLevel + 1, target = '🍓') {
            this.currLevel = level;
            this.currentBoard = BoardStore(boardsMap.MEMOJI, levelsMap.find[level], level + 6, false, false);
            this.currentBoard.setCell({
                icon: target,
                overwrite: true,
                id: Math.random() * this.currentBoard.size * this.currentBoard.size,
            });
        },

        /**Analytics, not yet configured on Mobile*/
        sendAnalytics(icon) {
            if (isWeb) {
                const analytics = {
                    id: profile.username || 'New User v3',
                    img: profile?.img || '',
                    gender: profile.gender,
                    flag: profile.flag,
                    icon: icon,
                    collected: this.collected,
                    level: this.currLevel,
                    score: profile.score,
                    size: this.collected?.size || 1,
                    timeStamp: new Date().getTime(),
                };
                gFire.analytics().logEvent('FindMoji', analytics);
                findMojiRef.add(analytics);
            }
        },
        cleanCollect(level) {
            this.collected = [];
            this.shuffles = 1;
            this.hints = 1;
            profile.addScore(level * level);
            this.sendAnalytics(this.currentEmoji?.icon || '');
        },
        addFind(emoji, level) {
            this.collected.push(emoji);
            profile.addScore(level * 2);
        },

        /**History & Analytics candidates*/
        currentEmoji: null,
        strike: 0,
        prevIndex: null,
        matchPair(emoji, index) {
            if (this.currentEmoji) {
                //Second time ✅
                if (this.currentEmoji === emoji) {
                    this.currentBoard.cells[index].toggleActive(true);
                    this.prevIndex = null;
                    this.strike++;
                    profile.addScore(this.strike * 2);
                    this.addMoves(1);
                    this.collected.push(emoji);
                } else {
                    //Fail ❌
                    this.addMoves();
                    this.strike = 0;
                    this.currentBoard.cells[this.prevIndex].toggleActive(false);
                    this.currentBoard.currCell.toggleActive(false);
                }
                this.currentEmoji = null;
            } else {
                //First time ✅
                this.currentEmoji = emoji;
                this.prevIndex = index;

                this.currentBoard.cells[index].toggleActive(true);
            }
        },
        lastHarvest: {}, //score, icon, size, isCombo
        maxCombo: {size: 0, icon: ''}, //icon, size

        reset(level) {
            this.sendAnalytics(this.currentEmoji?.icon || '');
            this.currentEmoji = '';
            this.collected = [];
            this.hints = 1;
            this.strike = 0;
            this.currLevel = level;
            this.moves = INIT_MOVES;
            this.currentBoard = BoardStore(
                boardsMap.MEMOJI,
                levelsMap.find[level % levelsMap.find.length],
                level + 1,
                false,
                true,
            );
        },
    });
