import {useNavigation} from '@react-navigation/core';
import C, {apply} from 'consistencss';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {SafeAreaView, View} from 'react-native';
import {profile} from '../App';
import {ResourcesMap} from '../comp/Box';

import Cell from '../comp/Cell';
import EmojiBubble from '../comp/EmojiBubble';
import {absCenter, bubblePos, colors, isWide, shadow} from '../design/gStyles';
import {screens} from '../routes';
import {laussane, MapCellWrapper, MapWrapper} from '../utils/MapUtils';
import {natureToResource} from '../utils/sets';

export default observer(() => {
    const currentBoard = profile.boards.villageMap;
    const {navigate, goBack} = useNavigation();
    return (
        <SafeAreaView style={apply(C.flex, C.hFull, C.wFull)}>
            <MapWrapper zoomMin={currentBoard.zoomMin} zoomMax={currentBoard.zoomMax} initPos={laussane}>
                {currentBoard.cells.map((item, index) => {
                    const bgColor =
                        currentBoard.currCellId === index
                            ? colors.white + (isWide ? '80' : '60')
                            : (!item.building && !item.icon) || item.building
                            ? colors.white + (isWide ? '50' : '30')
                            : 'transparent';
                    return (
                        <MapCellWrapper
                            lat={item.coord.latitude}
                            lng={item.coord.longitude}
                            onPress={() => {
                                currentBoard.setCurrent(index);
                                if (item.building) {
                                    navigate(item.isEvil ? screens.Battle.name : screens.Village.name, {initPos: item.coord});
                                } else if (item.availResources) {
                                    navigate(screens.Collect.name, {
                                        harvestList: natureToResource(item.icon),
                                        practiceMode: false,
                                        isResource: true, //item === harvest,
                                        level: index,
                                    });
                                } else {
                                    if (!item.building && !item.icon) {
                                        profile.buildModal.toggleModal();
                                    }
                                }
                            }}
                            coord={item.coord}>
                            <Cell
                                lat={item.coord.latitude}
                                lng={item.coord.longitude}
                                bg={bgColor}
                                cShadow={shadow(item.building ? (item.isEvil ? colors.enemy : colors.blue) : 'transparent', 8)}
                                index={index}
                                showRes
                                wrapStyle={[C.radius2]}
                                currCellId={currentBoard.currCellId}
                                item={item}
                            />
                        </MapCellWrapper>
                    );
                })}
            </MapWrapper>

            {/**Resources*/}
            <View style={absCenter}>
                <ResourcesMap list={profile.resources}/>
            </View>
            <View style={[bubblePos]}>
                <EmojiBubble
                    showOps
                    onPressWorld={() => navigate(screens.Planet.name)}
                    onPressSettings={() => navigate(screens.Profile.name)}
                    onPressBuildings={(item) => profile.buyBuilding(item, currentBoard)}
                    /*onPressUnits={(item) => profile.buyUnit(item, currentBoard)}*/
                    currProfile={profile}
                    emoji={profile.emoji}
                    flag={profile.flag}
                />
            </View>
        </SafeAreaView>
    );
});
