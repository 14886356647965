import {useNavigation} from '@react-navigation/core';
import C from 'consistencss';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ScrollView} from 'react-native';
import {profile} from '../App';
import {Footer, OnbHeader, TitleHeader} from '../comp/Header';
import ScoreBoard from '../comp/ScoreBoard';
import {isIOS} from '../design/gStyles';
import {safeBack} from '../routes';

export default observer(({emoji, title, subtitle, GameComp, scoreRef}) => {
    const navigation = useNavigation();

    return (
        <ScrollView contentContainerStyle={[C.itemsCenter, C.minhFull, C.wFull, C.bgWhite, isIOS && C.py6]}>
            <OnbHeader
                currProfile={profile}
                navigate={navigation.navigate}
                onPressBack={() => safeBack()}
                sound={profile.soundsOn}
            />
            <TitleHeader title={title} emoji={emoji} subtitle={subtitle}/>
            {GameComp}

            {scoreRef && <ScoreBoard dbRef={scoreRef}/>}

            <Footer/>
        </ScrollView>
    );
});
