import C from 'consistencss';
import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {deviceHeight, deviceWidth, isWide} from '../design/gStyles';
import {font, fonts} from '../design/fonts';
import {Button} from './Box';
import {EmojiCompat} from './WrapMoji';
import {BgShape, BgShapeHoriz} from '../design/shapes';
import {useNavigation} from '@react-navigation/native';

export const SideInfo = ({Video, icon, title, text, ExtraComp, cta, img, screen, index}) => {
    const isLeft = index % 2 === 0;
    const {navigate} = useNavigation();

    const Img =
        Video ||
        (() => (
            <TouchableOpacity
                onPress={() => {
                    navigate(screen);
                }}>
                <BgShape
                    /*col={colors.oceanBg}*/
                    wrapStyle={{position: 'absolute', left: isLeft ? '-8vw' : '12vw', top: isWide ? '-14vh' : '-6vh'}}
                />
                <BgShapeHoriz
                    /*col={colors.oceanBg}*/
                    wrapStyle={{position: 'absolute', left: isLeft ? '-8vw' : '12vw', top: isWide ? '-14vh' : '-6vh'}}
                />
                <Image
                    resizeMode={'center'}
                    style={[
                        C.shadowLg,
                        {width: deviceWidth * 0.4, minHeight: deviceHeight * (isWide ? 0.6 : 0.5)},
                        C.flex,
                        C.radius4,
                        /*isLeft ? C.left_6 : C.right_6,*/
                    ]}
                    source={img}
                    onPress={() => navigate(screen)}
                />
            </TouchableOpacity>
        ));
    const Info = ({isLeft}) => (
        <View
            style={[
                C.p4,
                C.itemsCenter,
                C.my4,
                C.justifyCenter,
                /*!isWide && */ isLeft ? C.itemsStart : C.itemsEnd,
                C.flex,
                {width: deviceWidth * 0.6},
            ]}>
            <View style={[C.row, C.itemsCenter, C.my2]}>
                <EmojiCompat emoji={icon} sizeNum={1}/>
                <Text style={[font.h1, C.ml2, C.selfCenter]}>{title} </Text>
            </View>
            <Text style={[fonts.body1, C.my2, isLeft ? C.alignLeft : C.alignRight]}>{text}</Text>
            {ExtraComp && (
                <Text style={[C.row, C.mt2, C.maxwFull, isLeft ? C.alignLeft : C.alignRight]}>
                    <ExtraComp/>
                </Text>
            )}
            {cta && <Button text={cta} onPress={() => navigate(screen)}/>}
        </View>
    );

    return (
        <View style={[/*!isNarrow &&*/ C.row, isWide ? C.my_6 : C.my_2]}>
            {/**Image one*/}
            <View>{isLeft ? <Img/> : <Info isLeft={isLeft}/>}</View>
            <View>{isLeft ? <Info isLeft={isLeft}/> : <Img/>}</View>
        </View>
    );
};
